import React, { useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";
import { REACT_PERSON_MANAGER_LIST } from "@/router/ReactEndPoints";
import RightViewLayout from "@/components/RighViewLayout";
import ProfileUplod from "@/components/UploadImg";
import CmpFormik from "@/components/Retail-components/CmpFormik";
import { useFormik } from "formik";
import { createValidation } from "@/util/validationUtils";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import SubmitButton from "@/components/Retail-components/SubmitButton";
import {
  loadClinetData,
  loadRoleData,
  loadOutletData,
  getFormConfig,
  getUserList,
} from "./index";
import OutletsCards from "./Component/OutletsCards";
import RolesCards from "./Component/RolesCards";
import { useStateUserInfo } from "@/redux/hooks/userInfo";
import { createPerson, editPerson } from "@/services/PersonService";
function AddPerson() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userInfor = useStateUserInfo();
  const location = useLocation();
  const { type, currentItem } = location.state;
  const { enqueueSnackbar } = useSnackbar();
  const [clients, setClients] = useState([]); // 零售商数据
  const [outlets, setOutlets] = useState([]); //门店列表数据
  const [roles, setRoles] = useState([]); //权限角色数据
  const [profile, setProfile] = useState(); // 上传商品图片
  const [isShowOutlet, setIsShowOutlet] = useState(false); // 是否显示门店卡片
  const [isLoading, setIsLoading] = useState(false);
  const formConfig = getFormConfig(clients, t, type); // 配置项
  let initialValues = {};
  if (type === "Edit") {
    initialValues = {
      id: currentItem?.id,
      clientId: currentItem?.clientId,
      firstName: currentItem?.firstName,
      lastName: currentItem?.lastName,
      personId: currentItem?.personId,
      phone: currentItem?.phone,
      email: currentItem?.email,
      joinDate: currentItem?.joinDate,
      password: currentItem?.password,
      confirmPassword: currentItem?.confirmPassword,
      roleId: currentItem?.roleId,
      outletIds: currentItem?.outletIds,
    };
  } else {
    initialValues = {
      clientId: "",
      firstName: "",
      lastName: "",
      personId: "",
      phone: "",
      email: "",
      joinDate: "",
      password: "",
      confirmPassword: "",
      roleId: "",
      outletIds: [],
    };
  }

  useEffect(() => {
    if (type === "Edit") {
      getUserList(currentItem?.id, addFormik, setProfile);
    }
  }, []);

  const handleValidationErrors = (values) => {
    if (values.roleId === "" || values.roleId === null) {
      enqueueSnackbar(t("PCS93"), {
        variant: "warning",
      });
      return true;
    }

    if (values.clientId) {
      if (values.outletIds.length === 0) {
        enqueueSnackbar(t("PCS80"), {
          variant: "warning",
        });
        return true;
      }
    }

    return false;
  };

  const handleResponse = (res) => {
    if (
      res?.data?.code === "LVLI0000" ||
      res?.data?.code === "OMSI0231" ||
      res?.data?.code === "LVLI0008"
    ) {
      enqueueSnackbar(res?.data?.message, { variant: "success" });
      navigate(REACT_PERSON_MANAGER_LIST);
    } else {
      enqueueSnackbar(res?.data?.message, { variant: "error" });
    }
  };

  const addFormik = useFormik({
    initialValues: initialValues,
    validationSchema: createValidation(formConfig),
    onSubmit: (values) => {
      setIsLoading(true);
      if (handleValidationErrors(values)) return;
      const submitFunction = type === "Edit" ? editPerson : createPerson;
      submitFunction(values).then((res) => {
        handleResponse(res);
        setIsLoading(false);
      });
    },
  });

  useEffect(() => {
    if (!addFormik.values.clientId) {
      setIsShowOutlet(false);
    } else {
      setIsShowOutlet(true);
    }

    loadClinetData(addFormik.values.clientId, setClients);
    loadRoleData(addFormik.values.clientId, setRoles);
    loadOutletData(addFormik.values.clientId, setOutlets);
  }, [addFormik.values.clientId]);

  let parentId = addFormik.values.parentId;
  const shouldShowCard =
    userInfor?.roleCode === "SUPER_ADMIN" ||
    (userInfor?.roleCode !== "CLIENT_USER" && parentId !== "0") ||
    (userInfor?.roleCode === "CLIENT_ADMIN" && parentId !== "0") ||
    userInfor?.roleCode === "TENANT_ADMIN";

  return (
    <RightViewLayout
      title={type === "Edit" ? t("LVLRCP017") : t("LVLRCP011")}
      navigateBack={REACT_PERSON_MANAGER_LIST}
    >
      <form noValidate onSubmit={addFormik.handleSubmit}>
        <Card>
          <Grid display={"flex"}>
            <Grid m={10}>
              <ProfileUplod
                imageInfo={t("LVLRCP012")}
                imageSize={t("LVLRCP013")}
                name={"profilePhoto"}
                style={{ borderStyle: "none !important" }}
                onsetProfile={(profile) => {
                  addFormik.setFieldValue(
                    "profilePhoto",
                    profile !== null || profile !== undefined
                      ? profile?.base64?.split(";base64,").pop()
                      : ""
                  );
                  setProfile(profile?.base64);
                }}
                profilePhoto={profile}
              />
            </Grid>
            <Grid flex={1} p={15} pt={5}>
              <CmpFormik
                sx={6}
                formik={addFormik}
                formConfig={formConfig}
              ></CmpFormik>
            </Grid>
          </Grid>
        </Card>

        {isShowOutlet && (
          <OutletsCards
            Arrary={outlets}
            addFormik={addFormik}
            name="outletIds"
          ></OutletsCards>
        )}

        {shouldShowCard && (
          <RolesCards
            Arrary={roles}
            addFormik={addFormik}
            name="roleId"
          ></RolesCards>
        )}

        <SubmitButton
          type="submit"
          backRoute={"/person/list"}
          isLoading={isLoading}
        ></SubmitButton>
      </form>
    </RightViewLayout>
  );
}

export default AddPerson;
