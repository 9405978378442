import React, { useEffect, useState } from "react";
import { Box, InputLabel, TextField, FormHelperText } from "@mui/material";

export default function CustomInput(props) {
  const {
    dateFormat = "dd-MM-yyyy",
    formik,
    name,
    error,
    disabled = false,
    isClear = true,
    labelPostion,
    value,
    ...orther
  } = props;

  const [data, setData] = useState("");
  useEffect(() => {
    setData(value);
  }, [value]);

  return (
    <Box letiant="standard" style={{ width: "100%" }}>
      <InputLabel
        shrink
        htmlFor="bootstrap-input"
        style={{ paddingLeft: "0px", fontSize: "20px", marginTop: "2px" }}
      >
        {props.required ? (
          <span style={{ color: "red", marginRight: "8px" }}>*</span>
        ) : null}
        {props.label}
      </InputLabel>
      <TextField
        {...props}
        autoComplete="off"
        fullWidth={true}
        sx={{
          "& .MuiOutlinedInput-input.MuiInputBase-inputSizeSmall": {
            fontSize: "14px",
          },
        }}
        type={props.type}
        value={data}
        name={name}
        error={error}
        label={""}
        format={dateFormat}
        onChange={(e) => {
          formik.setFieldValue(name, e);
          setData(e);
        }}
      />
      {((formik?.touched[name] && formik?.errors[name]) || error) && (
        <FormHelperText error id={`standard-weight-helper-text-${name}`}>
          {formik?.errors[name] || error}
        </FormHelperText>
      )}
    </Box>
  );
}

CustomInput.defaultProps = {
  validation: "none",
  regex: "none",
};
