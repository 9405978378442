import { Box, Grid, Paper, Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Space } from "antd";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ReactApexChart from "react-apexcharts";
import FormatPreValue from "./FormatPreValue";
import {
  getValue,
  preRange,
  preLastYear,
  gainAllDateBetRange,
  setOption,
} from "./utils";

const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);

export default function AgeDistribution() {
  const [compareType, setCompareType] = useState("none");
  const [rangeValue, setRangeValue] = useState([]);
  const [preRangeValue, setPreRangeValue] = useState([]);

  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [dateArr, setDateArr] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (compareType === "none") {
      loadData();
    } else {
      loadCompareData();
    }
  }, [compareType, dateArr]);

  const renderData = () => {
    return dateArr.map(() => {
      return getValue();
    });
  };

  const loadData = () => {
    const series = [
      {
        type: "line", //render a line chart for this data
        name: "0-17",
        data: renderData(),
      },
      {
        type: "line", //use column chart here.
        name: "18-34",
        data: renderData(),
      },
      {
        type: "line", //use column chart here.
        name: "35-54",
        data: renderData(),
      },
      {
        type: "line", //use column chart here.
        name: "55+",
        data: renderData(),
      },
    ];
    const options = {
      xaxis: {
        categories: dateArr,
      },
      stroke: {
        width: 1,
      },
      color: [
        "#5470c6",
        "#91cc75",
        "#fac858",
        "#ee6666",
        "#73c0de",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc",
      ],
    };
    setOption(options, dateArr);
    setSeries(series);
    setOptions(options);
  };

  const loadCompareData = () => {
    const series = [
      {
        type: "line", //render a line chart for this data
        name: "0-17",
        data: renderData(),
      },
      {
        type: "line", //render a line chart for this data
        name: "0-17(Pre. Period)",
        data: renderData(),
      },

      {
        type: "line", //use column chart here.
        name: "18-34",
        data: renderData(),
      },

      {
        type: "line", //use column chart here.
        name: "18-34(Pre. Period)",
        data: renderData(),
      },
      {
        type: "line", //use column chart here.
        name: "35-54",
        data: renderData(),
      },
      {
        type: "line", //use column chart here.
        name: "35-54(Pre. Period)",
        data: renderData(),
      },
      {
        type: "line", //use column chart here.
        name: "55+",
        data: renderData(),
      },

      {
        type: "line", //use column chart here.
        name: "55+(Pre. Period)",
        data: renderData(),
      },
    ];
    const options = {
      xaxis: {
        categories: dateArr,
      },
      color: [
        "#5470c6",
        "#91cc75",
        "#fac858",
        "#ee6666",
        "#73c0de",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc",
      ],
      stroke: {
        width: 1,
      },
    };
    setOption(options, dateArr);
    setSeries(series);
    setOptions(options);
  };

  const handleChange = (e) => {
    let value = e.target.value;
    setCompareType(value);
    if (value === "none") {
      setPreRangeValue([]);
    } else if (value === "previous") {
      setPreRangeValue(preRange(rangeValue));
    } else if (value === "LastYear") {
      setPreRangeValue(preLastYear(rangeValue));
    }
  };

  const RangePickerChange = (e) => {
    setRangeValue(e);
    if (e && e.length === 2) {
      if (compareType === "none") {
        setPreRangeValue([]);
      } else if (compareType === "previous") {
        setPreRangeValue(preRange(e));
      } else if (compareType === "LastYear") {
        setPreRangeValue(preLastYear(e));
      }
      let attr = gainAllDateBetRange(e);
      setDateArr(attr);
    } else {
      setDateArr([]);
    }
  };

  let boxStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Box style={{ height: "100%" }} p={2} component={Paper}>
      <Box m={2}>
        <Typography
          style={{
            fontWeight: 400,
          }}
          variant="title"
        >
          Age Distribution
        </Typography>
      </Box>

      <Grid
        m={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            m: 1,
          }}
        >
          <Typography sx={{ mr: 2 }}>Date Range</Typography>
          <RangePicker
            value={rangeValue}
            onChange={(e) => {
              RangePickerChange(e);
            }}
            format="DD/MM/YYYY"
          />
        </Grid>

        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            m: 1,
          }}
        >
          <Typography sx={{ mr: 2 }}>Compare with</Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={compareType}
            label=""
            sx={{
              p: 0,
            }}
            onChange={handleChange}
          >
            <MenuItem value={"none"}>None</MenuItem>
            <MenuItem value={"previous"}>Previous Period</MenuItem>
            <MenuItem value={"LastYear"}>Last Year</MenuItem>
          </Select>
        </Grid>

        {compareType !== "none" && (
          <Grid m={1}>
            <RangePicker
              value={preRangeValue}
              disabled={true}
              format="DD/MM/YYYY"
            />
          </Grid>
        )}
      </Grid>

      <Box>
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
        />
      </Box>
      {compareType !== "none" && (
        <Box>
          <Typography sx={{ m: 2 }}>
            Click on the age statistics below to see the difference on the chart
            above
          </Typography>
        </Box>
      )}
      {compareType !== "none" && (
        <Grid
          sx={{
            justifyContent: "space-around",
          }}
          container
        >
          <Grid style={boxStyle} p={2} item>
            <FormatPreValue
              style={{ fontSize: "20px" }}
              value={getValue(true)}
              isIcon={true}
            ></FormatPreValue>
            <Typography sx={{ mt: 2 }}>Male Change</Typography>
          </Grid>
          <Grid style={boxStyle} p={2} item>
            <FormatPreValue
              style={{ fontSize: "20px" }}
              value={getValue(true)}
              isIcon={true}
            ></FormatPreValue>
            <Typography sx={{ mt: 2 }}>Female Change</Typography>
          </Grid>
          <Grid style={boxStyle} p={2} item>
            <FormatPreValue
              style={{ fontSize: "20px" }}
              value={getValue(true)}
              isIcon={true}
            ></FormatPreValue>
            <Typography sx={{ mt: 2 }}>Overall Change</Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
