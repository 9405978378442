import React, { useEffect, useState, useRef } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { devicesMateDataStyle } from "../dashboard/css/DemoGraphicDataAnaysis";
import { getDeviceList } from "@/services/DeviceService";
import { Box, Paper, Typography, TextField, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FixedSizeList as List } from "react-window";
import { ReactComponent as TickMarkImg } from "@/assets/images/icon_tickmark.svg";
import { useSnackbar } from "notistack";

function DeviceList(props) {
  const {
    devices,
    setDevices,
    selectedDevice,
    setSelectedDevice,
    setIsLoading,
    isLoading,
  } = props;
  const { t } = useTranslation();
  const deviceNum = localStorage.getItem("DEVICENUM");
  //查询设备方法
  //获取记录的 device的数量，用来比较现在是否是查询过了
  const [serachDevice, setSerachDevice] = useState(0);
  const [serachSn, setSerachSn] = useState("");

  const [totalPages, setTotalPages] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 15,
  });

  useEffect(() => {
    loadDevicesData();
  }, [filters]);

  //Deveice List 表格数据
  const loadDevicesData = () => {
    setIsLoading(true);
    getDeviceList({ ...filters })
      .then((res) => {
        if (res?.data?.data) {
          let devices = res?.data?.data?.objects || [];
          const devicesValues = devices.filter(
            (item) =>
              item.deviceType === "sr-aibox" ||
              item.deviceType === "SmartRetail" ||
              item.deviceType === "sr_ai" ||
              item.deviceType === "sr-ai" ||
              item.deviceType === "T1"
          );

          setDevices((prevDevices) => [...prevDevices, ...devicesValues]);
          localStorage.setItem("DEVICENUM", devices.length);
          setTotalPages(res?.data?.data?.totalPages);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const [initData, setInitData] = useState(false);
  // 依赖 devices 变化来设置 initData
  useEffect(() => {
    if (devices && devices.length > 0 && !initData) {
      setInitData(true);
    }
  }, [devices, initData]);

  // 在 initData 为 true 时，只执行一次
  useEffect(() => {
    if (initData && devices && devices.length > 0) {
      setSelectedDevice(devices[0]);
    }
  }, [initData]);

  const handleSubmit = () => {
    if (serachSn === "") {
      enqueueSnackbar(t("PCS90"), {
        variant: "info",
      });
      return;
    } else {
      let params = {
        sn: serachSn,
        pageNumber: 1,
        pageSize: 100000,
      };
      getDeviceList(params)
        .then((res) => {
          if (res?.data?.data) {
            let devices = res?.data?.data?.objects || [];
            const devicesValues = devices.filter(
              (item) =>
                item.deviceType === "sr-aibox" ||
                item.deviceType === "SmartRetail" ||
                item.deviceType === "sr_ai" ||
                item.deviceType === "sr-ai"
            );

            setDevices(devicesValues);
            setSerachDevice(devicesValues.length);

            if (devicesValues.length === 0) {
              enqueueSnackbar(t("PCS117"), {
                variant: "info",
              });
            }
            if (devicesValues) {
              let select = devicesValues[0];
              setSelectedDevice(select);
            }
          }

          if (res?.data?.data.length === 0) {
            setSerachDevice(1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleScroll = ({ scrollDirection, scrollOffset }) => {
    const containerHeight = 600;
    const itemHeight = 44;
    const totalContentHeight = devices.length * itemHeight;

    // Check if bottom is reached
    if (scrollOffset + containerHeight >= totalContentHeight && !isLoading) {
      if (filters.pageNumber <= totalPages) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          pageNumber: prevFilters.pageNumber + 1,
        }));
      } else {
        return;
      }
    }

    // Check if top is reached
    // if (scrollOffset === 0 && !isLoading && filters.pageNumber > 1) {
    //   setFilters((prevFilters) => ({
    //     ...prevFilters,
    //     pageNumber: prevFilters.pageNumber - 1,
    //   }));
    // }
  };

  return (
    <Box
      container={true}
      spacing={2}
      flexDirection={"column"}
      component={Paper}
      elevation={1}
      variant="elevation"
      display={"flex"}
      width={"100%"}
      sx={devicesMateDataStyle}
    >
      <Box m={1}>
        <Typography variant="subtitle3">{t("LVLDV0008")}</Typography>
      </Box>

      <Box
        sx={{
          margin: "0px 10px",
        }}
      >
        <TextField
          size="small"
          label={t("LVLRC0039")}
          placeholder={t("LVLRC0045")}
          value={serachSn}
          onChange={(e) => setSerachSn(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {(serachDevice < deviceNum && serachDevice !== 0) ||
                serachSn === null ? (
                  <ClearIcon
                    style={{ cursor: "pointer", color: "#666" }}
                    onClick={() => {
                      setSerachSn("");
                      setDevices([]);
                      loadDevicesData();
                      setSerachDevice(0);
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "#333")}
                    onMouseLeave={(e) => (e.target.style.color = "#666")}
                  />
                ) : (
                  <SearchIcon
                    style={{ cursor: "pointer", color: "#666" }}
                    onClick={handleSubmit}
                    onMouseEnter={(e) => (e.target.style.color = "#333")}
                    onMouseLeave={(e) => (e.target.style.color = "#666")}
                  />
                )}
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Box>

      <List
        className="List"
        height={600}
        itemCount={devices.length}
        itemSize={44}
        width={"100%"}
        onScroll={handleScroll}
        overflowX={"none"}
      >
        {({ index, style }) => {
          const device = devices[index];
          return (
            <Box
              ml={1}
              style={{
                ...style,
                width: "90%",
              }}
            >
              <Box
                key={device?.id}
                component={Paper}
                elevation={1}
                variant="elevation"
                mt={1}
                ml={1}
                p={0.5}
                border={"1px solid #1487CA"}
                onClick={() => setSelectedDevice(device)}
              >
                <Box
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "5px",
                  }}
                  display={"flex"}
                  flexDirection={"row-reverse"}
                >
                  <Box item mt={-1} mr={-1}>
                    {selectedDevice && selectedDevice.id === device?.id && (
                      <TickMarkImg height={12} />
                    )}
                  </Box>
                </Box>
                <Box
                  style={{
                    width: "100%",
                  }}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Box
                    item
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                    textOverflow={"ellipsis"}
                  >
                    <Tooltip title={device?.sn} arrow placement="bottom">
                      <Typography
                        variant="dashboardDeviceLabel"
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                        textOverflow={"ellipsis"}
                      >
                        {device?.sn}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        }}
      </List>
    </Box>
  );
}

export default DeviceList;
