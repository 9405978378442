import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import OnboardLayout from "@/layout/components/OnboardLayout";
import CustomInput from "../../components/CustomInput";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import { useSnackbar } from "notistack";
import CommonUtil from "../../util/CommonUtils";
import { useTranslation } from "react-i18next";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [payload, setPayload] = useState({
    email: "",
  });

  const [error, setError] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    setPayload({
      ...payload,
      [name]: e.target.value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };
  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.email)) {
      setError({
        ...error,
        email: t("LVL0001"),
      });
      return;
    }
    if (!CommonUtil.isValidEmail(payload.email)) {
      setError({
        ...error,
        email: t("LVL0003"),
      });
      return;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      UserService.forgotPassword(payload.email).then((res) => {
        if (res?.data?.code === "NFSI0002") {
          enqueueSnackbar(res?.data?.message, { variant: "success" });
        } else enqueueSnackbar(res?.data?.message, { variant: "error" });
        navigate("/");
      });
    }
  };

  return (
    <OnboardLayout>
      <Typography fontSize={25} fontWeight={550} pt={4}>
        {t("LVLDAC0034")}
      </Typography>
      <Typography pt={2}>{t("PLACE011")}</Typography>
      <Box pt={3}>
        <CustomInput
          required
          label={t("LVLRCP004")}
          name="email"
          size="small"
          value={payload.email}
          error={error.email}
          helperText={error.email}
          placeholder={t("PLACE012")}
          validation="email"
          handleChange={handleChange}
          inputProps={{
            maxLength: 36,
          }}
        />
      </Box>
      <Box pt={6}>
        <Button fullWidth={true} variant="contained" onClick={handleSubmit}>
          {t("LVLDB0028")}
        </Button>
      </Box>
      <Box pt={1} pb={4}>
        <Button
          fullWidth={true}
          variant="outlined"
          sx={{ color: "black" }}
          onClick={() => navigate("/")}
        >
          {t("LVLDB0029")}
        </Button>
      </Box>
    </OnboardLayout>
  );
}
