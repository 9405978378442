/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState, useMemo } from "react";
import * as echarts from "echarts";
import "echarts-liquidfill";
import { useTranslation } from "react-i18next";
const AgeLineCharts = ({ ageData }) => {
  const chartRef = useRef(null);
  const { t } = useTranslation();
  const countArray = Object.values(ageData);

  let time = countArray[0]?.map((item) => item.date);
  let num0 = countArray[0]?.map((item) => item.count);
  let num12 = countArray[1]?.map((item) => item.count);
  let num18 = countArray[2]?.map((item) => item.count);
  let num35 = countArray[3]?.map((item) => item.count);
  let num55 = countArray[4]?.map((item) => item.count);

  const getOptions = () => {
    let option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
          crossStyle: {
            type: "solid",
            color: "#999",
          },
        },
      },
      toolbox: {},
      legend: {
        data: ["0 - 11", "12 - 17", "18 - 34", "35 - 54", "55 +"],
        bottom: "0",
        itemWidth: 10,
      },
      xAxis: [
        {
          type: "category",
          data: time?.map((item) => item?.replace(/-/g, "/")),
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          showSymbol: false,
          axisLabel: {
            formatter: "{value}",
          },
        },
      ],
      series: [
        {
          name: "0 - 11",
          type: "line",
          showSymbol: false,
          symbol: "line", // 将提示标志设置为线，并且更改颜色
          lineStyle: {
            color: "#5470c6",
            width: 5,
          },
          data: num0,
        },
        {
          name: "12 - 17",
          type: "line",
          showSymbol: false,
          symbol: "line",
          lineStyle: {
            color: "#91cc75",
            width: 5,
          },
          data: num12,
        },
        {
          name: "18 - 34",
          type: "line",
          showSymbol: false,
          symbol: "line",
          lineStyle: {
            color: "#fac858",
            width: 5,
          },
          data: num18,
        },
        {
          name: "35 - 54",
          type: "line",
          showSymbol: false,
          symbol: "line",
          lineStyle: {
            color: "#ee6666",
            width: 5,
          },
          data: num35,
        },
        {
          name: "55 +",
          type: "line",
          showSymbol: false,
          symbol: "line",
          lineStyle: {
            color: "#73c0de",
            width: 5,
          },
          data: num55,
        },
      ],
    };
    return option;
  };

  const options = useMemo(() => getOptions(), [ageData]);

  useEffect(() => {
    let myEcharts = null;
    const initChart = () => {
      myEcharts = echarts.init(chartRef.current, null, { renderer: "svg" });
      // 设置初始大小
      myEcharts.resize();
      // 监听窗口大小变化，自动调整图表大小
      window.addEventListener("resize", handleResize);
      // const options = getOptions();
      myEcharts.setOption(options);
    };
    const handleResize = () => {
      myEcharts.resize();
    };
    // 在组件挂载时进行初始化
    initChart();

    // 在组件卸载时移除事件监听
    return () => {
      window.removeEventListener("resize", handleResize);
      myEcharts.dispose();
    };
  }, [ageData]);
  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        minWidth: "40vw",
        height: "400px",
      }}
    ></div>
  );
};

export default AgeLineCharts;
