import { REACT_OUTLET_LIST } from "@/router/ReactEndPoints";
import {
  selectCity,
  selectCityZone,
  selectCountry,
  selectState,
} from "@/services/Location";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AccessControl from "../../components/AccessControl";
import CustomInput from "../../components/CustomInput";
import RightViewLayout from "../../components/RighViewLayout";
import { getClinetsList } from "../../services/CompanyService";
import { getTimezoneList, updateOutlet } from "../../services/OutletService";
import { getOutletTypeList } from "../../services/OutletTypeService";
import CommonUtil from "../../util/CommonUtils";
export default function EditOutlet() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [countries, setCountries] = useState([]);
  const [outletTypes, setOutletTypes] = useState([]);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});
  const [outletType, setOutletType] = useState({});
  const [timezone, setTimezone] = useState({});
  const [cityZone, setCityZone] = useState({});
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [cityZones, setCityZones] = useState([]);
  const [country, setCountry] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});
  const [timeZoneList, setTimeZoneList] = useState([]);

  const [payload, setPayload] = useState({
    id: "",
    name: "",
    code: "",
    clientId: "",
    outletTypeId: "",
    addressLine: "",
    countryId: "",
    stateId: "",
    cityId: "",
    cityZoneId: "",
    timeZone: "",
    timeZoneId: "",
    latitude: "",
    longitude: "",
  });

  const [error, setError] = useState({
    id: "",
    name: "",
    code: "",
    clientId: "",
    outletTypeId: "",
    addressLine: "",
    countryId: "",
    stateId: "",
    cityId: "",
    cityZoneId: "",
    timeZone: "",
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    let editRow = sessionStorage.getItem("editOutlet");
    if (editRow) {
      let current = JSON.parse(editRow);

      setPayload({ ...payload, ...current?.address, ...current });
      setCityZone(current.cityZoneName);
    }
    getOutletTypeList().then((res) => {
      // console.log("???????DDDDDDDD", res);
      if (res?.data?.code === "LVLI0000") {
        setOutletTypes(res?.data?.data || []);
      }
    });
    selectCityZone().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCityZones(res?.data?.data || []);
      }
    });

    const params = {
      pageNumber: 0,
      pageSize: 0,
      type: "RETAIL_CLIENT",
    };
    getClinetsList(params).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setClients(res?.data?.data);
      }
    });
    selectCountry().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCountries(res?.data?.data);
      } else {
        setCountries([]);
      }
    });
    getTimezoneList().then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setTimeZoneList(res?.data?.data);
      }
    });
  }, []);

  useEffect(() => {
    setState({});
    setCity({});
    if (!CommonUtil.isEmpty(country)) {
      // let ID = country.id;
      const { id } = country;
      selectState({
        countryId: id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setStateList(res?.data?.data);
        }
      });
    } else {
      setStateList([]);
    }
  }, [country]);

  useEffect(() => {
    setCity({});
    if (!CommonUtil.isEmpty(state)) {
      const { id } = state;
      selectCity({
        stateId: id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setCityList(res?.data?.data);
        }
      });
    } else {
      setCityList([]);
    }
  }, [state]);

  useEffect(() => {
    if (countries.length > 0) {
      let country = countries.filter((c) => c.id === payload.countryId);
      if (country) {
        setCountry({
          ...country[0],
        });
      }
    }
  }, [payload.countryId, countries]);

  useEffect(() => {
    if (clients.length > 0 && payload.clientId) {
      let client = clients.find((c) => c.id === payload.clientId);
      if (client) {
        setClient(client);
      }
    }
  }, [payload.clientId, clients]);

  useEffect(() => {
    if (outletTypes.length > 0 && payload.outletTypeId) {
      let outletType = outletTypes.find((c) => c.id === payload.outletTypeId);
      if (outletType) {
        setOutletType(outletType);
        setPayload({ ...payload, outletTypeId: outletType.id });
      }
    }
  }, [payload.outletType, outletTypes]);

  useEffect(() => {
    if (timeZoneList.length > 0 && payload.timeZoneId) {
      let timeZone = timeZoneList.find((c) => c.value === payload.timeZoneId);
      if (timeZone) {
        setTimezone(timeZone);
      }
    }
  }, [payload.timeZoneId, timeZoneList]);

  useEffect(() => {
    if (cityZones.length > 0 && payload.cityZoneId) {
      let cityZone = cityZones.find((c) => c.id === payload.cityZoneId);
      if (cityZone) {
        setCityZone(cityZone);
      }
    }
  }, [payload.cityZoneId, cityZones]);

  useEffect(() => {
    if (stateList.length > 0) {
      let state_ = stateList.filter((c) => c.id === payload.stateId);
      if (state_) {
        setState({
          ...state_[0],
        });
      }
    }
  }, [stateList, payload.stateId]);

  useEffect(() => {
    if (cityList.length > 0) {
      let city_ = cityList.filter((c) => c.id === payload.cityId);
      if (city_) {
        setCity({
          ...city_[0],
        });
      }
    }
  }, [cityList, payload.cityId]);

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.clientId)) {
      setError({
        ...error,
        clientId: t("LVL0001"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.outletTypeId)) {
      setError({
        ...error,
        outletTypeId: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.timeZoneId)) {
      setError({
        ...error,
        timeZone: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.addressLine)) {
      setError({
        ...error,
        addressLine: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.countryId)) {
      setError({
        ...error,
        countryId: t("LVL0001"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(state?.id)) {
      setError({
        ...error,
        stateId: t("LVL0001"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(city.id)) {
      setError({
        ...error,
        cityId: t("LVL0001"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.cityZoneId)) {
      setError({
        ...error,
        cityZone: t("LVL0001"),
      });
      return;
    }

    // if (CommonUtil.isEmptyString(payload.latitude)) {
    //   setError({
    //     ...error,
    //     latitude: t("LVL0001"),
    //   });
    //   return;
    // }

    // if (CommonUtil.isEmptyString(payload.longitude)) {
    //   setError({
    //     ...error,
    //     longitude: t("LVL0001"),
    //   });
    //   return;
    // }
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      let params = {
        id: payload.id,
        name: payload.name,
        code: payload?.code,
        clientId: payload.clientId,
        outletTypeId: payload.outletTypeId,
        addressLine1: payload.addressLine,
        countryId: payload.countryId,
        stateId: payload.stateId,
        cityId: payload.cityId,
        cityZoneId: payload.cityZoneId,
        timeZone: payload.timeZone,
        timeZoneId: payload.timeZoneId,
        latitude: payload?.latitude,
        longitude: payload?.longitude,
      };

      updateOutlet(params).then((response) => {
        if (response?.data?.code === "LVLI0010") {
          enqueueSnackbar(t("PLACE022"), {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
          navigate(REACT_OUTLET_LIST);
          return;
        }

        switch (response?.data?.code) {
          case "UASE0060":
            setError({
              ...error,
              name: response.data.message,
            });
            break;
          case "UASE0020":
            setError({
              ...error,
              ownerEmail: response.data.message,
            });
            break;
          case "UASE0011":
            setError({
              ...error,
              code: response.data.message,
            });
            break;
          default:
            enqueueSnackbar(response.data.message, { variant: "error" });
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  const handleLocationFromMap = (data) => {
    if (data) {
      var countries_ = [];
      var states_ = [];
      var cities_ = [];

      if (data.country) {
        countries_ = countries.filter((c) => c.name === data.country);
        if (countries_.length > 0) {
          setCountry(countries_[0]);
        }
        states_ = stateList.filter((c) => c.name === data.state);
        if (states_.length > 0) {
          setTimeout(() => {
            setState(states_[0]);
          }, 500);
        }
        cities_ = cityList.filter((c) => data.city?.includes(c.name));
        if (cities_.length > 0) {
          setTimeout(() => {
            setCity(cities_[0]);
          }, 1000);
        }
      }
      setPayload({
        ...payload,
        latitude: data.lat,
        longitude: data.lng,
        addressLine: data.addressLine,
        country: country?.id,
        state: state?.id,
        city: city?.id,
      });
      setError({
        ...error,
        latitude: "",
        longitude: "",
        addressLine: "",
        country: "",
        state: "",
        city: "",
      });
    }
  };

  return (
    <RightViewLayout navigateBack={REACT_OUTLET_LIST} title={t("LVLOT0017")}>
      <Grid container spacing={2} px={2} mt={0}>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLOT0002")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            disabled
            options={clients}
            value={client}
            onChange={(e, v) => {
              setClient(v);
              setPayload({ ...payload, clientId: v ? v.id : "" });
              setError({ ...error, clientId: "" });
              //   setPayload({ ...payload, companyId: v ? v.id : "" });
              //   setError({ ...error, companyId: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={!CommonUtil.isEmptyString(error.clientId)}
                helperText={error.clientId}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLOT0003")}
            size="small"
            name="name"
            error={error.name}
            value={payload.name}
            handleChange={handleChange}
            inputProps={{
              maxLength: 60,
            }}
            helperText={error.name}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} px={2} mt={0}>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLOT0004")}
            <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            options={outletTypes}
            value={outletType}
            onChange={(e, v) => {
              setOutletType(v);
              setPayload({ ...payload, outletTypeId: v ? v.id : "" });
              setError({ ...error, outletTypeId: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.outletTypeId}
                helperText={error.outletTypeId}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLOT0009")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            options={timeZoneList}
            value={timezone}
            onChange={(e, v) => {
              setTimezone(v);

              setPayload({
                ...payload,
                timeZone: v?.remark ?? "",
                timeZoneId: v?.value ?? "",
              });
              setError({ ...error, timeZone: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.timeZone}
                helperText={error.timeZone}
              />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2} mt={0}>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLLOC0035")}
            size="small"
            name="code"
            error={error.code}
            value={payload.code}
            handleChange={handleChange}
            inputProps={{
              maxLength: 60,
            }}
            helperText={error.code}
          ></CustomInput>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInput
            required
            label={t("LVLOT0012")}
            size="small"
            name="addressLine"
            value={payload.addressLine}
            error={error.addressLine}
            helperText={error.addressLine}
            inputProps={{ maxLength: 255 }}
            handleChange={handleChange}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <AddressFromMap
            //         onSelectLocation={(address) =>
            //           handleLocationFromMap(address)
            //         }
            //       />
            //     </InputAdornment>
            //   ),
            // }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} px={2} mt={0}>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLOT0005")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            options={countries}
            value={country}
            onChange={(e, v) => {
              setCountry(v);
              setPayload({
                ...payload,
                countryId: v ? v.id : "",
                stateId: "",
                cityId: "",
              });
              setError({ ...error, countryId: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={!CommonUtil.isEmptyString(error.countryId)}
                helperText={error.countryId}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLOT0006")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            options={stateList}
            value={state}
            onChange={(e, v) => {
              setState(v);
              setPayload({ ...payload, stateId: v ? v.id : "", cityId: "" });
              setError({ ...error, stateId: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.stateId}
                helperText={error.stateId}
              />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2} mt={0}>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLOT0007")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            options={cityList}
            value={city}
            onChange={(e, v) => {
              setCity(v);
              setPayload({ ...payload, cityId: v ? v.id : "" });
              setError({ ...error, cityId: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.cityId}
                helperText={error.cityId}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLGF0004")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            disabled
            error={error.cityZone}
            helperText={error.cityZone}
            options={cityZones}
            value={cityZone}
            onChange={(e, v) => {
              setCityZone(v);
              setPayload({ ...payload, cityZoneId: v ? v.id : "" });
              setError({ ...error, cityZone: "" });
            }}
            getOptionLabel={(option) => option?.name ?? ""}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.cityZone}
                helperText={error.cityZone}
              />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2} mt={0}>
        <Grid item md={6} xs={12}>
          <CustomInput
            label={t("LVLOT0013")}
            size="small"
            name="latitude"
            value={payload.latitude}
            error={error.latitude}
            helperText={error.latitude}
            inputProps={{ maxLength: 20 }}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            label={t("LVLOT0014")}
            size="small"
            name="longitude"
            value={payload.longitude}
            error={error.longitude}
            helperText={error.longitude}
            inputProps={{ maxLength: 20 }}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid mt={2} container spacing={2} px={2}>
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"row-reverse"}>
            <Box item pl={2}>
              <AccessControl authorization={"1014"}>
                <Button
                  variant="contained"
                  size="large"
                  className="text-transform-none"
                  onClick={handleSubmit}
                >
                  {t("LVLOT0016")}
                </Button>
              </AccessControl>
            </Box>
            <Box item>
              <Button
                className="text-transform-none"
                variant="outlined"
                onClick={() => navigate(REACT_OUTLET_LIST)}
                size="large"
              >
                {t("LVLOT0015")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
