import api from "../configurations/http-common";

const autorize = (data) => {
  return api.unsecuredAxios().post("/web/user/authorize", data);
};

const sentOtp = (phone) => {
  return api.unsecuredAxios().post("/web/user/login/" + phone);
};
const verifyOtp = (phone) => {
  return api
    .unsecuredAxios()
    .post("/web/user/authorize/" + phone.phone + "/verify/" + phone.otp);
};

const forgotPassword = async (userName) => {
  return api
    .securedAxios()
    .get("/web/user/forgot_password?emailId=" + userName);
};
const resetPassword = (payload) => {
  return api
    .securedAxios()
    .put(
      "/web/user/reset_password/" + encodeURIComponent(payload.code),
      payload
    );
};

const getUserDetails = (userIdOrEmailOrPhone, loadProfileImage) => {
  return api
    .securedAxios()
    .get(
      "web/user?loadProfileImage=" +
        loadProfileImage +
        "&userIdOrEmailOrPhone=" +
        userIdOrEmailOrPhone
    );
};
const updatePassword = (userName, payload) => {
  return api
    .securedAxios()
    .put("web/user/change_password/" + userName, payload);
};
const logout = () => {
  return api.securedAxios().get("web/user/revoke");
};

const updateUser = (userIdOrEmailOrPhone, payload) => {
  return api
    .securedAxios()
    .put("web/user?userIdOrEmailOrPhone=" + userIdOrEmailOrPhone, payload);
};

const tokenRefresh = (payload) => {
  return api.unsecuredAxios().put("/web/user/refresh_token", payload);
};

const UserService = {
  autorize,
  sentOtp,
  verifyOtp,
  forgotPassword,
  resetPassword,
  getUserDetails,
  updatePassword,
  logout,
  updateUser,
  tokenRefresh,
};

export default UserService;
