import React, { useEffect, useState } from "react";
import { REACT_OPEN_API_LIST } from "@/router/ReactEndPoints";
import { Box, Button, Grid, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import ListLayout from "@/components/ListLayout";
import CustomePhoneFiled from "@/components/CustomePhoneFiled";
import CustomInput from "@/components/CustomInput";
import CommonUtil from "@/util/CommonUtils";
import { handlerAddData, hanlerUpdata } from "@/services/openAPI";

function OpenAdd(props) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const [payload, setPayload] = useState({
    name: "",
    companyName: "",
    companyCode: "",
  });

  const [error, setError] = useState({
    name: "",
    companyName: "",
    companyCode: "",
  });

  useEffect(() => {
    let editRow = sessionStorage.getItem("editOpenAPI");
    if (editRow && location.state !== null) {
      let current = JSON.parse(editRow);
      setPayload({ ...payload, ...current });
    }
  }, []);

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: t("LVL0001"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.companyName)) {
      setError({
        ...error,
        companyName: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.companyCode)) {
      setError({
        ...error,
        companyCode: t("LVL0001"),
      });
      return;
    }

    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (location.state !== null) {
        let params = {
          ...payload,
          id: location?.state,
        };
        hanlerUpdata(params).then((response) => {
          if (response?.data?.code === "LVLI0003") {
            enqueueSnackbar(response.data.message, {
              variant: "success",
              anchorOrigin: {
                horizontal: "center",
                vertical: "top",
              },
              style: {
                marginTop: "300px",
              },
            });
            navigate(REACT_OPEN_API_LIST);
          } else if (response?.data?.code === "LVLE0000") {
            enqueueSnackbar(response.data.message, { variant: "error" });
          }
          return;
        });
      } else {
        var request = {
          ...payload,
        };
        handlerAddData(request).then((response) => {
          if (response?.data?.code === "LVLI0008") {
            enqueueSnackbar(response.data.message, {
              variant: "success",
              anchorOrigin: {
                horizontal: "center",
                vertical: "top",
              },
              style: {
                marginTop: "300px",
              },
            });
            navigate(REACT_OPEN_API_LIST);
          } else if (response?.data?.code === "LVLE0000") {
            enqueueSnackbar(response.data.message, { variant: "error" });
          }
          return;
        });
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  return (
    <>
      <ListLayout
        navigateBack={REACT_OPEN_API_LIST}
        title={location.state === null ? t("RTPD0008") : t("RTPD0009")}
      >
        <Grid sx={{ background: "#FFFFFF 0% 0% no-repeat padding-box" }}>
          <Grid container spacing={2} px={2}>
            <Grid item md={6} xs={12}>
              <CustomInput
                id="AddRetailClint1"
                required
                label={t("LVLLOC0034")}
                size="small"
                name="name"
                error={error.name}
                resetError={() => setError({ ...error, name: "" })}
                value={payload.name}
                handleChange={handleChange}
                inputProps={{
                  maxLength: 60,
                }}
                helperText={error.name}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInput
                id="AddRetailClint2"
                required
                label={t("LVLDAC0039")}
                size="small"
                name="companyCode"
                error={error.companyCode}
                resetError={() => setError({ ...error, companyCode: "" })}
                value={payload.companyCode}
                handleChange={handleChange}
                inputProps={{
                  maxLength: 30,
                }}
                validation="alpha-numeric"
                helperText={error.companyCode}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} px={2}>
            <Grid item md={6} xs={12}>
              <CustomInput
                id="AddRetailClint4"
                required
                label={t("LVLDAC0038")}
                size="small"
                name="companyName"
                value={payload.companyName}
                error={error.companyName}
                resetError={() => setError({ ...error, companyName: "" })}
                helperText={error.companyName}
                inputProps={{
                  maxLength: 60,
                }}
                handleChange={handleChange}
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ marginBottom: "-3px" }}
              >
                {t("LVLDAC0021")} {<span style={{ color: "red" }}>*</span>}
              </InputLabel>
              <CustomePhoneFiled
                id="AddPhoneno1"
                error={error.phone}
                resetError={() => setError({ ...error, phone: "" })}
                handleCountryCode={(data) => {
                  setCountryCode(data.dialCode);
                  setError({ ...error, phone: "" });
                }}
                countryCode={countryCode}
                placeholder={t("LVLDAC0021")}
                phoneNumber={phone}
                name={"phone"}
                size={"small"}
                handleChange={(e) => {
                  setPhone(e);
                  setError({ ...error, phone: "" });
                }}
                label={"LVLDAC0021"}
              />
            </Grid> */}
          </Grid>

          {/* <Grid container spacing={2} px={2}>
            <Grid item md={6} xs={12}>
              <CustomInput
                id="AddretailClint3"
                required
                label="E-Mail"
                size="small"
                name="email"
                value={payload.email}
                error={error.email}
                resetError={() => setError({ ...error, email: "" })}
                helperText={error.email}
                inputProps={{
                  maxLength: 36,
                }}
                validation="email"
                handleChange={handleChange}
              />
            </Grid>
          </Grid> */}

          <Grid container spacing={2} px={2}>
            <Grid item xs={12} sx={{ pb: "20px" }}>
              <Box display={"flex"} flexDirection={"row-reverse"}>
                <Box item pl={2}>
                  <Button
                    id="addRetailClint1"
                    variant="contained"
                    size="large"
                    className="text-transform-none"
                    onClick={handleSubmit}
                    style={{
                      size: "medium",
                      borderRadius: "8px",
                      opacity: 1,
                      background:
                        "transparent linear-gradient(270deg, #1487CA 0%, #78BC27 100%) 0% 0% no-repeat padding-box",
                    }}
                  >
                    {t("LVL0013")}
                  </Button>
                </Box>
                <Box item>
                  <Button
                    id="addRetailClint2"
                    className="text-transform-none"
                    variant="outlined"
                    onClick={() => navigate(REACT_OPEN_API_LIST)}
                    size="large"
                  >
                    {t("LVLRC0017")}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </ListLayout>
    </>
  );
}

export default OpenAdd;
