import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { exportExcelData } from "@/services/MetaDataService";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
export default function SelectDialog({
  setOpen,
  open,
  filters,
  selectEventType,
  selectedValue,
  selectedDevice,
  startTimes,
  endTimes,
  devices,
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [selectPhoto, setSelectPhoto] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const sn = devices.filter((item) => item.id === selectedDevice.id);
  const [disableButton, setDisableButton] = useState(false);
  const handlerPhoto = (event) => {
    const selectedMilliseconds = event.target.value;
    setSelectPhoto(selectedMilliseconds);
  };
  //Excel 导出方法

  const exportToExcel = () => {
    if (startTimes === null && endTimes === null) {
      enqueueSnackbar(t("PCS91"), {
        variant: "info",
      });
      setOpen(false);
      return;
    }
    const params = {
      deviceId: selectedDevice.id,
      deviceType: "sr-aibox",
      eventType:
        selectEventType === 1
          ? selectedValue
          : "374296b825121de96c5c6561a181496",
      startDateTime: startTimes,
      endDateTime: endTimes,
      exportType: selectPhoto,
      deviceSn: sn[0].sn,
      ...filters,
    };

    setIsLoading(true);
    setDisableButton(true);
    enqueueSnackbar(t("PCS92"), {
      variant: "info",
    });
    exportExcelData(params)
      .then((res) => {
        if (res?.headers?.code === "LVLE0000") {
          let message = decodeURIComponent(res?.headers?.message);
          message = message.replace(/\+/g, " ");
          enqueueSnackbar(message, {
            variant: "info",
          });
          setOpen(false);
          setIsLoading(false);
          setDisableButton(false);
          return;
        } else {
          const link = document.createElement("a");
          let blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          });
          link.style.display = "none";
          link.download = res.headers["content-disposition"]; //下载后文件名
          // link.download = "Export Data"; //下载的文件名
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setOpen(false);
          setDisableButton(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setDisableButton(false);
      });
  };

  const exportData = () => {
    if (startTimes === null && endTimes === null) {
      enqueueSnackbar(t("PCS91"), {
        variant: "info",
      });
      setOpen(false);
      return;
    }

    const params = {
      deviceId: selectedDevice.id,
      deviceType: "sr-aibox",
      eventType:
        selectEventType === 1
          ? selectedValue
          : "374296b825121de96c5c6561a181496",
      startDateTime: startTimes,
      endDateTime: endTimes,
      exportType: selectPhoto,
      deviceSn: sn[0].sn,
      ...filters,
    };
    setIsLoading(true);

    enqueueSnackbar(t("PCS92"), {
      variant: "info",
    });

    exportExcelData(params).then((res) => {
      const link = document.createElement("a");
      let blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      });
      link.style.display = "none";
      link.download = res.headers["content-disposition"]; //下载后文件名
      // link.download = "Export Data"; //下载的文件名
      link.href = URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setOpen(false);
      setDisableButton(false);
      setIsLoading(false);
    });
  };

  const clickExportData = () => {
    if (selectEventType === 1) {
      exportData();
    } else {
      exportToExcel();
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle fontSize={22}>{t("PLACE02")}</DialogTitle>
        <DialogContent sx={{ width: 400, top: "100px" }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Grid sx={{ marginTop: "10px" }}>
              <FormControl>
                <RadioGroup row value={selectPhoto} onChange={handlerPhoto}>
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label={t("MATADATA02")}
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label={t("MATADATA03")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t("LVLDB0012")}</Button>
          <Button
            onClick={() => clickExportData()}
            autoFocus
            disabled={disableButton}
          >
            {t("LVLDB0011")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
