import { useState, useEffect } from 'react'
import { RichTreeView } from '@mui/x-tree-view/RichTreeView'
import { useTreeItem2Utils } from '@mui/x-tree-view/hooks'
import { TreeItem2 } from '@mui/x-tree-view/TreeItem2'
import * as React from 'react'
const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const { interactions } = useTreeItem2Utils({
    itemId: props.itemId,
    children: props.children,
  })
  const handleContentClick = (event) => {
    event.defaultMuiPrevented = true
    interactions.handleSelection(event)
  }

  const handleIconContainerClick = (event) => {
    event.isIcon = true
    interactions.handleExpansion(event)
  }
  return (
    <TreeItem2
      {...props}
      ref={ref}
      slotProps={{
        content: { onClick: handleContentClick },
        iconContainer: { onClick: handleIconContainerClick },
      }}
    />
  )
})

/**
 * 树形选择
 * @param {*} props
 * @returns
 */
const CmpTreeSelect = (props) => {
  const {
    multiSelect = false,
    treeData = [],
    valueKey = 'id',
    labelKey = 'label',
    defaultSelected = [],
    selectChange = () => {},
    selectClick = () => {},
  } = props
  const getItemId = (item) => {
    return item[valueKey]
  }
  const getItemLabel = (item) => {
    return item[labelKey]
  }
  const [selectedItems, setSelectedItems] = useState([])
  useEffect(() => {
    setSelectedItems([...defaultSelected])
  }, [])

  const onSelectedItemsChange = (event, itemId, isSelected) => {
    if (multiSelect) {
      if (!event.isIcon) {
        let nids = selectedItems.filter((item) => {
          return item === itemId
        })
        if (nids.length > 0) {
          //存在
          let tempIds = selectedItems.filter((item) => {
            return item !== itemId
          })

          setSelectedItems([...tempIds])
        } else {
          //不存在
          setSelectedItems([...selectedItems, itemId])
        }
      }
    } else {
      if (!event.isIcon) {
        if (selectedItems.length === 0) {
          setSelectedItems([itemId])
        } else {
          let select = selectedItems[0]
          if (select !== itemId) {
            setSelectedItems([itemId])
            selectClick([itemId])
          } else {
            setSelectedItems([])
          }
        }
      }
    }
  }
  useEffect(() => {
    selectChange(selectedItems)
  }, [selectedItems])
  return (
    <RichTreeView
      items={treeData || []}
      selectedItems={selectedItems}
      slots={{ item: CustomTreeItem }}
      getItemId={getItemId}
      getItemLabel={getItemLabel}
      onSelectedItemsChange={onSelectedItemsChange}
    />
  )
}

export default CmpTreeSelect
