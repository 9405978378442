import { Box, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import AISidebar from "./AISidebar";
import AppContext from "../context/AppContext";

import layoutBg from "@/assets/ic_mg.png";
import Modal from "react-modal";

export default function AIMainLayout(props) {
  const [tokenRefreshTimer, setTokenRefreshTimer] = useState(-1);
  const [selectedClient, setSelectedClient] = useState({});
  const [isShow, setIsShow] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isSmall, setIsSmall] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (width <= 1300) {
      setIsShow(false);
      setIsSmall(true);
    } else {
      setIsShow(true);
      setIsSmall(false);
    }
  }, [width]);

  const value = {
    tokenRefreshTimer,
    selectedClient,
    setSelectedClient,
    setTokenRefreshTimer,
  };

  function openModal() {
    setIsShow(true);
  }

  function closeModal() {
    setIsShow(false);
  }

  const customStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      width: "0px",
      borderRadius: "0px",
      padding: "0px",
    },
  };

  return (
    <AppContext.Provider value={value}>
      <Grid
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${layoutBg})`,
        }}
      >
        <Grid style={{ height: "100%", display: "flex", flexDirection: "row" }}>
          <AISidebar isShow={isShow} setIsShow={setIsShow} />

          {isSmall && (
            <Modal
              style={customStyles}
              isOpen={isShow}
              onRequestClose={closeModal}
            ></Modal>
          )}
          <Grid
            container
            mx={2}
            style={{
              height: "100%",
              overflowY: "auto",
              display: "block",
              width: "-webkit-fill-available",
            }}
          >
            <Box my={2} mx={0} height={"95%"}>
              {props.children}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </AppContext.Provider>
  );
}
