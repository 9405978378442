import api from "../configurations/http-common";

export const getAllCountries = async (companyId) => {
  return api
    .securedAxios()
    .get("/web/countries");
};

export const getAllCities = async (request) => {
  return api
    .securedAxios()
    .get("/web/cities");
};

export const getAllStates = async (request) => {
    return api
      .securedAxios()
      .get("/web/states");
  };

 
