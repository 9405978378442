import { REACT_OUTLET_LIST } from "@/router/ReactEndPoints";
import {
  selectCity,
  selectCityZone,
  selectCountry,
  selectState,
} from "@/services/Location";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AccessControl from "../../components/AccessControl";
import CustomInput from "../../components/CustomInput";
import RightViewLayout from "../../components/RighViewLayout";
import AppContext from "../../context/AppContext";
import { getClinetsList } from "../../services/CompanyService";
import { createOutlet, getTimezoneList } from "../../services/OutletService";
import { getOutletTypeList } from "../../services/OutletTypeService";
import CommonUtil from "../../util/CommonUtils";

export default function AddOutlet() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [outletTypes, setOutletTypes] = useState([]);
  const [clients, setClients] = useState([]);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const { selectedClient } = useContext(AppContext);
  const [country, setCountry] = useState({});
  const [client, setClient] = useState({});
  const [outletType, setOutletType] = useState({});
  const [timeZone, setTimeZone] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});
  const [cityZone, setCityZone] = useState({});
  const [stateList, setStateList] = useState({});
  const [cityList, setCityList] = useState({});
  const [cityZones, setCityZones] = useState({});

  useEffect(() => {
    getOutletTypeList().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setOutletTypes(res?.data?.data || []);
      }
    });

    selectCityZone().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCityZones(res?.data?.data || []);
      }
    });

    const params = {
      pageNumber: 0,
      pageSize: 0,
      type: "RETAIL_CLIENT",
    };
    getClinetsList(params).then((res) => {
      // console.log("..............fdfdf....", res);
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setClients(res?.data?.data);
      }
    });

    selectCountry().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCountries(res?.data?.data);
      } else {
        setCountries([]);
      }
    });

    getTimezoneList().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setTimeZoneList(res?.data?.data || []);
      }
    });
  }, []);

  const [payload, setPayload] = useState({
    name: "",
    code: "",
    clientId: "",
    outletTypeId: "",
    addressLine1: "",
    cityId: "",
    stateId: "",
    countryId: "",
    cityZoneId: "",
    timeZone: "",
    latitude: "",
    longitude: "",
    clientName: "",
    outletTypeName: "",
    countryName: "",
    stateName: "",
    cityName: "",
  });

  const [error, setError] = useState({
    name: "",
    code: "",
    clientId: "",
    outletTypeId: "",
    addressLine1: "",
    cityId: "",
    stateId: "",
    countryId: "",
    cityZoneId: "",
    timeZone: "",
    latitude: "",
    longitude: "",
    clientName: "",
    outletTypeName: "",
    countryName: "",
    stateName: "",
    cityName: "",
  });

  useEffect(() => {
    setState({});
    setCity({});
    if (!CommonUtil.isEmpty(country)) {
      // let ID = country.id;
      const { id } = country;
      selectState({
        countryId: id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setStateList(res?.data?.data);
        }
      });
    } else {
      setStateList([]);
    }
  }, [country]);

  useEffect(() => {
    setCity({});
    if (!CommonUtil.isEmpty(state)) {
      const { id } = state;
      selectCity({
        stateId: id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setCityList(res?.data?.data);
        }
      });
    } else {
      setCityList([]);
    }
  }, [state]);

  const validateForm = (params) => {
    if (CommonUtil.isEmptyString(params.clientId)) {
      setError({
        ...error,
        clientId: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(params.name)) {
      setError({
        ...error,
        name: t("LVL0001"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(params.outletTypeId)) {
      setError({
        ...error,
        outletType: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(params.timeZone)) {
      setError({
        ...error,
        timeZone: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(params.code)) {
      setError({
        ...error,
        code: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(params.addressLine1)) {
      setError({
        ...error,
        addressLine1: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(params.countryId)) {
      setError({
        ...error,
        country: t("LVL0001"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(params.stateId)) {
      setError({
        ...error,
        state: t("LVL0001"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(params.cityId)) {
      setError({
        ...error,
        city: t("LVL0001"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(params.cityZoneId)) {
      setError({
        ...error,
        cityZone: t("LVL0001"),
      });
      return;
    }

    // if (CommonUtil.isEmptyString(params.latitude)) {
    //   setError({
    //     ...error,
    //     latitude: t("LVL0001"),
    //   });
    //   return;
    // }

    // if (CommonUtil.isEmptyString(params.longitude)) {
    //   setError({
    //     ...error,
    //     longitude: t("LVL0001"),
    //   });
    //   return;
    // }
    return true;
  };

  const handleSubmit = () => {
    let params = {
      name: payload?.name,
      code: payload?.code,
      clientId: payload?.clientId,
      outletTypeId: outletType?.id,
      addressLine1: payload.addressLine1,
      cityId: city?.id,
      stateId: state?.id,
      countryId: country?.id,
      cityZoneId: cityZone?.id,
      timeZone: timeZone?.remark,
      timeZoneId: timeZone?.value,
      latitude: payload?.latitude,
      longitude: payload?.longitude,
      clientName: "",
      outletTypeName: "",
      countryName: "",
      stateName: "",
      cityName: "",
    };
    if (validateForm(params)) {
      createOutlet(params).then((response) => {
        if (response?.data?.code === "LVLI0007") {
          enqueueSnackbar(t("PLACE021"), {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
          navigate(REACT_OUTLET_LIST);
          return;
        }

        switch (response?.data?.code) {
          case "LMSE6046":
            setError({
              ...error,
              name: "Outlet already exist with given name.",
            });
            break;
          default:
            enqueueSnackbar(response.data.message, { variant: "error" });
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  const handleLocationFromMap = (data) => {
    if (data) {
      var countries_ = [];
      var states_ = [];
      var cities_ = [];
      if (data.country) {
        countries_ = countries.filter((c) => c.name === data.country);
        if (countries_.length > 0) {
          setCountry(countries_[0]);
        }

        states_ = states.filter((c) => c.name === data.state);
        if (states_.length > 0) {
          setTimeout(() => {
            setState(states_[0]);
          }, 500);
        }

        cities_ = cities.filter((c) => data.city?.includes(c.name));
        if (cities_.length > 0) {
          setTimeout(() => {
            setCity(cities_[0]);
          }, 1000);
        }
      }
      setPayload({
        ...payload,
        latitude: data.lat,
        longitude: data.lng,
        addressLine1: data.addressLine1,
        country: country?.id,
        state: state?.id,
        city: city?.id,
      });
      setError({
        ...error,
        latitude: "",
        longitude: "",
        addressLine1: "",
        country: "",
        state: "",
        city: "",
      });
    }
  };

  return (
    <RightViewLayout navigateBack={REACT_OUTLET_LIST} title={t("LVLOT0011")}>
      <Grid container spacing={2} px={2} mt={0}>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLOT0002")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            options={clients}
            value={client}
            onChange={(e, v) => {
              setClient(v);
              setPayload({ ...payload, clientId: v ? v.id : "" });
              setError({ ...error, clientId: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={!CommonUtil.isEmptyString(error.clientId)}
                helperText={error.clientId}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLOT0003")}
            size="small"
            name="name"
            error={error.name}
            value={payload.name}
            handleChange={handleChange}
            inputProps={{
              maxLength: 60,
            }}
            helperText={error.name}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} px={2} mt={-1}>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLOT0004")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            options={outletTypes}
            value={outletType}
            onChange={(e, v) => {
              setOutletType(v);

              setPayload({ ...payload, outletType: v ? v.id : "" });
              setError({ ...error, outletType: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.outletType}
                helperText={error.outletType}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLOT0009")}
            <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            options={timeZoneList}
            value={timeZone}
            onChange={(e, v) => {
              setTimeZone(v);
              setPayload({ ...payload, timeZone: v ? v.value : "" });
              setError({ ...error, timeZone: "" });
            }}
            getOptionLabel={(option) => option?.name ?? ""}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.timeZone}
                helperText={error.timeZone}
              />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2} mt={0}>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLDB0033")}
            size="small"
            name="code"
            error={error.code}
            value={payload.code}
            handleChange={handleChange}
            inputProps={{
              maxLength: 60,
            }}
            helperText={error.code}
          ></CustomInput>
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLOT0012")}
            size="small"
            name="addressLine1"
            value={payload.addressLine1}
            error={error.addressLine1}
            helperText={error.addressLine1}
            inputProps={{ maxLength: 255 }}
            handleChange={handleChange}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <AddressFromMap
            //         onSelectLocation={(address) =>
            //           handleLocationFromMap(address)
            //         }
            //       />
            //     </InputAdornment>
            //   ),
            // }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} px={2} mt={0}>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLOT0005")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            options={countries}
            value={country}
            onChange={(e, v) => {
              setCountry(v);
              setPayload({ ...payload, country: v ? v.id : "" });
              setError({ ...error, country: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={!CommonUtil.isEmptyString(error.country)}
                helperText={error.country}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLOT0006")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            options={stateList}
            value={state}
            onChange={(e, v) => {
              setState(v);
              setPayload({ ...payload, state: v ? v.id : "" });
              setError({ ...error, state: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.state}
                helperText={error.state}
              />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2} mt={0}>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLOT0007")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            options={cityList}
            value={city}
            onChange={(e, v) => {
              setCity(v);
              setPayload({ ...payload, city: v ? v.id : "" });
              setError({ ...error, city: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.city}
                helperText={error.city}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLGF0004")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            error={error.cityZone}
            helperText={error.cityZone}
            options={cityZones}
            value={cityZone}
            onChange={(e, v) => {
              setCityZone(v);
              setPayload({ ...payload, cityZone: v ? v.id : "" });
              setError({ ...error, cityZone: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.cityZone}
                helperText={error.cityZone}
              />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2} mt={0}>
        <Grid item md={6} xs={12}>
          <CustomInput
            label={t("LVLOT0013")}
            size="small"
            name="latitude"
            value={payload.latitude}
            error={error.latitude}
            helperText={error.latitude}
            inputProps={{ maxLength: 20 }}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            label={t("LVLOT0014")}
            size="small"
            name="longitude"
            value={payload.longitude}
            error={error.longitude}
            helperText={error.longitude}
            inputProps={{ maxLength: 20 }}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid mt={2} container spacing={2} px={2}>
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"row-reverse"}>
            <Box item pl={2}>
              <AccessControl authorization={"1013"}>
                <Button
                  variant="contained"
                  size="large"
                  className="text-transform-none"
                  onClick={handleSubmit}
                >
                  {t("LVLOT0016")}
                </Button>
              </AccessControl>
            </Box>
            <Box item>
              <Button
                className="text-transform-none"
                variant="outlined"
                onClick={() => navigate(REACT_OUTLET_LIST)}
                size="large"
              >
                {t("LVLOT0015")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
