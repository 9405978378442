import jwt_decode from "jwt-decode";
import CommonUtils from "@/util/CommonUtils";
import AppContext from "@/context/AppContext";

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Index() {
  const { setTokenRefreshTimer } = useContext(AppContext);
  const navigate = useNavigate();

  const url = CommonUtils.getCookie("JUMP_URL");
  const token = CommonUtils.getCookie("USER_TOKEN");
  const refreshToken = CommonUtils.getCookie("REFRESH_TOKEN");

  localStorage.setItem("USER_TOKEN_RETAIL_AI", token);
  localStorage.setItem("REFRESH_TOKEN_RETAIL_AI", refreshToken);

  const decoded = jwt_decode(localStorage.getItem("USER_TOKEN_RETAIL_AI"));
  localStorage.setItem("USER", JSON.stringify(decoded));
  var currentSeconds = Math.floor(Date.now() / 1000);
  var secondsLeft = decoded.exp - currentSeconds;
  var timer = Math.floor(secondsLeft / 60) - 5;

  timer = timer * 60 * 1000;
  setTokenRefreshTimer(timer);

  useEffect(() => {
    navigate(url);
  }, []);

  return <div></div>;
}
