import React, { useEffect, useState } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_PRINCIPAL_MANAGEMENT } from "@/router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CustomePhoneFiled from "../../components/CustomePhoneFiled";
import CommonUtil from "../../util/CommonUtils";
import { useTranslation } from "react-i18next";
import { selectCountry, selectState, selectCity } from "@/services/Location";
import {
  updateClient,
  getCompanyByIdOrCode,
  sendActivationMail,
  getClinetsList,
  deleteClientById,
} from "../../services/CompanyService";
import { useSnackbar } from "notistack";
import {
  getAllCities,
  getAllCountries,
  getAllStates,
} from "../../services/LocationService";
import { getCityZoneList } from "../../services/CityZoneService";
import { getOutletTypeList } from "../../services/OutletTypeService";
import { getStateListByCountryId } from "../../services/StateService";
import { getCityListByState } from "../../services/CityService";

export default function UpdateRetailClient() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { code } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [payload, setPayload] = useState({
    name: "",
    code: "",
    email: "",
    mobile: "",
    addressLine: "",
    cityName: "",
    stateName: "",
    countryName: "",
    pincode: "",
    type: "PRINCIPAL",
    ownerEmail: "",
    firstName: "",
    lastName: "",
  });

  const [error, setError] = useState({
    name: "",
    code: "",
    email: "",
    mobile: "",
    addressLine: "",
    cityName: "",
    stateName: "",
    countryName: "",
    pincode: "",
    type: "PRINCIPAL",
    ownerEmail: "",
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    selectCountry().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCountries(res?.data?.data);
      } else {
        setCountries([]);
      }
    });
    let editRow = sessionStorage.getItem("eidtPrinciple");
    if (editRow) {
      let current = JSON.parse(editRow);
      setPayload({ ...payload, ...current });

      setCountry(current.countryName);
      setState(current.stateName);
      setCity(current.cityName);
      setCountryCode(current.mobileArea);
      setPhoneNumber(current.mobile);
    }
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(country)) {
      // let ID = country.id;
      const { id } = country;
      selectState({
        countryId: id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setStateList(res?.data?.data);
        }
      });
    } else {
      setStateList([]);
    }
  }, [country]);

  useEffect(() => {
    if (!CommonUtil.isEmpty(state)) {
      const { id } = state;
      selectCity({ stateId: id }).then((res) => {
        // console.log(">>>>>>>>>>>>>>>>>>Dfsdfs", res);
        if (res?.data?.code === "LVLI0000") {
          setCityList(res?.data?.data);
        }
      });
    } else {
      setCityList([]);
    }
  }, [state]);

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: t("LVL0001"),
      });
      return;
    }
    // if (CommonUtil.isEmptyString(payload.code)) {
    //   setError({
    //     ...error,
    //     code: t("LVL0001"),
    //   });
    //   return;
    // }

    // if (CommonUtil.isEmptyString(payload.email)) {
    //   setError({
    //     ...error,
    //     email: t("LVL0001"),
    //   });
    //   return;
    // } else if (!CommonUtil.isValidEmail(payload.email)) {
    //   setError({
    //     ...error,
    //     email: t("LVL0003"),
    //   });
    //   return;
    // }
    // if (!CommonUtil.isEmptyString(phoneNumber)) {
    //   if (CommonUtil.isEmptyString(countryCode)) {
    //     setError({
    //       ...error,
    //       phone: "Country Code is required.",
    //     });
    //     return;
    //   }
    //   var mobile = "+" + countryCode.replace("+", "") + "-" + phoneNumber;
    //   if (mobile.length < 10 || mobile.length > 25) {
    //     setError({
    //       ...error,
    //       phone: t("LVL0008"),
    //     });
    //     return false;
    //   }
    // }
    // if (!CommonUtil.isEmptyString(countryCode)) {
    //   if (CommonUtil.isEmptyString(phoneNumber)) {
    //     setError({
    //       ...error,
    //       phone: "Mobile Number is required",
    //     });
    //     return;
    //   }
    // }
    // if (CommonUtil.isEmptyString(payload.ownerFirstName)) {
    //   setError({
    //     ...error,
    //     ownerFirstName: t("LVL0001"),
    //   });
    //   return;
    // }

    // if (CommonUtil.isEmptyString(payload.ownerEmail)) {
    //   setError({
    //     ...error,
    //     ownerEmail: t("LVL0001"),
    //   });
    //   return;
    // } else if (!CommonUtil.isValidEmail(payload.ownerEmail)) {
    //   setError({
    //     ...error,
    //     ownerEmail: t("LVL0003"),
    //   });
    //   return;
    // }
    // if (CommonUtil.isEmptyString(payload.country)) {
    //   setError({
    //     ...error,
    //     country: t("LVL0001"),
    //   });
    //   return;
    // }
    // if (CommonUtil.isEmpty(state)) {
    //   setError({
    //     ...error,
    //     state: t("LVL0001"),
    //   });
    //   return;
    // }
    // if (CommonUtil.isEmpty(city)) {
    //   setError({
    //     ...error,
    //     city: t("LVL0001"),
    //   });
    //   return;
    // }
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      var request = {
        id: payload.id,
        name: payload.name,
        addressLine: payload.addressLine,
      };

      updateClient(request).then((response) => {
        if (response?.data?.code === "LVLI0003") {
          enqueueSnackbar(t("PLACE020"), {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
          navigate(REACT_PRINCIPAL_MANAGEMENT);
          return;
        }

        switch (response?.data?.code) {
          case "UASE0060":
            setError({
              ...error,
              name: response.data.message,
            });
            break;
          case "UASE0020":
            setError({
              ...error,
              ownerEmail: response.data.message,
            });
            break;
          case "UASE0011":
            setError({
              ...error,
              code: response.data.message,
            });
            break;
          default:
            enqueueSnackbar(response.data.message, { variant: "error" });
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  return (
    <RightViewLayout
      navigateBack={REACT_PRINCIPAL_MANAGEMENT}
      title={t("LVLDB0018")}
    >
      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLRC0002")}
            size="small"
            name="name"
            error={error.name}
            value={payload.name}
            handleChange={handleChange}
            inputProps={{
              maxLength: 60,
            }}
            helperText={error.name}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLRC0003")}
            size="small"
            name="code"
            error={error.code}
            value={payload.code}
            handleChange={handleChange}
            inputProps={{
              maxLength: 30,
            }}
            helperText={error.code}
            validation={"xxcode"}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLRC0004")}
            size="small"
            name="email"
            disabled
            value={payload.email}
            error={error.email}
            helperText={error.email}
            inputProps={{
              maxLength: 36,
            }}
            validation="email"
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ marginBottom: "-10px" }}
          >
            {t("LVLRC0005")}
          </InputLabel>
          <CustomePhoneFiled
            disabled
            error={error.mobile}
            handleCountryCode={(data) => {
              setCountryCode(data.dialCod);
              setError({ ...error, mobile: "" });
            }}
            countryCode={countryCode}
            placeholder={t("LVLRC0005")}
            phoneNumber={phoneNumber}
            name={"phoneNumber"}
            size={"small"}
            handleChange={(e) => {
              setPhoneNumber(e);
              setError({ ...error, mobile: "" });
            }}
            label={"Mobile"}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLRC0013")}
            size="small"
            name="firstName"
            value={payload.firstName}
            error={error.firstName}
            helperText={error.firstName}
            inputProps={{
              maxLength: 100,
            }}
            validation="alpha-numeric"
            handleChange={handleChange}
            disabled
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            label={t("LVLRC0014")}
            size="small"
            name="lastName"
            value={payload.lastName}
            error={error.lastName}
            helperText={error.lastName}
            inputProps={{
              maxLength: 100,
            }}
            validation="alpha-numeric"
            handleChange={handleChange}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2}>
        {/* <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLRC0015")}
            size="small"
            name="ownerEmail"
            value={payload.ownerEmail}
            error={error.ownerEmail}
            helperText={error.ownerEmail}
            inputProps={{
              maxLength: 36,
            }}
            validation="email"
            handleChange={handleChange}
            disabled
          />
        </Grid> */}
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLRC0007")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            disabled
            options={countries}
            value={country}
            onChange={(e, v) => {
              setCountry(v);
              setPayload({
                ...payload,
                countryName: v,
                state: "",
                city: "",
              });
              setState({});
              setCity({});
              setError({ ...error, countryName: "" });
            }}
            getOptionLabel={(option) => (option ? option : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={!CommonUtil.isEmptyString(error.countryName)}
                helperText={error.countryName}
              />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLRC0008")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            error={error.stateName}
            helperText={error.stateName}
            options={stateList}
            disabled
            value={state}
            onChange={(e, v) => {
              setState(v);
              setCity({});
              setError({ ...error, stateName: false });
            }}
            getOptionLabel={(option) => (option ? option : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                helperText={error.stateName}
                error={error.stateName}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLRC0009")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            disabled
            error={error.cityName}
            helperText={error.cityName}
            options={cityList}
            value={city}
            onChange={(e, v) => {
              setCity(v);
              setError({ ...error, cityName: false });
            }}
            getOptionLabel={(option) => (option ? option : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.cityName}
                helperText={error.cityName}
              />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2}>
        <Grid item xs={12}>
          <CustomInput
            label={t("LVLRC0006")}
            size="small"
            name="addressLine"
            value={payload.addressLine}
            error={error.addressLine}
            helperText={error.addressLine}
            inputProps={{ maxLength: 255 }}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid mt={2} container spacing={2} px={2}>
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"row-reverse"}>
            <Box item pl={2}>
              <Button
                variant="contained"
                size="large"
                className="text-transform-none"
                onClick={handleSubmit}
              >
                {t("LVLRC0016")}
              </Button>
            </Box>
            <Box item>
              <Button
                className="text-transform-none"
                variant="outlined"
                onClick={() => navigate(REACT_PRINCIPAL_MANAGEMENT)}
                size="large"
              >
                {t("LVLRC0017")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
