import React, { useState, useEffect } from "react";
import { timeZoneList } from "../constants/TimeZone";
import { t } from "i18next";

import jwt_decode from "jwt-decode";
import { Cookie } from "@mui/icons-material";
const CommonUtil = {
  isValidEmail(email) {
    if (email === undefined || email === "") return true;
    // const regex =
    //   /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+(?:\.[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const regex1 =
      /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/;
    const regex2 = /^[a-zA-Z]+(\.[a-zA-Z]+)?@[a-zA-Z]+\.[a-zA-Z]{2,}$/;
    const regex3 = /^[a-zA-Z]\w*@[\w]+\.[a-zA-Z]{2,}$/;
    const regex4 = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/;
    const regex5 =
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,4}\.[a-zA-Z]{2,4}$/;

    if (
      regex1.test(email.toLowerCase()) ||
      regex2.test(email.toLowerCase()) ||
      regex3.test(email.toLowerCase()) ||
      regex4.test(email.toLowerCase()) ||
      regex5.test(email.toLowerCase())
    ) {
      return true;
    }
    return false;
  },

  isDeviceNumber(deviceNumber) {
    if (deviceNumber === undefined || deviceNumber === "") return true;
    const regex1 = /^[0-9]+$/;
    return regex1.test(deviceNumber);
  },

  isValidMobile(mobile) {
    if (mobile === undefined || mobile === "") return true;
    const re = /^(\+\d{1,3}[- ]?)?\d{8,15}$/;
    return re.test(String(mobile));
  },

  isExceptNumericName(name) {
    if (name === undefined || name === "") return true;
    let finalString = name?.replace(/[^\d.-]/g, "");
    return finalString;
  },

  isValidName(name) {
    if (name === undefined || name === "") return true;
    let regex = /^[a-zA-Z0-9]+[\'\s]?[a-zA-Z0-9 ]+$/;
    return regex.test(name);
  },
  isAlphaNumeric(data) {
    if (data === undefined || data === "") return true;
    let regex = /^[a-zA-Z0-9]+$/i;
    return regex.test(data);
  },

  isValidNumericName(name) {
    if (name === undefined || name === "") return true;
    let regex = /^[A-Za-z0-9 ]+$/;
    return regex.test(name);
  },

  isValidNumeric(name) {
    if (name === undefined || name === "") return true;
    let regex = /^[0-9\. ]+$/;
    return regex.test(name);
  },
  isValidPasitiveNumeric(name) {
    if (name === undefined || name === "") return true;
    let regex = /^[0-9]+$/;
    return regex.test(name);
  },
  isValidDoubleValue(value) {
    if (value === undefined || value === "") return true;
    let regex = /^[+-]?([1-9][0-9]*|0)(\.[0-9]+)?((e|E)[+-]?[0-9]+)?$/;
    return regex.test(value);
  },

  isValidCode(code) {
    if (code === undefined || code === "") return true;
    let regex = /^[a-zA-Z0-9_-]*$/;
    return regex.test(code);
  },
  isValidZipcode(areaCode) {
    if (
      areaCode.length < 5 ||
      areaCode.length > 6 ||
      !areaCode.match(/^[0-9 ]+$/)
    )
      return true;
  },
  isAlphaNumericSlash(code) {
    if (code === undefined || code === "") return true;
    let regex = /^(?:[A-Za-z0-9]+)(?:[A-Za-z0-9 _]*)$/;
    return regex.test(code);
  },

  isEmptyString(val) {
    if (val === null || val === undefined || val === "") return true;
    else return false;
  },
  isAlphaNumericSpace(data) {
    if (data == undefined || data === "") return true;
    let regex = /^[a-zA-Z0-9_ ]+$/i;
    return regex.test(data);
  },
  isAlphaNumericSpecialChar(data) {
    if (data == undefined || data === "") return true;
    let regex = /^[ A-Za-z0-9_@./#&+-]*$/;
    return regex.test(data);
  },
  isAlphaNumericUnderscore(data) {
    if (data == undefined || data === "") return true;
    let regex = /^[A-Za-z0-9_]*$/;
    return regex.test(data);
  },
  formatDayAndMonth(numValue) {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  },

  isEmpty(obj) {
    if (obj === null || obj === undefined) return true;
    return Object.keys(obj).length === 0;
  },
  getCompany() {
    return localStorage ? localStorage.getItem("companyId") : null;
  },

  getLocalDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let newDate = new Date(date);
    let formated =
      newDate.getDate() +
      "-" +
      monthNames[newDate.getMonth()].substring(0, 3) +
      "-" +
      newDate.getFullYear() +
      ", " +
      (newDate.getHours() < 10
        ? "0" + newDate.getHours()
        : newDate.getHours()) +
      ":" +
      (newDate.getMinutes() < 10
        ? "0" + newDate.getMinutes()
        : newDate.getMinutes()) +
      ":" +
      (newDate.getSeconds() < 10
        ? "0" + newDate.getSeconds()
        : newDate.getSeconds());
    return formated;
  },

  getFormatedDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let newDate = new Date(date);
    let formated =
      newDate.getDate() +
      ", " +
      monthNames[newDate.getMonth()] +
      " " +
      newDate.getFullYear();
    return formated;
  },

  handleTextOverflow(text, length) {
    let maxLength = 20;
    if (length) {
      maxLength = length;
    }
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    } else {
      return text;
    }
  },

  getLocalDate1(date) {
    let newDate = new Date(date);
    return newDate
      .toISOString()
      .replace("T", " ")
      .substr(0, newDate.toISOString().lastIndexOf(":"));
  },

  getLocalTime(time) {
    let newTime = new Date(parseInt(time));
    return newTime.toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
    });
  },

  sentenceCase(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },
  getDefaultCountryCode() {
    let countryCode = "";
    if (localStorage.getItem("locationInfo")) {
      countryCode = JSON.parse(
        localStorage.getItem("locationInfo")
      ).country_code.toLowerCase();
    }
    return countryCode;
  },
  getDefaultCallingCountryCode() {
    let countryCallingCode = "";
    if (localStorage.getItem("locationInfo")) {
      countryCallingCode = JSON.parse(
        localStorage.getItem("locationInfo")
      ).country_calling_code.toLowerCase();
    }
    return countryCallingCode;
  },
  isValidPassword(password) {
    if (password === undefined || password === "") return true;
    const re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/;
    return re.test(String(password));
  },

  formatLongText(text) {
    if (text && text.length > 20) {
      return text.substr(0, 19) + "...";
    } else {
      return text;
    }
  },
  getLocationDetails(query) {
    let res = "";
    if (localStorage.getItem("locationInfo")) {
      let location = JSON.parse(localStorage.getItem("locationInfo"));
      switch (query) {
        case "ip": {
          res = location.ip;
          break;
        }
        case "latitude": {
          res = location.latitude;
          break;
        }
        case "longitude": {
          res = location.longitude;
          break;
        }
        case "country_code": {
          res = location.country_code;
          break;
        }
        case "country_name": {
          res = location.country_name;
          break;
        }
        case "country_calling_code": {
          res = location.country_calling_code;
          break;
        }
      }
    }
    return res;
  },
  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    let domain = window?.location?.host?.replace("level", "");
    if (window?.location?.host === domain)
      document.cookie = cname + "=" + cvalue + ";" + expires + `;path=/`;
    else
      document.cookie =
        cname + "=" + cvalue + ";" + expires + `;domain=${domain};path=/`;
  },
  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  getFormatedDateString(date) {
    let today = new Date(date);
    let fdate =
      (today.getDate() < 10 ? "0" + today.getDate() : today.getDate()) +
      "-" +
      (today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    return fdate;
  },

  resetUserSession() {
    localStorage.removeItem("USER_TOKEN_RETAIL_AI");
    localStorage.removeItem("REFRESH_TOKEN_RETAIL_AI");
    localStorage.removeItem("selectedClient");
    localStorage.removeItem("selectedClientId");
    localStorage.removeItem("USER");
    localStorage.removeItem("PERMISSION");
    localStorage.removeItem("USER_INFO");
    // Cookie.removeItem("USER_TOKEN_RETAIL_AI");
    // // Cookie.removeItem("REFRESH_TOKEN_RETAIL_AI");
    // CommonUtil.setCookie("USER_TOKEN_RETAIL_AI", null, 0);
    // CommonUtil.setCookie("REFRESH_TOKEN_RETAIL_AI", null, 0);
    CommonUtil.setCookie("redirect_url", null, 0);
  },

  getTimeZoneNameByValue(timeZone) {
    return timeZoneList.find((t) => t.value === timeZone)?.name;
  },
  getTimeZoneByValue(timeZone) {
    return timeZoneList.find((t) => t.value === timeZone);
  },
  GetWindowDimenssion() {
    const [size, setSize] = useState({
      width: window.innerWidth + "px",
      height: window.innerHeight + "px",
    });
    const updateSize = () =>
      setSize({
        width: window.innerWidth + "px",
        height: window.innerHeight + "px",
      });
    useEffect(() => (window.onresize = updateSize), [size]);
    return size;
  },
  capitalizeFirst(str) {
    return typeof str === "string"
      ? str.charAt(0).toUpperCase() + str.slice(1)
      : str;
  },

  decodeToken() {
    const jwtToken = localStorage.getItem("USER_TOKEN_RETAIL_AI");
    let decoded_jwtToken = {};
    if (jwtToken) {
      try {
        decoded_jwtToken = jwt_decode(jwtToken);
      } catch {}
      localStorage.removeItem("USER");
      localStorage.setItem("USER", JSON.stringify(decoded_jwtToken));
    }
    return decoded_jwtToken;
  },

  getLoginType() {
    let loginType = null;
    let user = CommonUtil.decodeToken();
    if (user) {
      if (user?.companyType === "CUSTOMER") {
        loginType = "RETAILCLIENT";
      } else if (
        user?.companyType === "PARTNER" &&
        user?.partnerType !== "PRINCIPAL"
      ) {
        loginType = "HQ";
      } else {
        loginType = "PRINCIPAL";
      }
    }
    return loginType;
  },

  getRoleCode() {
    let roleCode = null;
    let user = CommonUtil.decodeToken();
    if (user) {
      if (user?.roleCode === "EMPLOYEE") {
        roleCode = "EMPLOYEE";
      }
    }
    return roleCode;
  },
  getPageNumber(int) {
    if (int == 0) {
      return 1;
    } else {
      return int + 1;
    }
  },

  deleteEmptyKey(obj) {
    for (const key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
        delete obj[key];
      } else if (
        typeof obj[key] === "object" &&
        Object.keys(obj[key]).length === 0
      ) {
        delete obj[key];
      }
    }
    return obj;
  },

  dateFormat(date, fmt) {
    if (fmt === undefined || fmt === null) {
      fmt = "yyyy-MM-dd HH:mm:ss";
    }
    if (date === "" || date === undefined) {
      return "";
    }
    if (typeof date === "number") {
      date = new Date(date);
    }
    var o = {
      "M+": date.getMonth() + 1,
      "d+": date.getDate(),
      "h+": date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
      "H+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds(),
      "q+": Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    var week = {
      0: "/u65e5",
      1: "/u4e00",
      2: "/u4e8c",
      3: "/u4e09",
      4: "/u56db",
      5: "/u4e94",
      6: "/u516d",
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt?.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    }
    if (/(E+)/.test(fmt)) {
      fmt = fmt?.replace(
        RegExp.$1,
        (RegExp.$1.length > 1
          ? RegExp.$1.length > 2
            ? "/u661f/u671f"
            : "/u5468"
          : "") + week[this.getDay() + ""]
      );
    }
    for (var k in o) {
      let rg = "(" + k + ")";
      if (new RegExp(rg).test(fmt)) {
        fmt = fmt?.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
    return fmt;
  },
};

export default CommonUtil;
