import { FormHelperText, InputLabel, OutlinedInput, Stack } from '@mui/material'
import RequirePoint from './RequirePoint'
const CmpTextarea = (props) => {
  const {
    formik = null,
    placeholder = '',
    handleBlur,
    handleChange,
    label,
    name,
    error,
    labelPostion,
    sx,
    ...orther
  } = props
  const blurFn = (e) => {
    if (formik?.handleBlur) {
      formik?.handleBlur(e)
    }
    if (handleChange) {
      handleChange()
    }
  }
  const changeFn = (e) => {
    if (formik?.handleChange) {
      formik?.handleChange(e)
    }
    if (handleBlur) {
      handleBlur(e)
    }
  }
  return (
    <Stack spacing={1}>
      <Stack
        sx={{
          alignItems: labelPostion === 'left' ? 'flex-start' : '',
        }}
        direction={labelPostion === 'left' ? 'row' : 'column'}
        spacing={1}
      >
        <InputLabel
          style={{
            marginTop: labelPostion === 'left' ? '12px' : '',
          }}
          htmlFor={'CmpInput_' + name}
        >
          {props.required && <RequirePoint></RequirePoint>} {label}
        </InputLabel>

        <Stack
          sx={{
            flexGrow: 1,
            width: '100%',
          }}
        >
          <OutlinedInput
            id={'CmpInput_' + name}
            value={formik?.values[name]}
            name={name}
            onBlur={blurFn}
            onChange={changeFn}
            placeholder={placeholder}
            fullWidth
            multiline={true}
            error={Boolean(
              (formik?.touched[name] && formik?.errors[name]) || error
            )}
            minRows={5}
            {...orther}
          />
          {((formik?.touched[name] && formik?.errors[name]) || error) && (
            <FormHelperText error id={`standard-weight-helper-text-${name}`}>
              {formik?.errors[name] || error}
            </FormHelperText>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CmpTextarea
