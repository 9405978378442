export const highestStyle = {
  borderRadius: "15px",
  display: "flex",
  justifyContent: "space-between",
  whiteSpace: "nowrap",
};

export const blockStyle = {
  background: "#ee587d",
  color: "#fff",
  textAlign: "center",
  margin: "12px",
  borderRadius: "10px",
  // whiteSpace: "nowrap",
  padding: "0.2rem 0.3rem",
};
