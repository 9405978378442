// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function SvgIcon(theme) {
  return {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: "0.5rem",
          height: "0.5rem",
        },
      },
    },
  };
}
