import {
  Grid,
  Tooltip,
  TextField,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { ReactComponent as TickMarkImg } from "@/assets/images/icon_tickmark.svg";
import React, { useEffect, useState } from "react";
import ListLayout from "@/components/ListLayout";
import RingLoader from "react-spinners/RingLoader";
import DataTable from "@/components/DataTable";
import { useTranslation } from "react-i18next";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import AccessControl from "@/components/AccessControl";
import { CircularProgress } from "@mui/material";
import {
  getCountryList,
  selectCountry,
  selectState,
  selectCity,
  getStateList,
  getCityList,
  selectOutLet,
  getOutletType,
  getDataList,
  getCityZone,
  selectCityZone,
} from "@/services/Location";
import { useSnackbar } from "notistack";
import { getColumns } from "./Columns";
function Location() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchName, setSearchName] = useState("");
  const [selectEventType, setSelectEventType] = useState(1); // device List 事件类型
  const [totalRecords, setTotalRecords] = useState(0); // matadata 列表数据 总数
  const [selectCountryName, setSelectCountryName] = useState(""); // 下拉选中的国家名称
  const [selectCountryID, setSelectCountryID] = useState(""); // 下拉选中的国家ID
  const [isLoading, setIsLoading] = useState(false);
  const getRowId = (data) => data.id;

  // 表格加载
  const columns = getColumns(t, selectEventType);
  const defaultFilters = {
    pageNumber: 1,
    pageSize: 10,
  };

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const override = {
    display: "block",
    margin: "10% auto",
    borderColor: "#b37feb",
  };

  // 分页 切换上下页
  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      pageNumber: e + 1,
    });
  };

  //修改每页的数量
  const handlePageSize = (e) => {
    setFilters({
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (selectEventType === 2) {
      let params = {
        pageNumber: 1,
        pageSize: 10,
      };

      if (selectCountryID) {
        params.countryId = selectCountryID;
      }

      if (searchName) {
        params.name = searchName;
      }
      getStateList(params).then((res) => {
        // console.log(".................", res.data);
        if (res?.data?.code === "LVLI0000") {
          setData(res?.data?.data?.objects);
          setTotalRecords(res?.data?.data?.totalCount);
          setIsLoading(false);
        }
      });
    } else if (selectEventType === 3) {
      let params = {
        pageNumber: 1,
        pageSize: 10,
      };

      if (selectCountryID) {
        params.stateId = selectCountryID;
      }

      if (searchName) {
        params.name = searchName;
      }
      getCityList(params).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setData(res?.data?.data?.objects);
          setTotalRecords(res?.data?.data?.totalCount);
          setIsLoading(false);
        }
      });
    } else if (selectEventType === 4) {
      let params = {
        name: searchName,
        pageNumber: 1,
        pageSize: 10,
      };
      getOutletType(params).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setData(res?.data?.data?.objects);
          setTotalRecords(res?.data?.data?.totalCount);
          setIsLoading(false);
        }
      });
    } else if (selectEventType === 5) {
      let params = {
        name: searchName,
        pageNumber: 1,
        pageSize: 10,
      };
      getCityZone(params).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setData(res?.data?.data?.objects);
          setTotalRecords(res?.data?.data?.totalCount);
          setIsLoading(false);
        }
      });
    } else {
      const params = {
        name: searchName,
        pageNumber: 1,
        pageSize: 10,
      };
      getCountryList(params).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setData(res?.data?.data?.objects);
          setTotalRecords(res?.data?.data?.totalCount);
          setIsLoading(false);
        }
      });
    }
  };

  // const handleSubmit = () => {
  //   setFilters({ pageNumber: 1, pageSize: 10 });
  //   let params = {
  //     name: searchName,
  //     ...filters,
  //   };

  //   switch (selectEventType) {
  //     case 2:
  //       if (selectCountryID) {
  //         params.countryId = selectCountryID;
  //       }
  //       getStateList(params).then((res) => {
  //         if (res?.data?.code === "LVLI0000") {
  //           setData(res?.data?.data?.objects);
  //           setTotalRecords(res?.data?.data?.totalCount);
  //         }
  //       });
  //       break;
  //     case 3:
  //       if (selectCountryID) {
  //         params.stateId = selectCountryID;
  //       }
  //       getCityList(params).then((res) => {
  //         if (res?.data?.code === "LVLI0000") {
  //           setData(res?.data?.data?.objects);
  //           setTotalRecords(res?.data?.data?.totalCount);
  //         }
  //       });
  //       break;
  //     case 4:
  //       getOutletType(params).then((res) => {
  //         if (res?.data?.code === "LVLI0000") {
  //           setData(res?.data?.data?.objects);
  //           setTotalRecords(res?.data?.data?.totalCount);
  //         }
  //       });
  //       break;
  //     case 5:
  //       getCityZone(params).then((res) => {
  //         if (res?.data?.code === "LVLI0000") {
  //           setData(res?.data?.data?.objects);
  //           setTotalRecords(res?.data?.data?.totalCount);
  //         }
  //       });
  //       break;
  //     default:
  //       getCountryList(params).then((res) => {
  //         if (res?.data?.code === "LVLI0000") {
  //           setData(res?.data?.data?.objects);
  //           setTotalRecords(res?.data?.data?.totalCount);
  //         }
  //       });
  //   }
  // };
  const handleReset = () => {
    setSearchName("");
    setSelectCountryName("");
    setSelectCountryID("");

    switch (selectEventType) {
      case 2:
        getStateListInfo();
        break;
      case 3:
        getCityInfo();
        break;
      case 4:
        getOutletList();
        break;
      case 5:
        getCityZoneList();
        break;

      default:
        getCountryInfo();
        break;
    }
  };

  /**
   * 查询国家列表接口
   */

  const [data, setData] = useState([]);

  /**
   * 获取选中的国家 ID
   */
  const handlerCountryName = (e) => {
    setSelectCountryID(e.target.value.id);
    setSelectCountryName(e.target.value);
  };

  /**
   * 获取国家列表数据
   */
  const getCountryInfo = () => {
    const params = {
      ...filters,
    };
    setIsLoading(true);
    getCountryList(params).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setData(res?.data?.data?.objects);
        setTotalRecords(res?.data?.data?.totalCount);
        setIsLoading(false);
      }
    });
  };

  /**
   *根据国家 ID 获取省份列表数据
   */
  const getStateListInfo = () => {
    setSearchName("");
    let params = {
      ...filters,
    };

    if (selectCountryID) {
      params.countryId = selectCountryID;
    }
    setIsLoading(true);
    getStateList(params).then((res) => {
      // console.log(".................", res.data);
      if (res?.data?.code === "LVLI0000") {
        setData(res?.data?.data?.objects);
        setTotalRecords(res?.data?.data?.totalCount);
        setIsLoading(false);
      }
    });
  };

  /**
   * 根据省份ID 获取城市列表数据
   */

  const getCityInfo = () => {
    setSearchName("");
    let params = {
      ...filters,
    };

    if (selectCountryID) {
      params.stateId = selectCountryID;
    }
    setIsLoading(true);
    getCityList(params).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setData(res?.data?.data?.objects);
        setTotalRecords(res?.data?.data?.totalCount);
        setIsLoading(false);
      }
    });
  };

  /**
   *获取Outlet Type 数据列表
   */
  const getOutletList = () => {
    let params = {
      ...filters,
    };
    setIsLoading(true);
    getOutletType(params).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setData(res?.data?.data?.objects);
        setTotalRecords(res?.data?.data?.totalCount);
        setIsLoading(false);
      }
    });
  };

  /**
   * CityZone 列表数据接口
   */

  const getCityZoneList = () => {
    let params = {
      // name: searchName,
      ...filters,
    };
    setIsLoading(true);
    getCityZone(params).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setData(res?.data?.data?.objects);
        setTotalRecords(res?.data?.data?.totalCount);
        setIsLoading(false);
      }
    });
  };

  /**
   * 查询国家数据名称下拉框接口
   */
  const [countrySelectData, setCountrySelectData] = useState([]);
  const selectCountryData = () => {
    setIsLoading(true);
    selectCountry().then((res) => {
      if (res.data.code === "LVLI0000") {
        setCountrySelectData(res?.data?.data);
        setIsLoading(false);
      } else {
        setCountrySelectData([]);
        setIsLoading(false);
      }
    });
  };

  /**
   * 查询省份 列表数据名称下拉框接口
   */

  const selectStateData = () => {
    setIsLoading(true);
    selectState(selectCountryID).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCountrySelectData(res?.data?.data);
        setIsLoading(false);
      } else {
        setCountrySelectData([]);
        setIsLoading(false);
      }
    });
  };

  /**
   * 查询OutLet Type 下拉框数据接口
   */

  const selectOutletData = () => {
    selectOutLet().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCountrySelectData(res?.data?.data);
      } else {
        setCountrySelectData([]);
      }
    });
  };

  /**
   * 初始化国家接口
   */
  useEffect(() => {
    if (selectEventType === 1) {
      getCountryInfo();
    }
  }, [filters]);

  /**
   * 切换为国家数据列表
   */
  const handlerCountry = () => {
    setSelectEventType(1);
    setSelectCountryName("");
    setSelectCountryID("");
  };
  /**
   * 切换为省份列表
   */
  useEffect(() => {
    if (selectEventType === 2) {
      getStateListInfo();
    }
  }, [filters, selectCountryName]);
  const handleState = () => {
    setSelectEventType(2);
    setSelectCountryName("");
    setSelectCountryID("");
    selectCountryData();
  };

  /**
   * 切换为城市列表
   */

  useEffect(() => {
    if (selectEventType === 3) {
      getCityInfo();
    }
  }, [filters, selectCountryName]);

  const handlerCity = () => {
    setSelectEventType(3);
    setSelectCountryName("");
    setSelectCountryID("");
    selectStateData();
  };
  /**
   * 切换为OutLet TYpe列表
   */

  useEffect(() => {
    if (selectEventType === 4) {
      getOutletList();
    }
  }, [filters]);

  useEffect(() => {
    setFilters({ pageNumber: 1, pageSize: 10 });
  }, [selectEventType]);
  const handlerOutLet = () => {
    setSelectEventType(4);
    // selectOutletData();
    // getOutletList();
  };

  useEffect(() => {
    if (selectEventType === 5) {
      getCityZoneList();
    }
  }, [filters]);
  /**
   * 切换CityZone 列表接口
   */
  const handlerCityZone = () => {
    setSelectEventType(5);
    // setSelectCountryName(null);
    // setSelectCountryID(null);
    // getCityZoneList();
  };

  /**
   *同步按钮 接口
   同步 城市、省份、国家、outletType 到L3
   */
  const handlerListData = () => {
    setIsLoading(true);
    getDataList().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setIsLoading(false);
        window.location.reload();
      }
    });
  };

  return (
    <>
      <ListLayout navigateBack={false} title={t("LVLDB0002")}>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Grid container spacing={2}>
            <Grid item>
              {/* <AccessControl authorization={"1043"}> */}
              <Button
                style={{
                  width: "120px",
                  height: "40px",
                  background: "#fff",
                }}
                onClick={handlerCountry}
              >
                {t("LVLDAC0023")}
                {selectEventType === 1 && (
                  <TickMarkImg
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                    height={12}
                  />
                )}
              </Button>
              {/* </AccessControl> */}
            </Grid>
            <Grid item>
              {/* <AccessControl authorization={"1044"}> */}
              <Button
                style={{
                  width: "120px",
                  height: "40px",
                  background: "#fff",
                }}
                onClick={handleState}
              >
                {t("LVLLOC0050")}
                {selectEventType === 2 && (
                  <TickMarkImg
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                    height={12}
                  />
                )}
              </Button>
              {/* </AccessControl> */}
            </Grid>

            <Grid item>
              {/* <AccessControl authorization={"1045"}> */}
              <Button
                style={{
                  width: "120px",
                  height: "40px",
                  background: "#fff",
                }}
                onClick={handlerCity}
              >
                {t("LVLLOC0051")}
                {selectEventType === 3 && (
                  <TickMarkImg
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                    height={12}
                  />
                )}
              </Button>
              {/* </AccessControl> */}
            </Grid>

            <Grid item>
              {/* <AccessControl authorization={"1046"}> */}
              <Button
                style={{
                  width: "120px",
                  height: "40px",
                  background: "#fff",
                }}
                onClick={handlerCityZone}
              >
                {t("LVLLOC0052")}
                {selectEventType === 5 && (
                  <TickMarkImg
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                    height={12}
                  />
                )}
              </Button>
              {/* </AccessControl> */}
            </Grid>

            <Grid item>
              {/* <AccessControl authorization={"1047"}> */}
              <Button
                style={{
                  width: "120px",
                  height: "40px",
                  background: "#fff",
                }}
                onClick={handlerOutLet}
              >
                {t("LVLLOC0053")}
                {selectEventType === 4 && (
                  <TickMarkImg
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                    height={12}
                  />
                )}
              </Button>
              {/* </AccessControl> */}
            </Grid>

            <Grid>
              {selectEventType === 1 ||
              selectEventType === 4 ||
              selectEventType === 5 ? (
                ""
              ) : (
                <FormControl
                  fullWidth={true}
                  sx={{
                    minWidth: 140,
                    marginLeft: "30px",
                    marginTop: "20px",
                  }}
                >
                  <InputLabel
                    sx={{
                      marginTop: "-10px",
                    }}
                  >
                    {t("LVLDB0002")}
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={selectCountryName}
                    onChange={handlerCountryName}
                    endAdornment={
                      selectCountryName && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setSelectCountryName(null);
                              setSelectCountryID(null);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  >
                    {countrySelectData?.map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>

          <Box display={"flex"} width={600}>
            <TextField
              size="small"
              label={t("LVLDB0019")}
              placeholder={t("PLACE01")}
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            ></TextField>
            <Box sx={{ margin: "5px 35px", display: "flex" }}>
              <Button
                variant="contained"
                sx={{ marginLeft: 1, p: 0.5 }}
                style={{
                  height: "28px",
                }}
                onClick={handleSubmit}
              >
                {t("LVLGF0009")}
              </Button>

              <Button
                style={{
                  height: "28px",
                }}
                sx={{ marginLeft: 1, p: 0.5 }}
                variant="outlined"
                onClick={handleReset}
              >
                {t("LVLGF0008")}
              </Button>
            </Box>
          </Box>
          <AccessControl authorization={"1048"}>
            <Button
              variant="contained"
              sx={{ marginLeft: 1, p: 1 }}
              onClick={handlerListData}
            >
              {t("LOCATION13")}
            </Button>
          </AccessControl>
        </Grid>

        <Grid>
          {isLoading ? (
            <RingLoader
              color={"#597ef7"}
              loading={isLoading}
              cssOverride={override}
              size={60}
              speedMultiplier={3}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <DataTable
              height="auto"
              columns={columns}
              rows={data || []}
              getRowId={getRowId}
              onSelection={() => console.log("1111111")}
              page={filters.pageNumber - 1}
              totalRecords={totalRecords}
              rowsPerPage={filters.pageSize}
              onPageChange={(pn) => handlePageChange(pn)}
              onPageSizeChange={(ps) => handlePageSize(ps)}
              checkboxSelection={false}
            />
          )}
        </Grid>
      </ListLayout>
    </>
  );
}

export default Location;
