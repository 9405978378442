import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  Grid,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import CustomDatePicker from "../../PeopleCountingSummary/Component/StartTimeSelect";
import { isToday } from "@/util/utils.js";
import CommonUtil from "@/util/CommonUtils";
import { useTranslation } from "react-i18next";
import {
  endOfMonth,
  format,
  startOfMonth,
  add,
  subMonths,
  subDays,
} from "date-fns"; // 时间库
import { useSnackbar } from "notistack";
const DialogTime = ({
  open,
  setOpen,
  setStartTimes,
  setEndTimes,
  setSelectTime,
  setEndValueTime,
  setStartValueTime,
  startTimes,
  selectTime,
  setTimeType,
  timeType,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [startTime, setStartTime] = useState("");
  const [endTime, seEndTime] = useState("");

  const selectChangeTime = (e) => {
    setSelectTime(e.target.value);
  };

  useEffect(() => {
    if (selectTime === "1") {
      setTimeType(3);
    } else {
      setTimeType(2);
    }
  }, [selectTime]);

  // useEffect(() => {
  //   const firstOfMonth = startOfMonth(new Date());
  //   const formattedFirstOfMonth = format(firstOfMonth, "yyyy/MM/dd");
  //   setStartTime(formattedFirstOfMonth);

  //   const endOfMonthDay = endOfMonth(new Date());
  //   const formattedEndOfMonthDay = format(endOfMonthDay, "yyyy/MM/dd");
  //   seEndTime(formattedEndOfMonthDay);
  // }, [selectTime]);

  const [startTimeValue, setStartValue] = useState("");
  const [endTimeValue, setEndtimeValue] = useState("");

  //选择当前时间方法
  const startChangeDate = (e) => {
    // if (isToday(e)) {
    //   enqueueSnackbar("Today's date is not optional", {
    //     variant: "warning",
    //   });
    //   return;
    // }
    if (selectTime == 1) {
      //当选择Day 时做以下处理
      let currentStr = CommonUtil.dateFormat(e, "yyyy/MM/dd");
      setStartTime(currentStr);
      seEndTime(currentStr);

      const currentDate = new Date(currentStr);
      const previousWeek = subDays(currentDate, 1);

      const previousWeekStr = format(previousWeek, "yyyy/MM/dd");

      setStartValue(previousWeekStr);
      setEndtimeValue(previousWeekStr);
    } else {
      //当选择Month 时做以下处理
      let currentStr = CommonUtil.dateFormat(e, "yyyy/MM/dd");
      setStartTime(currentStr);

      // 限制掉 Week 时间选择范围
      const lastDayOfMonth = endOfMonth(new Date(e));
      const formattedDate = format(lastDayOfMonth, "yyyy/MM/dd");
      seEndTime(formattedDate);

      /**
       * 选择完时间后，比较时间默认为当前时间的上一个月
       */

      const currentDate = new Date(currentStr);
      const previousMonth = subMonths(currentDate, 1);
      const previousMonthStr = format(previousMonth, "yyyy/MM/dd");
      const previousMonthLastDay = endOfMonth(previousMonth);
      const previousMonthLastDayStr = format(
        previousMonthLastDay,
        "yyyy/MM/dd"
      );

      setStartValue(previousMonthStr);
      setEndtimeValue(previousMonthLastDayStr);
      // setStartValueTime(previousMonthStr);
      // setEndValueTime(previousMonthLastDayStr);
    }
  };

  //点击确定回显
  const handklerConfirm = () => {
    setStartTimes(startTime);
    setEndTimes(endTime);
    setStartValueTime(startTimeValue);
    setEndValueTime(endTimeValue);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle fontSize={24}>{t("PCS25")}</DialogTitle>
        <DialogContent>
          <Grid marginBottom={2}>
            <RadioGroup row value={selectTime} onChange={selectChangeTime}>
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={t("PCS32")}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={t("PCS27")}
              />
            </RadioGroup>
          </Grid>
          <Grid display={"flex"}>
            <Box sx={{ width: "200px", marginRight: "30px" }}>
              <CustomDatePicker
                size={"small"}
                disableFuture={true}
                // date={date}
                disabled={false}
                fullWidth={true}
                type={selectTime}
                label={""}
                placeholder={t("LVLRCP016")}
                SelectedDate={(e) => startChangeDate(e)}
              />
            </Box>

            {/* {selectTime == 1 ? (
              <Grid>
                <Box sx={{ lineHeight: "40px" }}>——</Box>
                <Box
                  sx={{
                    width: "200px",
                    marginLeft: "50px",
                    marginTop: "-38px",
                  }}
                >
                  <CustomDatePicker
                    size={"small"}
                    disableFuture={true}
                    // date={date}
                    fullWidth={true}
                    type={selectTime}
                    label={""}
                    disabled={false}
                    minDate={startWeekTime}
                    maxDate={endWeekTime}
                    placeholder={t("LVLRCP016")}
                    SelectedDate={(e) => endChangeDate(e)}
                  />
                </Box>
              </Grid>
            ) : (
              ""
            )} */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t("LVLDB0012")}</Button>
          <Button autoFocus onClick={handklerConfirm}>
            {t("LVLDV0096")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DialogTime;
