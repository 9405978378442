import RightViewLayout from "@/components/RighViewLayout";
import React, { useState, useRef, useEffect } from "react";
import { REACT_DEVICE_LIST } from "@/router/ReactEndPoints";
import { useTranslation } from "react-i18next";
import { getChannelSetting, saveSenceSetting } from "@/services/DeviceService";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  FormControl,
  Select,
  Button,
  Card,
  MenuItem,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useSnackbar } from "notistack";
function ChannelSetting(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]); //加载数据
  const [selectChannelNum, setSelectChannelNum] = useState({}); //选中的通道
  const [selectSence, setSelectSence] = useState(null); //选择设备场景 店内 或者 店外

  useEffect(() => {
    getChannelSetting(location.state.id)
      .then((res) => {
        if (res.data.data.length !== 0) {
          setData(res?.data?.data);
          setSelectChannelNum(res?.data?.data[0]);
          setSelectSence(res?.data?.data[0].scene);
        } else {
          enqueueSnackbar(t("PCS97"), {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err, {
          variant: "error",
        });
      });
  }, []);

  const handleChange = (e) => {
    setSelectChannelNum(e.target.value);
    setSelectSence(e.target.value.scene);
  };

  const changeSence = (e) => {
    setSelectSence(e.target.value);
  };

  const handleSubmit = () => {
    let params = {
      id: selectChannelNum?.id,
      deviceId: location?.state?.id,
      channelNum:
        selectChannelNum?.channelNum === undefined
          ? selectChannelNum
          : selectChannelNum?.channelNum,
      scene: selectSence,
    };

    saveSenceSetting(params).then((res) => {
      if (res.data.code === "LVLE0000") {
        enqueueSnackbar(res.data.message, {
          variant: "error",
        });
      }

      if (res.data.code === "LVLI0000") {
        enqueueSnackbar(res.data.message, {
          variant: "success",
        });

        navigate(REACT_DEVICE_LIST);
      }
    });
  };
  const renderedChannelNums = data?.map((item) => item.channelNum);
  return (
    <RightViewLayout navigateBack={REACT_DEVICE_LIST} title={t("LVLDV0108")}>
      <Card container style={{ borderRadius: "10px" }}>
        <Grid display={"flex"}>
          <Grid item xs={1.5} m={3}>
            <FormControl fullWidth={true}>
              <Typography sx={{ mr: 2 }}>{t("RTMT0021")}</Typography>
              <Select
                id="demo-simple-select"
                value={selectChannelNum}
                sx={{ marginTop: "10px" }}
                onChange={handleChange}
              >
                {data?.map((item) => (
                  <MenuItem key={item.id} value={item}>
                    {item.channelNum}
                  </MenuItem>
                ))}

                {[...Array(8).keys()].map((num) => {
                  const channelNum = String(num + 1);
                  // 如果 renderedChannelNums 中已经包含了当前数字，则不需要再渲染
                  if (renderedChannelNums.includes(channelNum)) {
                    return null;
                  }
                  return (
                    <MenuItem key={channelNum} value={channelNum}>
                      {channelNum}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={1.5} m={6}>
            <RadioGroup row value={selectSence} onChange={changeSence}>
              <FormControlLabel
                value="0"
                control={<Radio />}
                label={t("PCS71")}
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={t("PCS72")}
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid m={3} container px={15} display={"flex"}>
          <Button variant="contained" size="large" onClick={handleSubmit}>
            {t("LVL0013")}
          </Button>

          <Button
            variant="outlined"
            onClick={() => navigate(REACT_DEVICE_LIST)}
            size="large"
            sx={{ marginLeft: "1vw" }}
          >
            {t("LVLDB0012")}
          </Button>
        </Grid>
      </Card>
    </RightViewLayout>
  );
}

export default ChannelSetting;
