import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import bbb from "@/assets/bbb.png";
import ccc from "@/assets/ccc.png";
function AdditionalInformation() {
  const { t } = useTranslation();
  const bgColor = {
    borderRadius: "20px",
    background: "#f9fcff",
    fontSize: "0.3rem",
    fontWeight: 700,
    marginLeft: "1vw",
    boxShadow: "8px 8px 10px 6px rgba(0, 0, 0, 0.4)",
    marginTop: "3vh",
  };

  const imgStyle = {
    width: "13vw",
    borderRadius: "10px",
    height: "18vh",
    marginBottom: "2vh",
  };
  return (
    <Grid>
      <Box sx={bgColor}>
        <Box marginLeft={3} fontSize={30}>
          {t("PCS33")}
        </Box>
        <Box marginLeft={3}>{t("PCS50")}</Box>
        <Box m={2.5}>
          <img
            id="snapImage"
            src={bbb}
            style={{ width: "27.7vw", borderRadius: "10px" }}
          />
        </Box>
        <Box display={"flex"}>
          <Box marginLeft={3}>
            <Box>{t("PCS34")}</Box>
            <Box>
              <img id="snapImage" src={ccc} style={imgStyle} />
            </Box>
          </Box>
          <Box marginLeft={3}>
            <Box>{t("PCS35")}</Box>
            <Box>
              <img id="snapImage" src={ccc} style={imgStyle} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
export default AdditionalInformation;
