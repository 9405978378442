import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Paper, Typography, Card } from "@mui/material";
import BarCharts from "./BarChart";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useTranslation } from "react-i18next";
import AppLanguage from "@/lang/AppLanguages";
import { parseNumber } from "@/util/parseNumber";
import AduitLineCaharts from "./AduitLineCaharts";
function Aduit(props) {
  const {
    aduitData,
    previousPeriodsData,
    kidsRatio,
    adultRatio,
    startValueTime,
    startTimes,
  } = props;
  const { t } = useTranslation();
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");

  useEffect(() => {
    const compareTime = new Date(startValueTime);
    const times = new Date(startTimes);

    const language = AppLanguage.getPrevLanguage();
    let startMonth;
    let endMonth;
    if ("zh" === language) {
      startMonth = compareTime.toLocaleString("zh-CN", {
        month: "short",
      });
      endMonth = times.toLocaleString("zh-CN", { month: "short" });
    } else {
      startMonth = compareTime
        .toLocaleString("en-US", {
          month: "short",
        })
        .toUpperCase();
      endMonth = times
        .toLocaleString("en-US", { month: "short" })
        .toUpperCase();
    }
    setStartMonth(startMonth);
    setEndMonth(endMonth);
  }, [startTimes, startValueTime]);

  return (
    <Grid
      sx={{
        bgcolor: "#fff",
        border: "1px solid #eaebed",
        borderRadius: "0.2rem",
        padding: "0.5rem 0.6rem",
      }}
    >
      <Typography variant="h2" component="h2">
        {t("PCS135")}
      </Typography>
      <Typography variant="h3" component="h3">
        {startMonth} - {endMonth}
      </Typography>

      <Grid display={"flex"} columns={24} flexGrow={1} mt={2}>
        <Grid size={6}>
          <Grid
            sx={{
              background: "#fff",
              textAlign: "center",
              borderRadius: "15px",
              marginLeft: "1vw",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.4)",
              padding: "0.2rem 1rem",
            }}
          >
            <Box
              sx={{
                padding: "10px 20px",
                fontSize: "0.4rem",
                whiteSpace: "nowrap",
              }}
            >
              {t("PCS137")}
            </Box>

            <Box
              sx={{
                whiteSpace: "nowrap",
                color: "#fff",
                padding: "0.3rem 0.5rem",
                fontSize: "0.5rem",
                borderRadius: "8px",
                fontWeight: 700,
                background:
                  "linear-gradient(to right, #37c1e0 0%, #21a5d5 100%)",
                textAlign: "center",
              }}
            >
              {adultRatio}%
            </Box>
          </Grid>

          <Grid
            sx={{
              background: "#fff",
              textAlign: "center",
              borderRadius: "15px",
              marginLeft: "1vw",
              marginTop: "2vh",
              padding: "0.2rem 1rem",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.4)",
            }}
          >
            <Box
              sx={{
                padding: "10px 20px",
                fontSize: "0.4rem",
                whiteSpace: "nowrap",
              }}
              mt={1}
            >
              {t("PCS138")}
            </Box>

            <Box
              sx={{
                color: "#fff",
                borderRadius: "8px",
                background:
                  "linear-gradient(to right, rgba(156,151,252,1) 0%, rgba(109,101,198,1) 100%);",
                textAlign: "center",
                padding: "0.3rem 0.5rem",
              }}
            >
              <Box sx={{ fontSize: "0.5rem", fontWeight: 700 }}>
                {parseNumber(kidsRatio) + "%"}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid ml={2} flex={1}>
          <AduitLineCaharts
            aduitData={aduitData}
            previousPeriodsData={previousPeriodsData}
          ></AduitLineCaharts>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Aduit;
