/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState, useMemo } from "react";
import * as echarts from "echarts";
import "echarts-liquidfill";
import { useTranslation } from "react-i18next";
const LineCharts = ({ conversionRateList }) => {
  const chartRef = useRef(null);
  const tiemData = conversionRateList?.map((item) => item.event_date);
  const growth = conversionRateList?.map((item) => item.c_conversion_rate);
  const peopleCount = conversionRateList?.map((item) => item.p_conversion_rate);

  const { t } = useTranslation();

  const getOptions = () => {
    let option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (params) {
          var result = params[0].name + "<br/>"; // 如果需要显示横坐标的值，可以通过 params[0].name 获取
          params.forEach(function (item) {
            // 在悬浮框中显示的内容格式化为百分比
            result +=
              item.seriesName + ": " + item.value.toFixed(2) + "%" + "<br/>"; // 保留两位小数，并添加百分号
          });
          return result;
        },
      },
      grid: {
        top: "10%",
        right: "3%",
        left: "5%",
        bottom: "30%",
      },

      legend: {
        data: [t("PCS19"), t("PCS20")],
        bottom: "10%",
        textStyle: {
          color: "#747474",
        },
        icon: "Line",
        itemGap: 80,
        itemWidth: 14,
        itemHeight: 14,
        borderRadius: 5,
      },
      xAxis: [
        {
          type: "category",
          data: tiemData?.map((item) => item?.replace(/-/g, "/")),

          axisLine: {
            lineStyle: {
              color: "#7e9fb6",
            },
          },
          axisLabel: {
            color: "#7e9fb6",
            fontSize: 14,
            interval: 0, // 显示所有标签
            margin: 20, // 标签与轴的距离
            align: "center", // 标签对齐方式（可选）
          },
        },
      ],
      yAxis: [
        {
          axisLabel: {
            color: "#7e9fb6",
            formatter: "{value}%",
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#7e9fb6",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(126,159,182,0.12)",
            },
          },
        },
      ],
      series: [
        {
          name: t("PCS19"),
          type: "line",
          data: growth,
          showAllSymbol: true,
          showSymbol: false,
          symbol: "line",
          lineStyle: {
            color: "#5470c6",
            width: 5,
          },

          // animation: animationValue,
        },

        {
          name: t("PCS20"),
          type: "line",
          data: peopleCount,
          showAllSymbol: true,
          showSymbol: false,
          symbol: "line",
          lineStyle: {
            color: "#91cc75",
            width: 5,
          },

          // animation: animationValue,
        },
      ],

      dataZoom: [
        {
          // 设置滚动条的隐藏与显示
          show: conversionRateList.length > 3,
          // 设置滚动条类型
          type: "slider",
          // 设置背景颜色
          backgroundColor: "#ccc",
          // 设置选中范围的填充颜色
          fillerColor: "#ccc",
          // 设置边框颜色
          borderColor: "#ccc",
          // 是否显示detail，即拖拽时候显示详细数值信息
          showDetail: false,
          // 数据窗口范围的起始数值
          startValue: 0,
          // 数据窗口范围的结束数值（一页显示多少条数据）
          endValue: 6,
          // empty：当前数据窗口外的数据，被设置为空。
          // 即不会影响其他轴的数据范围
          filterMode: "empty",
          // 设置滚动条宽度，相对于盒子宽度
          width: "30%",
          // 设置滚动条高度
          height: 3,
          // 设置滚动条显示位置
          left: "center",
          // 是否锁定选择区域（或叫做数据窗口）的大小
          zoomLoxk: true,
          // 控制手柄的尺寸
          handleSize: 0,
          // dataZoom-slider组件离容器下侧的距离
          bottom: 100,
        },
        {
          // 没有下面这块的话，只能拖动滚动条，
          // 鼠标滚轮在区域内不能控制外部滚动条
          type: "inside",
          // 滚轮是否触发缩放
          zoomOnMouseWheel: false,
          // 鼠标滚轮触发滚动
          moveOnMouseMove: true,
          moveOnMouseWheel: true,
        },
      ],
    };

    return option;
  };
  const options = useMemo(() => getOptions(), [conversionRateList]);
  useEffect(() => {
    let myEcharts = null;
    const initChart = () => {
      myEcharts = echarts.init(chartRef.current, null, { renderer: "svg" });
      // 设置初始大小
      myEcharts.resize();
      // 监听窗口大小变化，自动调整图表大小
      window.addEventListener("resize", handleResize);
      // const options = getOptions();
      myEcharts.setOption(options);
    };
    const handleResize = () => {
      myEcharts.resize();
    };
    // 在组件挂载时进行初始化
    initChart();

    // 在组件卸载时移除事件监听
    return () => {
      window.removeEventListener("resize", handleResize);
      myEcharts.dispose();
    };
  }, [conversionRateList]);
  return (
    <div
      ref={chartRef}
      className="LineChatsStyle"
      style={{
        width: "100%",
        height: "500px",
        marginLeft: "0.8vw",
      }}
    ></div>
  );
};

export default LineCharts;
