import { Avatar, Tooltip, Popover, Button } from "@mui/material";
import CommonUtil from "@/util/CommonUtils";
import { useState, useEffect } from "react";
export const getColumns = (t, selectEventType) => {
  let columns = [];
  if (selectEventType === 1) {
    columns.push(
      {
        field: "CountryName",
        headerName: t("LVLLOC0001"),
        flex: 1,
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: (e) => (
          <Tooltip title={e.row.name} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.name)}</span>
          </Tooltip>
        ),
      },
      {
        field: "CountryCode",
        headerName: t("LVLRC0020"),
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => (
          <Tooltip title={e.row.code} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.code)}</span>
          </Tooltip>
        ),
      }
    );
  }

  if (selectEventType === 2) {
    columns.push(
      {
        field: "StateName",
        headerName: t("LVLLOC0008"),
        flex: 1,
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: (e) => (
          <Tooltip title={e.row.name} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.name)}</span>
          </Tooltip>
        ),
      },
      {
        field: "StateCode",
        headerName: t("LVLLOC0009"),
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => (
          <Tooltip title={e.row.code} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.code)}</span>
          </Tooltip>
        ),
      },
      {
        field: "CountryName",
        headerName: t("LVLLOC0001"),
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => {
          return (
            <Tooltip title={e.row?.countryVO?.name} arrow placement="bottom">
              <span>{CommonUtil.formatLongText(e.row?.countryVO?.name)}</span>
            </Tooltip>
          );
        },
      }
    );
  }

  if (selectEventType === 3) {
    columns.push(
      {
        field: "CityName",
        headerName: t("LVLLOC0015"),
        flex: 1,
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: (e) => (
          <Tooltip title={e.row.name} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.name)}</span>
          </Tooltip>
        ),
      },
      {
        field: "CityCode",
        headerName: t("LVLLOC0016"),
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => (
          <Tooltip title={e.row.code} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.code)}</span>
          </Tooltip>
        ),
      },
      {
        field: "StateName",
        headerName: t("LVLLOC0008"),
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => {
          return (
            <Tooltip title={e.row?.stateVO?.name} arrow placement="bottom">
              <span>{CommonUtil.formatLongText(e.row?.stateVO?.name)}</span>
            </Tooltip>
          );
        },
      }
    );
  }

  if (selectEventType === 4) {
    columns.push(
      {
        field: "OutletType",
        headerName: t("LVLOT0004"),
        flex: 1,
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: (e) => (
          <Tooltip title={e.row.name} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.name)}</span>
          </Tooltip>
        ),
      },
      {
        field: "OutletCode",
        headerName: t("LOCATION10"),
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => (
          <Tooltip title={e.row.code} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.code)}</span>
          </Tooltip>
        ),
      }
    );
  }

  if (selectEventType === 5) {
    columns.push(
      {
        field: "name",
        headerName: t("LOCATION11"),
        flex: 1,
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: (e) => {
          return (
            <Tooltip title={e.row.name} arrow placement="bottom">
              <span>{CommonUtil.formatLongText(e.row.name)}</span>
            </Tooltip>
          );
        },
      },
      {
        field: "code",
        headerName: t("LOCATION12"),
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => (
          <Tooltip title={e.row.code} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.code)}</span>
          </Tooltip>
        ),
      }
    );
  }
  return columns;
};
