import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import IconHandaler from "../../components/IconHandaler";
import {
  Tooltip,
  Grid,
  Box,
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  Typography,
  DialogTitle,
} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ListLayout from "../../components/ListLayout";
import RingLoader from "react-spinners/RingLoader";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as AddIcon } from "@/assets/images/icon_add.svg";
import { ReactComponent as RefreshIcon } from "@/assets/images/icon_refresh.svg";
import {
  REACT_DEVICE_ADD_INSTRUCTIONS,
  REACT_DEVICE_DETAILS,
  REACT_DEVICE_LIST,
  REACT_DEVICE_EDIT,
  REACT_DEVICE_SETTING,
  REACT_CHANNEL_SETTING,
} from "@/router/ReactEndPoints";
import CommonUtil from "../../util/CommonUtils";
import { deviceList, unBindDevice } from "@/services/DeviceService";
import MoreOption from "../../components/DataTableMoreOption";
import AccessControl from "@/components/AccessControl";
import { useTranslation } from "react-i18next";
import { filterOutlet, findOutletById } from "../../services/OutletService";
import { useSnackbar } from "notistack";
import { confirmAlert } from "react-confirm-alert";
import { CircularProgress } from "@mui/material";
import ConfirmModal from "../../components/ConfirmModel";
export default function Device() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { t } = useTranslation();
  const [outlets, setOutlets] = useState([]);
  const [siteIds, setSiteIds] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [serachSn, setSerachSn] = useState("");
  const [unbindOpen, setUnBindOpen] = useState(false);
  let outlet = {};

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 5,
    id: "",
    sn: "",
    deviceModel: "",
    deviceType: "",
    accountEmail: "",
    companyId: "",
    deviceAlias: "",
    mac: "",
    siteId: "",
    zoneId: "",
    checkInOrOutType: "",
    ip: "",
    registrationDevice: "",
  });

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 5,
    id: "",
    sn: "",
    deviceModel: "",
    deviceType: "",
    accountEmail: "",
    companyId: "",
    deviceAlias: "",
    mac: "",
    siteId: "",
    zoneId: "",
    checkInOrOutType: "",
    ip: "",
    registrationDevice: "",
  };

  useEffect(() => {
    loadData();
    // const ids = records?.map(record => record.siteId);
    // setSiteIds(ids);
  }, [filters]);

  const override = {
    display: "block",
    margin: "10% auto",
    borderColor: "#b37feb",
  };

  const loadData = () => {
    setIsLoading(true);
    deviceList(filters)
      .then((res) => {
        if (res.data.code === "DMSE9999") {
          enqueueSnackbar(res.data.message, {
            variant: "warning",
          });
        }
        if (res?.data?.data) {
          // console.log("????????????????????", res.data.data);
          setRecords(res?.data?.data?.objects || []);
          setTotalRecords(res?.data?.data?.totalCount);
          setPage(res?.data?.data?.currentPage);
          setRowsPerPage(res?.data?.data?.totalCount);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const [siteFilters, setSiteFilters] = useState({
    page: 0,
    rowsPerPage: 5,
    country: "",
    state: "",
    city: "",
    cityZone: "",
    ids: [],
    companyIds:
      CommonUtil.isEmptyString(localStorage.getItem("selectedClientId")) ||
      localStorage.getItem("selectedClientId") === "-1"
        ? []
        : [localStorage.getItem("selectedClientId")],
  });

  const loadOutlet = () => {
    setSiteFilters({
      ...siteFilters,
      ids: siteIds,
    });
    setIsLoading(true);
    filterOutlet(siteFilters.page + 1, siteFilters.rowsPerPage, siteFilters)
      .then((response) => {
        if (response?.data?.code === "LMSI6000" && response.data.data) {
          setOutlets(response?.data?.data?.sites);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadOutlet();
  }, []);

  // const getOutletName = (id) => {
  //   const outlet = outlets?.find((outlet) => outlet.id === id);
  //   return outlet ? outlet.name : "";
  // };

  const getCompanyId = (siteId) => {
    const outlet = outlets.find((outlet) => outlet.id === siteId);
    return outlet?.companyId;
  };

  const [deviceId, setDeviceId] = useState("");

  const unbindDevice = (id) => {
    setUnBindOpen(true);
    setDeviceId(id);
    // confirmAlert({
    //   customUI: ({ onClose }) => {
    //     return (
    //       <ConfirmModal
    //         open={true}
    //         text={t("LVLDV0058")}
    //         onConfirm={() => {
    //           unBindDevice(id).then((res) => {
    //             if (res?.data) {
    //               enqueueSnackbar(`${t("LVLDV0057")}`, {
    //                 variant: "success",
    //               });
    //               loadData();
    //             }
    //           });
    //           onClose();
    //         }}
    //         onClose={() => onClose()}
    //       />
    //     );
    //   },
    // });
    //   }
  };

  const authorityString = localStorage.getItem("PERMISSION");
  const roleType = JSON.parse(localStorage.getItem("USER_INFO"))?.roleCode;

  const permissionArray = [];
  permissionArray.push(authorityString);
  const defaultMenuData =
    roleType === "SUPER_ADMIN"
      ? [
          { auth: "1017", label: `${t("LVLDV0047")}`, index: 0 },
          { auth: "1019", label: `${t("LVLDB0032")}`, index: 1 },
          { auth: "1020", label: `${t("LVLDV0040")}`, index: 2 },
          // { auth: "1021", label: `${t("LVLDV0041")}`, index: 3 },
          // { auth: true, label: `${t("LVLDV0042")}`, index: 4 },
          { auth: "1022", label: `${t("LVLDV0067")}`, index: 5 },
          { auth: "1023", label: `${t("LVLDV0108")}`, index: 6 },
        ]
      : permissionArray.reduce((acc, permission, index) => {
          const menus = [
            { auth: "1017", label: `${t("LVLDV0047")}`, index: 0 },
            { auth: "1019", label: `${t("LVLDB0032")}`, index: 1 },
            { auth: "1020", label: `${t("LVLDV0040")}`, index: 2 },
            // { auth: "1021", label: `${t("LVLDV0041")}`, index: 3 },
            // { auth: true, label: `${t("LVLDV0042")}`, index: 4 },
            { auth: "1022", label: `${t("LVLDV0067")}`, index: 5 },
            { auth: "1023", label: `${t("LVLDV0108")}`, index: 6 },
          ];

          const filteredMenus = menus?.filter((menu) =>
            permission?.includes(menu.auth)
          );
          return acc.concat(
            filteredMenus?.map((menu) => ({ ...menu, action: index }))
          );
        }, []);

  // const defaultMenuData = [
  //   { auth: "device:detail", label: `${t("LVLDV0047")}`, index: 0 },
  //   { auth: "device:edit", label: `${t("LVLDB0032")}`, index: 1 },
  //   { auth: "device:unbind", label: `${t("LVLDV0040")}`, index: 2 },
  //   { auth: "device:reboot", label: `${t("LVLDV0041")}`, index: 3 },
  //   { auth: "device:delete", label: `${t("LVLDV0042")}`, index: 4 },
  //   { auth: "device:setting", label: `${t("LVLDV0067")}`, index: 5 },
  // ];

  const columns = [
    {
      field: "sn",
      headerName: `${t("LVLDV0001")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.sn} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.sn)}</span>
        </Tooltip>
      ),
    },
    {
      field: "deviceAlias",
      headerName: `${t("LVLDV0002")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.deviceAlias} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.deviceAlias)}</span>
        </Tooltip>
      ),
    },
    {
      field: "deviceModel",
      headerName: `${t("LVLDV0003")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.deviceModel} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.deviceModel)}</span>
        </Tooltip>
      ),
    },
    {
      field: "deviceType",
      headerName: `${t("LVLDV0004")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.deviceType} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.deviceType)}</span>
        </Tooltip>
      ),
    },
    // {
    //   field: "accountEmail",
    //   headerName: `${t("LVLDV0079")}`,
    //   flex: 1,
    //   renderCell: (e) => (
    //     <Tooltip title={e.row.clientName} arrow placement="bottom">
    //       <span>{CommonUtil.formatLongText(e.row.clientName)}</span>
    //     </Tooltip>
    //   ),
    // },
    {
      field: "siteId",
      headerName: `${t("LVLDV0005")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.outletName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.outletName)}</span>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: `${t("LVLDV0006")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => {
        return (
          <Tooltip title={e.row.status} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.status)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "mainOrSub",
      headerName: `${t("LVLDV0109")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => {
        return (
          <Tooltip title={e.row.mainOrSub} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.mainOrSub)}</span>
          </Tooltip>
        );
      },
    },
    {
      headerName: `${t("LVLDV0007")}`,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (e) => {
        return (
          <IconHandaler>
            <MoreOption
              data={defaultMenuData.filter((menu) => {
                if (e.row.deviceModel === "T1") {
                  return menu.index !== 5 || menu.index === 6;
                } else if (e.row.deviceModel === "PC") {
                  return menu.index !== 5 && menu.index !== 6;
                } else {
                  return menu;
                }
              })}
              handleSelect={(option) => handleActions(option, e)}
            />
          </IconHandaler>
        );
      },
    },
  ];

  const handlePageChange = (e) => {
    setFilters({ ...filters, pageNumber: e + 1 });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  const handleActions = (action, data) => {
    let row = data.row;
    if (action === 0) {
      sessionStorage.setItem("viewDevice", JSON.stringify(row));
      navigate(REACT_DEVICE_DETAILS + row.sn, {
        state: row,
      });
    } else if (action === 1) {
      //后续需要添加参数id查后端回显，暂时先写死
      navigate(REACT_DEVICE_EDIT + row.sn, {
        state: row,
      });
    } else if (action === 2) {
      unbindDevice(data.row.id, data.row.siteId);
    } else if (action === 3) {
      //重启
    } else if (action === 4) {
      //固件升级 firmware upgrade
    } else if (action === 5) {
      //通道设置
      navigate(REACT_DEVICE_SETTING + row.sn, {
        state: row,
      });
    } else if (action === 6) {
      navigate(REACT_CHANNEL_SETTING, {
        state: row,
      });
    }
  };

  const toolbarProps = {
    add: true,
    filter: false,
    refresh: true,
    onAdd: (data) => {
      navigate(REACT_DEVICE_ADD_INSTRUCTIONS);
    },
    onRefresh: (data) => {
      setFilters({ ...filters, ...defaultFilters });
    },
    onFilter: (data) => {
      console.log("onFilter");
    },
  };

  const globalFilterProps = {
    onReset: () => {
      setFilters({ ...filters, ...defaultFilters });
    },
    onFilter: (filter) => {
      let companies = [];
      if (CommonUtil.isEmpty(filter)) {
        filter = { ...defaultFilters };
      } else if (filter.client !== undefined && filter.client !== "-1") {
        companies.push(filter.client);
      }
      setFilters({
        ...filters,
        siteId: filter?.outlet,
      });
      console.log(filter?.outlet);
    },
  };

  const getRowId = (device) => device.id;
  const toAdd = () => {
    navigate(REACT_DEVICE_ADD_INSTRUCTIONS);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    deviceList({ pageNumber: 1, pageSize: filters.pageSize, sn: serachSn })
      .then((res) => {
        if (res.data.code === "DMSE9999") {
          enqueueSnackbar(res.data.message, {
            variant: "warning",
          });
        }
        if (res?.data?.data) {
          setRecords(res?.data?.data?.objects || []);
          setTotalRecords(res?.data?.data?.totalCount);
          // setPage(res?.data?.data?.currentPage);
          // setRowsPerPage(res?.data?.data?.totalCount);
          setIsLoading(false);
        } else {
          setRecords([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleReset = () => {
    setSerachSn("");
    loadData();
  };
  return (
    <ListLayout
      navigateBack={false}
      title={t("MENU10")}
      // toolbarProps={toolbarProps}
    >
      <Grid
        display={"flex"}
        sx={{
          background: "#FFF",
          height: "100px",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            margin: "30px 30px",
          }}
        >
          <TextField
            size="small"
            label={t("LVLDV0077")}
            placeholder={t("LVLDV0078")}
            value={serachSn}
            onChange={(e) => setSerachSn(e.target.value)}
          ></TextField>
        </Box>

        <Box sx={{ margin: "35px 35px" }}>
          <Button
            variant="contained"
            sx={{ marginLeft: 1, p: 0.5 }}
            style={{
              height: "28px",
            }}
            onClick={handleSubmit}
          >
            {t("LVLGF0009")}
          </Button>

          <Button
            style={{
              height: "28px",
            }}
            sx={{ marginLeft: 1, p: 0.5 }}
            variant="outlined"
            onClick={handleReset}
          >
            {t("LVLGF0008")}
          </Button>
        </Box>
      </Grid>
      <Grid display={"flex"} justifyContent={"flex-end"} marginTop={2}>
        <AccessControl authorization={"1018"}>
          <Box
            item
            pr={2}
            onClick={() => {
              toAdd();
            }}
          >
            <AddIcon width={"35"} height={"35"} className="pointer" />
          </Box>
        </AccessControl>
        <Box item pr={2}>
          <RefreshIcon
            width={"35"}
            height={"35"}
            className="pointer"
            onClick={() => loadData()}
          />
        </Box>
      </Grid>
      {isLoading ? (
        <RingLoader
          color={"#597ef7"}
          loading={isLoading}
          cssOverride={override}
          size={60}
          speedMultiplier={3}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        // <AccessControl authorization={"1016"}>
        <DataTable
          columns={columns}
          rows={records}
          onSelection={() => console.log()}
          getRowId={getRowId}
          page={filters.pageNumber - 1}
          totalRecords={totalRecords}
          rowsPerPage={filters.pageSize}
          onPageChange={(pn) => handlePageChange(pn)}
          onPageSizeChange={(ps) => handlePageSize(ps)}
        />
        // </AccessControl>
      )}

      <TitleDialog
        unbindOpen={unbindOpen}
        setUnBindOpen={setUnBindOpen}
        loadData={loadData}
        deviceId={deviceId}
      ></TitleDialog>
    </ListLayout>
  );
}

const TitleDialog = ({ unbindOpen, setUnBindOpen, deviceId, loadData }) => {
  const { t } = useTranslation();
  console.log("EEEEEEEEEEEEEEEEE", deviceId);
  const { enqueueSnackbar } = useSnackbar();
  const handlerSubmit = () => {
    unBindDevice(deviceId).then((res) => {
      if (res?.data) {
        enqueueSnackbar(`${t("LVLDV0057")}`, {
          variant: "success",
        });
        setUnBindOpen(false);
        loadData();
      }
    });
  };
  return (
    <React.Fragment>
      <Dialog open={unbindOpen} onClose={() => setUnBindOpen(false)}>
        <DialogTitle>
          <Typography variant="h4" component="p">
            {t("LVLDV0058")}
          </Typography>
        </DialogTitle>

        <DialogActions>
          <Button variant="contained" size="large" onClick={handlerSubmit}>
            {t("LVLOT0016")}
          </Button>
          <Button
            variant="outlined"
            size="large"
            onClick={() => setUnBindOpen(false)}
          >
            {t("LVLOT0015")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
