import { ReactComponent as ArrowDown } from "@/assets/dashboard/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "@/assets/dashboard/ArrowUp.svg";
import { Tooltip, Typography } from "@mui/material";
export default function FormatPreValue(props) {
  let value = props.value;
  let isIcon = props.isIcon;
  if (value > 0) {
    return (
      <span
        style={{
          color: "#09BF28",
          display: "flex",
          alignItems: "center",
          ...props.style,
        }}
      >
        <Tooltip title={Math.abs(props.value) + "%"} arrow placement="bottom">
          <span style={{ marginRight: "5px" }}>{Math.abs(props.value)}%</span>
        </Tooltip>

        {isIcon ? <ArrowUp height={20}></ArrowUp> : "↑"}
      </span>
    );
  } else {
    return (
      <span
        style={{
          color: "red",
          display: "flex",
          alignItems: "center",
          ...props.style,
        }}
      >
        <Tooltip title={Math.abs(props.value) + "%"} arrow placement="bottom">
          <span style={{ marginRight: "5px" }}>{Math.abs(props.value)}%</span>
        </Tooltip>

        {isIcon ? <ArrowDown height={20}></ArrowDown> : "↓"}
      </span>
    );
  }
}
