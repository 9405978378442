import { Avatar, Box, Paper } from "@mui/material";
import React from "react";

const ViewPage = (props) => {
  return (
    <>
      <Paper
        elevation={0}
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          paddingTop: "20px",
          paddingBottom: "36px",
          paddingLeft: "26px",
          border: "1px solid #DDDDDD",
          margin: "0 1rem 2rem 0",
          overflow: "hidden",
        }}
      >
        <Box>
          <Box marginTop={"20px"}>{props.children}</Box>
        </Box>
      </Paper>
    </>
  );
};

export default ViewPage;
