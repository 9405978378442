/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-no-undef */
import React from "react";

import {
  Stack,
  Grid,
  Button,
  Typography,
  InputLabel,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
  InputBase,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
export default function UserPrivateAgree(props) {
  const { open, handleClose = () => {} } = props;
  const { t } = useTranslation();
  const content = t("data.private_agree_text");
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  return (
    <>
      <BootstrapDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h4" component="p">
            {t("data.private_agree")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
