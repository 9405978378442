import React, { useState } from "react";
import { useActionData } from "react-router-dom";

function AccessControl(props) {
  const { children, authorization } = props;

  const [roleType, setRoleType] = useState("");
  // const [authorityString, setAuthorityString] = useActionData(null);
  const authorityString = localStorage.getItem("PERMISSION");
  setTimeout(() => {
    const _roleType = JSON.parse(localStorage.getItem("USER_INFO"))?.roleCode;

    // setAuthorityString(_authorityString);
    setRoleType(_roleType);
  }, 100);

  const permissionArray = [];
  permissionArray.push(authorityString);

  // 判断是否为超级管理员
  const isSuperAdmin = roleType === "SUPER_ADMIN";

  // 检查是否有相同的权限名称或是超级管理员
  const hasCommonPermission =
    isSuperAdmin ||
    (permissionArray[0] && permissionArray[0].includes(authorization));

  return <>{isSuperAdmin || hasCommonPermission ? children : null}</>;
}

export default AccessControl;
