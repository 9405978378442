// ==============================|| OVERRIDES - BUTTON ||============================== //

import { Label } from "@mui/icons-material";

export default function Button(theme) {
  const disabledStyle = {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.grey[200],
    },
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 400,
          height: "32px",
          lineHeight: "32px",
          fontSize: "0.3rem",
        },
        label: {
          fontSize: "14px",
        },
        contained: {
          ...disabledStyle,
        },
        outlined: {
          ...disabledStyle,
        },
        containedPrimary: {
          backgroundImage:
            "linear-gradient(to right, rgb(116, 183, 75), rgb(46, 150, 230))",
        },
      },
    },
  };
}
