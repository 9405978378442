import { useState, useEffect } from 'react'
import CmpTreeSelect from './CmpTreeSelect'
import Measure from 'react-measure'
import styled from '@emotion/styled'
import {
  Box,
  Popover,
  TextField,
  FormHelperText,
  IconButton,
  InputLabel,
  Stack,
} from '@mui/material'
import RequirePoint from './RequirePoint'
import DeleteIcon from '@mui/icons-material/Close'

const StyledPopover = styled(Popover)((props) => ({
  '& .MuiPaper-root': {
    width: '100%',
    //@ts-ignore
    maxWidth: props?.__width + 'px',
    maxHeight: '18rem',
    boxShadow:
      ' 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f5f5f5',
      borderRadius: '5px',
      // boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      borderRadius: '5px',
      // outline: '1px solid slategrey'
    },
    '&::-webkit-scrollbar-button': {
      backgroundColor: '#eee',
      display: 'none',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'black',
    },
  },
}))

const isAvailableArray = (arr) => Array.isArray(arr) && arr.length > 0

function flatTree(array, parentId) {
  return array.reduce((tree, cur) => {
    // 拿到遍历数据的 children
    const { children, ...other } = cur
    // return 返回新的数组
    return [
      ...tree, // 解构 tree
      { ...other },
      // 判断当前的children是否可用，可用时直接继续调用flatTree方法，否则就是空数组
      ...(isAvailableArray(children) ? flatTree(children, cur.id) : []),
    ]
  }, [])
}

const CmpInputTreeSelect = (props) => {
  const [selected, setSelected] = useState([''])
  const [selectValue, setSelectValue] = useState([''])
  const [anchorEl, setAnchorEl] = useState(null)
  const {
    valueKey = 'id',
    labelKey = 'label',
    isClear = true,
    name,
    formik = null,
    error = null,
    multiSelect = false,
    labelPostion,
    label = '',
  } = props
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    setSelected(props.selectd)
  }, [])
  let itemList = flatTree(props.treeData)
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const selectChange = (v) => {
    if (formik) {
      let nvalue = multiSelect ? v : v[0]
      setSelected(v)
      formik.setFieldValue(name, nvalue)
    }
    setSelected(v)
  }
  const onClear = () => {
    setSelected([])
    setSelectValue('')
  }

  const selectClick = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    let result = itemList.filter((item) => {
      let currentValue = item[valueKey]
      if (selected.indexOf(currentValue) > -1) {
        return true
      } else {
        return false
      }
    })
    let valueArry = result.map((item) => {
      return item[labelKey]
    })
    setSelectValue(valueArry.toString())
  }, [selected])

  return (
    <Stack spacing={1}>
      <Stack
        sx={{
          alignItems: labelPostion === 'left' ? 'flex-start' : '',
        }}
        direction={labelPostion === 'left' ? 'row' : 'column'}
        spacing={1}
      >
        <InputLabel
          style={{
            marginTop: labelPostion === 'left' ? '12px' : '',
          }}
          htmlFor={'CmpInput_' + name}
        >
          {props.required && <RequirePoint></RequirePoint>} {label}
        </InputLabel>

        <Stack
          sx={{
            flexGrow: 1,
            width: '100%',
          }}
        >
          <Measure bounds>
            {({
              measureRef,
              contentRect: {
                bounds: { width },
              },
            }) => (
              <Box
                ref={measureRef}
                sx={{
                  width: '100%',
                }}
              >
                <TextField
                  error={Boolean(
                    (formik?.touched[name] && formik?.errors[name]) || error
                  )}
                  aria-describedby={name + '_select_popover'}
                  variant="outlined"
                  required={false}
                  label=""
                  value={selectValue}
                  name={name}
                  id="equipmentItem"
                  onClick={handleClick}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    endAdornment:
                      selectValue && isClear ? (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation()
                            onClear()
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : (
                        ''
                      ),
                  }}
                />
                <StyledPopover
                  id={name + '_select_popover'}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  __width={width}
                >
                  <CmpTreeSelect
                    valueKey={valueKey}
                    labelKey={labelKey}
                    selectChange={selectChange}
                    selectClick={selectClick}
                    multiSelect={multiSelect}
                    defaultSelected={selected}
                    treeData={props.treeData}
                  />
                </StyledPopover>
              </Box>
            )}
          </Measure>
          {((formik?.touched[name] && formik?.errors[name]) || error) && (
            <FormHelperText error id={`standard-weight-helper-text-${name}`}>
              {formik?.errors[name] || error}
            </FormHelperText>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CmpInputTreeSelect
