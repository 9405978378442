import RightViewLayout from "../../../components/RighViewLayout";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { REACT_DEVICE_LIST } from "@/router/ReactEndPoints";
import DeviceIcon from "@/assets/images/icon_device.png";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getDeviceDetail } from "@/services/DeviceService";
import { Photo } from "@mui/icons-material";
export default function DeviceDetails() {
  const { t } = useTranslation();

  const { state } = useLocation();
  const [device, setDevice] = useState({});
  const [devicePicture, setDevicePicture] = useState(null);
  useEffect(() => {
    // setDevice(state);
    getDeviceDetail(state.id).then((res) => {
      setDevice(res?.data?.data);
      setDevicePicture(res?.data?.data.photo);
    });
  }, []);

  return (
    <RightViewLayout navigateBack={REACT_DEVICE_LIST} title={t("LVLDV0047")}>
      <Card sx={{ display: "flex" }} elevation={0}>
        <CardContent sx={{ marginBottom: 1 }}>
          <Grid container xs={12} alignItems={"center"}>
            <Grid item xs={2} justifySelf={"center"}>
              <img
                alt="complex"
                src={devicePicture || DeviceIcon}
                style={{ height: "100px", width: "100px", borderRadius: "50%" }}
              />
            </Grid>
            <Grid item container xs justifyContent="center" spacing={3}>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("LVLDV0080")}
                </Typography>
                <Typography>{device.id}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("LVLDV0003")}
                </Typography>
                <Typography>{device.deviceModel}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("LVLGF0006")}
                </Typography>
                <Typography>{device?.outletName}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("LVLDV0054")}
                </Typography>
                <Typography>{device.sn}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("LVLDV0004")}
                </Typography>
                <Typography>{device.deviceModel}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("LVLDV0055")}
                </Typography>
                <Typography>{device?.outletId}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("LVLDV0002")}
                </Typography>
                <Typography>{device.deviceAlias}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("LVLDV0056")}
                </Typography>
                <Typography>{device?.clientName}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" gutterBottom color="gray">
                  {t("LVLDV0006")}
                </Typography>
                <Typography>
                  {device.status}
                  {/* {device.status === 1 ? t("LVLDV0091") : t("LVLDV0092")} */}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* <Grid pt={3} pb={4}>
                    <Typography variant="h6">{t('LVLDV0052')}</Typography>
                </Grid> */}
      {/* <DataTable
                columns={columns}
            /> */}
    </RightViewLayout>
  );
}
