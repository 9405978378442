import { Box, Grid, Paper, Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Space } from "antd";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ReactApexChart from "react-apexcharts";
import FormatPreValue from "./FormatPreValue";
import {
  getValue,
  gainAllDateBetRange,
  setOption,
  preRange,
  preLastYear,
} from "./utils";
const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);

export default function EntryFlowTrend() {
  const [compareType, setCompareType] = useState("none");
  const [rangeValue, setRangeValue] = useState([]);
  const [preRangeValue, setPreRangeValue] = useState([]);

  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [dateArr, setDateArr] = useState([]);

  const [rightChart, setRightChart] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (compareType === "none") {
      loadData();
    } else {
      loadCompareData();
    }
  }, [compareType, dateArr]);

  const renderData = () => {
    return dateArr.map(() => {
      return getValue();
    });
  };

  const loadData = () => {
    const series = [
      {
        type: "bar", //render a line chart for this data
        name: "Entry Number",
        barWidth: 20,
        data: renderData(),
      },
    ];
    const options = {
      xaxis: {
        categories: dateArr,
      },
      stroke: {
        width: 2,
      },
      colors: ["#768CD1", "#9A9A9A", "#E31616"],
      plotOptions: {
        bar: {
          columnWidth: 30,
        },
      },
    };

    setOption(options, dateArr);
    if (dateArr.length < 1) {
      setRightChart([]);
    } else {
      setRightChart([
        {
          value: "35,000",
          title: "Average",
          bgColor: "#FEF7E9",
        },
        {
          value: "35,000",
          title: "Highest (11/09/2023)",
          bgColor: "#EDF7E9",
        },
        {
          value: "35,000",
          title: "Lowest (18/09/2023)",
          bgColor: "#FFE2E5",
        },
      ]);
    }

    setSeries(series);
    setOptions(options);
  };

  const loadCompareData = () => {
    const series = [
      {
        type: "bar", //render a line chart for this data
        name: "Entry Number",
        data: renderData(),
      },
      {
        type: "bar", //use column chart here.
        name: "Previous Period",
        data: renderData(),
      },
      {
        type: "line", //render a line chart for this data
        name: "Growth Rate",
        data: renderData(),
      },
    ];
    const options = {
      xaxis: {
        categories: dateArr,
      },
      colors: ["#768CD1", "#9A9A9A", "#E31616"],
      stroke: {
        width: 2,
      },
    };
    setOption(options, dateArr);
    if (dateArr.length < 1) {
      setRightChart([]);
    } else {
      setRightChart([
        {
          value: "20",
          title: "Overall Change",
          bgColor: "#FFE2E5",
        },
        {
          value: "40",
          title: "Highest Increase (18/09/2023)",
          bgColor: "#EDF7E9",
        },
        {
          value: "25",
          title: "Highest Decrease (25/09/2023)",
          bgColor: "#FFE2E5",
        },
      ]);
    }

    setSeries(series);
    setOptions(options);
  };

  const handleChange = (e) => {
    let value = e.target.value;
    setCompareType(value);
    if (value === "none") {
      setPreRangeValue([]);
    } else if (value === "previous") {
      setPreRangeValue(preRange(rangeValue));
    } else if (value === "LastYear") {
      setPreRangeValue(preLastYear(rangeValue));
    }
  };

  const RangePickerChange = (e) => {
    setRangeValue(e);
    if (e && e.length === 2) {
      if (compareType === "none") {
        setPreRangeValue([]);
      } else if (compareType === "previous") {
        setPreRangeValue(preRange(e));
      } else if (compareType === "LastYear") {
        setPreRangeValue(preLastYear(e));
      }
      let attr = gainAllDateBetRange(e);
      setDateArr(attr);
    } else {
      setDateArr([]);
    }
  };
  let boxStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Box p={2} component={Paper}>
      <Box m={2}>
        <Typography
          style={{
            fontWeight: 400,
          }}
          variant="title"
        >
          Entry Flow Trend
        </Typography>
      </Box>

      <Grid
        m={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            m: 1,
          }}
        >
          <Typography sx={{ mr: 2 }}>Date Range</Typography>
          <RangePicker
            value={rangeValue}
            onChange={(e) => {
              RangePickerChange(e);
            }}
            format="DD/MM/YYYY"
          />
        </Grid>

        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            m: 1,
          }}
        >
          <Typography sx={{ mr: 2 }}>Compare with</Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={compareType}
            label=""
            sx={{
              p: 0,
            }}
            onChange={handleChange}
          >
            <MenuItem value={"none"}>None</MenuItem>
            <MenuItem value={"previous"}>Previous Period</MenuItem>
            <MenuItem value={"LastYear"}>Last Year</MenuItem>
          </Select>
        </Grid>

        {compareType !== "none" && (
          <Grid m={1}>
            <RangePicker
              value={preRangeValue}
              disabled={true}
              format="DD/MM/YYYY"
            />
          </Grid>
        )}
      </Grid>

      <Grid container>
        <Grid lg={dateArr.length > 0 ? 9 : 12} sm="12" item>
          <ReactApexChart options={options} series={series} height={450} />
        </Grid>
        {dateArr.length > 0 && (
          <Grid lg="3" sm="12" item>
            {compareType === "none" ? (
              <Grid
                p={3}
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {rightChart.map((item) => {
                  return (
                    <Box
                      sx={{
                        mb: 2,
                        height: "100px",
                        backgroundColor: item.bgColor,
                        borderRadius: "10px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          mt: 2,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <FormatPreValue
                          style={{ fontSize: "20px" }}
                          value={getValue(true)}
                          isIcon={true}
                        ></FormatPreValue>
                      </Typography>
                      <Typography
                        mt={2}
                        sx={{
                          color: "#474B4F",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  );
                })}
              </Grid>
            ) : (
              <Grid
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                p={3}
              >
                {rightChart.map((item) => {
                  return (
                    <Box
                      sx={{
                        mb: 2,
                        height: "100px",
                        backgroundColor: item.bgColor,
                        borderRadius: "10px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          mt: 2,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <FormatPreValue
                          style={{ fontSize: "20px" }}
                          value={getValue(true)}
                          isIcon={true}
                        ></FormatPreValue>
                      </Typography>
                      <Typography
                        mt={2}
                        sx={{
                          color: "#474B4F",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  );
                })}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
