/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useMemo } from "react";
import * as echarts from "echarts";
import "echarts-liquidfill";
import { useTranslation } from "react-i18next";

const AduitLineCaharts = ({ aduitData }) => {
  const tiemData = aduitData?.map((item) => item.date);
  const growth = aduitData?.map((item) => item.growth);
  const adultInNumVolume = aduitData?.map((item) => item.adultInNum);
  const kidsInNumVolume = aduitData?.map((item) => item.kidsInNum);

  const { t } = useTranslation();
  const ev = t("PCS133");
  const pp = t("PCS134");
  const gr = t("PCS17");

  const chartRef = useRef(null);

  const getOptions = () => {
    let option = {
      grid: {
        top: "5%",
        left: "5%",
        right: "5%",
        bottom: "13%",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
          label: {
            show: true,
          },
        },
        formatter: function (params) {
          var result = params[0].name + "<br/>"; // 如果需要显示横坐标的值，可以通过 params[0].name 获取
          params.forEach(function (item) {
            // 如果 seriesName 是 "Growth rate"，则保持原来的格式
            if (item.seriesName == "Growth rate") {
              result += item.seriesName + ": " + item.value + "%<br/>";
            } else {
              // 否则，格式化数据为带千位分隔符的形式
              var formattedValue = item?.value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              result += item.seriesName + ": " + formattedValue + "<br/>";
            }
          });
          return result;
        },
      },

      legend: {
        data: [ev, pp, gr],
        bottom: "0",
        textStyle: {
          color: "#747474",
        },
        width: "100%",
        backgroundColor: "#f5f5f5",
        borderColor: "#ccc",
        borderWidth: 1,
        padding: [10, 100],
        itemGap: 150,
        itemWidth: 14,
        itemHeight: 14,
        borderRadius: 5,
        align: "auto",
      },
      xAxis: {
        type: "category",
        data: tiemData?.map((item) => item?.replace(/-/g, "/")),
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          color: "#393939", //X轴文字颜色
        },
      },
      yAxis: [
        {
          type: "value",
          nameTextStyle: {
            color: "#393939",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#eeeeee",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: "#393939",
          },
        },
        {
          type: "value",
          nameTextStyle: {
            color: "#393939",
            // padding: [0, 0, 0, 40], // 四个数字分别为上右下左与原位置距离
          },
          position: "right",
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: "#393939",
            formatter: "{value}%",
          },
        },
      ],
      series: [
        {
          name: ev,
          type: "bar",
          barWidth: 30,
          itemStyle: {
            color: "#3ecae4",
            width: 2,
          },
          symbolSize: 4,
          data: adultInNumVolume?.map((value) => ({
            value: value,
            formatted: value.toLocaleString(),
          })),
        },
        {
          name: pp,
          type: "bar",
          barWidth: 30,
          itemStyle: {
            color: "#a09cff",
            width: 2,
          },
          symbolSize: 4,
          data: kidsInNumVolume?.map((value) => ({
            value: value,
            formatted: value.toLocaleString(),
          })),
        },
        {
          name: gr,
          type: "line",
          yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
          showAllSymbol: true, //显示所有图形。
          showSymbol: false,
          symbol: "line", //标记的图形为实心圆
          symbolSize: 4, //标记的大小
          lineStyle: {
            color: "#002c89",
            width: 5,
          },
          data: growth,
        },
      ],
      dataZoom: [
        {
          // 设置滚动条的隐藏与显示
          show: aduitData?.length > 6,
          // 设置滚动条类型
          type: "slider",
          // 设置背景颜色
          backgroundColor: "#ccc",
          // 设置选中范围的填充颜色
          fillerColor: "#ccc",
          // 设置边框颜色
          borderColor: "#ccc",
          // 是否显示detail，即拖拽时候显示详细数值信息
          showDetail: false,
          // 数据窗口范围的起始数值
          startValue: 0,
          // 数据窗口范围的结束数值（一页显示多少条数据）
          endValue: 6,
          // empty：当前数据窗口外的数据，被设置为空。
          // 即不会影响其他轴的数据范围
          filterMode: "empty",
          // 设置滚动条宽度，相对于盒子宽度
          width: "30%",
          // 设置滚动条高度
          height: 3,
          // 设置滚动条显示位置
          left: "center",
          // 是否锁定选择区域（或叫做数据窗口）的大小
          zoomLoxk: true,
          // 控制手柄的尺寸
          handleSize: 0,
          // dataZoom-slider组件离容器下侧的距离
          bottom: 40,
        },
        {
          // 没有下面这块的话，只能拖动滚动条，
          // 鼠标滚轮在区域内不能控制外部滚动条
          type: "inside",
          // 滚轮是否触发缩放
          zoomOnMouseWheel: false,
          // 鼠标滚轮触发滚动
          moveOnMouseMove: true,
          moveOnMouseWheel: true,
        },
      ],
    };

    return option;
  };

  const options = useMemo(() => getOptions(), [aduitData]);
  useEffect(() => {
    let myEcharts = null;
    const initChart = () => {
      myEcharts = echarts.init(chartRef.current, null, { renderer: "svg" });
      // 设置初始大小
      myEcharts.resize();
      // 监听窗口大小变化，自动调整图表大小
      window.addEventListener("resize", handleResize);
      // const options = getOptions();
      myEcharts.setOption(options);
    };
    const handleResize = () => {
      myEcharts.resize();
    };
    // 在组件挂载时进行初始化
    initChart();

    // 在组件卸载时移除事件监听
    return () => {
      window.removeEventListener("resize", handleResize);
      myEcharts.dispose();
    };
  }, [aduitData]);

  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        height: "400px",
      }}
    ></div>
  );
};

export default AduitLineCaharts;
