import React, { useEffect, useState } from "react";
import ListLayout from "../../../../components/ListLayout";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { ReactComponent as PeopleCountingBlueImg } from "@/assets/images/ai_people_counting_blue.svg";
import { ReactComponent as TickMarkImg } from "@/assets/images/icon_tickmark.svg";
import { ReactComponent as DeviceDefaultImg } from "@/assets/images/device_default_img.svg";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { ReactComponent as PeopleCountingGreenImg } from "@/assets/images/ai_people_counting_green.svg";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import ChannelSelect from "../../../../components/ChannelSelect";
import HoursSelect from "../../../../components/HoursSelect";

import { deviceList, filterDeviceEvents } from "@/services/DeviceService";
import CommonUtil from "../../../../util/CommonUtils";

export default function PersonEntering(props) {
  const [events, setEvents] = useState([]);
  const [date, setDate] = useState(new Date());
  const { t } = useTranslation();
  const [groupedData, setGroupedData] = useState({
    timeSlots: [],
    userCounts: [],
  });
  useEffect(() => {
    if (props.DeviceId && !CommonUtil.isEmptyString(props.DeviceId)) {
      loadDeviceEvents({ deviceId: props.DeviceId });
    }
  }, [date, props.DeviceId]);
  const loadDeviceEvents = (filter) => {
    let currentStr = CommonUtil.dateFormat(date, "yyyy-MM-dd");
    filterDeviceEvents({
      pageNumber: 1,
      pageSize: 10000,
      startDateTime: currentStr + " 00:00:00",
      endDateTime: currentStr + " 23:00:00",
      deviceId: filter.deviceId,
    }).then((res) => {
      if (res?.data?.data) {
        setEvents(res?.data?.data?.list || []);
      } else {
        setEvents([]);
      }
    });
  };

  const barChartData = {
    series: [
      {
        name: "Person Entering",
        data: groupedData?.userCounts,
      },
    ],
    options: {
      colors: ["#5470C6", "#91CC75"],
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "30%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: groupedData?.timeSlots,
      },
      yaxis: {
        title: {
          text: "",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  useEffect(() => {
    if (events.length > 0) {
      const _groupedData = groupByTimeUTCAndCountGender(events);
      setGroupedData({ ...groupedData, ..._groupedData });
    } else {
      setGroupedData({
        timeSlots: [],
        userCounts: [],
      });
    }
    // Call the function with the provided data
  }, [events]);
  function groupByTimeUTCAndCountGender(data) {
    const timeUTCGroups = {};
    data.forEach((event) => {
      const eventTimeUTC = new Date(
        event.eventValues.find((v) => v.name === "time_utc").value
      );
      let hourse = Math.floor(eventTimeUTC.getHours() / 3) * 3;
      eventTimeUTC.setHours(hourse);
      const roundedTimeUTC = new Date(
        Math.floor(eventTimeUTC.getTime() / (1 * 60 * 60 * 1000)) *
          (1 * 60 * 60 * 1000)
      );
      if (!timeUTCGroups[roundedTimeUTC]) {
        timeUTCGroups[roundedTimeUTC] = { count: 0 };
      }
      timeUTCGroups[roundedTimeUTC].count++;
    });
    // Extract time slots and gender counts into separate arrays
    const timeSlots = [];
    const userCounts = [];
    Object.keys(timeUTCGroups)
      .sort()
      .forEach((timeSlot) => {
        const hour = new Date(timeSlot).getHours();
        const formattedTimeSlot = `${hour}:00 - ${hour + 3}:00`;
        timeSlots.push(formattedTimeSlot);
        userCounts.push(timeUTCGroups[timeSlot].count);
      });
    return { timeSlots, userCounts };
  }

  const changeDate = (e) => {
    setDate(e);
  };

  return (
    <Box id="barChart" my={1} py={1} component={Paper} elevation={1}>
      <Grid
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
        container
        px={1}
      >
        <Typography item variant="subtitle3">
          Number of Person Entering
        </Typography>
        <Grid
          sx={{
            display: "flex",
          }}
          item
        >
          <Box sx={{ width: "140px" }}>
            <ChannelSelect deviceId={props.DeviceId}></ChannelSelect>
          </Box>
          <Box sx={{ width: "140px", ml: 2 }}>
            <CustomDatePicker
              size={"small"}
              disableFuture={true}
              date={date}
              disabled={false}
              label={""}
              placeholder={t("LVLRCP016")}
              SelectedDate={(e) => changeDate(e)}
            />
          </Box>
          <Box sx={{ width: "140px", ml: 2 }}>
            <HoursSelect hour={1}></HoursSelect>
          </Box>
        </Grid>
      </Grid>
      <ReactApexChart
        options={barChartData.options}
        series={barChartData.series}
        type="bar"
        height={350}
      />
    </Box>
  );
}
