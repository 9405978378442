import api from "../configurations/http-common";

export const findOutletById = async (id) => {
  return api.securedAxios().get("web/outlet/" + id);
};

export const filterOutlet = async (page, size, request) => {
  let query = "";
  if (request?.companyIds) {
    query += "&companyIds=" + request.companyIds;
  }
  if (request?.ids) {
    query += "&ids=" + request.ids;
  }
  if (request?.country) {
    query += "&country=" + request.country;
  }
  if (request?.state) {
    query += "&state=" + request.state;
  }
  if (request?.city) {
    query += "&city=" + request.city;
  }
  if (request?.outletType) {
    query += "&outletType=" + request.outletType;
  }
  if (request?.cityZone) {
    query += "&cityZone=" + request.cityZone;
  }
  if (page !== null || page !== "") {
    query += "&pageNumber=" + page;
  }
  if (size !== null || size !== "") {
    query += "&pageSize=" + size;
  }
  return api.securedAxios().get("web/outlets/list?" + query);
};

export const getOutletList = (params) => {
  return api.securedAxios().get("/web/outlets/list", {
    params,
  });
};

export const createOutlet = async (payload) => {
  return api.securedAxios().post("/web/add_outlet", payload);
};

export const updateOutlet = async (params) => {
  return api.securedAxios().put("/web/update_outlet", params);
};

export const deleteOutlet = async (id, companyId) => {
  return api
    .securedAxios()
    .delete("web/outlet/" + id + "?companyId=" + companyId);
};

export const getTimezoneList = async () => {
  return api.securedAxios().get("/web/timezone");
};
