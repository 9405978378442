import EditIcon from "@mui/icons-material/Edit";
import { Grid, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import RingLoader from "react-spinners/RingLoader";
import AccessControl from "../../components/AccessControl";
import DataTable from "../../components/DataTable";
import IconHandaler from "../../components/IconHandaler";
import CommonUtil from "../../util/CommonUtils";
import { useNavigate } from "react-router-dom";
import { REACT_TENANT_MANAGER_ADD } from "@/router/ReactEndPoints";

const MaterialTable = (props) => {
  const { isLoading, records, rowCount, pagination, setPagination } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(() => [
    {
      field: "tenantId",
      headerName: t("tenant.tenantId"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => {
        return (
          <Tooltip title={e.row.tenantId} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.tenantId)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "companyName",
      headerName: t("LVLDAC0038"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.companyName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.companyName)}</span>
        </Tooltip>
      ),
    },
    {
      field: "contactPhone",
      headerName: t("tenant.phone"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => {
        return (
          <Tooltip title={e.row.contactPhone} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.contactPhone)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "info",
      headerName: t("tenant.info"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.info} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.info)}</span>
        </Tooltip>
      ),
    },
    {
      field: "address",
      headerName: t("tenant.address"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.address} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.address)}</span>
        </Tooltip>
      ),
    },
    {
      field: "contactEmail",
      headerName: t("tenant.account"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.contactEmail} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.contactEmail)}</span>
        </Tooltip>
      ),
    },
    {
      headerName: t("LVLOT0010"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (e) => (
        <IconHandaler>
          {/* <Tooltip title={t("LVLDB0020")} arrow>
            <VisibilityIcon
              onClick={() => handleActions("View", e)}
              style={{
                alignSelf: "center",
                paddingTop: "0px",
                fontSize: "16px",
                color: "#A2A3A3",
              }}
            />
          </Tooltip> */}
          <AccessControl authorization={"1064"}>
            <Tooltip title={t("LVLDB0021")} arrow sx={{ marginLeft: 1 }}>
              <EditIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "16px",
                  color: "#A2A3A3",
                }}
                onClick={() => handleActions("Edit", e)}
              />
            </Tooltip>
          </AccessControl>
          {/* <AccessControl authorization={"1015"}>
            <Tooltip title={t("LVLDB0022")} arrow sx={{ marginLeft: 1 }}>
              <DeleteIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  fontSize: "16px",
                  color: "#A2A3A3",
                }}
                onClick={() => handleActions("Delete", e)}
              />
            </Tooltip>
          </AccessControl> */}
        </IconHandaler>
      ),
    },
  ]);

  const handleActions = (action, data) => {
    if (action === "View") {
      sessionStorage.setItem("editOutlet", JSON.stringify(data.row));
      navigate(REACT_OUTLET_VIEW + data.id);
    } else if (action === "Edit") {
      navigate(REACT_TENANT_MANAGER_ADD, { state: { currentItem: data.row } });
    } else if (action === "Delete") {
      delteItem(data.id);
    }
  };

  return (
    <Grid>
      {isLoading ? (
        <RingLoader
          color={"#597ef7"}
          loading={isLoading}
          cssOverride={{
            display: "block",
            margin: "10% auto",
            borderColor: "#b37feb",
          }}
          size={60}
          speedMultiplier={3}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <DataTable
          height="auto"
          columns={columns}
          rows={records || []}
          getRowId={(data) => data?.id}
          page={pagination.pageNumber - 1}
          totalRecords={rowCount}
          rowsPerPage={pagination.pageSize}
          onSelection={() => console.log()}
          onPageChange={(e) => {
            setPagination({
              ...pagination,
              pageNumber: e + 1,
            });
          }}
          onPageSizeChange={(e) => {
            setPagination({
              pageNumber: 1,
              pageSize: e,
            });
          }}
          checkboxSelection={false}
        />
      )}
    </Grid>
  );
};

export default MaterialTable;
