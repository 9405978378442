import React, { useEffect, useState } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_RETAIL_CLIENT_LIST } from "@/router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import dayjs from "dayjs";
import CustomDatePicker from "../../components/CustomDatePicker";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  FormGroup,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";

import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useNavigate } from "react-router-dom";
import CustomePhoneFiled from "../../components/CustomePhoneFiled";
import { useTranslation } from "react-i18next";
import { createClient, getPriceList } from "../../services/CompanyService";
import { useSnackbar } from "notistack";
import CommonUtil from "../../util/CommonUtils";
import { formatDateFromString } from "../../util/utils";
import { selectCountry, selectState, selectCity } from "@/services/Location";
import { getRolesList } from "../../services/authoritation";
import AccessControl from "../../components/AccessControl";
export default function AddRetailClient() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState({});

  const [stateList, setStateList] = useState({});
  const [state, setState] = useState({});

  const [cityList, setCityList] = useState({});
  const [city, setCity] = useState({});

  const [mobileArea, setMobileArea] = useState("");
  const [mobile, setMobile] = useState("");

  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState("");
  const [outlets, setOutlets] = useState([]);
  const [selectedOutlets, setSelectedoutlets] = useState([]);
  const [createDate, setCreateDate] = useState(""); //订阅设备开始时间
  const [expiredDate, setExpiredDate] = useState(""); //设备有效期
  const [payload, setPayload] = useState({
    addressLine: "",
    // addressLine2: "",
    city: "",
    code: "",
    country: "",
    createRequestedFrom: "",
    createdAt: "",
    createdBy: "",
    id: "",
    latitude: "",
    longitude: "",
    name: "",
    pincode: "",
    state: "",
    status: "",
    type: "RETAIL_CLIENT",
    updateRequestedFrom: "",
    updatedAt: "",
    updatedBy: "",
    deviceNum: "",
  });

  const [error, setError] = useState({
    addressLine: "",
    // addressLine2: "",
    city: "",
    code: "",
    country: "",
    createRequestedFrom: "",
    createdAt: "",
    createdBy: "",
    id: "",
    latitude: "",
    longitude: "",
    name: "",
    pincode: "",
    state: "",
    status: "",
    type: "",
    deviceNum: "",
    updateRequestedFrom: "",
    updatedAt: "",
    updatedBy: "",
  });
  let roleType = JSON.parse(localStorage.getItem("USER_INFO")).roleCode;
  useEffect(() => {
    if (roleType === "SUPER_ADMIN") {
      getRolesList().then((res) => {
        setRoles(res?.data?.data);
      });
    } else {
      const id = location?.state?.id;
      getRolesList(id).then((res) => {
        setRoles(res?.data?.data);
      });
    }
  }, [roleType, location?.state?.id]);

  const handleRoleSelection = (role) => {
    setSelectedRoles(role);
  };

  const label = (label) => {
    return (
      <Tooltip title={label} arrow placement="bottom">
        <span>{CommonUtil.formatLongText(label)}</span>
      </Tooltip>
    );
  };

  useEffect(() => {
    selectCountry().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCountries(res?.data?.data);
      } else {
        setCountries([]);
      }
    });
  }, []);

  useEffect(() => {
    setState({});
    setCity({});
    if (!CommonUtil.isEmpty(country)) {
      // let ID = country.id;
      const { id } = country;
      selectState({
        countryId: id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setStateList(res?.data?.data);
        }
      });
    } else {
      setStateList([]);
    }
  }, [country]);

  useEffect(() => {
    setCity({});
    if (!CommonUtil.isEmpty(state)) {
      const { id } = state;
      selectCity({ stateId: id }).then((res) => {
        // console.log(">>>>>>>>>>>>>>>>>>Dfsdfs", res);
        if (res?.data?.code === "LVLI0000") {
          setCityList(res?.data?.data);
        }
      });
    } else {
      setCityList([]);
    }
  }, [state]);

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.code)) {
      setError({
        ...error,
        code: t("LVL0001"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.email)) {
      setError({
        ...error,
        email: t("LVL0001"),
      });
      return;
    } else if (!CommonUtil.isValidEmail(payload.email)) {
      setError({
        ...error,
        email: t("LVL0003"),
      });
      return;
    }

    if (!CommonUtil.isEmptyString(mobile)) {
      if (CommonUtil.isEmptyString(mobileArea)) {
        setError({
          ...error,
          phone: "Country Code is required.",
        });
        return;
      }
      var mobileStr = "+" + mobileArea.replace("+", "") + "-" + mobile;
      if (mobileStr.length < 10 || mobileStr.length > 25) {
        setError({
          ...error,
          phone: t("LVL0008"),
        });
        return false;
      }
    }
    if (!CommonUtil.isEmptyString(mobileArea)) {
      if (CommonUtil.isEmptyString(mobile)) {
        setError({
          ...error,
          phone: "Mobile Number is required",
        });
        return;
      }
    }
    // if (CommonUtil.isEmptyString(payload.superAdminFirstName)) {
    //   setError({
    //     ...error,
    //     superAdminFirstName: t("LVL0001"),
    //   });
    //   return;
    // }

    if (CommonUtil.isEmpty(payload.firstName)) {
      setError({
        ...error,
        firstName: t("LVL0001"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.countryId)) {
      setError({
        ...error,
        countryId: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmpty(state)) {
      setError({
        ...error,
        state: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmpty(city)) {
      setError({
        ...error,
        city: t("LVL0001"),
      });
      return;
    }

    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      var request = {
        ...payload,
        countryId: country.id,
        stateId: state.id ? state.id : "",
        cityId: city.id ? city.id : "",
        mobileArea,
        mobile,
        roleId: selectedRoles,
      };

      createClient(request).then((response) => {
        if (response?.data?.code === "LVLI0008") {
          enqueueSnackbar(t("PLACE019"), {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });

          navigate(REACT_RETAIL_CLIENT_LIST);
          return;
        }
        switch (response?.data?.code) {
          case "UASE0060":
            setError({
              ...error,
              name: response?.data?.message,
            });
            break;
          case "UASE0020":
            setError({
              ...error,
              superAdminEmail: "email already exist",
            });
            break;
          case "UASE0011":
            setError({
              ...error,
              code: response?.data?.message,
            });
            break;
          default:
            enqueueSnackbar(response?.data?.message, { variant: "error" });
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  return (
    <RightViewLayout
      navigateBack={REACT_RETAIL_CLIENT_LIST}
      title={t("LVLRC0012")}
    >
      <Card elevation={0} sx={{ borderRadius: "10px" }}>
        <Grid container px={4} marginTop={2} marginBottom={5}>
          <Grid container spacing={2} px={2}>
            <Grid item md={6} xs={12}>
              <CustomInput
                required
                label={t("LVLRC0023")}
                size="small"
                name="name"
                error={error.name}
                value={payload.name}
                handleChange={handleChange}
                inputProps={{
                  maxLength: 60,
                }}
                helperText={error.name}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInput
                required
                label={t("LVLRC0025")}
                size="small"
                name="code"
                error={error.code}
                value={payload.code}
                handleChange={handleChange}
                inputProps={{
                  maxLength: 30,
                }}
                helperText={error.code}
                validation={"code"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} px={2} mt={0}>
            <Grid item md={6} xs={12}>
              <CustomInput
                required
                label={t("LVLRC0004")}
                size="small"
                name="email"
                value={payload.email}
                error={error.email}
                helperText={error.email}
                inputProps={{
                  maxLength: 36,
                }}
                validation="email"
                handleChange={handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ marginBottom: "-10px" }}
              >
                {t("LVLRC0005")}
              </InputLabel>
              <CustomePhoneFiled
                error={error.phone}
                handleCountryCode={(data) => {
                  setMobileArea(data.dialCode);
                  setError({ ...error, mobileArea: "" });
                }}
                countryCode={mobileArea}
                placeholder={t("LVLRC0005")}
                phoneNumber={mobile}
                name={"phoneNumber"}
                size={"small"}
                handleChange={(e) => {
                  setMobile(e);
                  setError({ ...error, mobile: "" });
                }}
                label={"Mobile"}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} px={2} mt={-1}>
            <Grid item md={6} xs={12}>
              <CustomInput
                required
                label={t("LVLRC0013")}
                size="small"
                name="firstName"
                value={payload.firstName}
                error={error.firstName}
                helperText={error.firstName}
                inputProps={{
                  maxLength: 100,
                }}
                validation="alpha-numeric"
                handleChange={handleChange}
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLRC0013")}
            size="small"
            name="superAdminFirstName"
            value={payload.superAdminFirstName}
            error={error.superAdminFirstName}
            helperText={error.superAdminFirstName}
            inputProps={{
              maxLength: 100,
            }}
            validation="alpha-numeric"
            handleChange={handleChange}
          />
        </Grid> */}
            <Grid item md={6} xs={12}>
              <CustomInput
                label={t("LVLRC0014")}
                size="small"
                name="lastName"
                value={payload.lastName}
                error={error.lastName}
                helperText={error.lastName}
                inputProps={{
                  maxLength: 100,
                }}
                validation="alpha-numeric"
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} px={2} mt={0}>
            <Grid item md={6} xs={12}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ paddingLeft: "0px" }}
              >
                {t("LVLRC0007")} <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Autocomplete
                options={countries}
                value={country}
                onChange={(e, v) => {
                  setCountry(v);
                  setPayload({ ...payload, countryId: v ? v.id : "" });
                  setError({ ...error, countryId: "" });
                }}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={!CommonUtil.isEmptyString(error.countryId)}
                    helperText={error.countryId}
                  />
                )}
              ></Autocomplete>
            </Grid>

            <Grid item md={6} xs={12}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ paddingLeft: "0px" }}
              >
                {t("LVLRC0008")} <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Autocomplete
                error={error.state}
                helperText={error.state}
                options={stateList}
                value={state}
                onChange={(e, v) => {
                  setState(v);
                  setError({ ...error, state: false });
                }}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={error.state}
                    helperText={error.state}
                  />
                )}
              ></Autocomplete>
            </Grid>
          </Grid>
          <Grid container spacing={2} px={2} mt={0}>
            <Grid item md={6} xs={12}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ paddingLeft: "0px" }}
              >
                {t("LVLRC0009")} <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Autocomplete
                error={error.city}
                helperText={error.city}
                options={cityList}
                value={city}
                onChange={(e, v) => {
                  setCity(v);
                  setError({ ...error, city: false });
                }}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={error.city}
                    helperText={error.city}
                  />
                )}
              ></Autocomplete>
            </Grid>

            <Grid item md={6} xs={12}>
              <CustomInput
                label={t("LVLRC0027")}
                size="small"
                name="addressLine"
                value={payload.addressLine}
                error={error.addressLine}
                helperText={error.addressLine}
                inputProps={{ maxLength: 255 }}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>

      {/* <Card elevation={0} sx={{ borderRadius: "10px", marginTop: "20px" }}>
        <FormControl>
          <Typography
            pb={2}
            fontSize={18}
            fontWeight={500}
            marginLeft={2}
            marginTop={2}
          >
            {t("PCS73")}
          </Typography>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {priceValue?.map((item) => {
              return (
                <Grid item key={item?.id} marginLeft={2}>
                  <FormControlLabel
                    value={item.priceStrategyId}
                    control={<Radio />}
                    label={item.name}
                    onChange={(e) => serPriceStrategy(e.target.value)}
                  />
                </Grid>
              );
            })}
          </RadioGroup>
        </FormControl>
      </Card> */}

      {/* <Card elevation={0} sx={{ borderRadius: "10px", mt: 2 }}>
        <Grid m={2}>
          <Typography fontSize={"0.4rem"} fontWeight={500}>
            Subscription
          </Typography>
        </Grid>

        <Grid container spacing={2} px={6} mb={2}>
          <Grid item md={6} xs={12}>
            <CustomInput
              required
              label={t("LVLRC0047")}
              size="small"
              name="deviceNum"
              error={error.deviceNum}
              disabled={!roleType.endsWith("ADMIN")}
              value={payload.deviceNum}
              handleChange={handleChange}
              inputProps={{
                maxLength: 60,
              }}
              validation={"Devicenumber"}
              helperText={error.deviceNum}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomDatePicker
              size={"small"}
              disableFuture={false}
              disabled={!roleType.endsWith("ADMIN")}
              fullWidth
              required={true}
              type={1}
              date={null}
              label={t("LVLRC0049")}
              SelectedDate={(e) => setCreateDate(e)}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomDatePicker
              size={"small"}
              disableFuture={false}
              disabled={!roleType.endsWith("ADMIN")}
              fullWidth
              required={true}
              type={1}
              date={null}
              label={t("LVLRC0048")}
              SelectedDate={(e) => setExpiredDate(e)}
            />
          </Grid>
        </Grid>
      </Card> */}

      {/* <Card elevation={0} sx={{ borderRadius: "10px", marginTop: "20px" }}>
        <Grid pt={2} marginLeft={2}>
          <Typography pb={2} fontSize={"0.4rem"} fontWeight={500}>
            {t("PCS74")}
          </Typography>
          <Grid container columnSpacing={2}>
            {roles?.map((role, index) => (
              <Grid item key={role.id} xs={6} sm={4} md={3} lg={2}>
                <FormControlLabel
                  control={
                    <Radio
                      id={`role-radio-${index}`}
                      checked={selectedRoles === role.id}
                      onChange={() => handleRoleSelection(role.id)}
                      value={role.id}
                      name="role-selection"
                    />
                  }
                  label={role.name}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Card> */}

      <Grid mt={2} container spacing={2} px={2}>
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"row-reverse"}>
            <Box item pl={2}>
              <AccessControl authorization={"1005"}>
                <Button
                  variant="contained"
                  size="large"
                  className="text-transform-none"
                  onClick={handleSubmit}
                >
                  {t("LVLRC0016")}
                </Button>
              </AccessControl>
            </Box>
            <Box item>
              <Button
                className="text-transform-none"
                variant="outlined"
                onClick={() => navigate(REACT_RETAIL_CLIENT_LIST)}
                size="large"
              >
                {t("LVLRC0017")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
