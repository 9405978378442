import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { ReactComponent as Info2 } from "@/assets/images/device_guide_configure.svg";
import { ReactComponent as Info3 } from "@/assets/images/device_guide_factory_reset.svg";
import { ReactComponent as Info1 } from "@/assets/images/device_guide_power_on.svg";
import RightViewLayout from "../../../components/RighViewLayout";
import { REACT_DEVICE_ADD } from "@/router/ReactEndPoints";
import { useTranslation } from "react-i18next";

const DeviceInstructionPage = (props) => {
  const [read, setRead] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const handleSubmit = () => {
    if (read) navigate(REACT_DEVICE_ADD);
    else {
      enqueueSnackbar(`${t("LVLDV0009")}`, {
        variant: "error",
      });
    }
  };
  return (
    <>
      <RightViewLayout
        navigateBack={-1}
        title={t("LVLDV0010")}
        actions={props.actions}
      >
        <Grid container spacing={1} mb={2} p={1} overflow="auto">
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography style={{ marginBottom: "1rem", fontWeight: 450 }}>
              {t("LVLDV0011")}
            </Typography>
          </Grid>
          <Grid
            item
            lg={3.9}
            md={6}
            sm={12}
            xs={12}
            border="1px solid #DDDDDD"
            borderRadius="5px"
            mr={"5px"}
          >
            <DetailBox
              title={t("LVLDV0012")}
              information={t("LVLDV0013")}
              icon={<Info1 />}
            />
          </Grid>
          <Grid
            item
            lg={3.9}
            md={6}
            sm={12}
            xs={12}
            border="1px solid #DDDDDD"
            borderRadius="5px"
            mr={"5px"}
          >
            <DetailBox
              title={t("LVLDV0014")}
              information={t("LVLDV0015")}
              icon={<Info2 />}
            />
          </Grid>
          <Grid
            item
            lg={3.9}
            md={6}
            sm={12}
            xs={12}
            border="1px solid #DDDDDD"
            borderRadius="5px"
            mr={"5px"}
          >
            <DetailBox
              title={t("LVLDV0016")}
              information={t("LVLDV0017")}
              icon={<Info3 />}
            />
          </Grid>
          <Grid item lg={10} md={10} sm={10} xs={10} marginTop="20px">
            <Box display={"flex"}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox onChange={(e) => setRead(e.target.checked)} />
                  }
                  label={t("LVLDV0018")}
                />
              </FormGroup>
            </Box>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2} marginTop="20px" pr={3}>
            <Button
              fullWidth={true}
              style={{ width: "160px", height: "48px" }}
              variant="contained"
              onClick={() => handleSubmit()}
            >
              {t("LVLDV0019")}
            </Button>
          </Grid>
        </Grid>
      </RightViewLayout>
    </>
  );
};

const DetailBox = (props) => {
  return (
    <Box padding={1}>
      <Typography sx={{ fontSize: "16px", fontWeight: 450, color: "#474B4F" }}>
        {props.title}
      </Typography>
      <Typography
        sx={{
          marginTop: "8px",
          fontSize: "14px",
          fontWeight: 420,
          color: "#474B4F",
          opacity: "50%",
        }}
      >
        {props.information}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "15px",
          height: "200px",
          alignItems: "center",
        }}
      >
        {props.icon}
      </Box>
    </Box>
  );
};

export default DeviceInstructionPage;
