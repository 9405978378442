/* eslint-disable no-undef */
import { useState } from "react";
const useLoading = () => {
  const [loading, setLoading] = useState(true);

  const toggleLoading = () => {
    setLoading(false);
  };

    const openLoading = () => {
    setLoading(true);
  };
  return [loading, toggleLoading,openLoading];
};

export default useLoading;
