import { Box, Grid, Paper, Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Space } from "antd";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ReactApexChart from "react-apexcharts";
import FormatPreValue from "./FormatPreValue";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  getValue,
  gainAllDateBetRange,
  setOption,
  preRange,
  preLastYear,
} from "./utils";
const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);

export default function ConversionRate() {
  const [compareType, setCompareType] = useState("none");
  const [rangeValue, setRangeValue] = useState([]);
  const [preRangeValue, setPreRangeValue] = useState([]);

  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [dateArr, setDateArr] = useState([]);
  const [rightChart, setRightChart] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (compareType === "none") {
      loadData();
    } else {
      loadCompareData();
    }
  }, [compareType, dateArr]);

  const renderData = () => {
    return dateArr.map(() => {
      return getValue();
    });
  };

  const loadData = () => {
    const series = [
      {
        type: "line", //render a line chart for this data
        name: "Conversion Rate",
        data: renderData(),
      },
    ];
    const options = {
      xaxis: {
        categories: dateArr,
      },
      stroke: {
        width: 2,
        // curve:'straight' , //'smooth' | 'straight' | 'stepline' | ('smooth' | 'straight' | 'stepline')[]
        // lineCap:'square' //'butt' | 'square' | 'round'
      },
      colors: ["#A7D690"],
    };
    setOption(options, dateArr);

    if (dateArr.length < 1) {
      setRightChart([]);
    } else {
      setRightChart([
        {
          value: "7%",
          title: "Highest (11/09/2023)",
          bgColor: "#EDF7E9",
        },
        {
          value: "3%",
          title: "Lowest (18/09/2023)",
          bgColor: "#FFE2E5",
        },
      ]);
    }

    let columnsConfig = [
      {
        field: "data",
        headerName: "Data",
      },
      {
        field: "visitorsEnter",
        headerName: "Visitors Enter",
      },
      {
        field: "visitorsPass",
        headerName: "Visitors Pass",
      },
      {
        field: "conversionRate",
        headerName: "Conversion Rate",
      },
    ];
    setColumns(columnsConfig);
    function createData(data, visitorsEnter, visitorsPass, conversionRate) {
      return { data, visitorsEnter, visitorsPass, conversionRate };
    }
    const rows = dateArr.map((item) => {
      return createData(item, getValue(), getValue(), getValue() + "%");
    });
    setTableData(rows);
    setSeries(series);
    setOptions(options);
  };

  const loadCompareData = () => {
    const series = [
      {
        type: "line", //render a line chart for this data
        name: "Conversion Rate",
        data: renderData(),
      },
      {
        type: "line", //render a line chart for this data
        name: "Previous Period",
        data: renderData(),
      },
    ];
    const options = {
      xaxis: {
        categories: dateArr,
      },
      stroke: {
        width: 2,
        curve: "straight", //'smooth' | 'straight' | 'stepline' | ('smooth' | 'straight' | 'stepline')[]
        lineCap: "butt", //'butt' | 'square' | 'round'
      },
      colors: ["#A7D690", "#CB9C9C"],
    };

    setOption(options, dateArr);

    if (dateArr.length < 1) {
      setRightChart([]);
    } else {
      setRightChart([
        {
          value: "+200%",
          title: "Biggest Increase (18/09/2023)",
          bgColor: "#EDF7E9",
        },
        {
          value: "-17%",
          title: "Biggest Decrease (04/09/2023)",
          bgColor: "#FFE2E5",
        },
      ]);
    }

    function createData(conversionRate, previousPeriod, change) {
      return { conversionRate, previousPeriod, change };
    }

    let columnsConfig = [
      {
        field: "conversionRate",
        headerName: "Conversion Rate",
      },
      {
        field: "previousPeriod",
        headerName: "Previous Period",
      },
      {
        field: "change",
        headerName: "Change",
      },
    ];
    setColumns(columnsConfig);

    const rows = dateArr.map((item) => {
      return createData(getValue() + "%", getValue() + "%", getValue() + "%");
    });

    setTableData(rows);
    setSeries(series);
    setOptions(options);
  };

  //   const handleChange = (e) => {
  //     let value = e.target.value;
  //     setCompareType(value);
  //   };
  //   const RangePickerChange = (e) => {
  //     setRangeValue(e);
  //     if (e && e.length === 2) {
  //       let startDate = e[0];
  //       let endDate = e[1];
  //       startDate = new Date(
  //         startDate.year(),
  //         startDate.month() - 1,
  //         startDate.date()
  //       );
  //       endDate = new Date(endDate.year(), endDate.month() - 1, endDate.date());
  //       let start = dayjs(startDate);
  //       let end = dayjs(endDate);
  //       setPreRangeValue([start, end]);
  //       let attr = gainAllDateBetRange(startDate, endDate);
  //       setDateArr(attr);
  //     } else {
  //       setDateArr([]);
  //     }
  //   };

  const handleChange = (e) => {
    let value = e.target.value;
    setCompareType(value);
    if (value === "none") {
      setPreRangeValue([]);
    } else if (value === "previous") {
      setPreRangeValue(preRange(rangeValue));
    } else if (value === "LastYear") {
      setPreRangeValue(preLastYear(rangeValue));
    }
  };

  const RangePickerChange = (e) => {
    setRangeValue(e);
    if (e && e.length === 2) {
      if (compareType === "none") {
        setPreRangeValue([]);
      } else if (compareType === "previous") {
        setPreRangeValue(preRange(e));
      } else if (compareType === "LastYear") {
        setPreRangeValue(preLastYear(e));
      }
      let attr = gainAllDateBetRange(e);
      setDateArr(attr);
    } else {
      setDateArr([]);
    }
  };

  let boxStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Box p={2} component={Paper}>
      <Box m={2}>
        <Typography
          style={{
            fontWeight: 400,
          }}
          variant="title"
        >
          Conversion Rate
        </Typography>
      </Box>

      <Grid
        m={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            m: 1,
          }}
        >
          <Typography sx={{ mr: 2 }}>Date Range</Typography>
          <RangePicker
            value={rangeValue}
            onChange={(e) => {
              RangePickerChange(e);
            }}
            format="DD/MM/YYYY"
          />
        </Grid>

        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            m: 1,
          }}
        >
          <Typography sx={{ mr: 2 }}>Compare with</Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={compareType}
            label=""
            sx={{
              p: 0,
            }}
            onChange={handleChange}
          >
            <MenuItem value={"none"}>None</MenuItem>
            <MenuItem value={"previous"}>Previous Period</MenuItem>
            <MenuItem value={"LastYear"}>Last Year</MenuItem>
          </Select>
        </Grid>

        {compareType !== "none" && (
          <Grid m={1}>
            <RangePicker
              value={preRangeValue}
              disabled={true}
              format="DD/MM/YYYY"
            />
          </Grid>
        )}
      </Grid>

      <Grid container>
        <Grid lg={dateArr.length > 0 ? 9 : 12} sm="12" item>
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={450}
          />
        </Grid>

        {dateArr.length > 0 && (
          <Grid lg="3" sm="12" item>
            {compareType === "none" ? (
              <Grid
                p={3}
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {rightChart.map((item) => {
                  return (
                    <Box
                      sx={{
                        mb: 2,
                        height: "100px",
                        backgroundColor: item.bgColor,
                        borderRadius: "10px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          mt: 2,
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "26px",
                          fontWeight: 500,
                        }}
                      >
                        {item.value}
                      </Typography>
                      <Typography
                        mt={2}
                        sx={{
                          color: "#474B4F",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  );
                })}
              </Grid>
            ) : (
              <Grid
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                p={3}
              >
                {rightChart.map((item) => {
                  return (
                    <Box
                      sx={{
                        mb: 2,
                        height: "100px",
                        backgroundColor: item.bgColor,
                        borderRadius: "10px",
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          mt: 2,
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "26px",
                          fontWeight: 500,
                        }}
                      >
                        {item.value}
                      </Typography>
                      <Typography
                        mt={2}
                        sx={{
                          color: "#474B4F",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  );
                })}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      <Grid
        sx={{
          mt: 2,
        }}
      >
        {dateArr.length > 0 && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#ECECED",
                  }}
                >
                  {columns.map((item) => {
                    return (
                      <TableCell key={item.field}>{item.headerName}</TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => {
                  return (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {columns.map((item) => {
                        return (
                          <TableCell key={item.field}>
                            {row[item.field]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Box>
  );
}
