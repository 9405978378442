import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

function AnalyticalList({ conversionRateList }) {
  const { t } = useTranslation();

  const tableRadius = "7px";
  const tableBorder = "2px solid #f5f5f5";

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "transparent",
      color: theme.palette.common.black,
      fontSize: 16,
      lineHeight: "20px",
      borderBottom: "0",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      borderBottom: "0",
    },

    "&:nth-of-type(even)": {
      backgroundColor: "#f5f5f5",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    display: "table",
    width: "100%",
    tableLayout: "fixed",
    "td:nth-child(1), th:nth-child(1)": {
      width: "18%",
    },
    "td:nth-child(2), th:nth-child(2)": {
      width: "15%",
    },
    "td:nth-child(3), th:nth-child(3)": {
      width: "12%",
    },
    "td:nth-child(4), th:nth-child(4)": {
      width: "13%",
    },
    "td:nth-child(5), th:nth-child(5)": {
      width: "20%",
    },
  }));

  const columns = [
    {
      field: "period",
      headerName: t("PCS21"),
    },
    {
      field: "passby",
      headerName: t("PCS139"),
    },
    {
      field: "enter",
      headerName: t("PCS140"),
    },
    {
      field: "leave",
      headerName: t("PCS141"),
    },

    {
      field: "prev",
      headerName: t("PCS142"),
    },
    {
      field: "change",
      headerName: t("PCS143"),
    },
  ];

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead
          sx={{
            display: "block",
            border: tableBorder,
            borderRadius: tableRadius,
            width: "calc(100% - 8px)",
          }}
        >
          <StyledTableRow>
            {columns?.map((header, index) => {
              return (
                <StyledTableCell align="center" key={header.field}>
                  {header.headerName}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        </TableHead>

        <TableBody
          sx={{
            display: "block",
            maxHeight: "320px",
            overflow: "scroll",
            marginTop: "16px",
            border: tableBorder,
            borderRadius: tableRadius,

            "&::-webkit-scrollbar": {
              width: "8px", // 控制滚动条的宽度
              backgroundColor: "rgba(0, 0, 0, 0.1)", // 背景颜色
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.3)", // 滚动条滑块颜色
              borderRadius: "10px", // 滑块圆角
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "rgba(0, 0, 0, 0.05)", // 滚动条轨道颜色
            },
          }}
        >
          {conversionRateList?.map((row, index) => {
            const change = row.change;
            const changeText = `${change > 0 ? "+" : ""}${change}%`;
            const changeColor = change < 0 ? "red" : "green";
            return (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">
                  {row.event_date.replace(/-/g, "/")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.passByNum}
                </StyledTableCell>
                <StyledTableCell align="center">{row?.inNum}</StyledTableCell>
                <StyledTableCell align="center">{row?.outNum}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.c_conversion_rate}%
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ color: changeColor }}>
                  {row.p_conversion_rate}%
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AnalyticalList;
