import api from "../configurations/http-common";

export const getTenantList = async (params) => {
  return api.securedAxios().get("/web/tenant", {
    params,
  });
};

export const addTenant = async (data) => {
  return api.securedAxios().post("/web/tenant", data);
};

export const updateTenant = async (data) => {
  return api.securedAxios().put("/web/tenant", data);
};
