import { useMemo } from "react";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import palette from "./palette";
import typography from "./typography";
import customShadows from "./shadows";
import componentsOverride from "./overrides";
import shape from "./shape";
export default function ThemeCustomization({ children }) {
  const theme = palette("light", "default");
  const themeTypography = typography(
    `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`
  );
  const themeShape = shape();
  const themeCustomShadows = useMemo(() => customShadows(theme), [theme]);
  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1800,
        },
      },
      direction: "ltr",
      mixins: {
        toolbar: {
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      spacing: 8,
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography,
      shape: themeShape,
      components: {
        MuiAppBar: {
          styleOverrides: {
            colorPrimary: {
              backgroundColor: "white",
              color: "#304669",
            },
          },
        },

        MuiAutocomplete: {
          sizeSmall: {
            paddingBottom: "0px !importent",
            paddingTop: "0px !importent",
          },
        },
        MuiButton: {
          styleOverrides: {
            text: {
              textTransform: "none",
            },
            sizeLarge: {
              width: "180px",
              height: "46px",
              borderRadius: "8px",
            },
            sizeMedium: {
              font: "normal normal normal 14px/22px Roboto",
              textTransform: "none",
              padding: "8px 16px",
            },
          },
        },
      },
    }),
    [theme, themeTypography, themeCustomShadows, themeShape]
  );

  const themes = createTheme(themeOptions);

  //自定义组件样式覆盖
  themes.components = componentsOverride(themes);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
