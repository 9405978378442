//新增零售商模块配置信息
export const getAountInfoConfig = (t, displayType, country, state, city) => {
  let baseConfig = [
    {
      name: "clientName",
      label: t("subscription.clinet_name"),
      type: "input",
      placeholder: t("subscription.please_enter_clinet_name"),
      required: true,
      display: displayType === "add",
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.please_enter_clinet_name"),
        },
      ],
    },
    {
      name: "email",
      label: t("subscription.customer_email_address"),
      type: "input",
      placeholder: t("subscription.please_enter_email_address"),
      required: true,
      disabled: displayType !== "add",
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.please_enter_email_address"),
        },
        {
          type: "email",
          message: t("subscription.email_formatted"),
        },
      ],
    },
    {
      name: "clientCode",
      label: t("subscription.client_Code"),
      type: "input",
      placeholder: t("subscription.enter_customer_code"),
      required: true,
      display: displayType === "add",
      size: "small",
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.enter_customer_code"),
        },
      ],
    },
    {
      name: "firstName",
      label: t("LVLRCP014"),
      type: "input",
      placeholder: t("subscription.please_enter_first_name"),
      required: true,
      display: displayType === "add",
      size: "small",
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.please_enter_first_name"),
        },
      ],
    },
    {
      name: "lastName",
      label: t("LVLRCP015"),
      type: "input",
      placeholder: t("subscription.please_enter_last_name"),
      required: true,
      display: displayType === "add",
      size: "small",
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.please_enter_last_name"),
        },
      ],
    },
    {
      name: "countryId",
      label: t("LVLRC0007"),
      type: "autocomplete",
      options: country,
      placeholder: t("subscription.please_select_country"),
      required: true,
      display: displayType === "add",
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.please_select_country"),
        },
      ],
    },
    {
      name: "stateId",
      label: t("LVLRC0008"),
      type: "autocomplete",
      options: state,
      placeholder: t("subscription.please_select_province"),
      required: true,
      display: displayType === "add",
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.please_select_province"),
        },
      ],
    },
    {
      name: "cityId",
      label: t("LVLRC0009"),
      type: "autocomplete",
      options: city,
      placeholder: t("subscription.please_select_city"),
      required: true,
      display: displayType === "add",
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.please_select_city"),
        },
      ],
    },

    {
      name: "addressLine",
      label: t("subscription.area"),
      size: "small",
      type: "input",
      required: true,
      display: displayType === "add",
      placeholder: t("subscription.enterArea"),
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.enterArea"),
        },
      ],
    },

    {
      name: "mobile",
      label: t("LVL0018"),
      size: "small",
      type: "phone",
      required: true,
      display: displayType === "add",
      placeholder: t("subscription.enterMobileNumber"),
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.enterMobileNumber"),
        },

        {
          type: "phoneNumber",
          message: t("subscription.please_formatted_phone"),
        },
      ],
    },
  ];

  return baseConfig;
};

//新增设备数量模块配置信息
export const getDeviceNumberConfig = (t, type, state) => {
  let baseConfig = [
    {
      name: "deviceCount",
      label: t("LVLRC0047"),
      type: "input",
      placeholder: t("subscription.enter_Dev_number"),
      required: true,
      disabled: state?.id === "1" || state?.type === "extend",
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.enter_Dev_number"),
        },
        {
          type: "number",
          message: t("subscription.only_number"),
        },
      ],
    },
    {
      name: "unit",
      sx: 12,
      type: "radio",
      defaultValue: "1",
      disabled: state?.type === "more",
      display: state?.id === "1" || state?.type === "more" ? false : true,
      options: [
        {
          label: t("subscription.Monthly"),
          value: "0",
        },
        {
          label: t("subscription.Annually"),
          value: "1",
        },
      ],
    },
    {
      name: "count",
      label:
        state?.type === "extend"
          ? type === "0"
            ? t("subscription.extend_months")
            : t("subscription.extend_years")
          : type === "0"
          ? t("subscription.subMonths")
          : t("subscription.subAnnually"),
      type: "input",
      disabled: state?.id === "1" || state?.type === "more",
      placeholder:
        type === "0"
          ? t("subscription.please_enter_number_months")
          : t("subscription.please_enter_number_Annually"),
      required: state?.id === "1" || state?.type === "more" ? false : true,
      display: state?.id === "1" || state?.type === "more" ? false : true,
      validation: [
        {
          type: "string",
          message: "",
        },
        state?.id === "1" ||
          (state?.type !== "more" && {
            type: "required",
            message: t("subscription.please_subscription_number"),
          }),
        {
          type: "number",
          message: t("subscription.only_number"),
        },
      ],
    },
    // {
    //   name: "zoneId",
    //   label: t("LVLOT0009"),
    //   type: "autocomplete",
    //   typeValue: "2",
    //   options: timeZoon,
    //   placeholder: t("subscription.please_select_zoonTime"),
    //   required: true,
    //   disabled: state?.type === "more" || state?.type === "extend",
    //   validation: [
    //     {
    //       type: "string",
    //       message: "",
    //     },
    //     {
    //       type: "required",
    //       message: t("subscription.please_select_zoonTime"),
    //     },
    //   ],
    // },
    {
      name: "activateTime",
      label: t("LVLRC0049"),
      type: "selectDate",
      placeholder: t("subscription.please_select_startTime"),
      required: true,
      disabled: state?.type === "more" || state?.type === "extend",
      size: "small",
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.please_select_startTime"),
        },
      ],
    },

    {
      name: "expiraTime",
      label: t("LVLRC0048"),
      size: "small",
      type: "selectDate",
      required: true,
      disabled: true,
      placeholder: t("subscription.please_select_endTime"),
      validation: [
        {
          type: "string",
          message: "",
        },
      ],
    },
  ];

  return baseConfig;
};

//新增合同信息模块配置信息
export const getContractInfoConfig = (t, type, unitData) => {
  let baseConfig = [
    {
      name: "contractNo",
      label: t("subscription.Contract_ID"),
      type: "input",
      placeholder: t("subscription.please_enter_contract_Id"),
      required: true,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.please_enter_contract_Id"),
        },
        {
          type: "number",
          message: t("subscription.only_number"),
        },
      ],
    },
    {
      name: "contractAmount",
      label: t("subscription.Contract_Amount"),
      type: "input",
      placeholder: t("subscription.please_enter_contract_amount"),
      required: true,
      size: "small",
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.please_enter_contract_amount"),
        },
        {
          type: "number",
          message: t("subscription.only_number"),
        },
      ],
    },

    {
      name: "contractAmountUnit",
      label: t("subscription.Contract_Unit"),
      type: "autocomplete",
      placeholder: t("subscription.please_enter_contract_unit"),
      options: unitData,
      typeValue: "1",
      size: "small",
      required: true,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("subscription.please_enter_contract_unit"),
        },
      ],
    },
  ];

  return baseConfig;
};
