import {
  InputLabel,
  Stack,
  Autocomplete,
  TextField,
  FormHelperText,
} from "@mui/material";
import RequirePoint from "./RequirePoint";
import { useEffect, useState } from "react";

function CmpAutocomplete(props) {
  const {
    formik = null,
    placeholder = "",
    handleBlur,
    handleChange,
    label,
    name,
    error,
    disabled = false,
    isClear = true,
    labelPostion,
    spacing = 1,
    width,
    height = "40px",
    inputType,
    options,
    fontSize = "22px",
    readonly,
    typeValue = "0",
    ...orther
  } = props;

  const [data, setData] = useState(null);

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
    if (userInfo?.clientId) {
      const list = options.find((item) => item.id === userInfo?.clientId);
      setData(list);
      formik.setFieldValue(name, userInfo?.clientId);
    } else if (typeValue == "1") {
      const list = options?.find((item) => {
        return item?.value === formik.values[name];
      });

      setData(list);
      formik.setFieldValue(name, formik.values[name]);
    } else if (typeValue == "2") {
      const list = options?.find((item) => {
        return item?.value === formik.values[name];
      });

      setData(list);
      formik.setFieldValue(name, formik.values[name]);
    } else {
      const list = options.find((item) => item.id === formik.values[name]);
      setData(list);
      formik.setFieldValue(name, list);
    }
  }, [options]);

  return (
    <Stack>
      <Stack
        direction={labelPostion === "left" ? "row" : "column"}
        sx={{
          alignItems: labelPostion === "left" ? "flex-start" : "",
        }}
        spacing={spacing}
      ></Stack>
      <InputLabel
        shrink
        htmlFor={"CmpAutoComPlete_" + name}
        style={{
          marginTop: labelPostion === "left" ? "12px" : "",
          width: width,
          fontSize: fontSize,
        }}
      >
        {props.required && <RequirePoint></RequirePoint>}
        {label}
      </InputLabel>
      <Stack
        sx={{
          flexGrow: 1,
          width: "100%",
        }}
      >
        <Autocomplete
          disablePortal
          id={props.id}
          fullWidth
          options={options || []}
          onChange={(event, newValue) => {
            if (typeValue === "2") {
              setData(newValue);
              formik.setFieldValue(name, newValue?.value);
            } else if (typeValue === "1") {
              setData(newValue);
              formik.setFieldValue(name, newValue?.value);
            } else {
              // 当用户点击清除按钮时，newValue 会是 null
              if (newValue === null || newValue === "") {
                formik.setFieldValue(name, ""); // 或者设置为其他合适的默认值
                setData(null);
              } else {
                formik.setFieldValue(name, newValue?.id); // 保存选中的 id 到 formik
                setData(newValue); // 设置 data 为选中的对象
              }
            }
          }}
          value={data || null}
          disabled={disabled}
          name={name}
          isOptionEqualToValue={(option, value) => (option.id ? option.id : "")}
          getOptionLabel={(option) =>
            typeValue === "1"
              ? option?.value
                ? option?.value
                : ""
              : option?.name
              ? option?.name
              : ""
          }
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              readonly={readonly}
              placeholder={placeholder}
            />
          )}
          {...orther}
        />
        {((formik?.touched[name] && formik?.errors[name]) || error) && (
          <FormHelperText error id={`standard-weight-helper-text-${name}`}>
            {formik?.errors[name] || error}
          </FormHelperText>
        )}
      </Stack>
    </Stack>
  );
}

export default CmpAutocomplete;
