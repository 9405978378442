import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  Grid,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import CustomDatePicker from "./StartTimeSelect";
import dayjs from "dayjs";
import CommonUtil from "@/util/CommonUtils";
import { useTranslation } from "react-i18next";
import {
  endOfMonth,
  format,
  startOfMonth,
  add,
  subMonths,
  subDays,
} from "date-fns"; // 时间库
const DialogTime = ({
  open,
  setOpen,
  setStartTimes,
  setEndTimes,
  setSelectTime,
  setEndValueTime,
  setStartValueTime,
  startTimes,
  selectTime,
}) => {
  const { t } = useTranslation();
  //现在选择的时间
  const [startWeekTime, setStartWeekTime] = useState("");
  const [endWeekTime, setEndWeekTime] = useState("");

  const [startTime, setStartTime] = useState("");
  const [endTime, seEndTime] = useState("");
  const [diffDay, setDiffDay] = useState(0); //相差天数
  const selectChangeTime = (e) => {
    setSelectTime(e.target.value);
  };

  // useEffect(() => {
  //   const firstOfMonth = startOfMonth(new Date());
  //   const formattedFirstOfMonth = format(firstOfMonth, "yyyy/MM/dd");
  //   setStartTime(formattedFirstOfMonth);

  //   const endOfMonthDay = endOfMonth(new Date());
  //   const formattedEndOfMonthDay = format(endOfMonthDay, "yyyy/MM/dd");
  //   seEndTime(formattedEndOfMonthDay);
  // }, [selectTime]);

  const [startTimeValue, setStartValue] = useState("");
  const [endTimeValue, setEndtimeValue] = useState("");
  const startChangeDate = (e) => {
    if (selectTime == 1) {
      let currentStr = CommonUtil.dateFormat(e, "yyyy/MM/dd");
      setStartTime(currentStr);

      // 限制掉 Week 时间选择范围
      setStartWeekTime(currentStr);
      const maxDate = add(new Date(e), { days: 30 }); // 将开始时间推迟六天
      setEndWeekTime(maxDate);
    } else {
      let currentStr = CommonUtil.dateFormat(e, "yyyy/MM/dd");
      setStartTime(currentStr);

      // 限制掉 Week 时间选择范围
      const lastDayOfMonth = endOfMonth(new Date(e));
      const formattedDate = format(lastDayOfMonth, "yyyy/MM/dd");
      seEndTime(formattedDate);

      /**
       * 选择完时间后，比较时间默认为当前时间的上一个月
       */

      const currentDate = new Date(currentStr);
      const previousMonth = subMonths(currentDate, 1);
      const previousMonthStr = format(previousMonth, "yyyy/MM/dd");
      const previousMonthLastDay = endOfMonth(previousMonth);
      const previousMonthLastDayStr = format(
        previousMonthLastDay,
        "yyyy/MM/dd"
      );

      setStartValue(previousMonthStr);
      setEndtimeValue(previousMonthLastDayStr);
      // setStartValueTime(previousMonthStr);
      // setEndValueTime(previousMonthLastDayStr);
    }
  };

  const endChangeDate = (e) => {
    let currentStr = CommonUtil.dateFormat(e, "yyyy-MM-dd");
    seEndTime(currentStr);
  };

  const handklerConfirm = () => {
    if (selectTime == 1) {
      setStartTimes(startTime);
      setEndTimes(endTime);

      const currentDate = new Date(startTime);
      const differenceInDays = dayjs(endTime).diff(dayjs(startTime), "day");
      const lastWeek = subDays(currentDate, 1);
      const previousWeek = subDays(lastWeek, differenceInDays);

      const previousWeekStr = format(previousWeek, "yyyy/MM/dd");
      const lastWeekStr = format(lastWeek, "yyyy/MM/dd");

      setStartValueTime(previousWeekStr);
      setEndValueTime(lastWeekStr);
    } else {
      const currentDate = new Date(startTime);
      const previousMonth = subMonths(currentDate, 1);
      const previousMonthStr = format(previousMonth, "yyyy/MM/dd");
      const previousMonthLastDay = endOfMonth(previousMonth);
      const previousMonthLastDayStr = format(
        previousMonthLastDay,
        "yyyy/MM/dd"
      );

      setStartTimes(startTime);
      setEndTimes(endTime);
      setStartValueTime(previousMonthStr);
      setEndValueTime(previousMonthLastDayStr);
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle fontSize={24}>{t("PCS25")}</DialogTitle>
        <DialogContent>
          <Grid marginBottom={2}>
            <RadioGroup row value={selectTime} onChange={selectChangeTime}>
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={t("PCS32")}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={t("PCS27")}
              />
            </RadioGroup>
          </Grid>
          <Grid display={"flex"}>
            <Box sx={{ width: "200px", marginRight: "30px" }}>
              <CustomDatePicker
                size={"small"}
                disableFuture={true}
                // date={date}
                disabled={false}
                fullWidth={true}
                type={selectTime}
                label={""}
                placeholder={t("LVLRCP016")}
                SelectedDate={(e) => startChangeDate(e)}
              />
            </Box>

            {selectTime == 1 ? (
              <Grid>
                <Box sx={{ lineHeight: "40px" }}>——</Box>
                <Box
                  sx={{
                    width: "200px",
                    marginLeft: "50px",
                    marginTop: "-38px",
                  }}
                >
                  <CustomDatePicker
                    size={"small"}
                    disableFuture={true}
                    // date={date}
                    fullWidth={true}
                    type={selectTime}
                    label={""}
                    disabled={false}
                    minDate={startWeekTime}
                    maxDate={endWeekTime}
                    placeholder={t("LVLRCP016")}
                    SelectedDate={(e) => endChangeDate(e)}
                  />
                </Box>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t("LVLDB0012")}</Button>
          <Button autoFocus onClick={handklerConfirm}>
            {t("LVLDV0096")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DialogTime;
