import axios from "axios";
import CommonUtil from "../util/CommonUtils";
import jwt_decode from "jwt-decode";
import UserService from "@/services/UserService";

const { tokenRefresh } = UserService;

// 是否正在刷新的标记
let isRefreshing = false;
// 重试队列，每一项将是一个待执行的函数形式
let tryRequestQueue = [];

const axiosHelper = (headers) => {
  let req = axios.create({
    baseURL: "/zata", //process.env.REACT_APP_SERVER_URL,
    headers: headers,
  });
  // const pendingRequest = new Map();
  req.interceptors.request.use(function (request) {
    localStorage.setItem("isLoading", true);
    window.dispatchEvent(new Event("storage"));
    return request;
  });

  req.interceptors.response.use(
    function (response) {
      if (response.data?.code === "LVLE0075") {
        console.log("过期了");
        // token 失效重新返回登录
        if (!isRefreshing) {
          isRefreshing = true;

          var decoded_jwtToken = jwt_decode(
            localStorage.getItem("USER_TOKEN_RETAIL_AI")
          );
          var payload = {
            refreshToken: localStorage
              .getItem("REFRESH_TOKEN_RETAIL_AI")
              .replace("Bearer ", ""),
            companyCode: decoded_jwtToken.companyCode,
          };

          return tokenRefresh(payload)
            .then((res) => {
              const tokenRes = res?.data;
              if ("UASI0011" !== tokenRes?.code) {
                window.location = "/";
                isRefreshing = false;
                return Promise.reject("error");
              }
              const token = tokenRes.data.access_token;
              // 刷新token成功，将最新的token更新到header中
              console.log("Refreshed token @" + new Date());
              localStorage.setItem("USER_TOKEN_RETAIL_AI", "Bearer " + token);
              localStorage.setItem(
                "REFRESH_TOKEN_RETAIL_AI",
                "Bearer " + tokenRes?.data.refresh_token
              );

              const decoded = jwt_decode(token);
              localStorage.setItem("USER", JSON.stringify(decoded));

              // 获取当前失败的请求
              const config = response.config;
              // 重置一下配置
              config.headers["Authorization"] = "Bearer " + token;
              // 已经刷新了token，将所有队列中的请求进行重试
              tryRequestQueue.forEach((cb) => cb(token));
              tryRequestQueue = [];

              // 重试当前请求并返回promise
              return req(config);
            })
            .catch((tokenRes) => {
              window.location = "/";
              isRefreshing = false;
              return Promise.reject("error");
            })
            .finally(() => {
              isRefreshing = false;
            });
        } else {
          console.log("加入等待队列");
          // 正在刷新token，返回一个未执行resolve的promise
          return new Promise((resolve) => {
            // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
            tryRequestQueue.push((token) => {
              // config.baseURL = '';
              response.config.headers["Authorization"] = "Bearer " + token;
              resolve(req(response.config));
            });
          });
        }
      }

      localStorage.setItem("isLoading", false);
      window.dispatchEvent(new Event("storage"));
      return response;
    },
    function (error) {
      if (
        401 === error.response?.status &&
        window.location.pathname !== "/error"
      ) {
        CommonUtil.setCookie("auto_login", "", 0);
        window.location = "/error";
      }
    }
  );

  return req;
};

const securedAxios = () => {
  return axiosHelper({
    "Content-type": "application/json",
    Authorization: localStorage.getItem("USER_TOKEN_RETAIL_AI"),
    "accept-language": localStorage.getItem("RC_LANG_KEY"),
    "tenant-id": localStorage.getItem("TENANT_ID"),
  });
};

const unsecuredAxios = () => {
  return axiosHelper({
    "Content-type": "application/json",
    "accept-language": localStorage.getItem("RC_LANG_KEY"),
  });
};

// const http = (config) => {
//   const hash = location.hash;
//   // 生成请求Key
//   const reqKey = generateReqKey(config, hash);
//   if (pendingRequest.has(reqKey)) {
//     return pendingRequest.get(reqKey);
//   }
//   const instance = vAxios(config);
//   pendingRequest.set(reqKey, instance);
//   instance.finally(() => {
//     pendingRequest.delete(reqKey);
//   });
//   return instance;
// };

const api = {
  axiosHelper,
  securedAxios,
  unsecuredAxios,
  // http,
};

export default api;
