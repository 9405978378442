import { getResourceList, getRolesPermission } from "@/services/authoritation";
import { Box, Grid, Paper, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  REACT_SUB_HANDLER_LIST,
  REACT_SUB_HISTORY_LIST,
} from "@/router/ReactEndPoints";
import { ReactComponent as Authoritation } from "@/assets/images/authoritation.svg";
import { ReactComponent as LocationMenu } from "@/assets/images/Location.svg";
import { ReactComponent as InActiveDashboardMenu } from "@/assets/images/menu_dashboard_out.svg";
import { ReactComponent as InActiveDeviceMenu } from "@/assets/images/menu_device.svg";
import { ReactComponent as MateDataMenu } from "@/assets/images/Menu_MateData.svg";
import { ReactComponent as InActiveMetadataMenu } from "@/assets/images/menu_metadata.svg";
import { ReactComponent as InActiveOutletMenu } from "@/assets/images/menu_outlet.svg";
import { ReactComponent as ActivePrincipalMenu } from "@/assets/images/menu_principal.svg";
import { ReactComponent as InActiveRetailClientMenu } from "@/assets/images/menu_retail_client.svg";
import { ReactComponent as ActiveRetailClientMenu } from "@/assets/images/menu_retail_client_active.svg";
import { ReactComponent as InActivePrincipalMenu } from "@/assets/images/menu_principal.svg";
import { ReactComponent as InActiveTenantListMenu } from "../assets/images/tenantList.svg";
import { ReactComponent as InActivePersontMenu } from "../assets/images/Person_menu.svg";
import { ReactComponent as InActiveSubscriptionMenu } from "../assets/images/subscription.svg";

import { useSnackbar } from "notistack";
// import SidebarMenuItem from "../components/layout-components/SidebarMenuItem";
import SidebarMenuItem from "../layout/components/SidebarMenuItem";
// import SubMenuItem from "../components/layout-components/SubMenuItem";
import SubMenuItem from "./components/SubMenuItem";
// import UserProfileMenu from "../components/layout-components/UserProfileMenu";
import UserProfileMenu from "./leftMenu/UserProfileMenu";
import SwitchLanguage from "./leftMenu/SwitchLanguage";
// import CompanyProfileMenu from "../components/layout-components/CompanyProfileMenu";
import CompanyProfileMenu from "./leftMenu/CompanyProfileMenu";

import Logo from "@/assets/zatalogo.png";
import { getVersionCode } from "@/services/OnboardService";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

export default function Sidebar(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const path = window.location.pathname;

  const [dashboardOpen, setDashboardOpen] = useState(false);
  const [subscriptOpen, setSubscriptionOpen] = useState(false);
  const [authority, setAuthority] = useState([]);
  const [permission, setPermission] = useState([]);
  const [version, setVersion] = useState(null);

  const { isShow, setIsShow } = props;

  const handleClick = () => {
    setDashboardOpen(!dashboardOpen);
  };

  const handleClick1 = () => {
    setSubscriptionOpen(!subscriptOpen);
  };

  const getIcons = (key) => {
    switch (key) {
      case "menu_retail_client.svg":
        return InActiveRetailClientMenu;
      case "menu_active_retail_client.svg":
        return ActiveRetailClientMenu;
      case "menu_outlet.svg":
        return InActiveOutletMenu;
      case "menu_device.svg":
        return InActiveDeviceMenu;
      case "menu_metadata_active.svg":
        return InActiveMetadataMenu;
      case "Menu_MateData.svg":
        return MateDataMenu;
      case "menu_principal.svg":
        return ActivePrincipalMenu;
      case "Location.svg":
        return LocationMenu;
      case "menu_openAPI.svg":
        return InActivePrincipalMenu;
      case "menu_tenant.svg":
        return InActiveTenantListMenu;
      case "menu_person.svg":
        return InActivePersontMenu;
      default:
        return Authoritation;
    }
  };

  useEffect(() => {
    if (
      path.includes("/SummaryPeopleCounting") ||
      path.includes("/SummaryShelfMonitoring") ||
      path.includes("/SummaryDemographic") ||
      path.includes("/PeopleCounting") ||
      path.includes("/ShelfMonitoring") ||
      path.includes("/Demographic")
    ) {
      setDashboardOpen(true);
    }
    getVersionCode().then((res) => {
      setVersion(res?.data?.data?.version || "V1.0.0");
    });
  }, []);

  useEffect(() => {
    getResourceList().then((res) => {
      res?.data?.code?.startsWith("LVLE") &&
        enqueueSnackbar(res?.data.message, {
          variant: "error",
        });
      setAuthority(res?.data?.data);
    });

    getRolesPermission().then((res) => {
      setPermission(res?.data?.data);
      localStorage.setItem("PERMISSION", res?.data?.data);
    });
  }, []);

  const filteredAuthority = authority?.filter((item) => item?.id !== "1");

  const filterData = filteredAuthority?.filter((item) => item?.id !== "11");

  const fillterDashBoard = authority?.filter((item) => item?.id === "1");

  const subscriptionList = authority?.filter((item) => item?.id === "11");

  const headerStyle = {
    flex: 1,
    overflowY: "auto",
    maxWidth: "800px",
    minWidth: "250px",
    overflowX: "hidden", // 隐藏横向滚动条
    scrollbarWidth: "thin",
    scrollbarColor: "rgb(145, 204, 117,0.8) transparent",
    border: "3px solid rgb(145, 204, 117)",
    boxShadow: "5px 5px 2px 1px #0000001f",
    borderRadius: "5px",
  };

  headerStyle["&::-webkit-scrollbar"] = {
    width: "1px",
  };

  headerStyle["&::-webkit-scrollbar-thumb"] = {
    background: "#d1e7c3",
    borderRadius: "1px",
  };

  headerStyle["&::-webkit-scrollbar-track"] = {
    background: "transparent",
  };

  const handleDrawerToggle = () => {
    setIsShow(!isShow);
  };

  const iconBackColor = "grey.100";
  const iconBackColorOpen = "grey.300";

  return (
    <Box display={"flex"} flexDirection={"column"}>
      {/* <Box>
        <IconButton
          disableRipple
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          color="secondary"
          sx={{
            color: "text.primary",
            bgcolor: isShow ? iconBackColorOpen : iconBackColor,
            borderRadius: 0,
            fontSize: "20px",
            ml: 2,
            mt: 2,
          }}
        >
          {!isShow ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </IconButton>
      </Box> */}
      {isShow && (
        <Box
          component={Paper}
          elevation={0}
          variant="outlined"
          my={2}
          ml={2}
          p={1}
          justifyContent={"flex-start"}
          sx={headerStyle}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Grid justifyContent={"space-between"}>
              <Box textAlign={"center"} marginTop={1} position={"relative"}>
                {/* <Logo height={60} width={200} /> */}
                {/* <Avatar src={Logo}></Avatar> */}
                <Box height={60} width={200} marginLeft={2}>
                  <img src={Logo} height={60} width={200}></img>
                </Box>
              </Box>

              <Box
                sx={{
                  color: "#1487ca",
                  float: "right",
                }}
                fontSize={18}
              >
                {version}
              </Box>

              {fillterDashBoard?.map((data, index) => {
                return (
                  <Grid marginTop={4} key={index}>
                    <ListItemButton
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={handleClick}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: "20px", pr: 2 }}>
                          <InActiveDashboardMenu />
                        </ListItemIcon>
                        <Typography
                          key={index}
                          variant="menuItem"
                          sx={{ display: { md: "inline" } }}
                        >
                          {data.name}
                        </Typography>
                      </Box>
                      {dashboardOpen ? (
                        <ExpandLess fontSize="small" htmlColor="#A2A3A3" />
                      ) : (
                        <ExpandMore fontSize="small" htmlColor="#A2A3A3" />
                      )}
                    </ListItemButton>
                    {data?.resourceList?.map((item, index) => (
                      <Collapse
                        in={dashboardOpen}
                        timeout="auto"
                        unmountOnExit
                        key={index}
                      >
                        <List component="div" disablePadding>
                          <SubMenuItem
                            isActive={path.includes(item.frontPath)}
                            key={index}
                            link={item.frontPath}
                            label={item.name}
                          ></SubMenuItem>
                        </List>
                      </Collapse>
                    ))}
                  </Grid>
                );
              })}

              {subscriptionList?.map((data, index) => {
                return (
                  <Grid
                    key={index}
                    sx={{
                      mt: 1.5,
                      ml: -0.5,
                      mb: 1.5,
                    }}
                  >
                    <ListItemButton
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={handleClick1}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: "20px", pr: 2 }}>
                          <InActiveSubscriptionMenu />
                        </ListItemIcon>
                        <Typography
                          key={index}
                          variant="menuItem"
                          sx={{ display: { md: "inline" } }}
                        >
                          {data.name}
                        </Typography>
                      </Box>
                      {subscriptOpen ? (
                        <ExpandLess fontSize="small" htmlColor="#A2A3A3" />
                      ) : (
                        <ExpandMore fontSize="small" htmlColor="#A2A3A3" />
                      )}
                    </ListItemButton>
                    {data?.resourceList?.map((item, index) => {
                      return (
                        <Collapse
                          in={subscriptOpen}
                          timeout="auto"
                          unmountOnExit
                          key={index}
                        >
                          <List component="div" disablePadding>
                            <SubMenuItem
                              isActive={path.includes(item.frontPath)}
                              key={index}
                              link={item.frontPath}
                              label={item.name}
                            ></SubMenuItem>
                          </List>
                        </Collapse>
                      );
                    })}
                  </Grid>
                );
              })}

              <Grid marginTop={fillterDashBoard?.length === 0 ? 4 : 0}>
                {filterData?.map((firstMenu, index) => {
                  return (
                    <div key={index}>
                      {firstMenu?.resourceList.map((secondMenu) => (
                        <SidebarMenuItem
                          link={secondMenu.frontPath}
                          key={secondMenu.id}
                          label={firstMenu.name}
                          isActive={path.includes(secondMenu.frontPath)}
                          activeMenu={getIcons(firstMenu.icon)}
                          inActiveMenu={getIcons(firstMenu.icon)}
                        />
                      ))}
                    </div>
                  );
                })}
              </Grid>
            </Grid>

            <Grid display={"block"}>
              <Box
                display={"grid"}
                sx={{
                  alignItems: "flex-end",
                }}
              >
                <CompanyProfileMenu />
                <UserProfileMenu />
                <SwitchLanguage />
              </Box>
            </Grid>
          </Box>

          {/* <RefreshToken /> */}
        </Box>
      )}
    </Box>
  );
}
