import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
function TableList({ matchFrequencyDatas }) {
  const { t } = useTranslation();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontSize: 16,
      lineHeight: "20px",
      border: "none",
      // borderRadius: "30px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
    },

    "&:nth-of-type(even)": {
      backgroundColor: "#f5f5f5",
    },
  }));
  const columns = [
    {
      field: "brand",
      headerName: t("PCS41"),
    },
    {
      field: "match",
      headerName: t("PCS42"),
    },
    {
      field: "total",
      headerName: t("PCS43"),
    },
    {
      field: "Misplaced",
      headerName: t("PCS44"),
    },
    {
      field: "OutOfStock",
      headerName: t("PCS45"),
    },
    {
      field: "totalCaptures",
      headerName: t("PCS39"),
    },
    {
      field: "accuracy",
      headerName: t("PCS46"),
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns?.map((header, index) => {
              return (
                <StyledTableCell
                  align="center"
                  key={header.field}
                  dangerouslySetInnerHTML={{
                    __html: header.headerName,
                  }}
                ></StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {matchFrequencyDatas.map((row, index) => {
            const change = row.change;
            return (
              <TableRow key={index}>
                <StyledTableCell align="center">{row.brand}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.matchFrequency}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  {row.total}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.misplaced}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.outOfStock}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.totalCaptures}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  color={change < 0 ? "red" : "green"}
                >
                  {row.accuracy}%
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableList;
