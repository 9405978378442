import React, { useEffect } from 'react';
import { Grid } from '@mui/material';

export default function IconHandaler(props) {
  const rowInfo = (parameter1, parameter2) => {
    // alert('Row Id - ' + parameter1.id);
    // alert('Row info - ' + JSON.stringify(parameter1.row));
    // alert('Icon Name - ' + parameter2.title);
    props.handleActions(parameter2.title, props.data);
  };
  return (
    <Grid style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
      {/* {props.icons.map((item, index) => (
        <img src={item.icon} alt={index} style={{ height: '20px', width: '20px', margin: '0px 6px', opacity: 0.5, padding: '4px' }} onClick={(e) => rowInfo(props.data, item)} />
      ))} */}
      {props.children}
    </Grid>
  );
}
