import api from "../configurations/http-common";

export const createPerson = async (payload) => {
  return api.securedAxios().post("web/person", payload);
};

export const personList = async (params) => {
  let url = "/web/client_users";
  return api.securedAxios().get(url, {
    params,
  });
};

export const deletePerson = async (personIdOrCode, clientCompanyId) => {
  let url = "web/person/";
  if (personIdOrCode) {
    url = url + personIdOrCode;
  }
  if (clientCompanyId) {
    url = url + "?clientCompanyId=" + clientCompanyId;
  }

  return api.securedAxios().delete(url);
};

export const getPersonByIdOrCode = async (personIdOrCode, clientCompanyId) => {
  let url = "web/person/";
  if (personIdOrCode) {
    url = url + personIdOrCode;
  }
  if (clientCompanyId) {
    url = url + "?clientCompanyId=" + clientCompanyId;
  }

  return api.securedAxios().get(url);
};

export const updatePerson = async (personIdOrCode, payload) => {
  let url = "web/person/" + personIdOrCode;
  return api.securedAxios().put(url, payload);
};

export const activatePersonAccount = async (request) => {
  return api.securedAxios().put("/web/person/activate_account", request);
};

export const sendActivationMail = async (request) => {
  return api.securedAxios().post("web/person/resend_activation_mail", request);
};

export const editPerson = async (payload) => {
  return api.securedAxios().put("web/person", payload);
};

export const getClientUserInfo = async (id) => {
  return api.securedAxios().get(`/web/person/${id}`);
};

export const changePassword = async (id, payload) => {
  return api.securedAxios().put(`/web/person/editPwd/${id}`, payload);
};

export const getPersonList = async (params) => {
  return api.securedAxios().get("/web/user/page", {
    params,
  });
};
