/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";
import { REACT_DEVICE_LIST } from "@/router/ReactEndPoints";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { getDeviceTime } from "@/services/DeviceService";
import { useTranslation } from "react-i18next";
import Lightbox from "react-image-lightbox";
import DataTable from "../../../components/DataTable";
import _ from "lodash";
import "react-image-lightbox/style.css";

import {
  Box,
  Button,
  Tooltip,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
// import DeviceIcon from "@/assets/images/icon_device.png";
import AccessControl from "@/components/AccessControl";
import CommonUtil from "@/util/CommonUtils";
import DialogChange from "../Component/ChangeDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getDeviceSetting,
  getDevicePhoto,
  getIdentlyResult,
  saveDeviceSetting,
  getRegPhoto,
} from "@/services/DeviceService";
import { useSnackbar } from "notistack";
function DeviceSetShopShelves(props) {
  const {
    deviceOutLetId,
    captureInterval,
    channelName,
    deviceID,
    channelNum1,
    channeAlgType,
    channelIp,
    channelmainStream,
    clientID,
  } = props;

  let navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 5,
    id: "",
    sn: "",
    deviceModel: "",
    deviceType: "",
    accountEmail: "",
    companyId: "",
    deviceAlias: "",
    mac: "",
    siteId: "",
    zoneId: "",
    checkInOrOutType: "",
    ip: "",
    registrationDevice: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // 每页显示的数据条数
  const productListRef = useRef();

  /**
   * ----------------------------------------------------------
   * 画线操作
   */

  const channelContainerRef = useRef(null);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const pointsRef = useRef([]); // 储存用户点击的点

  const clickTimeout = useRef(null); // 延迟处理点击事件的定时器
  const lastMousePosition = useRef({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [draggedPointIndex, setDraggedPointIndex] = useState(-1);
  const [detectArea, setDetectArea] = useState([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = imageRef.current;

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
    };

    if (canvas) {
      canvas.addEventListener("mousemove", handleMouseMove);
    }
    return () => {
      if (canvas) {
        canvas.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, []);

  const handleMouseDown = (event) => {
    clearTimeout(clickTimeout.current); // 清除之前的定时器

    clickTimeout.current = setTimeout(() => {
      if (pointsRef.current.length === 4) {
        lastMousePosition.current = {
          x: event.clientX,
          y: event.clientY,
        };
        setIsDragging(true);
        return; // 已经达到五个点，不接受新的点击
      }

      const canvas = canvasRef.current;

      //获取图片的实际宽度和高度
      const ctx = canvas.getContext("2d");
      const rect = canvas.getBoundingClientRect();

      // 获取 canvas 的实际宽度和高度
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // 根据实际宽高计算坐标
      const x = ((event.clientX - rect.left) / rect.width) * canvasWidth;
      const y = ((event.clientY - rect.top) / rect.height) * canvasHeight;

      // 检查是否点击了已有的点
      for (let i = 0; i < pointsRef.current.length; i++) {
        const point = pointsRef.current[i];
        const distance = Math.sqrt(
          Math.pow(point.x - x, 2) + Math.pow(point.y - y, 2)
        );
        if (distance <= 3) {
          // 按下了已有的点，设置拖动的点的索引
          setDraggedPointIndex(i);
          return;
        }
      }

      // 在画布上打点
      ctx.beginPath();
      ctx.arc(x, y, 10, 0, 2 * Math.PI);
      ctx.fillStyle = "#fff";
      ctx.strokeStyle = "red";
      ctx.fill();
      ctx.stroke();

      // 储存用户点击的点
      pointsRef.current.push({ x, y });
    }, 200); // 设置延迟时间为200毫秒
  };

  const handleMouseMove = (event) => {
    if (!isDragging && draggedPointIndex === -1) {
      return;
    }
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // 获取鼠标移动距离
    const deltaX = event.clientX - lastMousePosition.current.x;
    const deltaY = event.clientY - lastMousePosition.current.y;

    if (isDragging) {
      detectArea.forEach((point) => {
        point[0] += deltaX;
        point[1] += deltaY;
      });
    } else if (draggedPointIndex !== -1) {
      const point = pointsRef.current[draggedPointIndex];
      point.x += deltaX;
      point.y += deltaY;
    }

    // 清空画布并重新绘制
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(imageRef.current, 0, 0);
    ctx.beginPath();
    ctx.moveTo(detectArea[0][0], detectArea[0][1]);
    ctx.lineTo(detectArea[1][0], detectArea[1][1]);
    ctx.lineTo(detectArea[2][0], detectArea[2][1]);
    ctx.lineTo(detectArea[3][0], detectArea[3][1]);
    ctx.closePath();
    ctx.strokeStyle = "red";
    ctx.lineWidth = 10;
    ctx.stroke();

    // 更新最后一次鼠标位置
    lastMousePosition.current.x = event.clientX;
    lastMousePosition.current.y = event.clientY;
  };

  const handleMouseUp = (event) => {
    clearTimeout(clickTimeout.current); // 清除之前的定时器

    if (isDragging) {
      setIsDragging(false);
      return;
    }

    clickTimeout.current = setTimeout(() => {
      if (pointsRef.current.length === 4) {
        enqueueSnackbar(t("PCS89"), {
          variant: "warning",
        });
        return; // 已经达到五个点，不接受新的点击
      }

      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const rect = canvas.getBoundingClientRect();
      // 获取 canvas 的实际宽度和高度
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // 根据实际宽高计算坐标
      const x = ((event.clientX - rect.left) / rect.width) * canvasWidth;
      const y = ((event.clientY - rect.top) / rect.height) * canvasHeight;

      // 检查是否点击了已有的点
      for (let i = 0; i < pointsRef.current.length; i++) {
        const point = pointsRef.current[i];
        const distance = Math.sqrt(
          Math.pow(point.x - x, 2) + Math.pow(point.y - y, 2)
        );
        if (distance <= 3) {
          setDraggedPointIndex(i);
          return;
        }
      }

      // 在画布上打点
      ctx.beginPath();
      ctx.arc(x, y, 10, 0, 2 * Math.PI);
      ctx.fillStyle = "#fff";
      ctx.strokeStyle = "red";
      ctx.fill();
      ctx.stroke();

      // 储存用户点击的点
      pointsRef.current.push({ x, y });

      // 如果储存了四个点，绘制方框
      if (pointsRef.current.length === 4) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(imageRef.current, 0, 0);

        ctx.beginPath();
        ctx.moveTo(pointsRef.current[0].x, pointsRef.current[0].y);
        ctx.lineTo(pointsRef.current[1].x, pointsRef.current[1].y);
        ctx.lineTo(pointsRef.current[2].x, pointsRef.current[2].y);
        ctx.lineTo(pointsRef.current[3].x, pointsRef.current[3].y);
        ctx.closePath();

        ctx.strokeStyle = "red";
        ctx.lineWidth = 10;
        ctx.stroke();
        let point = []; //将四个点的坐标保存

        pointsRef.current.map((item) => {
          const itemValues = Object.values(item);
          point.push(itemValues);
          for (let i = 0; i < point.length; i++) {
            for (let j = 0; j < point[i].length; j++) {
              point[i][j] = parseInt(point[i][j]);
            }
          }
          let result = [point];
          setDetectArea(JSON.stringify(result));
        });
      }
    }, 200); // 设置延迟时间为200毫秒
  };

  //清楚画布方法
  const clearCancas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    // 清除画布上的内容
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // 清空储存的点和检测区域
    pointsRef.current = [];
    setDetectArea([]);
    setAreaPositions([])
    setBoxPositions([])
    ctx.drawImage(imageRef.current, 0, 0);
  };

  useEffect(() => {
    loadData(deviceID);
  }, [filters, deviceID, channelNum1]);

  /**
   * @param setAutoCapture 保存Auto Capture分钟值
   * @method autoCaptureHandle  修改选中的Auto Capture分钟值
   */

  const [autoCapture, setAutoCapture] = useState(30);
  useEffect(() => {
    if (captureInterval == "3600000") {
      setAutoCapture(60);
    } else if (captureInterval == "5400000") {
      setAutoCapture(90);
    } else if (captureInterval == "7200000") {
      setAutoCapture(120);
    } else {
      setAutoCapture(30);
    }
  }, [captureInterval]);

  /**
   * 设置通道时间
   */
  const autoCaptureHandle = (event) => {
    const selectedMilliseconds = event.target.value;
    setAutoCapture(selectedMilliseconds);
    const params = {
      deviceId: deviceID,
      channelNum: channelNum1,
      channelName: channelName,
      ip: channelIp,
      mainStream: channelmainStream,
      captureInterval: selectedMilliseconds,
      algType: channeAlgType,
    };
    getDeviceTime(params).then((res) => {
      if (res?.data?.code == "DMSI0000") {
        enqueueSnackbar(t("LVLDV0103"), {
          variant: "success",
        });
        window.location.reload();
      }
    });
  };

  /**
   * 左侧识别图片
   */

  const [photoURl, setPhotoURL] = useState("");
  const [photoBase64, setPhotoBasePhoto] = useState(null);
  const [areaPositions, setAreaPositions] = useState([]);
  const [boxPositions, setBoxPositions] = useState([]);
  useEffect(() => {
    let params = {
      deviceId: deviceID,
      channelNum: channelNum1,
    };
    getDevicePhoto(params).then((res) => {
      let data = res?.data?.data;
      setDetectArea(data?.deviceSetPhotoDO?.detectArea);
      setPhotoBasePhoto(data?.deviceSetPhotoDO?.photoUrl);
      setPhotoURL(data?.framePhotoBase64 || data?.deviceSetPhotoDO?.photoUrl);
      setAreaPositions(data?.areaPositions);
      setBoxPositions(data?.boxPositions);
    });
  }, [channelNum1]);

  /**
   * 表格的列 设置
   */
  const columns = [
    {
      field: "position",
      headerName: `${t("LVLDV0061")}`,
      flex: 1,
      sortable: false,
      renderCell: (e) => {
        const onClickHandler = () => handlerIsShow(e);
        return (
          <Tooltip
            onClick={onClickHandler}
            title={e.row.position}
            arrow
            placement="bottom"
          >
            <span>{CommonUtil.formatLongText(e.row.position)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "recognitionTime",
      headerName: `${t("LVLDV0062")}`,
      flex: 1,
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.time} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.time)}</span>
        </Tooltip>
      ),
    },
    {
      field: "productName",
      headerName: `${t("LVLDV0063")}`,
      flex: 1,
      sortable: false,
      // editable: true,
      renderCell: (e) => (
        <Tooltip
          title={
            e.row.productDO ? e.row.productDO.productName : e.row.productName
          }
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(
              e.row.productDO ? e.row.productDO.productName : e.row.productName
            )}
          </span>
        </Tooltip>
      ),
    },
    //
    {
      field: "barCode",
      headerName: `${t("LVLDV0064")}`,
      flex: 1,
      sortable: false,
      // editable: true,
      renderCell: (e) => (
        <Tooltip
          title={e.row.productDO ? e.row.productDO.barCode : e.row.barCode}
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(
              e.row.productDO ? e.row.productDO.barCode : e.row.barCode
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "category",
      headerName: `${t("LVLDV0065")}`,
      flex: 1,
      // editable: true,
      sortable: false,
      renderCell: (e) => (
        <Tooltip
          title={
            e.row.productDO
              ? e.row.productDO.categoryLevel1
              : e.row.categoryLevel1
          }
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(
              e.row.productDO
                ? e.row.productDO.categoryLevel1
                : e.row.categoryLevel1
            )}
          </span>
        </Tooltip>
      ),
    },
    // {
    //   field: "regPhoto",
    //   headerName: `${t("LVLDV0066")}`,
    //   flex: 1,
    //   sortable: false,
    //   renderCell: (e) => (
    //     <Tooltip title={e.row.regPhoto} arrow placement="bottom">
    //       <span>{CommonUtil.formatLongText(e.row.regPhoto)}</span>
    //     </Tooltip>
    //   ),
    // },
  ];

  /**
   * 加载表格数据
   * @param {*setRecords} 存储后端接口返回的数据
   * @param {*setTotalRecords}  存储数据的总数
   */

  const loadData = (deviceID) => {
    let params = {
      deviceId: deviceID,
      channelNum: channelNum1,
    };
    getDeviceSetting(params).then((res) => {
      setRecords(res?.data?.data || []);
      setTotalRecords(res?.data?.data?.totalCount);
    });

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // 获取当前页码的数据
    const currentPageData = records.slice(startIndex, endIndex);
    setRecords(currentPageData);
  };

  /**
   * 重新获取注册图
   */

  const getReCapture = () => {
    let params = {
      deviceId: deviceID,
      channelNum: channelNum1,
    };
    enqueueSnackbar(t("RTPD00013"), {
      variant: "info",
    });
    getRegPhoto(params).then((res) => {
      if (res.data?.code === "DMSI0000") {
        enqueueSnackbar(t("RTPD00010"), {
          variant: "success",
        });
        setPhotoBasePhoto(res.data.data[0]);
        setPhotoURL(res.data.data[0]);
        clearCancas();
      } else {
        enqueueSnackbar(t("RTPD00011"), {
          variant: "warning",
        });
      }
    });
  };

  /**
   * 重新获取识别结果
   * @url 识别商品图片地址
   * @outLetId
   * @detectArea 所识别图片区域坐标
   */

  const getReResult = () => {
    const params = {
      url: photoURl,
      outLetId: deviceOutLetId,
      detectArea: JSON.parse(detectArea),
    };
    enqueueSnackbar(t("PCS103"), {
      variant: "info",
    });
    getIdentlyResult(params).then((res) => {
      let data = res?.data?.data;

      if (data === null) {
        enqueueSnackbar(t("PCS89"), {
          variant: "warning",
        });
        return;
      } else {
        enqueueSnackbar(t("RTPD00010"), {
          variant: "success",
        });
        setPhotoURL(photoURl);
        setRecords(data?.deviceSettingDTOs || []);
        setAreaPositions(data?.areaPositions);
        setBoxPositions(data?.boxPositions);
        setTotalRecords(res?.data?.data?.totalCount);
      }
    });
    // clearCancas();
  };

  useEffect(() => {
    setTimeout(() => {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      const image = imageRef.current;

      // 设置画布尺寸与图片一致
      const updateCanvasSize = () => {
        canvas.width = image.width;
        canvas.height = image.height;
      };

      const drawRectangles = () => {
        // 清除画布
        context.clearRect(0, 0, canvas.width, canvas.height);

        // 绘制红色矩形
        context.strokeStyle = "red";
        context.lineWidth = 4;
        areaPositions?.forEach(
          ({ x1, y1, x2, y2, x3, y3, x4, y4, position }) => {
            context.beginPath();
            context.moveTo(x1, y1);
            context.lineTo(x2, y2);
            context.lineTo(x3, y3);
            context.lineTo(x4, y4);
            context.closePath();
            context.stroke();

            // 计算中心点
            const centerX = (x1 + x3) / 2;
            const centerY = (y1 + y3) / 2;
            context.fillStyle = "red";
            context.font = "32px Arial";
            context.fillText(position, centerX, centerY);
          }
        );

        // 绘制绿色矩形
        context.strokeStyle = "green";
        context.lineWidth = 4;
        boxPositions?.forEach(
          ({ x1, y1, x2, y2, x3, y3, x4, y4, position }) => {
            context.beginPath();
            context.moveTo(x1, y1);
            context.lineTo(x2, y2);
            context.lineTo(x3, y3);
            context.lineTo(x4, y4);
            context.closePath();
            context.stroke();

            // 计算中心点
            const centerX = (x1 + x3) / 2;
            const centerY = (y1 + y3) / 2;
            context.fillStyle = "red";
            context.font = "32px Arial";
            context.fillText(position, centerX, centerY);
          }
        );
      };

      updateCanvasSize();
      drawRectangles();
    }, 1000);
  }, [areaPositions, boxPositions, photoURl]);

  /**
   * 更新行数据  从DialogChange 组件传递过来
   * @method {*} selectValue
   */
  const [open, setOpen] = useState(false);
  const [dataList, setDataList] = useState([]);
  const selectValue = (value) => {
    dataList.row.productDO = value;
    let id = dataList.row.id;
    const rowIndex = records.findIndex((item) => item.id === id);

    // 如果找到了对应的行索引
    if (rowIndex !== -1) {
      // 创建一个新的行对象，包含更新后的数据
      const updatedRow = { ...records[rowIndex], productDO: value };
      records.slice(rowIndex, 1, updatedRow);
      // 更新表格数据
      setRecords(records || []);
      // setRecords([...records]);
    }
  };

  /**
   * @param  id  id 为拿到的当前行的数据
   * @param  setOpen 打开DialogChange弹窗
   * @param  setDataList  存储当前行数据，传递给DialogChange 做回显
   */
  const handlerIsShow = (id) => {
    //禁止双击多选框打开弹窗
    if (id.field.includes("__check__")) return;
    setOpen(true);
    productListRef.current.getloadData();
    setDataList(id);
  };

  /**
   * 判断勾选的的那几行
   * @param  row
   * @param setSelectionModel 存储选中的行的数据
   */
  // const [updatedDeviceSettings, setUpdatedDeviceSettings] = useState([]);
  // const [selectionModel, setSelectionModel] = useState([]);

  // // 将 deviceSettings 放在 useState 中
  // const [deviceSettings, setDeviceSettings] = useState([]);

  // useEffect(() => {
  //   const newDeviceSettings = records?.map((item) => {
  //     console.log("TTTTTTTTTTTTTTTTT", item);
  //     return {
  //       deviceId: deviceID,
  //       channelNum: channelNum1,
  //       position: item.position,
  //       time: item.time,
  //       productId: item?.productDO?.id,
  //       x1: item?.x1,
  //       y1: item?.y1,
  //       x2: item?.x2,
  //       y2: item?.y2,
  //       x3: item?.x3,
  //       y3: item?.y3,
  //       x4: item?.x4,
  //       y4: item?.y4,
  //       areaNum: item?.areaNum,
  //       area: item?.area,
  //       isPush: item.isPush,
  //     };
  //   });
  //   setDeviceSettings(newDeviceSettings);
  // }, [records, deviceID, channelNum1]);

  // 在 deviceSettings 更新后处理行选择
  // useEffect(() => {
  //   if (deviceSettings.length > 0) {
  //     handleRowSelection(selectionModel);
  //   }
  // }, [deviceSettings]);

  // const handleRowSelection = (selectedRows) => {
  //   const updatedSettings = deviceSettings.map((item) => {
  //     if (selectedRows.includes(item.position)) {
  //       return { ...item, isPush: "1" };
  //     } else {
  //       return { ...item, isPush: "0" };
  //     }
  //   });

  //   setUpdatedDeviceSettings(updatedSettings);
  //   setSelectionModel(selectedRows);
  // };

  // const handleRowChange = (newSelection) => {
  //   setSelectionModel(newSelection);
  // };

  const [updatedDeviceSettings, setUpdatedDeviceSettings] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);

  const deviceSettings = records?.map((item) => {
    return {
      deviceId: deviceID,
      channelNum: channelNum1,
      position: item.position,
      time: item.time,
      productId: item?.productDO?.id,
      x1: item?.x1,
      y1: item?.y1,
      x2: item?.x2,
      y2: item?.y2,
      x3: item?.x3,
      y3: item?.y3,
      x4: item?.x4,
      y4: item?.y4,
      areaNum: item?.areaNum,
      area: item?.area,
      isPush: item.isPush,
    };
  });

  //处理先勾选后，再更新数据导致更新的数据保存失败
  useEffect(() => {
    if (deviceSettings.length > 0) {
      handleRowSelection(selectionModel);
    }
  }, [deviceSettings]);

  const handleRowSelection = (e) => {
    const updatedDeviceSettings = deviceSettings?.map((item) => {
      if (e.includes(item.position)) {
        return { ...item, isPush: "1" }; // 如果该行被选中，则将 isPush 设置为 "1"
      } else {
        return { ...item, isPush: "0" }; // 如果该行未被选中，则将 isPush 设置为 "0"
      }
    });

    setUpdatedDeviceSettings(updatedDeviceSettings);
    // 处理取消选中行的逻辑
    const unselectedRows = selectionModel.filter((row) => !e.includes(row));
    setSelectionModel(e); // 更新选中行状态
    if (unselectedRows.length > 0) {
      setSelectionModel((prevSelectionModel) =>
        prevSelectionModel.filter((row) => !unselectedRows.includes(row))
      );
    }
  };

  //保存device setting
  const handleSubmit = () => {
    setIsLoading(true);
    const params = {
      deviceId: deviceID,
      channelNum: channelNum1,
      photoUrl: photoBase64,
      outletId: deviceOutLetId,
      detectArea: detectArea,
      deviceSettings:
        updatedDeviceSettings.length > 0
          ? updatedDeviceSettings
          : deviceSettings,
    };

    enqueueSnackbar(t("PLACE07"), {
      variant: "warning",
    });
    saveDeviceSetting(params)
      .then((res) => {
        if (res?.data?.code == "LVLE0000") {
          enqueueSnackbar(res?.data?.message, {
            variant: "warning",
          });
        }
        loadData(deviceID);
        setIsLoading(false);

        enqueueSnackbar(t("PCS104"), {
          variant: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const selectedPositions = records.filter((item) => item.isPush === "1");
    const valuesList = selectedPositions.map((obj) => obj.position);
    setSelectionModel(valuesList || []);
  }, [records]);

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (imageUrl) => {
    setLightboxOpen(true);
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const image = new Image();
    image.crossOrigin = "anonymous"; // 确保跨域问题
    image.src = imageUrl;

    image.onload = () => {
      // 设置画布尺寸与图片一致
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      const drawRectangles = () => {
        // 绘制红色矩形
        context.strokeStyle = "red";
        context.lineWidth = 4;
        areaPositions.forEach(
          ({ x1, y1, x2, y2, x3, y3, x4, y4, position }) => {
            context.beginPath();
            context.moveTo(x1, y1);
            context.lineTo(x2, y2);
            context.lineTo(x3, y3);
            context.lineTo(x4, y4);
            context.closePath();
            context.stroke();

            // 计算中心点
            const centerX = (x1 + x3) / 2;
            const centerY = (y1 + y3) / 2;
            context.fillStyle = "red";
            context.font = "32px Arial";
            context.fillText(position, centerX, centerY);
          }
        );

        // 绘制绿色矩形
        context.strokeStyle = "green";
        context.lineWidth = 4;
        boxPositions.forEach(({ x1, y1, x2, y2, x3, y3, x4, y4, position }) => {
          context.beginPath();
          context.moveTo(x1, y1);
          context.lineTo(x2, y2);
          context.lineTo(x3, y3);
          context.lineTo(x4, y4);
          context.closePath();
          context.stroke();

          // 计算中心点
          const centerX = (x1 + x3) / 2;
          const centerY = (y1 + y3) / 2;
          context.fillStyle = "green";
          context.font = "32px Arial";
          context.fillText(position, centerX, centerY);
        });
      };

      drawRectangles();

      // 将 canvas 转换为图片
      const dataURL = canvas.toDataURL("image/png");
      setSelectedImage(dataURL);
    };
  };

  return (
    <>
      <Grid xs={12} mb={2} style={{ color: "#6a7171" }}>
        {t("LVL0012")}
      </Grid>
      <Grid container xs={12}>
        <Grid item xs={6}>
          <Grid xs={12} mb={2}>
            <div
              ref={channelContainerRef}
              className="channel_wrap"
              id="channel_wrap"
              style={{
                width: "100%",
                height: "600px",
                background: "#ccc",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url(${photoURl})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "100% 100%",
                }}
              >
                <img
                  ref={imageRef}
                  id="snapImage"
                  src={photoURl}
                  style={{ width: "100%", height: "100%", display: "none" }}
                />
                <canvas
                  id="channel_canvas"
                  ref={canvasRef}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  style={{
                    width: "100%",
                    height: "100%",
                    zIndex: 1111111111,
                  }}
                >
                  no support canvas
                </canvas>

                {lightboxOpen && (
                  <Lightbox
                    mainSrc={selectedImage}
                    onCloseRequest={() => setLightboxOpen(false)}
                  ></Lightbox>
                )}
              </div>
              <div
                ref={channelContainerRef}
                id="channel_container"
                className="channel_container"
              ></div>
            </div>
          </Grid>
          <Grid container xs={12}>
            <Grid container xs={6}>
              <Box item>
                <Tooltip title={t("PCS98")}>
                  <Button
                    className="text-transform-none"
                    size="Small"
                    onClick={getReCapture}
                  >
                    {t("LVLDB0014")}
                  </Button>
                </Tooltip>
                <Tooltip title={t("PCS99")}>
                  <Button
                    className="text-transform-none"
                    size="Small"
                    onClick={getReResult}
                  >
                    {t("LVLDB0015")}
                  </Button>
                </Tooltip>
                <Tooltip title={t("PCS100")}>
                  <Button
                    className="text-transform-none"
                    size="Small"
                    onClick={clearCancas}
                  >
                    {t("LVLDB0016")}
                  </Button>
                </Tooltip>
                <Tooltip title={t("PCS101")}>
                  <Button
                    className="text-transform-none"
                    size="Small"
                    onClick={() => handleImageClick(photoURl)}
                  >
                    {t("LVLDB0017")}
                  </Button>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          mb={6}
          style={{ backgroundColor: "#FFFFFF", height: "600px" }}
        >
          <Grid style={{ marginLeft: "20px" }}>
            <Grid item mb={2}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  {t("LVLDV0060")}
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={autoCapture}
                  onChange={autoCaptureHandle}
                >
                  <FormControlLabel
                    value={30}
                    control={<Radio />}
                    label={"30 " + t("LVLDV0093")}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={60}
                    control={<Radio />}
                    label={"60 " + t("LVLDV0093")}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={90}
                    control={<Radio />}
                    label={"90 " + t("LVLDV0093")}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={120}
                    control={<Radio />}
                    label={"120 " + t("LVLDV0093")}
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item mb={4}>
              <span style={{ fontWeight: "bold" }}>{t("LVL0014")}</span>
            </Grid>
            <Grid item>
              <div style={{ width: "100%", height: "430px" }}>
                <DataGrid
                  rows={records || []}
                  columns={columns}
                  onCellDoubleClick={handlerIsShow} //双击方法
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: filters.pageSize,
                      },
                    },
                  }}
                  rowSelectionModel={selectionModel}
                  pageSizeOptions={[5, 10, 20, 30, 50]}
                  getRowId={(data) => data.position}
                  checkboxSelection
                  disableColumnMenu
                  disableRowSelectionOnClick
                  page={filters.pageNumber - 1}
                  rowCount={totalRecords}
                  onRowSelectionModelChange={handleRowSelection}
                  onPageChange={(pn) => setCurrentPage(pn)}
                  onPageSizeChange={(ps) => setPageSize(ps)}
                  localeText={{
                    noRowsLabel: t("PCS105"),
                    noResultsOverlayLabel: t("PCS105"),
                    pagination: {
                      paginationModel: {
                        rowsPerPage: t("PCS102"),
                      },
                    },
                    footerRowSelected: (count) =>
                      count !== 1
                        ? `${count.toLocaleString()} ${t("PCS106")}`
                        : `${count.toLocaleString()} ${t("PCS107")}`,
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid xs={12} sx={{ marginTop: 6, marginRight: 1 }}>
            <Box display={"flex"} flexDirection={"row-reverse"}>
              <Box item pl={2}>
                <AccessControl authorization={"1018"}>
                  <LoadingButton
                    variant="contained"
                    size="Small"
                    className="text-transform-none"
                    onClick={handleSubmit}
                    color="primary"
                    loading={isLoading}
                  >
                    {t("LVL0013")}
                  </LoadingButton>
                </AccessControl>
              </Box>
              <Box item>
                <Button
                  className="text-transform-none"
                  variant="outlined"
                  onClick={() => navigate(REACT_DEVICE_LIST)}
                  size="Small"
                >
                  {t("LVLDB0012")}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid>
          <DialogChange
            open={open}
            setOpen={setOpen}
            dataList={dataList}
            filters={filters}
            totalRecords={totalRecords}
            selectValue={selectValue}
            records={records}
            deviceOutLetId={deviceOutLetId}
            clientID={clientID}
            ref={productListRef}
          ></DialogChange>
        </Grid>
      </Grid>
    </>
  );
}

export default DeviceSetShopShelves;
