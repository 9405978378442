import { Avatar, Box, Paper } from "@mui/material";
import OverFlowText from "../../components/OverFlowText";
import { useState } from "react";
import { useEffect } from "react";
import {
  getPersonByIdOrCode,
  getClientUserInfo,
} from "../../services/PersonService";
import { useLocation, useParams } from "react-router-dom";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_PERSON_LIST, RETAIL_CLIENT } from "@/router/ReactEndPoints";
import { useTranslation } from "react-i18next";

export default function ViewPerson() {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const [person, setPerson] = useState({});
  const [profile, setProfile] = useState(null);

  // useEffect(() => {
  //   getPersonByIdOrCode(params.data, params.id).then((res) => {
  //     if (res?.data?.data && res?.data?.code === "LVLI0000") {
  //       setPerson(res?.data?.data?.user);
  //       setProfile(res?.data?.data.profilePhotoURL);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    getClientUserInfo(location?.state?.currentItem).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setPerson(res?.data?.data.user);
        setProfile(res?.data?.data?.user?.profilePhoto);
      }
    });
  }, []);
  return (
    <RightViewLayout
      title={t("LVLRCP018")}
      // navigateBack={
      //   RETAIL_CLIENT +
      //   params.name +
      //   "/" +
      //   params.id +
      //   "/" +
      //   location.state.code +
      //   REACT_PERSON_LIST
      // }

      navigateBack={"/person/list"}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          paddingTop: "20px",
          paddingBottom: "36px",
          paddingLeft: "26px",
          border: "1px solid #DDDDDD",
          margin: "0 1rem 2rem 0",
          overflow: "hidden",
        }}
      >
        <Box>
          <Box>
            <Avatar
              imgProps={{ draggable: "false" }}
              alt={t("PLACE03")}
              src={profile}
              sx={{ width: 150, height: 150 }}
            />
          </Box>
          <Box pt={2}>
            <Box>
              <OverFlowText variant="fieldLabel">
                {t("LVLRCP014")}:
              </OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue">
              {person && person.firstName ? person.firstName : "-"}
            </OverFlowText>

            <Box mt={2}>
              <OverFlowText variant="fieldLabel">
                {t("LVLRCP015")}:
              </OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue">
              {person && person.lastName ? person.lastName : "-"}
            </OverFlowText>

            <Box mt={2}>
              <OverFlowText variant="fieldLabel">
                {t("LVLRCP003")}:
              </OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue">
              {person && person.personId ? person.personId : "-"}
            </OverFlowText>

            <Box mt={2}>
              <OverFlowText variant="fieldLabel">
                {t("LVLRCP004")}:
              </OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue">
              {person && person.email ? person.email : "-"}
            </OverFlowText>

            <Box mt={2}>
              <OverFlowText variant="fieldLabel">
                {t("LVLRCP005")}:
              </OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue">
              {person && person.phone ? person.phone : "-"}
            </OverFlowText>

            <Box mt={2}>
              <OverFlowText variant="fieldLabel">{t("LVLRCP006")}</OverFlowText>
            </Box>
            <OverFlowText variant="fieldValue">
              {person && person.joinDate ? person.joinDate : "-"}
            </OverFlowText>
          </Box>
        </Box>
      </Paper>
    </RightViewLayout>
  );
}
