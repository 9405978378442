import { formatDateFromString } from "@/util/utils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
export const getInitialValues = (state) => {
  if (state?.type === "add") {
    return {
      clientName: "",
      clientCode: "",
      id: state?.id,
      type: "RETAIL_CLIENT",
      packageId: state?.id,
      email: "",
      firstName: "",
      lastName: "",
      countryId: "",
      stateId: "",
      cityId: "",
      addressLine: "",
      mobileArea: "",
      mobile: "",
      deviceCount: state?.id === "1" ? "2" : "",
      // zoneId: "",
      activateTime: "",
      expiraTime: "",
      contractNo: "",
      contractAmount: "",
      contractAmountUnit: "",
      merchantUrl: "",
      attachmentUrl: "",
      unit: "1",
    };
  } else {
    return {
      clientName: state?.currentItem?.clientName,
      clientCode: state?.currentItem?.clientCode,
      id: state?.currentItem?.id,
      type: "RETAIL_CLIENT",
      packageId: state?.currentItem?.packageId,
      email: state?.currentItem?.accountEmail,
      firstName: state?.currentItem?.firstName,
      lastName: state?.currentItem?.lastName,
      countryId: state?.currentItem?.countryId,
      stateId: state?.currentItem?.stateId,
      cityId: state?.currentItem?.cityId,
      addressLine: state?.currentItem?.addressLine,
      mobileArea: state?.currentItem?.mobileArea,
      mobile: state?.currentItem?.mobile,
      deviceCount: state?.currentItem?.deviceCount,
      // zoneId: state?.currentItem?.zoneId,
      activateTime:
        state?.type === "update"
          ? dayjs().format("YYYY-MM-DD")
          : formatDateFromString(state?.currentItem?.activateTime),
      expiraTime: dayjs(state?.currentItem?.expiraTime).format("YYYY-MM-DD"),
      contractNo: state?.currentItem?.contractNo,
      contractAmount: state?.currentItem?.constractAmount,
      contractAmountUnit: state?.currentItem?.constractAmountUnit,
      merchantUrl: state?.currentItem?.merchantUrl,
      attachmentUrl: state?.currentItem?.attachmentUrl,
      unit: state?.currentItem?.unit || "1",
      count: state?.currentItem?.count,
    };
  }
};
