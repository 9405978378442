import { FormHelperText, InputLabel, Stack } from '@mui/material'
import CmpBaseSelect from './CmpBaseSelect'
import RequirePoint from './RequirePoint'
const CmpSelect = (props) => {
  const {
    formik = null,
    placeholder = '',
    handleBlur,
    handleChange,
    label,
    dicType = '',
    isDic = false,
    name,
    error,
    value,
    labelPostion,
    ...orther
  } = props

  const blurFn = (e) => {
    if (formik?.handleBlur) {
      formik?.handleBlur(e)
    }
    if (handleChange) {
      handleChange()
    }
  }
  const changeFn = (e) => {
    if (formik?.handleChange) {
      formik?.handleChange(e)
    }
    if (handleBlur) {
      handleBlur(e)
    }
  }
  return (
    <Stack spacing={1}>
      <Stack
        direction={labelPostion === 'left' ? 'row' : 'column'}
        sx={{
          alignItems: labelPostion === 'left' ? 'flex-start' : '',
        }}
        spacing={1}
      >
        <InputLabel
          style={{
            marginTop: labelPostion === 'left' ? '12px' : '',
          }}
          htmlFor={'cmpSelect' + name}
        >
          {props.required && <RequirePoint></RequirePoint>} {label}
        </InputLabel>

        <Stack
          sx={{
            width: '100%',
            maxWidth: labelPostion === 'left' ? 'calc(100% - 60px )' : '100%',
            borderTop: '1px solid #ffffff',
          }}
        >
          <CmpBaseSelect
            id={'cmpSelect_' + name}
            formik={formik}
            dicType={dicType}
            name={name}
            value={formik ? formik.values[name] : value}
            onChange={changeFn}
            onBlur={blurFn}
            size="small"
            onClear={() => {}}
            placeholder={placeholder}
            error={Boolean(
              (formik?.touched[name] && formik?.errors[name]) || error
            )}
            isDic={isDic}
            {...orther}
          />
          {((formik?.touched[name] && formik?.errors[name]) || error) && (
            <FormHelperText error id={`standard-weight-helper-text-${name}`}>
              {formik?.errors[name] || error}
            </FormHelperText>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
export default CmpSelect
