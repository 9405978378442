import React from "react";
import RightViewLayout from "./RighViewLayout";
import GlobalFilter from "./GlobalFilter";
import { Box, Grid } from "@mui/material";
import { ReactComponent as AddIcon } from "@/assets/images/icon_add.svg";
import { ReactComponent as FilterIcon } from "@/assets/images/icon_filter.svg";
import { ReactComponent as RefreshIcon } from "@/assets/images/icon_refresh.svg";
export default function ListLayout(props) {
  let showToolbarProps = props.toolbarProps
    ? props.toolbarProps.add ||
      props.toolbarProps.filter ||
      props.toolbarProps.refresh
    : false;

  return (
    <RightViewLayout {...props} type="table">
      {props.globalFilterProps && <GlobalFilter {...props.globalFilterProps} />}
      {!props.locationMenuProps && showToolbarProps && (
        <Box
          display={"flex"}
          width={"100%"}
          flexDirection={"row-reverse"}
          my={2}
        >
          {props.toolbarProps.add && (
            <Grid pr={2} onClick={() => props.toolbarProps.onAdd()}>
              <AddIcon width={"35"} height={"35"} className="pointer" />
            </Grid>
          )}
          {props.toolbarProps.filter && (
            <Grid pr={2} onClick={() => props.toolbarProps.onFilter()}>
              <FilterIcon width={"35"} height={"35"} className="pointer" />
            </Grid>
          )}
          {props.toolbarProps.refresh && (
            <Grid pr={2} onClick={() => props.toolbarProps.onRefresh()}>
              <RefreshIcon width={"35"} height={"35"} className="pointer" />
            </Grid>
          )}
        </Box>
      )}
      {props.children}
    </RightViewLayout>
  );
}
