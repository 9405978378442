import {
  Card,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
function OutletsCards(props) {
  const { t } = useTranslation();
  const { Arrary, addFormik, name } = props;

  return (
    <Card elevation={0} sx={{ borderRadius: "10px", marginTop: "20px" }}>
      <Grid pt={2} marginLeft={2}>
        <Typography pb={2} fontSize={18} fontWeight={500}>
          {t("PCS82")}
        </Typography>
        <Grid container lg={12}>
          <Grid p={1} style={{ width: "100%" }}>
            <FormGroup row>
              {Arrary?.map((item, index) => (
                <Grid item key={index} xs={3} lg={3} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`role-Checkbox-${index}`}
                        name={name}
                        value={item?.id}
                        checked={
                          Array.isArray(addFormik.values[name]) &&
                          addFormik.values[name]?.includes(item?.id)
                        }
                        onChange={addFormik.handleChange}
                      />
                    }
                    label={item.name}
                  />
                </Grid>
              ))}
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default OutletsCards;
