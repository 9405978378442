import { Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
function SubmitButton(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Grid
      item
      xs={12}
      display={"flex"}
      flexDirection={"row-reverse"}
      mt={2}
      container
    >
      <LoadingButton
        variant="contained"
        size="large"
        loading={props?.isLoading}
        type={props.type}
        sx={{
          ml: 3,
        }}
        className="text-transform-none"
      >
        {t("LVLOT0016")}
      </LoadingButton>

      <Button
        className="text-transform-none"
        variant="outlined"
        onClick={() => navigate(props.backRoute)}
        size="large"
      >
        {t("LVLOT0015")}
      </Button>
    </Grid>
  );
}
export default SubmitButton;
