import { createSlice } from "@reduxjs/toolkit";
// 初始化状态
const initialState = {
  userInfo: "",
  permission: [], //例如["sys:aa:edit","sys:aa:add","sys:aa:del"]
};

// 创建分片
const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    clearUser(state) {
      state.userInfo = {};
    },
    // 权限字符串
    setPermission(state, action) {
      state.permission = action.payload;
    },
  },
});

// 导出
export default user.reducer;

export const { setUserInfo, clearUser, setPermission } = user.actions;
