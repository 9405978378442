import { useEffect, useState, useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { bodyStyle, highestStyle, blockStyle } from "../../css/FourSquares";
import { VisitorDemographicContext } from "../DemographicSummary";
import { useTranslation } from "react-i18next";
import { parseNumber } from "@/util/parseNumber";

function FourSquares() {
  const [ageBracket, setAgeBracket] = useState("");
  const { t } = useTranslation();
  const [leastAgeBracket, setLeastAgeBracket] = useState("");

  const calculateAgeBracket = (demographic) => {
    switch (demographic) {
      case "F0":
      case "M0":
        return "Age 0 -11";
      case "F1":
      case "M1":
        return "Age 12 - 17";
      case "F2":
      case "M2":
        return "Age 18 -34";
      case "F3":
      case "M3":
        return "Age 35 -54";
      case "F4":
      case "M4":
        return "Age 55+";
      default:
        return "Age 0 -11";
    }
  };

  const {
    highestDemographic,
    highestDemographicCount,
    highestTime,
    highestTimeCount,
    leastDemographic,
    leastDemographicCount,
    leastTime,
    leastTimeCount,
  } = useContext(VisitorDemographicContext);
  useEffect(() => {
    setAgeBracket(calculateAgeBracket(highestDemographic));
    setLeastAgeBracket(calculateAgeBracket(leastDemographic));
  }, [highestDemographic, leastDemographic]);

  return (
    <Grid container justifyContent={"space-between"} padding={"0 0.3rem"}>
      <Grid
        item
        sx={{
          ...highestStyle,
          background: "#fbe2e5",
          display: "flex",
          flexDirection: "row",
        }}
        xs={2.8}
        container
      >
        <Box
          xs={8}
          item
          sx={{
            m: 2,
            flexDirection: "column",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography variant="subtitle2">{t("PCS58")}</Typography>
          <Typography variant="h3">
            {highestTime?.replace(/-/g, "/")}
          </Typography>
        </Box>
        <Box
          sx={{
            ...blockStyle,
            background: "#ee587c",
            maxWidth: "100%",
            overflow: "hidden",
            flexShrink: 1,
          }}
          xs={4}
          item
        >
          <Typography variant="h2" fontWeight={500} color={"#FFF"}>
            {parseNumber(highestTimeCount)}
          </Typography>
          <Typography variant="subtitle2" color={"#FFF"}>
            {t("PCS59")}
          </Typography>
        </Box>
      </Grid>

      <Grid
        item
        sx={{
          ...highestStyle,
          background: "#fef3dd",
          display: "flex",
          flexDirection: "row",
        }}
        xs={2.8}
        container
      >
        <Box
          xs={8}
          item
          sx={{
            m: 2,
            flexDirection: "column",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography variant="subtitle2">{t("PCS58")}</Typography>
          <Typography variant="h3">{leastTime?.replace(/-/g, "/")}</Typography>
        </Box>
        <Box
          sx={{
            ...blockStyle,
            background: "#f39379",
            maxWidth: "100%",
            overflow: "hidden",
            flexShrink: 1,
          }}
          xs={4}
          item
        >
          <Typography variant="h2" fontWeight={500} color={"#FFF"}>
            {parseNumber(leastTimeCount)}
          </Typography>
          <Typography variant="subtitle2" color={"#FFF"}>
            {t("PCS59")}
          </Typography>
        </Box>
      </Grid>

      <Grid
        item
        sx={{
          ...highestStyle,
          background: "#dcfde8",
          display: "flex",
          flexDirection: "row",
        }}
        xs={2.8}
        container
      >
        <Box
          xs={8}
          item
          sx={{
            m: 2,
            flexDirection: "column",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography variant="subtitle2">{t("PCS61")}</Typography>

          <Grid display={"flex"}>
            <Typography variant="h2">{highestDemographic}</Typography>
            <Typography
              variant="subtitle2"
              sx={{
                m: 1,
              }}
            >
              {highestDemographic?.charAt(0) === "M" ? t("PCS62") : t("PCS70")}(
              {ageBracket})
            </Typography>
          </Grid>
        </Box>
        <Box
          sx={{
            ...blockStyle,
            background: "#65d957",
            maxWidth: "100%",
            overflow: "hidden",
            flexShrink: 1,
          }}
          xs={4}
          item
        >
          <Typography variant="h2" fontWeight={500} color={"#FFF"}>
            {parseNumber(highestDemographicCount)}
          </Typography>
          <Typography variant="subtitle2" color={"#FFF"}>
            {t("PCS59")}
          </Typography>
        </Box>
      </Grid>

      <Grid
        item
        sx={{
          ...highestStyle,
          background: "#f5e9ff",
          display: "flex",
          flexDirection: "row",
        }}
        xs={2.8}
        container
      >
        <Box
          xs={8}
          item
          sx={{
            m: 2,
            flexDirection: "column",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography variant="subtitle2">{t("PCS63")}</Typography>
          <Grid display={"flex"}>
            <Typography variant="h2">{leastDemographic}</Typography>
            <Typography
              variant="subtitle2"
              sx={{
                m: 1,
              }}
            >
              {leastDemographic?.charAt(0) === "M" ? t("PCS62") : t("PCS70")}(
              {leastAgeBracket})
            </Typography>
          </Grid>
        </Box>
        <Box
          sx={{
            ...blockStyle,
            background: "#c082f9",
            maxWidth: "100%",
            overflow: "hidden",
            flexShrink: 1,
          }}
          xs={4}
          item
        >
          <Typography variant="h2" fontWeight={500} color={"#FFF"}>
            {parseNumber(leastDemographicCount)}
          </Typography>
          <Typography color={"#FFF"} variant="subtitle2">
            {t("PCS59")}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default FourSquares;
