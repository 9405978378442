import React, { useEffect, useState } from "react";
import ListLayout from "../../../../components/ListLayout";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { ReactComponent as PeopleCountingBlueImg } from "@/assets/images/ai_people_counting_blue.svg";
import { ReactComponent as TickMarkImg } from "@/assets/images/icon_tickmark.svg";
import { ReactComponent as DeviceDefaultImg } from "@/assets/images/device_default_img.svg";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { ReactComponent as PeopleCountingGreenImg } from "@/assets/images/ai_people_counting_green.svg";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import ChannelSelect from "../../../../components/ChannelSelect";
import HoursSelect from "../../../../components/HoursSelect";

import { deviceList, filterDeviceEvents } from "@/services/DeviceService";
import CommonUtil from "../../../../util/CommonUtils";

export default function ComparisonState(props) {
  const [events, setEvents] = useState([]);
  const [comparisonEvents, setComparisonEvents] = useState([]);

  const [date, setDate] = useState(new Date());
  const [comparisonDate, setComparisonDate] = useState(new Date());

  const [currentData, setCurrentData] = useState({});
  const [comparisonData, setComparisonData] = useState({});

  const { t } = useTranslation();

  const [groupedData, setGroupedData] = useState({
    cuttent: [],
    comparison: [],
    timeSlots: [],
  });

  useEffect(() => {
    if (props.DeviceId && !CommonUtil.isEmptyString(props.DeviceId)) {
      loadDeviceEvents({ deviceId: props.DeviceId }, false);
    }
  }, [date, props.DeviceId]);

  useEffect(() => {
    if (props.DeviceId && !CommonUtil.isEmptyString(props.DeviceId)) {
      loadDeviceEvents({ deviceId: props.DeviceId }, true);
    }
  }, [comparisonDate, props.DeviceId]);

  useEffect(() => {
    let tempObj = {
      ...currentData,
      ...comparisonData,
    };

    let cuttent = [];
    let comparison = [];
    let timeSlots = [];
    Object.keys(tempObj).forEach((key) => {
      timeSlots.push(key);
      cuttent.push(currentData[key] || 0);
      comparison.push(comparisonData[key] || 0);
    });
    setGroupedData({
      cuttent,
      comparison,
      timeSlots,
    });
  }, [currentData, comparisonData]);

  const loadDeviceEvents = (filter, isomparisonDate) => {
    let currentStr = "";
    if (isomparisonDate) {
      currentStr = CommonUtil.dateFormat(comparisonDate, "yyyy-MM-dd");
    } else {
      currentStr = CommonUtil.dateFormat(date, "yyyy-MM-dd");
    }
    filterDeviceEvents({
      pageNumber: 1,
      pageSize: 10000,
      startDateTime: currentStr + " 00:00:00",
      endDateTime: currentStr + " 23:00:00",
      deviceId: filter.deviceId,
    }).then((res) => {
      if (res?.data?.data) {
        if (isomparisonDate) {
          setComparisonEvents(res?.data?.data?.list || []);
        } else {
          setEvents(res?.data?.data?.list || []);
        }
      } else {
        if (isomparisonDate) {
          setComparisonEvents([]);
        } else {
          setEvents([]);
        }
      }
    });
  };

  const barChartData = {
    series: [
      {
        name: CommonUtil.dateFormat(date, "yyyy-MM-dd"),
        data: groupedData.cuttent,
      },
      {
        name: CommonUtil.dateFormat(comparisonDate, "yyyy-MM-dd"),
        data: groupedData.comparison,
      },
    ],
    options: {
      colors: ["#9A60B4", "#FC8452"],
      fill: {
        opacity: 1,
      },
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      xaxis: {
        type: "string",
        categories: groupedData.timeSlots,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };

  useEffect(() => {
    if (events.length > 0) {
      const data = groupByTimeUTCAndCountGender(events);
      setCurrentData(data);
    } else {
      setCurrentData({});
    }
  }, [events]);

  useEffect(() => {
    if (comparisonEvents.length > 0) {
      const data = groupByTimeUTCAndCountGender(comparisonEvents);
      setComparisonData(data);
    } else {
      setComparisonData({});
    }
  }, [comparisonEvents]);

  function groupByTimeUTCAndCountGender(data) {
    const timeUTCGroups = {};
    data.forEach((event) => {
      const eventTimeUTC = new Date(
        event.eventValues.find((v) => v.name === "time_utc").value
      );
      let hourse = Math.floor(eventTimeUTC.getHours() / 3) * 3;
      eventTimeUTC.setHours(hourse);
      const roundedTimeUTC = new Date(
        Math.floor(eventTimeUTC.getTime() / (1 * 60 * 60 * 1000)) *
          (1 * 60 * 60 * 1000)
      );
      if (!timeUTCGroups[roundedTimeUTC]) {
        timeUTCGroups[roundedTimeUTC] = { count: 0 };
      }
      timeUTCGroups[roundedTimeUTC].count++;
    });
    let info = {};
    Object.keys(timeUTCGroups)
      .sort()
      .forEach((timeSlot) => {
        const hour = new Date(timeSlot).getHours();
        // const formattedTimeSlot = `${hour}:00 - ${hour + 3}:00`;
        info[hour] = timeUTCGroups[timeSlot].count;
      });
    return info;
  }

  const changeDate = (e) => {
    setDate(e);
  };
  const changeComparisonDate = (e) => {
    setComparisonDate(e);
  };

  return (
    <Box id="barChart" my={1} py={1} component={Paper} elevation={1}>
      <Grid
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
        container
        px={1}
      >
        <Typography item variant="subtitle3">
          Comparison State
        </Typography>
        <Grid
          sx={{
            display: "flex",
          }}
          item
        >
          <Box sx={{ width: "140px" }}>
            <ChannelSelect deviceId={props.DeviceId}></ChannelSelect>
          </Box>
          <Box sx={{ width: "140px", ml: 2 }}>
            <CustomDatePicker
              size={"small"}
              disableFuture={true}
              date={date}
              disabled={false}
              label={""}
              placeholder={t("LVLRCP016")}
              SelectedDate={(e) => changeDate(e)}
            />
          </Box>
          <Box sx={{ width: "140px", ml: 2 }}>
            <CustomDatePicker
              size={"small"}
              disableFuture={true}
              date={comparisonDate}
              disabled={false}
              label={""}
              placeholder={t("LVLRCP016")}
              SelectedDate={(e) => changeComparisonDate(e)}
            />
          </Box>
          <Box sx={{ width: "140px", ml: 2 }}>
            <HoursSelect hour={1}></HoursSelect>
          </Box>
        </Grid>
      </Grid>
      <ReactApexChart
        options={barChartData.options}
        series={barChartData.series}
        type="area"
        height={350}
      />
    </Box>
  );
}
