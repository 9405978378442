import api from "../configurations/http-common";
import CommonUtil from "../util/CommonUtils";
export const getOutPhotoList = async (payload) => {
  let list = [];

  if (list.length < 1) {
    let arry = ["Correct", "Misplace", "Out of Stock"];
    for (let i = 0; i < 10; i++) {
      let rund = Math.floor(Math.random() * 3);
      list.push({
        id: i,
        number: 1 + i,
        productName: "ZH Cigarette " + i,
        status: arry[rund],
      });
    }
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(list);
    }, 1000);
  });
};

//People Counting Summary 查询接口
export const getPeopleCounting = async (payload) => {
  return api.securedAxios().get("/web/getPeopleCountingData", {
    params: payload,
  });
};

//Dashboard PeopleCountingSunmmart.js 调用
export const getStoreOutlet = (payload) => {
  return api.securedAxios().get("/web/outlets", {
    params: payload,
  });
};

//DashBoard Demographic Summary 接口
export const getDemographic = (payload) => {
  return api.securedAxios().get("/web/getDemographicData", {
    params: payload,
  });
};

//Shelf Monitoring Summary  数据接口

export const getMonitoring = (payload) => {
  return api.securedAxios().get("/web/getShelfMonitoringDatas", {
    params: payload,
  });
};

//Shelf Monitoring Summary  图片接口

export const getMonitoringCaptures = (payload) => {
  return api.securedAxios().get("/web/getShelfMonitoringCaptures", {
    params: payload,
  });
};

// PeopleCount 导出接口
export const exportExCelData = (payload) => {
  return api.securedAxios().get("/web/exportPeopleCountingData", {
    params: payload,
    responseType: "blob",
  });
};
