import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as FilterIcon } from "@/assets/images/icon_filter.svg";
import AppContext from "../context/AppContext";
import { getCityListByState } from "../services/CityService";
import { getCityZoneList } from "../services/CityZoneService";
import { listCompanies } from "../services/CompanyService";
import { getAllCountries } from "../services/LocationService";
import { getOutletTypeList } from "../services/OutletTypeService";
import { getStateListByCountryId } from "../services/StateService";
import CommonUtil from "../util/CommonUtils";

const GlobalFilter = (props) => {
  const { t } = useTranslation();

  const [toggleFilter, setToggleFilter] = useState(false);

  const [clientType, setClientType] = useState({});
  const [clientTypes, setClientTypes] = useState([
    {
      value: "0",
      name: "Retail Client",
    },
    {
      value: "1",
      name: "Principle",
    },
  ]);

  const [countries, setCountries] = useState([]);
  const [countryForClient, setCountryForClient] = useState({});
  const [country, setCountry] = useState({}); //outlets Country

  //Retail Client
  const [client, setClient] = useState({});
  const [clients, setClients] = useState([]);

  const [stateList, setStateList] = useState([]);
  const [state, setState] = useState({});

  const [city, setCity] = useState({});
  const [cityList, setCityList] = useState([]);

  const [cityZone, setCityZone] = useState({});
  const [cityZones, setCityZones] = useState([]);

  const [outletType, setOutletType] = useState({});
  const [outletTypes, setOutletTypes] = useState([]);

  useEffect(() => {
    getAllCountries().then((res) => {
      //获取所有国家
      if (res?.data?.code === "LVLI0000") {
        setCountries(res?.data?.data);
      }
    });

    getCityZoneList().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCityZones(res?.data?.data?.zones || []);
      }
    });

    getOutletTypeList().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setOutletTypes(res?.data?.data?.outletTypes || []);
      }
    });
  }, []);

  useEffect(() => {
    if (
      CommonUtil.isEmpty(country) &&
      CommonUtil.isEmpty(cityZone) &&
      CommonUtil.isEmpty(outletType)
    ) {
      setOutlet(null);
      setOutlets([]);
    }
    setClient(null);
    setClients([]);
    if (!CommonUtil.isEmpty(countryForClient)) {
      loadRetailClient();
    }
  }, [countryForClient]);

  const loadRetailClient = () => {
    listCompanies({
      pageNumber: 1,
      pageSize: 1000,
      country: countryForClient?.id,
    }).then((res) => {
      if (
        res?.data?.code === "UASI0029" &&
        res?.data?.data &&
        res?.data?.data?.companies
      ) {
        setClients(res?.data?.data?.companies);
      }
    });
  };

  useEffect(() => {
    setState(null);
    setCity(null);
    if (!CommonUtil.isEmpty(country)) {
      getStateListByCountryId({
        countryId: country.id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setStateList(res?.data?.data?.states);
        }
      });
    } else {
      setStateList([]);
    }
  }, [country]);

  useEffect(() => {
    setCity(null);
    setCityList([]);
    if (!CommonUtil.isEmpty(state)) {
      getCityListByState({
        stateId: state.id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setCityList(res?.data?.data?.cities || []);
        }
      });
    }
  }, [state]);

  //以上新代码
  const { selectedClient, setSelectedClient } = useContext(AppContext);
  const [outlet, setOutlet] = useState({});
  const [outlets, setOutlets] = useState([]);
  const [filters, setFilters] = useState({
    page: 0,
    rowsPerPage: 5,
    country: "",
    state: "",
    city: "",
    cityZone: "",
    outletType: "",
    ids: [],
    companyIds:
      CommonUtil.isEmptyString(localStorage.getItem("selectedClientId")) ||
      localStorage.getItem("selectedClientId") === "-1"
        ? []
        : [localStorage.getItem("selectedClientId")],
  });

  const handleReset = () => {
    localStorage.setItem("selectedClientId", "-1");
    localStorage.setItem("selectedClient", JSON.stringify({}));
    setOutlet(null);
    setOutletType(null);
    setCityZone(null);
    setCity(null);
    setState(null);
    setCountry(null);
    setClient({});
    setCountryForClient({});
    setOutlets([]);
    setStateList([]);
    setCityList([]);
    setClients([]);
    props.onReset();
  };

  const handleSubmit = () => {
    setToggleFilter(!toggleFilter);
    const filter = {
      country: country?.id,
      city: city?.id,
      state: state?.id,
      outletType: outletType?.id,
      cityZone: cityZone?.id,
      outlet: outlet?.id,
      client: filters.companyIds,
    };
    if (client && JSON.stringify(client) !== "{}") {
      setSelectedClient(client);
      localStorage.setItem("selectedClient", JSON.stringify(client));
      localStorage.setItem("selectedClientId", client?.id);
    } else {
      localStorage.setItem("selectedClientId", "-1");
      localStorage.setItem("selectedClient", JSON.stringify({}));
    }
    if (props.onFilter) {
      props.onFilter(filter);
    }
  };

  return (
    <>
      <Box
        sx={{ display: { xs: "flex", md: "none" }, width: "100%" }}
        flexDirection={"row-reverse"}
      >
        <Box item onClick={() => setToggleFilter(!toggleFilter)}>
          <FilterIcon width={"35"} height={"35"} className="pointer" />
        </Box>
      </Box>

      <Grid
        container
        my={1}
        sx={{
          width: "100%",
          display: { xs: toggleFilter ? "block" : "none", md: "flex" },
        }}
        p={2}
        component={Paper}
        elevation={0}
        alignItems={"center"}
        className="customAutocomplete"
      >
        <Box
          sx={{
            display: { xs: toggleFilter ? "block" : "none", md: "flex" },
            width: "100%",
            p: 0,
            m: 0,
          }}
        >
          <Grid item lg={2} sm={4} xs={12} pr={0.8}>
            <Typography sx={{ fontSize: 13 }}>{t("RTMT0014")}</Typography>
            <Autocomplete
              noOptionsText={t("LVLGF0011")}
              options={clientTypes}
              value={clientType}
              onChange={(e, v) => {
                setClientType(v);
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>

          <Grid item lg={2} sm={4} xs={12} pr={0.8}>
            <Typography sx={{ fontSize: 13 }}>{t("LVLGF0010")}</Typography>
            <Autocomplete
              noOptionsText={t("LVLGF0011")}
              options={countries}
              value={countryForClient}
              onChange={(e, v) => {
                setCountryForClient(v);
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>

          <Grid item lg={2} sm={4} xs={12} pr={0.8}>
            <Typography sx={{ fontSize: 13 }}>{t("LVLGF0005")}</Typography>
            <Autocomplete
              noOptionsText={t("LVLGF0011")}
              options={clients}
              value={client}
              onChange={(e, v) => {
                setClient(v);
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>

          <Grid item lg={2} sm={4} xs={12} pr={0.8}>
            <Typography sx={{ fontSize: 13 }}>{t("LVLGF0001")}</Typography>
            <Autocomplete
              noOptionsText={t("LVLGF0011")}
              options={countries}
              value={country}
              onChange={(e, v) => {
                setCountry(v);
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>

          <Grid item lg={2} sm={4} xs={12} pr={0.8}>
            <Typography sx={{ fontSize: 13 }}>{t("LVLGF0002")}</Typography>
            <Autocomplete
              noOptionsText={t("LVLGF0011")}
              options={stateList}
              value={state}
              onChange={(e, v) => {
                setState(v);
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>

          <Grid item lg={2} sm={4} xs={12} pr={0.8}>
            <Typography sx={{ fontSize: 13 }}>{t("LVLGF0003")}</Typography>
            <Autocomplete
              noOptionsText={t("LVLGF0011")}
              options={cityList}
              value={city}
              onChange={(e, v) => {
                setCity(v);
                setOutlet(null);
                setOutlets([]);
                // if (!CommonUtil.isEmpty(v)) {
                //   let client_ = [];
                //   if (client?.id) {
                //     client_.push(client.id);
                //   }
                //   setFilters({
                //     ...filters,
                //     companyIds: client_,
                //     city: v?.id,
                //   });
                // } else {
                //   setFilters({ ...filters, city: "" });
                // }
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>

          <Grid item lg={2} sm={4} xs={12} pr={0.8}>
            <Typography sx={{ fontSize: 13 }}>{t("LVLGF0004")}</Typography>
            <Autocomplete
              noOptionsText={t("LVLGF0011")}
              options={cityZones}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              value={cityZone}
              onChange={(e, v) => {
                setCityZone(v);
                setOutlet(null);
                setOutlets([]);
                if (!CommonUtil.isEmpty(v)) {
                  let client_ = [];
                  if (client?.id) {
                    client_.push(client.id);
                  }
                  setFilters({
                    ...filters,
                    companyIds: client_,
                    cityZone: v?.id,
                  });
                } else {
                  setFilters({ ...filters, cityZone: "" });
                }
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>

          <Grid item lg={2} sm={4} xs={12} pr={0.8}>
            <Typography sx={{ fontSize: 13 }}>{t("LVLGF0007")}</Typography>
            <Autocomplete
              noOptionsText={t("LVLGF0011")}
              options={outletTypes}
              value={outletType}
              onChange={(e, v) => {
                setOutletType(v);
                setOutlet(null);
                setOutlets([]);
                if (!CommonUtil.isEmpty(v)) {
                  let client_ = [];
                  if (client?.id) {
                    client_.push(client.id);
                  }
                  setFilters({
                    ...filters,
                    companyIds: client_,
                    outletType: v?.id,
                  });
                } else {
                  setFilters({ ...filters, outletType: "" });
                }
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>
          {/* {window.location.href.includes("/dashboard")?"":<Grid item lg={2} sm={4} xs={12}>
              <Typography sx={{ fontSize: 13 }}>{t("LVLGF0006")}</Typography>
              <Autocomplete
                noOptionsText={t("LVLGF0011")}
                options={outlets}
                value={outlet}
                onChange={(e, v) => {
                  setOutlet(v);
                }}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                renderInput={(params) => <TextField {...params} size="small" />}
              ></Autocomplete>
            </Grid>} */}

          {/* <Grid item lg={2} sm={4} xs={12}>
              <Typography sx={{ fontSize: 13 }}>{t("LVLGF0006")}</Typography>
              <Autocomplete
                noOptionsText={t("LVLGF0011")}
                options={outlets}
                value={outlet}
                onChange={(e, v) => {
                  setOutlet(v);
                }}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                renderInput={(params) => <TextField {...params} size="small" />}
              ></Autocomplete>
            </Grid> */}
          <Grid container lg={2}>
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"row-reverse"}
              pt={1}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ marginLeft: 1 }}
                  onClick={handleSubmit}
                >
                  {t("LVLGF0009")}
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
                pr={1}
              >
                <Button variant="outlined" onClick={handleReset}>
                  {t("LVLGF0008")}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

GlobalFilter.defaultProps = {
  onClientChange: () => {},
};

export default GlobalFilter;

/**
 * 
  //   const defaultFilters = {
  //     page: 0,
  //     rowsPerPage: 5,
  //     country: "",
  //     state: "",
  //     city: "",
  //     cityZone: "",
  //     outletType: "",
  //     ids: [],
  //     companyIds: [],
  //   };

  //   const loadOutlets = () => {
  //     filterOutlet(1, 1000, filters).then((response) => {
  //       if (response?.data?.code === "LMSI6000" && response.data.data) {
  //         setOutlets(response?.data?.data?.sites);
  //       } else if (response?.data?.code === "LVLE0054") {
  //         setOutlets([]);
  //       }
  //     });
  //   };

  //   const resetOutlet = () => {
  //     setOutlet(null);
  //     setOutlets([]);
  //   };

  //   useEffect(() => {
  //     filterOutletType(0, 0).then((res) => {
  //       if (res?.data.code === "LVLI0000") {
  //         setOutletTypes(res?.data?.data);
  //       }
  //     });
  //     // // loadCompanies();
  //     // getAllCountries().then((res) => {
  //     //   if (res?.data?.code === "LVLI0000") {
  //     //     setCountries(res?.data?.data);
  //     //   }
  //     // });

  //     getAllCityZones().then((res) => {
  //       if (res?.data.code === "LVLI0000") {
  //         setCityZones(res?.data?.data);
  //       }
  //     });
  //   }, []);

  //   useEffect(() => {
  //     let retailClient = JSON.parse(localStorage.getItem("selectedClient"));
  //     if (
  //       countries.length > 0 &&
  //       CommonUtil.isEmpty(countryForClient) &&
  //       retailClient?.country
  //     ) {
  //       let country_ = countries.find((c) => c.id === retailClient?.countryId);
  //       setCountryForClient(country_);
  //     }
  //   }, [countries]);

  //   useEffect(() => {
  //     let retailClient = JSON.parse(localStorage.getItem("selectedClient"));
  //     let country_ = countries.find((c) => c.id === retailClient?.countryId);
  //     if (clients.length > 0 && country_?.id) {
  //       setClient(retailClient);
  //     } else if (clients.length) {
  //       if (
  //         !CommonUtil.isEmpty(countryForClient) &&
  //         CommonUtil.isEmpty(client) &&
  //         CommonUtil.isEmpty(country) &&
  //         CommonUtil.isEmpty(cityZone) &&
  //         CommonUtil.isEmpty(outletType)
  //       ) {
  //         let rClients = [];
  //         clients.forEach((c) => rClients.push(c.id));
  //         let filters_ = { ...filters };
  //         filters_.companyIds = rClients;
  //         setFilters({ ...filters, ...filters_ });
  //       }
  //     }
  //   }, [clients]);

  //   useEffect(() => {
  //     if (!_.isEqual(filters, defaultFilters)) {
  //       setOutlet(null);
  //       loadOutlets();
  //     }
  //   }, [filters]);

  //   useEffect(() => {
  //     resetOutlet();
  //     if (!CommonUtil.isEmpty(client)) {
  //       setFilters({ ...filters, companyIds: [client?.id] });
  //     } else {
  //       if (
  //         !CommonUtil.isEmpty(country) ||
  //         !CommonUtil.isEmpty(cityZone) ||
  //         !CommonUtil.isEmpty(outletType)
  //       ) {
  //         setFilters({ ...filters, companyIds: [] });
  //       } else if (!CommonUtil.isEmpty(countryForClient) && clients.length > 0) {
  //         let rClients = [];
  //         clients.forEach((c) => rClients.push(c.id));
  //         setFilters({ ...filters, companyIds: rClients });
  //       } else if (country?.id) {
  //         setFilters({ ...filters, companyIds: [], country: country?.id });
  //       } else {
  //         let rClients = [];
  //         clients.forEach((c) => rClients.push(c.id));
  //         let filters_ = { ...filters };
  //         filters_.country = "";
  //         filters_.companyIds = rClients;
  //         setFilters({ ...filters, ...filters_ });
  //       }
  //     }
  //   }, [client]);
 */
