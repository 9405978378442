import "./gradientBox.css";
const GradientBox = (props) => {
  const { children, style, ...rest } = props;
  return (
    <div
      style={{ margin: "10px", ...style }}
      {...rest}
      className={"gradientBox"}
    >
      {children}
    </div>
  );
};
export default GradientBox;
