import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box, Grid, Typography, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import GradientBox from "@/components/GradientBox";
function LocationCard({ title, onClick, open, locationName }) {
  const { t } = useTranslation();

  const boxStyle = {
    width: "220px",
    minHeight: "80px",
    flexGrow: 1,
    borderRadius: "15px",
    padding: "0.2rem 0.4rem",
  };

  return (
    <GradientBox onClick={onClick}>
      <Grid borderRadius={"15px"} sx={boxStyle}>
        <Typography
          style={{
            fontSize: "14px",
            color: "rgba(121,121,121,1)",
          }}
        >
          {title}
        </Typography>

        <Grid display={"flex"} justifyContent={"space-between"}>
          <Tooltip
            title={locationName ?? title}
            arrow
            placement="bottom"
            style={{
              fontSize: "14px",
              fontWeight: "700",
              color: "rgba(14,14,14,1)",
              marginTop: "10px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {locationName ?? title}
          </Tooltip>

          <Box>{open ? <ExpandMoreIcon /> : <ExpandLessIcon />}</Box>
        </Grid>
      </Grid>
    </GradientBox>
  );
}

export default LocationCard;
