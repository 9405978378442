import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";

export default function ConfirmModal(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    props.onClose();
  };

  const handleSubmit = () => {
    props.onConfirm();
  };

  return (
    <div>
      <Dialog maxWidth="xs" open={open} onClose={handleClose}>
        {props.title && (
          <DialogTitle id="form-dialog-title">
            <Box display="flex">
              <Box flexGrow={1}>
                <span style={{ fontSize: "1rem" }}>{props.title}</span>
              </Box>
            </Box>
          </DialogTitle>
        )}

        <DialogContent>
          <Box display="flex" p={1}>
            {props.text}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary" size="small">
            {t("LVLDB0011")}
          </Button>
          <Button onClick={handleClose} color="primary" size="small">
            {t("LVLDB0012")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
