import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import CmpFormik from "@/components/Retail-components/CmpFormik";
import { useTranslation } from "react-i18next";
import CheckboxIcon from "@mui/icons-material/CheckBoxOutlineBlank"; // 未选中状态图标
import CheckboxCheckedIcon from "@mui/icons-material/CheckBox"; // 选中状态图标
import { computerTime } from "../js/index";
import { getDeviceNumberConfig } from "../js/Config";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
function DeviceNumber(props) {
  const { addFormik, deviceConfig, setDeviceConfig } = props;

  const { t } = useTranslation();
  const { state } = useLocation();

  useEffect(() => {
    let type = addFormik.values["unit"];
    let configInfo = getDeviceNumberConfig(t, type, state);
    setDeviceConfig(configInfo);
  }, [addFormik.values["unit"]]);

  useEffect(() => {
    if (state?.id === "1") {
      let freeTime = dayjs(addFormik.values["activateTime"])
        .add(14, "day")
        .format("YYYY-MM-DD");
      addFormik.setFieldValue("expiraTime", freeTime);
    } else {
      if (state?.type === "update") {
        let starttime = dayjs(
          computerTime(
            addFormik.values["count"],
            addFormik.values["unit"],
            addFormik.values["activateTime"]
          )
        )
          .add(0, "day")
          .format("YYYY-MM-DD");
        addFormik.setFieldValue("expiraTime", starttime);
      } else if (state?.type === "extend") {
        if (dayjs(state?.currentItem?.expiraTime).isBefore(dayjs())) {
          let starttime = dayjs(
            computerTime(
              addFormik.values["count"],
              addFormik.values["unit"],
              dayjs()
            )
          )
            .add(1, "day")
            .format("YYYY-MM-DD");
          addFormik.setFieldValue(
            "activateTime",
            state?.currentItem?.activateTime
          );
          addFormik.setFieldValue("expiraTime", starttime);
        } else {
          let starttime = dayjs(
            computerTime(
              addFormik.values["count"],
              addFormik.values["unit"],
              state?.currentItem?.expiraTime
            )
          )
            .add(0, "day")
            .format("YYYY-MM-DD");
          addFormik.setFieldValue("expiraTime", starttime);
        }
      } else if (state?.type === "more") {
        addFormik.setFieldValue(
          "expiraTime",
          dayjs(state?.currentItem?.expiraTime).format("YYYY-MM-DD")
        );
      } else {
        let tiemInfo = dayjs(
          computerTime(
            addFormik.values["count"],
            addFormik.values["unit"],
            addFormik.values["activateTime"]
          )
        )
          .add(0, "day")
          .format("YYYY-MM-DD");
        addFormik.setFieldValue("expiraTime", tiemInfo);
      }
    }
  }, [
    addFormik.values["unit"],
    addFormik.values["count"],
    addFormik.values["activateTime"],
  ]);

  const CustomRadio = ({ value, label, disabled }) => (
    <FormControlLabel
      value={value}
      control={
        <Radio
          icon={<CheckboxIcon />}
          name="packageId"
          disabled={disabled}
          checkedIcon={<CheckboxCheckedIcon />}
          sx={{
            "&.Mui-checked": {
              color: "#78bc27", // 选中颜色
            },
          }}
        />
      }
      label={label}
    />
  );

  return (
    <Grid bgcolor={"#f7fbfe"}>
      <Typography fontSize={16} ml={4} pt={2}>
        {t("PCS121")}
      </Typography>

      <Grid ml={10} mt={2}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby=""
            name="packageId"
            defaultValue={"0"}
            value={addFormik.values["packageId"]}
            onChange={addFormik?.handleChange}
          >
            <CustomRadio
              value="1"
              label={t("subscription.trial_package")}
              disabled={
                state?.type === "update" ||
                (state?.id !== "1" && state?.type === "add") ||
                state?.type === "more" ||
                state?.type === "extend"
              }
            />
            <CustomRadio
              value="2"
              label={t("subscription.starter_package")}
              disabled={
                (state?.type === "update" &&
                  state?.currentItem?.packageId === "3") ||
                (state?.id !== "2" && state?.type === "add") ||
                state?.type === "more" ||
                state?.type === "extend"
              }
            />
            <CustomRadio
              value="3"
              label={t("subscription.professional_package")}
              disabled={
                (state?.type === "update" &&
                  state?.currentItem?.packageId === "4") ||
                (state?.id !== "3" && state?.type === "add") ||
                state?.type === "more" ||
                state?.type === "extend"
              }
            />
            <CustomRadio
              value="4"
              label={t("subscription.summit_package")}
              disabled={
                (state?.id !== "4" && state?.type === "add") ||
                state?.type === "more" ||
                state?.type === "extend"
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid pl={10} mr={4} mt={2} pb={2} pr={6}>
        <CmpFormik
          sx={6}
          formik={addFormik}
          formConfig={deviceConfig}
        ></CmpFormik>
      </Grid>
    </Grid>
  );
}

export default DeviceNumber;
