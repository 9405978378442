import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo, clearUser, setPermission } from "@/store/reducers/user";
export const useStateUserInfo = () =>
  useSelector((store) => store.user.userInfo);
export const useStatePermission = () =>
  useSelector((store) => store.user.permission);
export function useDispatchUser() {
  const dispatch = useDispatch();
  const stateSetUser = useCallback(
    (userInfo) => dispatch(setUserInfo(userInfo)),
    [dispatch]
  );
  const stateClearUser = useCallback(() => dispatch(clearUser()), [dispatch]);
  const stateSetPermission = useCallback(
    (permission) => dispatch(setPermission(permission)),
    [dispatch]
  );
  return { stateSetUser, stateClearUser, stateSetPermission };
}
