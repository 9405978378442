import { Grid, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import RingLoader from "react-spinners/RingLoader";
import AccessControl from "../../components/AccessControl";
import DataTable from "../../components/DataTable";
import IconHandaler from "../../components/IconHandaler";
import CommonUtil from "../../util/CommonUtils";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { REACT_TENANT_MANAGER_ADD } from "@/router/ReactEndPoints";
import { ReactComponent as UpgradeMenu } from "../../assets/images/Upgrade.svg";
import { ReactComponent as ExtendnMenu } from "../../assets/images/extend.svg";
import { ReactComponent as MoreMenu } from "../../assets/images/More.svg";
import { currencySymbols } from "@/util/unit";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const MaterialTable = (props) => {
  const { isLoading, records, rowCount, pagination, setPagination } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(() => [
    {
      field: "clientName",
      headerName: t("subscription.customerName"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => {
        return (
          <Tooltip title={e.row.clientName} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.clientName)}</span>
          </Tooltip>
        );
      },
    },

    {
      field: "accountEmail",
      headerName: t("subscription.accountCreatedBy"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => {
        return (
          <Tooltip title={e.row.accountEmail} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.accountEmail)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "contractNo",
      headerName: t("subscription.contractID"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip
          title={CommonUtil.formatLongText(e.row.contractNo)}
          arrow
          placement="bottom"
        >
          <span>{CommonUtil.formatLongText(e.row.contractNo)}</span>
        </Tooltip>
      ),
    },
    {
      field: "constractAmount",
      headerName: t("subscription.contractAmount"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip
          title={
            currencySymbols[e.row.constractAmountUnit]
              ? currencySymbols[e.row.constractAmountUnit] +
                e.row.constractAmount
              : e.row.constractAmount
          }
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(
              currencySymbols[e.row.constractAmountUnit]
                ? currencySymbols[e.row.constractAmountUnit] +
                    e.row.constractAmount
                : e.row.constractAmount
            )}
          </span>
        </Tooltip>
      ),
    },

    {
      field: "deviceCount",
      headerName: t("subscription.Device_Count"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.deviceCount} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.deviceCount)}</span>
        </Tooltip>
      ),
    },
    {
      field: "packageNameI18n",
      headerName: t("subscription.subscriptionPackage"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip
          title={t(`subscription.${e.row.packageNameI18n}`)}
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(
              t(`subscription.${e.row.packageNameI18n}`)
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "activateTime",
      headerName: t("subscription.subscriptionCreationDate"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip
          title={dayjs(e.row.activateTime).format("YYYY-MM-DD")}
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(
              dayjs(e.row.activateTime).format("YYYY-MM-DD") || "-"
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "expiraTime",
      headerName: t("subscription.expirationDate"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip
          title={dayjs(e.row.expiraTime).format("YYYY-MM-DD")}
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(
              dayjs(e.row.expiraTime).format("YYYY-MM-DD") || "-"
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      headerName: t("LVLOT0010"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (e) => {
        return (
          <IconHandaler>
            <AccessControl authorization={"1074"}>
              <Tooltip title={t("subscription.Update")} arrow>
                <UpgradeMenu onClick={() => handleActions("update", e)} />
              </Tooltip>
            </AccessControl>

            {e.row.packageId !== "1" && (
              <AccessControl authorization={"1073"}>
                <Tooltip
                  title={t("subscription.extend")}
                  arrow
                  sx={{ marginLeft: 1 }}
                >
                  <ExtendnMenu onClick={() => handleActions("extend", e)} />
                </Tooltip>
              </AccessControl>
            )}
            {e.row.packageId !== "1" && (
              <AccessControl authorization={"1072"}>
                <Tooltip
                  title={t("subscription.more")}
                  arrow
                  sx={{ marginLeft: 1 }}
                >
                  <MoreMenu onClick={() => handleActions("more", e)} />
                </Tooltip>
              </AccessControl>
            )}
          </IconHandaler>
        );
      },
    },
  ]);

  const handleActions = (action, data) => {
    if (action === "update") {
      navigate(`/subscription/add`, {
        state: { currentItem: data.row, type: action },
      });
    } else if (action === "extend") {
      navigate(`/subscription/add`, {
        state: { currentItem: data.row, type: action },
      });
    } else if (action === "more") {
      navigate(`/subscription/add`, {
        state: { currentItem: data.row, type: action },
      });
    }
  };

  return (
    <Grid>
      {isLoading ? (
        <RingLoader
          color={"#597ef7"}
          loading={isLoading}
          cssOverride={{
            display: "block",
            margin: "10% auto",
            borderColor: "#b37feb",
          }}
          size={60}
          speedMultiplier={3}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <DataTable
          height="auto"
          columns={columns}
          rows={records || []}
          getRowId={(data) => data?.id}
          page={pagination.pageNumber - 1}
          totalRecords={rowCount}
          rowsPerPage={pagination.pageSize}
          onSelection={() => console.log()}
          onPageChange={(e) => {
            setPagination({
              ...pagination,
              pageNumber: e + 1,
            });
          }}
          onPageSizeChange={(e) => {
            setPagination({
              pageNumber: 1,
              pageSize: e,
            });
          }}
          checkboxSelection={false}
        />
      )}
    </Grid>
  );
};

export default MaterialTable;
