import { getClinetsList } from "@/services/CompanyService";
import { getStoreOutlet } from "@/services/DashboardService";
import { getRolesList } from "@/services/authoritation";
import { getClientUserInfo } from "@/services/PersonService";
import axios from "axios";

export const loadClinetData = (clientId, setClients) => {
  const params = {
    pageNumber: 0,
    pageSize: 0,
    type: "RETAIL_CLIENT",
    clientId: clientId,
  };
  getClinetsList(params).then((res) => {
    if (res?.data?.code === "LVLI0000") {
      setClients(res?.data?.data);
    } else {
      setClients([]);
    }
  });
};

export const loadOutletData = (clientId, setOutlets) => {
  if (!clientId) {
    return;
  }
  let params = {
    clientId: clientId,
  };
  getStoreOutlet(params).then((res) => {
    if (res?.data?.code === "LVLI0000") {
      setOutlets(res?.data?.data);
    } else {
      setOutlets([]);
    }
  });
};

export const loadRoleData = (roleId, setRoles) => {
  getRolesList(roleId).then((res) => {
    if (res?.data?.code === "LVLI0000") {
      setRoles(res?.data?.data);
    } else {
      setRoles([]);
    }
  });
};

export const uploadProfilePhoto = (profile) => {
  var bodyFormData = new FormData();
  bodyFormData.append("url", "User info URl");
  bodyFormData.append(
    "file",
    profile !== null || profile !== undefined
      ? profile?.split(";base64,").pop()
      : ""
  );
  axios({
    method: "post",
    url: "/web/person/profile",
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("USER_TOKEN_RETAIL_AI"),
    },
  });
};

export const getFormConfig = (clients, t, type) => {
  let baseConfig = [
    {
      name: "clientId",
      label: t("LVLOT0002"),
      type: "person",
      placeholder: t("PCS95"),
      required: false,
      options: clients,
      disabled: type === "Edit",
    },

    {
      name: "firstName",
      label: t("LVLRCP014"),
      type: "input",
      placeholder: t("PCS125"),
      required: true,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("PCS125"),
        },
      ],
    },
    {
      name: "lastName",
      label: t("LVLRCP015"),
      type: "input",
      placeholder: t("PCS126"),
      required: false,
    },
    {
      name: "personId",
      label: t("LVLRCP003"),
      type: "input",
      placeholder: t("PCS127"),
      required: true,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("PCS127"),
        },
      ],
    },

    {
      name: "phone",
      label: t("LVLRC0005"),
      type: "phone",
      placeholder: t("tenant.enter_phone"),
      required: true,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("tenant. enter_phone"),
        },
        {
          type: "phoneNumber",
          message: t("subscription.please_formatted_phone"),
        },
      ],
    },

    {
      name: "email",
      label: t("LVLRCP004"),
      type: "input",
      placeholder: t("subscription.please_enter_email_address"),
      required: true,
      disabled: type === "Edit",
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "email",
          message: t("subscription.email_formatted"),
        },
        {
          type: "required",
          message: t("subscription.please_enter_email_address"),
        },
      ],
    },

    {
      name: "joinDate",
      label: t("LVLRCP006"),
      type: "selectDate",
      placeholder: t("LVLRCP016"),
      size: "small",
      required: true,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("PCS128"),
        },
      ],
    },

    {
      name: "password",
      label: t("LVLRCP023"),
      type: "input",
      inputType: "password",
      placeholder: t("PCS129"),
      required: type === "Edit" ? false : true,
      display: type === "Edit" ? false : true,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("PCS130"),
        },
        {
          type: "secondConfirm",
          message: t("PCS131"),
        },
      ],
    },

    {
      name: "confirmPassword",
      label: t("LVLRCP024"),
      type: "input",
      inputType: "password",
      placeholder: t("PCS130"),
      required: type === "Edit" ? false : true,
      display: type === "Edit" ? false : true,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("PCS130"),
        },
        {
          type: "secondConfirm",
          message: t("PCS131"),
        },
      ],
    },
  ];

  return baseConfig;
};

export const getUserList = (id, addFormik, setProfile) => {
  getClientUserInfo(id).then((res) => {
    if (res?.data?.code === "LVLI0000") {
      addFormik.setFieldValue(
        "outletIds",
        res?.data?.data?.user?.outletIdList || []
      );

      addFormik.setFieldValue("parentId", res?.data?.data?.user?.parentId);
      addFormik.setFieldValue("mobileArea", res?.data?.data?.user?.mobileArea);
      addFormik.setFieldValue(
        "profilePhoto",
        res?.data?.data?.user?.profilePhoto?.split(";base64,").pop()
      );
      setProfile(res?.data?.data?.user?.profilePhoto);
    }
  });
};
