import { Box, Divider, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomInput from "./CustomInput";
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import i18n from "i18next";
import cn from "react-phone-input-2/lang/cn.json";
import es from "react-phone-input-2/lang/es.json";

import { ReactComponent as DownArrow } from "@/assets/images/downArrow.svg";
import CommonUtil from "../util/CommonUtils";
import AppLanguage from "../lang/AppLanguages";
const CustomePhoneFiled = (props) => {
  const { t } = useTranslation();
  const [local, setLocal] = useState();
  const useStyles = makeStyles({
    borderClass: {
      "&.react-tel-input .selected-flag .flag": {
        display: CommonUtil.isEmpty(props.countryCode) ? "none" : "block",
      },
      "& .react-tel-input::after": {
        content: DownArrow,
      },
    },
    customInput: {
      "& .MuiOutlinedInput-root ": {
        paddingLeft: "0px",
      },
    },
  });
  const classes = useStyles();

  const lang = AppLanguage.getPrevLanguage();
  const countries = "zh" === lang ? cn : es;

  useEffect(() => {
    let language = AppLanguage.getDefaultLanguage();
    language = language === null ? "en" : language;
    i18n.changeLanguage(language);
    setLocal(language);
  }, []);

  return (
    <Box variant="standard" style={{ width: "100%" }}>
      <Box sx={{ margin: " 10px 0px", width: "100%" }}>
        <CustomInput
          type={"text"}
          placeholder={props.placeholder}
          autoComplete="off"
          fullWidth={true}
          disabled={props.disabled}
          className={classes.customInput}
          error={props.error}
          name={props.name}
          value={props.phoneNumber}
          variant="outlined"
          size={props.size}
          handleChange={(e) => props.handleChange(e.target.value)}
          helperText={props.error}
          validation="mobile"
          InputProps={{
            startAdornment: (
              <>
                <PhoneInput
                  disabled={props.disabled}
                  autoFormat={true}
                  countryCodeEditable={true}
                  enableSearch={true}
                  searchPlaceholder={"Search"}
                  searchNotFound={"No Options Found"}
                  onChange={(value, data) => {
                    props.handleCountryCode(data);
                  }}
                  localization={countries}
                  inputProps={{
                    disabled: true,
                  }}
                  value={props.countryCode}
                  containerClass={classes.borderClass}
                  placeholder={t("LVLRC0020")}
                  specialLabel={true}
                  // isValid={CommonUtil.isEmptyString(props.error)}
                  style={{ width: "auto" }}
                  inputStyle={{ marginBottom: "0px" }}
                />
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ marginRight: "10px" }}
                />
              </>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomePhoneFiled;
