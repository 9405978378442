const timeZoneData = [
  {
    value: '-12:00',
    name: '(GMT-12:00) International Date Line West',
  },
  {
    value: '-11:00',
    name: '(UTC-11)Coordinated Universal Time-11',
  },
  {
    value: '-10:00',
    name: '(UTC-10)Hawaii',
  },
  {
    value: '-09:00',
    name: '(UTC-9)Alaska',
  },
  {
    value: '-08:00',
    name: '(UTC-8)Pacific time (American and Canada) Baja California',
  },
  {
    value: '-07:00',
    name: '(UTC-7)La Paz, The mountain time (American and Canada), Arizona',
  },
  {
    value: '-06:00',
    name: '(UTC-6)Saskatchewan, Central time, Central America',
  },
  {
    value: '-05:00',
    name: '(UTC-5)Bogota, Lima, Quito, Rio Branco, Eastern time, Indiana(East)',
  },
  {
    value: '-04:30',
    name: '(UTC-4:30)Caracas',
  },
  {
    value: '-04:00',
    name: '(UTC-4)Atlantic time, Cuiaba, Georgetown, La Paz, Santiago',
  },
  {
    value: '-03:30',
    name: '(UTC-3:30)Newfoundland',
  },
  {
    value: '-03:00',
    name: '(UTC-3)Brasilia, Buenos Aires, Greenland, Cayenne',
  },
  {
    value: '-02:00',
    name: '(UTC-2)The International Date Line West-02',
  },
  {
    value: '-01:00',
    name: '(UTC-1)Cape Verde Islands, Azores',
  },
  {
    value: '-00:00',
    name: '(UTC)Dublin, Edinburgh, Lisbon, London, The International Date Line West',
  },
  {
    value: '+01:00',
    name: '(UTC+1)Amsterdam, Brussels, Sarajevo',
  },
  {
    value: '+02:00',
    name: '(UTC+2)Beirut, Damascus, Eastern Europe, Cairo,Athens, Jerusalem',
  },
  {
    value: '+03:00',
    name: '(UTC+3)Baghdad, Kuwait, Moscow, St Petersburg,Nairobi',
  },
  {
    value: '+03:30',
    name: '(UTC+3:30)Teheran or Tehran',
  },
  {
    value: '+04:00',
    name: '(UTC+4)Abu Dhabi, Yerevan, Baku, Port Louis, Samarra',
  },
  {
    value: '+04:30',
    name: '(UTC+4:30)Kabul',
  },
  {
    value: '+05:00',
    name: '(UTC+5)Ashgabat, Islamabad, Karachi',
  },
  {
    value: '+05:30',
    name: '(UTC+5:30)Chennai, Calcutta Mumbai, New Delhi',
  },
  {
    value: '+05:45',
    name: '(UTC+5:45)Kathmandu',
  },
  {
    value: '+06:00',
    name: '(UTC+6)Astana, Dhaka, Novosibirsk',
  },
  {
    value: '+06:30',
    name: '(UTC+6:30)Yangon',
  },
  {
    value: '+07:00',
    name: '(UTC+7)Bangkok, Hanoi, Jakarta',
  },
  {
    value: '+08:00',
    name: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi,Kuala Lumpur, Singapore',
  },
  {
    value: '+09:00',
    name: '(UTC+9)Osaka, Tokyo, Seoul, Yakutsk',
  },
  {
    value: '+09:30',
    name: '(UTC+9:30)Adelaide, Darwin',
  },
  {
    value: '+10:00',
    name: '(UTC+10)Brisbane, Vladivostok, Guam, Canberra',
  },
  {
    value: '+11:00',
    name: '(UTC+11)Solomon Islands, New Caledonia',
  },
  {
    value: '+12:00',
    name: '(UTC+12)Anadyr, Oakland, Wellington, Fiji',
  },
  {
    value: '+13:00',
    name: "(UTC+13)Nuku'alofa, The Samoa Islands",
  },
  {
    value: '+14:00',
    name: '(UTC+14)Christmas Island',
  },
];

export const timeZoneList = timeZoneData;
