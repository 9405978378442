import * as Yup from "yup";
export const createValidation = (config, expendObj = {}) => {
  let shapeObj = {};
  config.forEach((item) => {
    if (item?.required) {
      let validation = item.validation;
      if (
        validation &&
        (item.conditionalRendering === undefined ||
          (item.conditionalRendering && item.conditionalRendering()))
      ) {
        let temp = Yup;
        validation.forEach((valid) => {
          let type = valid.type;

          if (type === "string" || type === "required") {
            temp = temp[type](valid.message);
          } else if (type === "number") {
            temp = temp?.matches(/^\d+(\.\d+)?$/, {
              message: valid.message,
              excludeEmptyString:
                valid.excludeEmptyString === undefined
                  ? true
                  : valid.excludeEmptyString,
            });
          } else if (type === "positive") {
            temp = temp.positive(valid.message);
          } else if (type === "min" || type === "max") {
            if (valid.value === undefined) {
              console.error("请配置" + temp[type] + "范围的值");
            } else {
              temp = temp[type](valid.value, valid.message);
            }
          } else if (type === "test") {
            temp = temp.test({
              name: valid[type],
              test(value, ctx) {
                return valid.callback(value, ctx);
              },
            });
          } else if (type === "matches") {
            temp = temp.matches(valid.matches, {
              message: valid.message,
              excludeEmptyString:
                valid.excludeEmptyString === undefined
                  ? true
                  : valid.excludeEmptyString,
            });
          } else if (type === "email") {
            temp = temp.email(valid.message);
          } else if (type === "password") {
            temp = temp.matches(/^[^\u4e00-\u9fa5]{8,20}$/, {
              message: valid.message,
            });
          } else if (type === "secondConfirm") {
            temp = temp.oneOf([Yup.ref("password"), null], valid.message);
          } else if (type === "phoneNumber") {
            temp = temp.matches(
              /^\+?1?\s*[-\/.]?\s*\(?\d{3}\)?[-\/.\s]?\d{3}[-\/.]?\d{4}$/,
              {
                message: valid.message,
              }
            );
          } else {
            console.error("当前类型还没有配置校验规则");
          }
        });
        shapeObj[item.name] = temp;
      }
    }
  });
  let tempObj = {
    ...shapeObj,
    ...expendObj,
  };

  return Yup.object().shape(tempObj);
};

// username: Yup.string().required("请输入用户名称"),
// phone: Yup.string()
//   .required("请输入手机号")
//   .matches(/^1[3456789]\d{9}$/, "请输入正确手机号"),
// // email: Yup.string()
// //   .required("请输入邮箱")
// //   .matches(
// //     /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
// //     "请输入正确邮箱"
// //   ),
// cardType: Yup.string().required("请选择证件类型"),
// citizenCode: Yup.string().when("cardType", {
//   is: (cardType) => {
//     return cardType == 1
//   },
//   then: () => {
//     return Yup.string().required('请输入证件号码').matches(
//       /^(?!^0+$)[a-zA-Z0-9]{3,20}$/,
//       '请输入正确的护照号码'
//     )
//   },
//   otherwise: () => {
//     return Yup.string().required('请输入证件号码').matches(
//       /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2]\d|3[01])\d{3}([0-9]|X)$/,
//       '请输入正确的身份证号码'
//     )
//   },
// }),

// validationSchema: Yup.object().shape({
//   tenantName: Yup.string().required("请输入企业名称").matches(/^[\u4e00-\u9fa5a-zA-Z().、]{0,100}$/, "汉字、英文字符或特殊字符“（”、“）”、“.”,最长不超过100个字符"),
//   tenantCode: Yup.string()
//     .required("请输入社会统一信用代码")
//     .transform((value) => value.toUpperCase().slice(0, 18)) // 转换为大写并截断超长输入
//     .matches(/^[A-Z0-9]{18}$/, "社会统一信用代码应为18位数字或大写字母"),
//   corporation: Yup.string().required("请输入企业法人").matches(/^[\u4e00-\u9fa5a-zA-Z]{1,50}$/, "企业法人只能包含汉字和英文字符，且长度不超过50个字符"),
//   provinceCode: Yup.string().required("请选择选择省份"),
//   cityCode: Yup.string().required("请选择城市"),
//   areaCode: Yup.string().required("请选择区县"),
//   operationDomains: Yup.string().required("请输入运营端名称").max(200, "运营端名称长度不能超过200个字符").test('is-different', '面客端域名与运营端名称不能完全一致', function (value) {
//     const { clientDomains } = this.parent;
//     return value !== clientDomains;
//   }),
//   operationCertificate: Yup.string().required("请上传运营端域名证书"),
//   clientDomains: Yup.string().required("请输入面客端域名")
//     .max(200, "面客端域名长度不能超过200个字符").test('is-different', '面客端域名与运营端名称不能完全一致', function (value) {
//       const { operationDomains } = this.parent;
//       return value !== operationDomains;
//     }),
//   address: Yup.string().required("请输入注册地址")
//     .max(150, "注册地址长度不能超过150个字符"),
//   clientCertificate: Yup.string().required("请上传面客端域名证书"),
//   businessLicenseUrl: Yup.string().required("请上传营业执照"),
//   businessLogoUrl: Yup.string().required("请上传企业logo"),
// }),
