import api from "../configurations/http-common";
import CommonUtil from "../util/CommonUtils";

export const deviceList = async (filter) => {
  let url = "web/devices?";

  if (filter?.pageNumber) {
    url = url + "pageNumber=" + filter.pageNumber;
  }
  if (filter?.pageSize) {
    url = url + "&pageSize=" + filter.pageSize;
  }
  if (filter?.id) {
    url = url + "&id=" + filter.id;
  }
  if (filter?.sn) {
    url = url + "&sn=" + filter.sn;
  }
  if (filter?.deviceType) {
    url = url + "&deviceType=" + filter.deviceType;
  }
  if (filter?.deviceModel) {
    url = url + "&deviceModel=" + filter.deviceModel;
  }
  if (filter?.companyId) {
    url = url + "&companyId=" + filter.companyId;
  }
  if (filter?.deviceAlias) {
    url = url + "&deviceAlias=" + filter.deviceAlias;
  }
  if (filter?.mac) {
    url = url + "&mac=" + filter.mac;
  }
  if (filter?.siteId) {
    url = url + "&siteId=" + filter.siteId;
  }
  if (filter?.zoneId) {
    url = url + "&zoneId=" + filter.zoneId;
  }
  if (filter?.checkInOrOutType) {
    url = url + "&checkInOrOutType=" + filter.checkInOrOutType;
  }
  if (filter?.ip) {
    url = url + "&ip=" + filter.ip;
  }
  if (filter?.registrationDevice) {
    url = url + "&registrationDevice=" + filter.registrationDevice;
  }
  const loginType = CommonUtil.getLoginType();
  if (loginType !== "HQ") {
    url = url + "&cascade=0";
  } else {
    url = url + "&cascade=1";
  }
  return api.securedAxios().post(url);
};

export const filterDeviceEvents = async (params) => {
  let url = "web/device_events";
  params.deviceType = "sr-aibox";
  const loginType = CommonUtil.getLoginType();
  if (loginType !== "HQ") {
    params.cascade = 0;
  } else {
    params.cascade = 1;
  }
  return api.securedAxios().get(url, {
    params,
  });
};

export const bindDevice = async (payload) => {
  return api.securedAxios().put("/web/device", payload);
};

export const addDevice = async (payload) => {
  return api.securedAxios().put("/web/add_device", payload);
};

export const findDeviceBySn = async (sn, params) => {
  return api.securedAxios().get(`/web/devices/pre_register/${sn}`, {
    params,
  });
};

export const unBindDevice = async (deviceId) => {
  let url = `/web/delete_device/${deviceId}`;
  return api.securedAxios().delete(url);
};

export const getDeviceBySn = async (sn) => {
  return await api.securedAxios().post(
    "/web/devices",
    {},
    {
      params: {
        sn,
      },
    }
  );
  // return new Promise((resvle,reject)=>{
  //     let details =  sessionStorage.getItem('viewDevice')
  //     if(details){
  //         resvle(JSON.parse(details))
  //     }else{
  //         resvle({})
  //     }

  // })
};

export const getChannelList = async (deviceId) => {
  return api.securedAxios().get(`/web/channels/${deviceId}`);
};

//获取Metadata 数据分类接口
export const getChannelType = async () => {
  return api.securedAxios().get(`/web/eventType`);
};

//获取设备通道信息
export const getDeviceChannel = async (deviceId) => {
  return api
    .securedAxios()
    .get(`/web/device/getDeviceChannel?deviceId=${deviceId}`);
};

//获取设备通道间隔时间
export const getDeviceTime = async (payload) => {
  return api.securedAxios().put(`/web/device/setDeviceChannel`, payload);
};

//获取DevicePhoto
export const getDevicePhoto = async ({ deviceId, channelNum }) => {
  return api
    .securedAxios()
    .get(
      `/web/device/deviceSetPhoto?deviceId=${deviceId}&channelNum=${channelNum}`
    );
};

//获取device setting 数据列表
export const getDeviceSetting = async ({ deviceId, channelNum }) => {
  return api
    .securedAxios()
    .get(
      `/web/device/deviceSetting?deviceId=${deviceId}&channelNum=${channelNum}`
    );
};

//重新获取注册图
export const getRegPhoto = async ({ deviceId, channelNum }) => {
  return api
    .securedAxios()
    .get(
      `/web/device/getRegisterPhoto?deviceId=${deviceId}&channelNum=${channelNum}`
    );
};

//重新获取识别结果
export const getIdentlyResult = async (payload) => {
  return api.securedAxios().post(`/web/device/getIdentifyResult`, payload);
};

//保存device setting
export const saveDeviceSetting = async (payload) => {
  return api.securedAxios().post(`/web/device/saveDeviceSetting`, payload);
};

//查看设备详情
export const getDeviceDetail = async (id) => {
  return api.securedAxios().get(`/web/devices/` + id);
};

export const getDeviceList = (payload) => {
  return api.securedAxios().get("/web/deviceList", {
    params: payload,
  });
};

//获取Channe Setting 下拉框数据
export const getChannelSetting = (id) => {
  return api.securedAxios().get(`/web/deviceChannel?deviceId=${id}`);
};

//保存场景设置
export const saveSenceSetting = (payload) => {
  return api.securedAxios().post("/web/deviceChannel", payload);
};

export const editDevice = (payload) => {
  return api.securedAxios().put("/web/updateDevice", payload);
};
