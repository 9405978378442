import React, { useEffect, useState } from "react";
import ListLayout from "../../../../components/ListLayout";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { ReactComponent as PeopleCountingBlueImg } from "@/assets/images/ai_people_counting_blue.svg";
import { ReactComponent as TickMarkImg } from "@/assets/images/icon_tickmark.svg";
import { ReactComponent as DeviceDefaultImg } from "@/assets/images/device_default_img.svg";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { ReactComponent as PeopleCountingGreenImg } from "@/assets/images/ai_people_counting_green.svg";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import ChannelSelect from "../../../../components/ChannelSelect";
import HoursSelect from "../../../../components/HoursSelect";

import { deviceList, filterDeviceEvents } from "@/services/DeviceService";
import CommonUtil from "../../../../util/CommonUtils";

export default function AgeAttribute(props) {
  const [events, setEvents] = useState([]);
  const [date, setDate] = useState(new Date());
  const { t } = useTranslation();

  const [data, setData] = useState({
    totalAdults: 0,
    totalOldage: 0,
    totalChildrens: 0,
  });

  useEffect(() => {
    if (props.DeviceId && !CommonUtil.isEmptyString(props.DeviceId)) {
      loadDeviceEvents({ deviceId: props.DeviceId });
    }
  }, [date, props.DeviceId]);

  const loadDeviceEvents = (filter) => {
    let currentStr = CommonUtil.dateFormat(date, "yyyy-MM-dd");
    filterDeviceEvents({
      pageNumber: 1,
      pageSize: 10000,
      startDateTime: currentStr + " 00:00:00",
      endDateTime: currentStr + " 23:00:00",
      deviceId: filter.deviceId,
    }).then((res) => {
      if (res?.data?.data) {
        setEvents(res?.data?.data?.list || []);
      } else {
        setEvents([]);
      }
    });
  };

  const pieChartData = {
    series: [data?.totalChildrens, data?.totalAdults, data?.totalOldage],
    options: {
      colors: ["#5470C6", "#a7d690", "#fbd479", "#f18585", "#fd9d75"],
      chart: {
        type: "donut",
      },
      labels: ["Children", "Middle Age", "Old Age"],

      dataLabels: { enabled: false },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    let child = 0;
    let adult = 0;
    let old = 0;
    events.forEach((event) => {
      let eventValues = event?.eventValues;
      if (eventValues instanceof Array && eventValues.length > 0) {
        eventValues.forEach((ev) => {
          if (ev.name === "age_scope") {
            switch (ev.value) {
              case "1":
                adult++;
                break;
              case "2":
                old++;
                break;
              default:
                child++;
                break;
            }
          }
        });
      }
    });
    setData({
      totalAdults: adult,
      totalOldage: old,
      totalChildrens: child,
    });
  }, [events]);

  const changeDate = (e) => {
    setDate(e);
  };

  return (
    <Box id="barChart" my={1} py={1} component={Paper} elevation={1}>
      <Grid
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
        container
        px={1}
      >
        <Typography item variant="subtitle3">
          Age Attribute
        </Typography>
        <Grid
          sx={{
            display: "flex",
          }}
          item
        >
          <Box sx={{ width: "140px" }}>
            <ChannelSelect deviceId={props.DeviceId}></ChannelSelect>
          </Box>
          <Box sx={{ width: "140px", ml: 2 }}>
            <CustomDatePicker
              size={"small"}
              disableFuture={true}
              date={date}
              disabled={false}
              label={""}
              placeholder={t("LVLRCP016")}
              SelectedDate={(e) => changeDate(e)}
            />
          </Box>
          <Box sx={{ width: "140px", ml: 2 }}>
            <HoursSelect hour={1}></HoursSelect>
          </Box>
        </Grid>
      </Grid>
      <ReactApexChart
        options={pieChartData.options}
        series={pieChartData.series}
        type="donut"
        height={350}
      />
    </Box>
  );
}
