import React, { useEffect, useState, useRef } from "react";
import copy from "copy-to-clipboard";
import ListLayout from "@/components/ListLayout";
import AccessControl from "@/components/AccessControl";
import DataTable from "@/components/DataTable";
import RingLoader from "react-spinners/RingLoader";
import { ReactComponent as AddIcon } from "@/assets/images/icon_add.svg";
import { ReactComponent as RefreshIcon } from "@/assets/images/icon_refresh.svg";
import { REACT_OPEN_API_ADD } from "@/router/ReactEndPoints";
import { Grid, Box, TextField, Button, message } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import {
  loadData,
  getColumns,
  handleSubmit,
  handlePageChange,
  handlePageSize,
} from "./method";
function OpenAPI(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0); //数据列表总数
  const [showSecretKey, setSecretKey] = useState({});
  const [columsWidth, setColumWidth] = useState(false);
  const [storeName, setStoreName] = useState("");
  const textRef = useRef(null);
  const override = {
    display: "block",
    margin: "20% auto",
    borderColor: "#b37feb",
  };

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  //点击复制文本
  const handleCopyClick = () => {
    copy(textRef.current.innerText);
    enqueueSnackbar(t("PCS81"), {
      variant: "success",
    });
  };
  const columns = getColumns(
    columsWidth,
    t,
    showSecretKey,
    setSecretKey,
    setColumWidth,
    enqueueSnackbar,
    navigate,
    textRef,
    handleCopyClick
  );

  const toAdd = () => {
    navigate(REACT_OPEN_API_ADD);
  };
  const refreshClick = () => {
    getDataList();
  };

  const handleReset = () => {
    setStoreName("");
    getDataList();
  };
  const getRowId = (data) => data.id;

  useEffect(() => {
    getDataList();
  }, [filters]);

  const getDataList = () => {
    loadData(filters, setIsLoading, setRecords, setTotalRecords);
  };

  const changePage = (e) => {
    handlePageChange(e, setIsLoading, setFilters, filters);
  };

  const changeNumber = (e) => {
    handlePageSize(e, setIsLoading, setFilters, filters);
  };

  const handleSubmitList = () => {
    handleSubmit(setIsLoading, storeName, setRecords, setTotalRecords, filters);
  };

  return (
    <>
      <ListLayout navigateBack={false} title={t("RTPD0007")}>
        <Grid
          display={"flex"}
          sx={{
            width: "100%",
            background: "#FFF",
            height: "100px",
            borderRadius: "15px",
          }}
        >
          <Box
            sx={{
              margin: "30px 30px",
            }}
          >
            <TextField
              size="small"
              label={t("LVLDB0019")}
              placeholder={t("PLACE01")}
              value={storeName}
              onChange={(e) => setStoreName(e.target.value)}
            ></TextField>
          </Box>

          <Box sx={{ margin: "35px 35px" }}>
            <Button
              variant="contained"
              sx={{ marginLeft: 1, p: 0.5 }}
              style={{
                height: "28px",
              }}
              onClick={handleSubmitList}
            >
              {t("LVLGF0009")}
            </Button>

            <Button
              style={{
                height: "28px",
              }}
              sx={{ marginLeft: 1, p: 0.5 }}
              variant="outlined"
              onClick={handleReset}
            >
              {t("LVLGF0008")}
            </Button>
          </Box>
        </Grid>

        <Grid
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
          justifyContent={"flex-end"}
          container
        >
          <Box
            display={"flex"}
            width={"100%"}
            flexDirection={"row-reverse"}
            my={2}
          >
            <AccessControl authorization={"1055"}>
              <Box
                item
                pr={2}
                onClick={() => {
                  toAdd();
                }}
              >
                <AddIcon width={"35"} height={"35"} className="pointer" />
              </Box>
            </AccessControl>
            <Box item pr={2}>
              <RefreshIcon
                width={"35"}
                height={"35"}
                className="pointer"
                onClick={refreshClick}
              />
            </Box>
          </Box>
        </Grid>
        <Grid>
          {isLoading ? (
            <RingLoader
              color={"#597ef7"}
              loading={isLoading}
              cssOverride={override}
              size={60}
              speedMultiplier={3}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <DataTable
              height="auto"
              columns={columns}
              rows={records || []}
              getRowId={getRowId}
              page={filters.pageNumber - 1}
              totalRecords={totalRecords}
              rowsPerPage={filters.pageSize}
              onSelection={() => console.log()}
              onPageChange={(pn) => changePage(pn)}
              onPageSizeChange={(ps) => changeNumber(ps)}
              checkboxSelection={false}
            />
          )}
        </Grid>
      </ListLayout>
    </>
  );
}

export default OpenAPI;
