import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enUsTrans from "./modules/en";
import zhCnTrans from "./modules/zh";
import geGerman from "./modules/ge";
import esUsTrans from "./modules/es";
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "",
    initImmediate: false,
    fallbackLng: "en",
    preload: ["en", "es", "zh", "ge"],
    // preload: ["en", "zh"],
    debug: false,
    logger: {
      level: "error",
    },
    // backend: {
    // loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
    // loadPath: "/src/lang/{{ns}}/{{lng}}.js",
    // },

    // ns: ["translations"],
    // defaultNS: "translations",
    // ns: ["modules"],
    // defaultNS: "modules",
    resources: {
      en: {
        // 这里是我们的翻译文本
        translation: enUsTrans,
      },
      zh: {
        translation: zhCnTrans,
      },
      es: {
        translation: esUsTrans,
      },
      ge: {
        translation: geGerman,
      },
    },

    keySeparator: ".",
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      //   wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
      useSuspense: true,
    },
  });

export default i18n;
