import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box, Grid, Typography, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import GradientBox from "@/components/GradientBox";
import zIndex from "@mui/material/styles/zIndex";
import Label from "@/themes/overrides/Label";
import CommonUtil from "@/util/CommonUtils";

function CustomCards({
  title,
  startTimes,
  endTimes,
  onClick,
  open,
  startName,
  endName,
  isShowAllStore,
  storeName,
  store,
  locationName,
}) {
  const { t } = useTranslation();

  const boxStyle = {
    width: "220px",
    minHeight: "80px",
    flexGrow: 1,
    borderRadius: "15px",
    padding: "0.2rem 0.4rem",
  };

  return (
    <GradientBox onClick={onClick}>
      <Grid borderRadius={"15px"} sx={boxStyle}>
        <Typography
          style={{
            fontSize: "14px",
            color: "rgba(121,121,121,1)",
          }}
        >
          {title}
        </Typography>

        <Grid display={"flex"} justifyContent={"space-between"}>
          {store ? (
            <Tooltip
              title={
                storeName === "All" && isShowAllStore
                  ? t("PCS09")
                  : storeName?.name
              }
              arrow
              placement="bottom"
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "rgba(14,14,14,1)",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {storeName === "All" && isShowAllStore
                ? t("PCS09")
                : storeName?.name}
            </Tooltip>
          ) : (
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "rgba(14,14,14,1)",
                whiteSpace: "nowrap",
              }}
            >
              {startTimes || startName} ~ {endTimes || endName}
            </Typography>
          )}

          {locationName && (
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "rgba(14,14,14,1)",
                whiteSpace: "nowrap",
              }}
            >
              {/* {locationName === "" ||
              locationName === undefined ||
              locationName === null
                ? { title }
                : { locationName }} */}

              {locationName}
            </Typography>
          )}

          <Box>{open ? <ExpandMoreIcon /> : <ExpandLessIcon />}</Box>
        </Grid>
      </Grid>
    </GradientBox>
  );
}

export default CustomCards;
