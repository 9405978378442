import { createTheme } from "@mui/material/styles";
import { presetPalettes as colors } from "@ant-design/colors";
const palette = (mode) => {
  return createTheme({
    palette: {
      primary: {
        main: "#1487CA",
        contrastText: "#ffffff",
      },
    },
  });
};
export default palette;
