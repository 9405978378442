import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfor, clearUser } from "../reducers/user";
import CommonUtil from "@/util/CommonUtils";
export const useStateUserInfo = () =>
  useSelector((store) => {
    // console.log("store", store);
    return store.user.userInfor;
  });

export function useDispatchUser() {
  const dispatch = useDispatch();
  const stateSetUser = useCallback(
    (userInfor) => dispatch(setUserInfor(userInfor)),
    [dispatch]
  );

  const stateClearUser = useCallback(() => dispatch(clearUser()), [dispatch]);
  return { stateSetUser, stateClearUser };
}
