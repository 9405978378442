import api from "../configurations/http-common";

export const getSate = async () => {
  return api.securedAxios().get("web/states");
};

export const getSateById = async (id) => {
  return api.securedAxios().get("web/state" + "?id=" + id);
};

export const filterState = async (pageNumber, pageSize, countryId) => {
  var params = "?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
  if (countryId) params = params + "&countryId=" + countryId;
  return api.securedAxios().get("web/states/list" + params);
};

//新接口
export const getStateListByCountryId = async (params) => {
  return api.securedAxios().get("/web/states/list", {
    params
  });
};

export const createSate = async (payload) => {
  console.log(payload);
  return api.securedAxios().post("web/state", payload);
};

export const editState = async (payload) => {
  return api.securedAxios().put("web/state", payload);
};

export const deleteState = async (id) => {
  return api.securedAxios().delete("web/state" + "?id=" + id);
};
