import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { getChannelList } from "../services/DeviceService";
import { useEffect, useState } from "react";

export default function ChannelSelect(props) {
  const [channelOptions, setChannelOptions] = useState([]);
  const [channelObj, setChannelObj] = useState([]);
  useEffect(() => {
    if (props.deviceId) {
      getChannelList(props.deviceId)
        .then((res) => {
          // console.log("fafdadfkkkkkkkkkkkkkkkkkkkk", res);
          if (res?.data?.data?.srChannels) {
            setChannelOptions([
              {
                channelName: "All",
                channelNum: "all",
              },
              ...res?.data?.data?.srChannels,
            ]);
          } else {
            setChannelOptions([
              {
                channelName: "All",
                channelNum: "all",
              },
            ]);
          }
        })
        .catch(() => {});
    }
  }, [props.deviceId]);

  useEffect(() => {
    let slect = channelOptions.filter((item) => {
      if (item.value === props.channel) {
        return true;
      } else {
        return false;
      }
    });
    if (slect && slect.length > 0) {
      setChannelObj(slect[0]);
    } else {
      setChannelObj(channelOptions[0]);
    }
  }, [props.channel, channelOptions]);

  const changeChannel = (v) => {
    if (props.change) {
      props.change(v);
    }
  };
  return (
    <Autocomplete
      options={channelOptions}
      value={channelObj}
      fullWidth={true}
      onChange={(e, v) => {
        let value = v ? v.value : "";
        changeChannel(value);
      }}
      getOptionLabel={(option) =>
        option.channelName ? option.channelName : ""
      }
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          error={""}
          helperText={""}
          style={{ backgroundColor: "#FFFFFF" }}
        />
      )}
    ></Autocomplete>
  );
}
