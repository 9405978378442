import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TitleBar from "./TitleBar";
import { listCompanies } from "../services/CompanyService";
import AppContext from "../context/AppContext";

export default function RightViewLayout(props) {
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(
    JSON.parse(localStorage.getItem("selectedClient"))
  );
  const { setSelectedClient } = useContext(AppContext);

  // useEffect(() => {
  //   if (window.location.href.includes("/dashboard")) {
  //     listCompanies().then((res) => {
  //       if (
  //         res?.data?.code === "UASI0000" &&
  //         res?.data?.data &&
  //         res?.data?.data?.companies
  //       ) {
  //         setClients(res?.data?.data?.companies);
  //       }
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (client && JSON.stringify(client) !== "{}") {
      setSelectedClient(client);
      localStorage.setItem("selectedClient", JSON.stringify(client));
      localStorage.setItem("selectedClientId", client?.id);
    } else {
      localStorage.setItem("selectedClientId", "-1");
      localStorage.setItem("selectedClient", JSON.stringify({}));
    }
  }, [client]);

  useEffect(() => {
    if (clients.length > 0 && localStorage.getItem("selectedClient")) {
      setClient(JSON.parse(localStorage.getItem("selectedClient")));
    }
  }, [clients]);

  return (
    <Box pt={1} sx={{ pb: { xs: "50px", md: "0px" } }}>
      <TitleBar
        navigateBack={props.navigateBack}
        title={props.title ? props.title : "Dummy title"}
        actions={props.actions}
      >
        {props.searchProps}
      </TitleBar>
      <Grid
        container
        component={Paper}
        elevation={0}
        variant="elevation"
        style={{
          minHeight: props.type !== "table" ? "75vh" : "none",
          overflowY: "visible",
          height: "fit-content",
          marginBottom: props.type !== "table" ? "60px" : "none",
          backgroundColor: "transparent",
        }}
        p={1}
      >
        <Box width={"100%"}>{props.children}</Box>
      </Grid>
    </Box>
  );
}
