import { InputLabel, TextField, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Otp } from "@/assets/images/icon_otp.svg";
import { ReactComponent as Password } from "@/assets/images/icon_password.svg";
import { ReactComponent as VisibilityIcon } from "@/assets/images/icon_viewoff.svg";
import { ReactComponent as VisibilityOffIcon } from "@/assets/images/icon_viewon.svg";
import UserService from "../services/UserService";
import CommonUtil from "../util/CommonUtils";
import CustomInput from "./CustomInput";

export default function PasswordField(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [visibility, setVisibility] = React.useState(true);
  const [counter, setCounter] = React.useState(60);
  const [sendOtp, setSendOtp] = React.useState(false);

  const [error, setError] = React.useState({
    userName: "",
    password: "",
    countryCode: "",
  });

  useEffect(() => {
    props.onChange("");
  }, [props.passwordType]);

  useEffect(() => {
    props.setPasswordType("Password");
  }, [props.userNameType]);

  useEffect(() => {
    if (sendOtp) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter, sendOtp]);

  const handleSendOtp = async (data) => {
    if (CommonUtil.isEmptyString(props.phone)) {
      enqueueSnackbar("Mobile number is required", { variant: "error" });
      return false;
    } else {
      if (
        CommonUtil.isEmptyString(props.countryCode) ||
        props.countryCode === "-"
      ) {
        enqueueSnackbar(t("LVL0007"), { variant: "error" });
        return false;
      }
    }
    if (
      props.phone.length + props.countryCode.length < 8 ||
      props.phone.length + props.countryCode.length > 23
    ) {
      enqueueSnackbar(t("LVL0008"), { variant: "error" });
      return;
    }
    if (counter > 59 || counter <= 0) {
      setSendOtp(true);
    }

    if (counter === 300 || counter === 0) {
      await UserService.sentOtp(props.phone).then((response) => {
        if (response?.data?.code === "UASI0014") {
          setSendOtp(true);
          enqueueSnackbar(t("ZLINK0094"), {
            variant: "success",
          });
          setCounter(300);
        } else {
          if (response?.data?.code === "UASE0077") {
            setError({
              ...error,
              otp: response.data.message,
            });
          } else {
            setError({
              ...error,
              userName: response.data.message,
            });
          }
        }
      });
    }
  };

  const passwordFiled = () => (
    <Box
      component="form"
      noValidate
      sx={{
        display: "flex",
        gridTemplateColumns: { sm: "1fr 1fr" },
        gap: 2,
      }}
    >
      <CustomInput
        label={t("LVL0019")}
        size="small"
        required
        placeholder={t("LVL0019")}
        id="bootstrap-input"
        fullWidth={true}
        {...props}
        handleChange={(e) => props.onChange(e.target.value)}
        error={props.error}
        helperText={props.error}
        type={visibility ? "password" : "text"}
        autoComplete="off"
        InputProps={{
          endAdornment: visibility ? (
            <VisibilityOffIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setVisibility(!visibility);
              }}
            />
          ) : (
            <VisibilityIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setVisibility(!visibility);
              }}
            />
          ),
        }}
      />
      {props.userNameType === "Phone" && (
        <Tooltip title="Login with otp">
          <Otp
            onClick={() => props.setPasswordType("Password")}
            style={{
              height: "45px",
              width: "45px",
              marginTop: "20px",
              cursor: "pointer",
            }}
          />
        </Tooltip>
      )}
    </Box>
  );

  const otpFiled = () => (
    <Box
      component="form"
      noValidate
      sx={{
        display: "flex",
        gridTemplateColumns: { sm: "1fr 1fr" },
        gap: 2,
      }}
    >
      <Tooltip title="Login with Password">
        <Password
          onClick={() => props.setPasswordType("Password")}
          style={{
            height: "45px",
            width: "45px",
            marginTop: "20px",
            cursor: "pointer",
          }}
        />
      </Tooltip>
      <Box variant="standard" style={{ width: "100%" }} pb={1}>
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          style={{ marginLeft: "-55px" }}
        >
          {"One Time Password"}{" "}
          {props.required ? " " : <span style={{ color: "red" }}> * </span>}
        </InputLabel>
        <TextField
          label={""}
          size="small"
          required
          placeholder="Enter your One Time Password"
          id="bootstrap-input"
          fullWidth={true}
          {...props}
          onChange={(e) => props.onChange(e.target.value)}
          error={props.error}
          helperText={props.error}
          value={props.password}
          InputProps={{
            endAdornment: (
              <div
                onClick={() => props.handleSendOtp()}
                style={{
                  back: " #FFFFFF 0% 0% no-repeat padding-box",
                  border: "1px solid #1487CA",
                  borderRadius: " 5px",
                  width: "40%",
                  fontSize: "14px",
                  //padding: '2px',
                  textAlign: " center",
                  color: "#1487CA",
                  cursor: "pointer",
                }}
              >
                {/* {sendOtp && counter !== 0 && counter + ' s'}
                {sendOtp && counter === 0 && 'Resend OTP'}
                {!sendOtp && 'Get OTP'} */}
                {props.sendOtp
                  ? props.counter !== 0
                    ? props.counter + " s"
                    : "Resend OTP"
                  : "Get OTP"}
              </div>
            ),
          }}
        />
      </Box>
    </Box>
  );

  return props.passwordType === "Password" || props.userNameType === "Email"
    ? passwordFiled()
    : otpFiled();
}
