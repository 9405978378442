import React, { useState, useEffect, createContext, useRef } from "react";
import { Box, Card, Grid } from "@mui/material";
import { getDemographic, getStoreOutlet } from "@/services/DashboardService";
import SelectTimeRange from "./Component/SelectTimeRange";
import LineCharts from "./Component/LineChart";
import AgeLineCharts from "./Component/AgeLineCharts";
import { headerStyle } from "../css/peopleCounting.js";
import { useSnackbar } from "notistack";
import { checkUserRole } from "@/util/utils.js";
import FourSquares from "./Component/FourSquares.jsx";
import DataAnaysis from "./Component/DataAnaysis.jsx";
import { contentStyle, bottomStyle } from "../css/Demographic.js";
import { useTranslation } from "react-i18next";
import { useStateUserInfo } from "@/hooks/user";

import {
  getStoreData,
  handlerTimeData,
} from "../PeopleCountingSummary/Component/GetTime.js";
// 创建一个 Context
export const VisitorDemographicContext = createContext();
export default function DemographicFun() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [startTimes, setStartTimes] = useState(""); //第一个时间组件选中的开始时间
  const [endTimes, setEndTimes] = useState(""); //第一个时间组件选中的结束时间
  const [startValueTime, setStartValueTime] = useState(""); //第二个时间组件选中的开始时间
  const [endValueTime, setEndValueTime] = useState(""); //第二个时间组件选中的结束时间
  const [selectTime, setSelectTime] = useState(2); //选择的时间范围 类型 1 为7天 2 为5天
  const [timeType, setTimeType] = useState(2); //默认的时间类型 day / mouth
  const [storeName, setStoreName] = useState(""); //选中的门店名称
  const [selectedTime, setSelectedTime] = useState(1);
  const [isInitialized, setIsInitialized] = useState(false); //判断是否初始化加载接口
  const [totalNumber, setTotalNumber] = useState(0); // Total Captures

  /**
   * 四个方块绑定数据
   */
  const [highestDemographic, setHighestDemographic] = useState("");
  const [highestDemographicCount, setHighestDemographicCount] = useState(0);
  const [highestTime, setHighestTime] = useState("");
  const [highestTimeCount, setHighestTimeCount] = useState(0);
  const [leastDemographic, setLeastDemographic] = useState("");
  const [leastDemographicCount, setLeastDemographicCount] = useState(0);
  const [leastTime, setLeastTime] = useState("");
  const [leastTimeCount, setLeastTimeCount] = useState(0);
  const [storeList, setStoreList] = useState([]);
  //visitorDemographic 数据绑定
  const [visitorDemographic, setVisitorDemographic] = useState([]);

  // LineChart 组件数据Gender Distribution 模块
  const [genderData, setGenderData] = useState([]);
  // Age Distribution 模块数据
  const [ageData, setAgeData] = useState([]);
  const userInfo = useStateUserInfo();
  const [clientType, setClientType] = useState(
    JSON.parse(localStorage.getItem("USER_INFO"))?.roleCode
  );
  const [isShowAllStore, setIsShowAllStore] = useState(false); //判断是都为超级管理员
  const [clientId, setClientId] = useState(
    JSON.parse(localStorage.getItem("USER_INFO"))?.clientId
  );
  useEffect(() => {
    if (userInfo) {
      setClientType(userInfo.roleCode);
      setClientId(userInfo?.clientId);
    }
  }, [userInfo]);

  useEffect(() => {
    handlerTimeData(
      setStartTimes,
      setEndTimes,
      setEndValueTime,
      setStartValueTime
    );
    if (isInitialized === true) return;
    setIsInitialized(true);
  }, [isInitialized]);

  useEffect(() => {
    if (!clientType) {
      return;
    }

    if (
      clientType === "CLIENT_ADMIN" ||
      clientType === "CLIENT_USER" ||
      clientType === "SUPER_ADMIN" ||
      !clientId
    ) {
      setIsShowAllStore(true);
      setStoreName("All");
    } else {
      getStoreData(setStoreName, setStoreList);
    }
  }, [clientType]);

  useEffect(() => {
    if (
      startTimes !== "" &&
      endTimes !== "" &&
      startValueTime !== "" &&
      endValueTime !== "" &&
      isInitialized === true &&
      storeName !== ""
    ) {
      loadData();
    }
  }, [isInitialized, storeName]);

  /**
   * 保证切换 2 4 6区间范围时自动调接口
   */
  useEffect(() => {
    if (isInitialized === true) {
      loadData();
    }
  }, [selectedTime]);

  const loadData = () => {
    let params = {
      type: timeType,
      outletId: storeName === "All" ? "All" : storeName?.id,
      startDate: startTimes.replace(/\//g, "-"),
      endDate: endTimes.replace(/\//g, "-"),
      previousStartDate: startValueTime.replace(/\//g, "-"),
      previousEndDate: endValueTime.replace(/\//g, "-"),
      hourType: selectedTime,
    };
    getDemographic(params).then((res) => {
      let data = res?.data?.data;
      // console.log("GGGGGGGGGGGGGGGGG", data);
      setTotalNumber(data?.totalVisitors || 0);
      setHighestDemographic(data?.highestVisitorsByDemographic);

      setHighestDemographicCount(data?.highestVisitorsByDemographicCount);
      setHighestTime(data?.highestVisitorsByTime);
      setHighestTimeCount(data?.highestVisitorsByTimeCount);

      setLeastDemographic(data?.leastVisitorsByDemographic);
      setLeastDemographicCount(data?.leastVisitorsByDemographicCount);
      setLeastTime(data?.leastVisitorsByTime);
      setLeastTimeCount(data?.leastVisitorsByTimeCount);

      setVisitorDemographic(data?.visitorDemographic || []);
      setGenderData(data?.genderDistribution || []);
      // setAgeData(data?.ageDistribution.chartDatas || []);

      if (data?.ageDistribution) {
        setAgeData(data.ageDistribution.chartDatas || []);
      } else {
        setAgeData([]);
      }
    });
  };

  const box1Ref = useRef(null);
  const box2Ref = useRef(null);

  const handleScroll = (e) => {
    if (e.target === box1Ref.current) {
      // 如果是第一个盒子的滚动，应用到第二个盒子上
      box2Ref.current.scrollTop = e.target.scrollTop;
    } else if (e.target === box2Ref.current) {
      // 如果是第二个盒子的滚动，应用到第一个盒子上
      box1Ref.current.scrollTop = e.target.scrollTop;
    }
  };

  /**
   * @param {contextValue} SelectTimeRange  组件参数
   */
  const contextValue = {
    startTimes,
    setStartTimes,
    endTimes,
    setEndTimes,
    startValueTime,
    setStartValueTime,
    endValueTime,
    setEndValueTime,
    setSelectTime,
    selectTime,
    setTimeType,
    timeType,
    storeName,
    setStoreName,
    loadData,
    totalNumber,
    storeList,
    isShowAllStore,

    highestDemographic,
    highestDemographicCount,
    highestTime,
    highestTimeCount,
    leastDemographic,
    leastDemographicCount,
    leastTime,
    leastTimeCount,
    visitorDemographic,
    setSelectedTime,
    selectTime,
  };

  return (
    <VisitorDemographicContext.Provider value={contextValue}>
      <Grid
        sx={{ width: "100%", height: "100%", padding: "0 20px" }}
        ref={box1Ref}
        onScroll={handleScroll}
      >
        <Box fontSize={28} sx={{ whiteSpace: "nowrap" }}>
          {t("MENU16")}
        </Box>
        <Box
          sx={{
            fontSize: "24px",
            fontWeight: 700,
            color: "#000",
            whiteSpace: "nowrap",
          }}
        >
          {t("PCS02")}
        </Box>

        <SelectTimeRange></SelectTimeRange>

        <Grid sx={contentStyle} ref={box2Ref} onScroll={handleScroll} mt={1}>
          <Box
            ml={2}
            lineHeight={3}
            fontWeight={700}
            sx={{ fontSize: "0.5rem" }}
          >
            {t("PCS57")}
          </Box>

          <FourSquares></FourSquares>

          <DataAnaysis></DataAnaysis>
        </Grid>

        <Grid
          display={"flex"}
          sx={headerStyle}
          justifyContent={"space-between"}
        >
          <Grid
            m={1}
            sx={{
              background: "#FFF",
              borderRadius: "20px",
            }}
          >
            <Box fontWeight={700} fontSize={24} m={2}>
              {t("PCS55")}
            </Box>
            <LineCharts genderData={genderData}></LineCharts>
          </Grid>

          <Grid
            m={1}
            sx={{
              background: "#FFF",
              borderRadius: "20px",
            }}
          >
            <Box fontWeight={700} fontSize={24} m={2}>
              {t("PCS56")}
            </Box>
            <AgeLineCharts ageData={ageData}></AgeLineCharts>
          </Grid>
        </Grid>
      </Grid>
    </VisitorDemographicContext.Provider>
  );
}
