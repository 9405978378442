import api from "../configurations/http-common";
import CommonUtil from "../util/CommonUtils";

export const getCompanyDetails = async (companyId) => {
  return api
    .securedAxios()
    .get("web/company/" + companyId + "?toggleKeyToURL=" + true);
};

export const createClient = async (request) => {
  return api.securedAxios().post("/web/add_client", request);
};

export const sendActivationMail = async (request) => {
  return api.securedAxios().post("web/company/resend_activation_mail", request);
};

export const activateAccount = async (request) => {
  return api.securedAxios().put("web/company/activate_account", request);
};

export const updateClient = async (params) => {
  return api.securedAxios().put("/web/update_client", params);
};

export const loadAllCompanies = async () => {
  return listCompanies({ pageNumber: 1, pageSize: 1000 });
};

export const listCompanies = async (filter) => {
  const loginType = CommonUtil.getLoginType();
  let url = "web/companies/list?type=RETAIL_CLIENT";
  if (loginType !== "HQ") {
    url = url + "&cascadeLimit=0";
  } else {
    url = url + "&cascadeLimit=1";
  }
  if (filter?.name) {
    url = url + "&name=" + filter.name;
  }
  if (filter?.code) {
    url = url + "&code=" + filter.code;
  }
  if (filter?.country) {
    url = url + "&country=" + filter.country;
  }
  if (filter?.operator) {
    url = url + "&operator=" + filter.operator;
  }
  if (filter?.pageNumber) {
    url = url + "&pageNumber=" + filter.pageNumber;
  }
  if (filter?.pageSize) {
    url = url + "&pageSize=" + filter.pageSize;
  }
  return api.securedAxios().get(url);
};

export const listPrincipalCompanies = async (filter) => {
  const loginType = CommonUtil.getLoginType();

  let url = "api/principal/list?type=PARTNER&partnerType=PRINCIPAL";
  if (loginType !== "HQ") {
    url = url + "&cascadeLimit=0";
  } else {
    url = url + "&cascadeLimit=1";
  }

  if (filter?.name) {
    url = url + "&name=" + filter.name;
  }
  if (filter?.code) {
    url = url + "&code=" + filter.code;
  }
  if (filter?.country) {
    url = url + "&country=" + filter.country;
  }
  if (filter?.operator) {
    url = url + "&operator=" + filter.operator;
  }
  if (filter?.pageNumber) {
    url = url + "&pageNumber=" + filter.pageNumber;
  }
  if (filter?.pageSize) {
    url = url + "&pageSize=" + filter.pageSize;
  }
  return api.securedAxios().get(url);
};

export const listCompaniesPages = async (page, pageSize) => {
  return api
    .securedAxios()
    .get(
      "web/companies/list/page" +
        "?pageNumber=" +
        page +
        "&pageSize=" +
        pageSize
    );
};

export const getCompanyByIdOrCode = async (companyIdOrCode) => {
  return api.securedAxios().get("web/companies/" + companyIdOrCode);
};

export const getClinetsList = (params) => {
  params = CommonUtil.deleteEmptyKey(params);
  return api.securedAxios().get("/web/clients/list", {
    params,
  });
};

export const deleteClientById = (id) => {
  return api.securedAxios().delete(`/web/delete_outlet/${id}`);
};

/**
 * 查询价格 订阅接口
 */

export const getPriceList = () => {
  return api.securedAxios().get("/web/priceStrategy");
};

export const getCompany = async () => {
  return api.securedAxios().get("web/company");
};
