import React, { useEffect, useState, useMemo } from "react";
import DataTable from "../../components/DataTable.jsx";
import IconHandaler from "../../components/IconHandaler.jsx";
import DeleteIcon from "@mui/icons-material/Delete";
import RingLoader from "react-spinners/RingLoader";
import ConfirmModal from "../../components/ConfirmModel.jsx";
import { confirmAlert } from "react-confirm-alert";
import { ReactComponent as AddIcon } from "@/assets/images/icon_add.svg";
import { ReactComponent as RefreshIcon } from "@/assets/images/icon_refresh.svg";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ListLayout from "../../components/ListLayout.jsx";
import { useNavigate } from "react-router-dom";
import {
  REACT_PERSON_LIST,
  REACT_RETAIL_CLIENT_ADD,
  REACT_RETAIL_CLIENT_UPDATE,
  REACT_RETAIL_CLIENT_VIEW,
  RETAIL_CLIENT,
} from "@/router/ReactEndPoints";
import {
  sendActivationMail,
  getCompanyByIdOrCode,
  getClinetsList,
  deleteClientById,
} from "../../services/CompanyService.js";
import CommonUtil from "../../util/CommonUtils.js";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import AccessControl from "../../components/AccessControl.jsx";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { checkUserRole } from "@/util/utils.js";
export default function RetailClient() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const roleCode = CommonUtil.getRoleCode();
  const [storeName, setStoreName] = useState("");
  const [clientID, setClinetID] = useState("");
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    name: "",
    code: "",
    country: "",
    operator: "",
  });

  const override = {
    display: "block",
    margin: "10% auto",
    borderColor: "#b37feb",
  };

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 10,
    name: "",
    code: "",
    country: "",
    operator: "",
  };
  const refreshClick = () => {
    loadData();
  };

  //判断是否是超级管理员 如果是就隐藏新增零售商按钮
  const [hideButton, setHideButton] = useState(false);

  useEffect(() => {
    checkUserRole(setHideButton);
  }, []);

  useEffect(() => {
    loadData();
  }, [filters]);

  const loadData = () => {
    setIsLoading(true);
    try {
      getClinetsList({
        ...filters,
        type: "RETAIL_CLIENT",
      }).then((res) => {
        // console.log(".......................", res?.data?.data?.client);
        if (
          res?.data?.code === "LVLI0000" &&
          res?.data?.data &&
          res?.data?.data?.client
        ) {
          setRecords(res?.data?.data?.client);
          setTotalRecords(res?.data?.data?.totalCount);
          setIsLoading(false);
        }
      });
    } catch (error) {
      enqueueSnackbar("fail to load.", {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const delteItem = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t("LVLLOC0004")}
            onConfirm={() => {
              deleteClientById(id).then((response) => {
                if (response.data) {
                  enqueueSnackbar(response.data.message, {
                    variant: "success",
                  });
                  loadData();
                }
              });
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };
  const columns = useMemo(() => {
    const columns = [
      {
        field: "name",
        headerName: t("LVLRC0023"),
        flex: 1.5,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => (
          <Tooltip title={e.row.name} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.name)}</span>
          </Tooltip>
        ),
      },

      {
        field: "code",
        headerName: t("LVLRC0025"),
        flex: 1.5,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => (
          <Tooltip title={e.row.code} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.code)}</span>
          </Tooltip>
        ),
      },

      {
        field: "email",
        headerName: t("LVLRC0004"),
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => (
          <Tooltip title={e.row.email} arrow placement="bottom">
            <span> {CommonUtil.formatLongText(e.row.email)}</span>
          </Tooltip>
        ),
      },

      {
        field: "mobile",
        headerName: t("LVLRC0005"),
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => {
          const containsNumericDigits = /\d/.test(e.row.mobile);
          const phoneToDisplay = containsNumericDigits ? e.row.mobile : "-";
          return (
            <Tooltip title={phoneToDisplay} arrow placement="bottom">
              <span>{CommonUtil.formatLongText(phoneToDisplay)}</span>
            </Tooltip>
          );
        },
      },

      {
        field: "addressLine",
        headerName: t("LVLRC0006"),
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => (
          <Tooltip title={e.row.addressLine} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.addressLine)}</span>
          </Tooltip>
        ),
      },
      {
        field: "country",
        headerName: t("LVLRC0007"),
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => {
          return (
            <Tooltip title={e.row?.countryName || ""} arrow placement="bottom">
              <span>{CommonUtil.formatLongText(e.row?.countryName || "")}</span>
            </Tooltip>
          );
        },
      },

      {
        field: "state",
        headerName: t("LVLRC0008"),
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => (
          <Tooltip title={e.row?.stateName} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row?.stateName)}</span>
          </Tooltip>
        ),
      },

      {
        field: "city",
        headerName: t("LVLRC0009"),
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => (
          <Tooltip title={e.row?.cityName} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row?.cityName)}</span>
          </Tooltip>
        ),
      },

      {
        headerName: t("LVLRC0010"),
        sortable: false,
        headerAlign: "center",
        align: "center",
        flex: 1.5,
        renderCell: (e) => (
          <IconHandaler>
            <Tooltip title={t("LVLRC0028")} arrow>
              <VisibilityIcon
                onClick={() => handleActions("View", e)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "20px",
                  color: "#A2A3A3",
                }}
              />
            </Tooltip>

            <AccessControl authorization={"1006"}>
              <Tooltip title={t("LVLRC0029")} arrow sx={{ pl: 1 }}>
                <EditIcon
                  style={{
                    alignSelf: "center",
                    paddingTop: "0px",
                    paddingLeft: "5px",
                    opacity: "1",
                    fontSize: "20px",
                    color: "#A2A3A3",
                  }}
                  onClick={() => handleActions("Edit", e)}
                />
              </Tooltip>
            </AccessControl>
            {/* <AccessControl authorization={"1007"}>
              <Tooltip title={t("LVLRC0030")} sx={{ pl: 1 }}>
                <PeopleAltIcon
                  onClick={() =>
                    navigate(
                      RETAIL_CLIENT +
                        "retail" +
                        "/" +
                        e.row.id +
                        "/" +
                        e.row.code +
                        REACT_PERSON_LIST
                    )
                  }
                  style={{
                    alignSelf: "center",
                    paddingTop: "0px",
                    paddingLeft: "5px",
                    opacity: "1",
                    fontSize: "20px",
                    color: "#A2A3A3",
                  }}
                />
              </Tooltip>
            </AccessControl> */}

            {/* <Tooltip title="ResendEmail" arrow sx={{ pl: 1 }}>
              <ForwardToInboxIcon
                onClick={() => handleActions("ResendEmail", e)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "20px",
                  color: "#A2A3A3",
                }}
              />
            </Tooltip> */}

            {/* <Tooltip title="Delete" arrow sx={{ marginLeft: 1 }}>
              <DeleteIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  fontSize: "16px",
                  color: "#A2A3A3",
                }}
                onClick={() => handleActions("Delete", e)}
              />
            </Tooltip> */}
          </IconHandaler>
        ),
      },
    ];
    return columns;
  });

  const handleActions = (action, data) => {
    if (action === "View") {
      sessionStorage.setItem("currentClinet", JSON.stringify(data.row));
      navigate(REACT_RETAIL_CLIENT_VIEW + data.row.code);
    } else if (action === "Edit") {
      navigate(REACT_RETAIL_CLIENT_UPDATE + data.row.code, {
        state: data.row,
      });
    } else if (action === "Delete") {
      delteItem(data.row.id);
    } else if (action === "ResendEmail") {
      getCompanyByIdOrCode(data.row.code).then((response) => {
        if (response?.data?.code === "UASI0026" && response?.data?.data) {
          let request = {
            name: data.row.name,
            code: data.row.code,
            ownerEmail: response.data.data.ownerEmail,
            phone: data.row.phone,
            address: data.row.address,
            city: data.row.city,
            state: data.row.state,
          };

          sendActivationMail(request).then((res) => {
            if (res?.data?.code === "LVLI0000") {
              enqueueSnackbar("Activation mail sent successfully", {
                variant: "success",
              });
            }
          });
        }
      });
    }
  };

  const handlePageChange = (e) => {
    setFilters({ ...filters, pageNumber: e + 1 });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    try {
      getClinetsList({
        pageNumber: 1,
        pageSize: filters.pageSize,
        name: storeName,
        code: clientID,
        type: "RETAIL_CLIENT",
      }).then((res) => {
        if (
          res?.data?.code === "LVLI0000" &&
          res?.data?.data &&
          res?.data?.data?.client
        ) {
          setRecords(res?.data?.data?.client);
          setTotalRecords(res?.data?.data?.totalCount);
          setIsLoading(false);
        }
      });
    } catch (error) {
      enqueueSnackbar("fail to load." + error, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setClinetID("");
    setStoreName("");
    loadData();
  };

  return (
    <ListLayout navigateBack={false} title={t("MENU08")}>
      <Grid
        display={"flex"}
        sx={{
          background: "#FFF",
          height: "100px",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            margin: "30px 30px",
          }}
        >
          <TextField
            size="small"
            label={t("LVLRC0037")}
            placeholder={t("LVLRC0038")}
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
          ></TextField>
        </Box>
        <Box
          sx={{
            margin: "30px 30px",
          }}
        >
          <TextField
            size="small"
            label={t("LVLRC0025")}
            placeholder={t("LVLRC0026")}
            value={clientID}
            onChange={(e) => setClinetID(e.target.value)}
          ></TextField>
        </Box>
        <Box sx={{ margin: "35px 35px" }}>
          <Button
            variant="contained"
            sx={{ marginLeft: 1, p: 0.5 }}
            style={{
              height: "28px",
            }}
            onClick={handleSubmit}
          >
            {t("LVLGF0009")}
          </Button>

          <Button
            style={{
              height: "28px",
            }}
            sx={{ marginLeft: 1, p: 0.5 }}
            variant="outlined"
            onClick={handleReset}
          >
            {t("LVLGF0008")}
          </Button>
        </Box>
      </Grid>
      <Grid display={"flex"} justifyContent={"flex-end"} marginTop={2}>
        <Box item pr={2}>
          <RefreshIcon
            width={"35"}
            height={"35"}
            className="pointer"
            onClick={refreshClick}
          />
        </Box>
      </Grid>

      {isLoading ? (
        <RingLoader
          color={"#597ef7"}
          loading={isLoading}
          cssOverride={override}
          size={60}
          speedMultiplier={3}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <DataTable
          columns={columns}
          rows={records}
          onSelection={() => console.log()}
          page={filters.pageNumber - 1}
          totalRecords={totalRecords}
          rowsPerPage={filters.pageSize}
          onPageChange={(pn) => handlePageChange(pn)}
          onPageSizeChange={(ps) => handlePageSize(ps)}
        />
      )}
    </ListLayout>
  );
}
