export const parseNumber = (num) => {
  var newNum = "";
  var count = 0;
  var num = String(num); //数字转为字符串;
  // 当字符串不含有小数点
  if (num.indexOf(".") == -1) {
    for (var i = num.length - 1; i >= 0; i--) {
      if (count % 3 == 0 && count != 0) {
        newNum = num.charAt(i) + "," + newNum;
      } else {
        newNum = num.charAt(i) + newNum;
      }
      count++;
    }
    // num = newNum + ".00"; //自动补小数点后两位
    return newNum;
  } else {
    // 当字符串含有小数点
    for (var i = num.indexOf(".") - 1; i >= 0; i--) {
      if (count % 3 == 0 && count != 0) {
        newNum = num.charAt(i) + "," + newNum;
      } else {
        newNum = num.charAt(i) + newNum; //字符拼接
      }
      count++;
    }
    num = newNum + (num + "00").substr((num + "00").indexOf("."), 3);
    return num;
  }
};

export const formatNumber = (number) => {
  return new Intl.NumberFormat().format(number);
};
