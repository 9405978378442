import OnboardLayout from "@/layout/components/OnboardLayout";
import { REACT_FORGOT_PASSWORD } from "@/router/ReactEndPoints";
import { getResourceList } from "@/services/authoritation";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import JSEncrypt from "jsencrypt";
import jwt_decode from "jwt-decode";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PasswordField from "../../components/PasswordField";
import UserNameField from "../../components/UserNameField";
import AppContext from "../../context/AppContext";
import UserService from "../../services/UserService";
import CommonUtil from "../../util/CommonUtils";
import { getAgree, setAgree } from "./ageeKey";
import UserAgree from "./UserAgree";
import UserPrivateAgree from "./UserPrivateAgree";
export default function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [userOpen, setUserOpen] = React.useState(false);

  const [loginCheckcked, setLoginCheckcked] = React.useState(getAgree());
  const [state, setState] = useState({
    // userName: "hq@zkdigimaxdev.com",
    // password: "Zkdigimax@2023",
    // userName: "hq@zkdigimaxdev.com",
    // password: "Zkdigimax@2023",
    userName: "",
    password: "",
    // userName: "rahidoco.aremarov@labworld.org",
    // password: "123456",
    otp: "",
    phoneNumber: "",
    countryCode: "",
  });
  const [error, setError] = useState({
    userName: "",
    password: "",
    countryCode: "",
    phoneNumber: "",
  });

  const [userNameType, setUserNameType] = useState("Email");
  const [passwordType, setPasswordType] = useState("Password");
  const [countryCode, setCountryCode] = React.useState("");
  const [counter, setCounter] = React.useState(60);
  const [sendOtp, setsendOtp] = React.useState(false);
  const [privateOpen, setPrivateOpen] = React.useState(false);
  const { setTokenRefreshTimer } = useContext(AppContext);
  const [user, setUser] = useState({});
  // useEffect(() => {
  //   // CommonUtil.resetUserSession();
  //   setTimeout(() => {
  //     setState({
  //       ...state,
  //       // userName: "hq@zkdigimaxdev.com",
  //       // password: "Zkdigimax@2023",
  //       // userName: "rahidoco.aremarov@labworld.org",
  //       // password: "123456",
  //       userName: "",
  //       password: "",
  //     });
  //   }, 1000);
  // }, []);

  useEffect(() => {
    if (sendOtp) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter, sendOtp]);

  const handleChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });

    setError({
      ...error,
      [name]: "",
    });
    if (name === "phoneNumber") {
      setError({
        ...error,
        userName: "",
      });
    }
  };
  const handleSendOtp = (data) => {
    if (CommonUtil.isEmptyString(state.userName)) {
      setError({
        ...error,
        userName: t("LVL0001"),
      });
      return false;
    }
    if (CommonUtil.isEmptyString(countryCode) || countryCode === "-") {
      setError({
        ...error,
        userName: t("LVL0007"),
      });
      return false;
    }
    if (state.userName.length < 10 || state.userName.length > 25) {
      setError({
        ...error,
        userName: t("LVL0008"),
      });
      return false;
    }
    if (counter > 59 || counter <= 0) {
      UserService.sentOtp(state.userName).then((response) => {
        if (response?.data?.code === "LVLI0004") {
          setsendOtp(true);
          enqueueSnackbar(t(response.data.message), {
            variant: "success",
          });
          setCounter(60);
        } else {
          if (response?.data?.code === "UASE0077") {
            setError({
              ...error,
              password: response.data.message,
            });
          } else {
            setError({
              ...error,
              userName: response.data.message,
            });
          }
        }
      });
    }
  };

  const validatePayload = () => {
    if (userNameType === "Email") {
      if (CommonUtil.isEmptyString(state.userName)) {
        setError({
          ...error,
          userName: t("LVL0001"),
        });
        return false;
      }
      if (!CommonUtil.isValidEmail(state.userName)) {
        setError({
          ...error,
          userName: t("LVL0003"),
        });
        return false;
      }
    } else {
      if (CommonUtil.isEmptyString(state.userName)) {
        setError({
          ...error,
          userName: t("LVL0001"),
        });
        return false;
      } else {
        if (CommonUtil.isEmptyString(countryCode) || countryCode === "-") {
          setError({
            ...error,
            userName: t("LVL0007"),
          });
          return false;
        }
        if (state.userName.length < 8 || state.userName.length > 25) {
          setError({
            ...error,
            userName: t("LVL0008"),
          });
          return false;
        }
      }
    }

    if (CommonUtil.isEmptyString(state.password)) {
      setError({
        ...error,
        password: t("LVL0001"),
      });
      return false;
    }

    return true;
  };

  const handlePrivateClose = () => {
    setPrivateOpen(false);
  };

  const handleClose = () => {
    setUserOpen(false);
  };
  /**
   * 判断是否拥有一个权限
   * @param {} data
   */

  const findFirstFrontPath = (menu) => {
    if (menu?.frontPath) {
      return menu?.frontPath;
    }

    if (menu?.resourceList) {
      for (let child of menu?.resourceList) {
        const foundPath = findFirstFrontPath(child);
        if (foundPath) {
          return foundPath;
        }
      }
    }

    return null;
  };

  const loadCallBack = () => {
    getResourceList().then((res) => {
      if (res?.data?.code?.startsWith("LVLE")) {
        enqueueSnackbar(res?.data.message, {
          variant: "error",
        });
      }

      let menus = res?.data?.data;
      menus.length === 0 &&
        enqueueSnackbar(t("PCS124"), {
          variant: "error",
        });
      const firstPath = findFirstFrontPath(menus[0]);
      navigate(firstPath);
    });
  };

  const handleSubmit = async (data) => {
    if (loginCheckcked === true) {
      if (validatePayload()) {
        setError({
          userName: "",
          password: "",
          countryCode: "",
          phoneNumber: "",
        });

        let payload = "";
        if (countryCode === "" && state.userName.includes("@")) {
          payload = {
            userName: state.userName,
            password: state.password,
          };
        } else {
          const mob = "+" + countryCode.replace("+", "") + "-" + state.userName;
          payload = {
            userName: mob,
            password: state.password,
          };
        }

        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(process.env.RSA_PUBLIC_KEY);
        const encryptedPassword = encrypt.encrypt(payload.password);
        payload.password = encryptedPassword;

        if (passwordType !== "Otp") {
          await UserService.autorize(payload).then((response) => {
            if (response?.data?.code === "UASI0011") {
              if (response?.data?.data?.access_token !== null) {
                localStorage.removeItem("USER_INFO");
                localStorage.setItem(
                  "USER_TOKEN_RETAIL_AI",
                  "Bearer " + response?.data?.data?.access_token
                );
                localStorage.setItem(
                  "REFRESH_TOKEN_RETAIL_AI",
                  "Bearer " + response?.data?.data?.refresh_token
                );
                if (response?.data?.data?.tenantId) {
                  localStorage.setItem(
                    "TENANT_ID",
                    response?.data?.data?.tenantId
                  );
                }
                localStorage.setItem("ROLE_KEY", response?.data?.data?.roleKey);
                // CommonUtil.setCookie(
                //   "USER_TOKEN_RETAIL_AI",
                //   "Bearer " + response?.data?.data?.access_token,
                //   1
                // );
                // CommonUtil.setCookie(
                //   "REFRESH_TOKEN_RETAIL_AI",
                //   "Bearer " + response?.data?.data?.refresh_token,
                //   1
                // );

                const decoded = jwt_decode(
                  localStorage.getItem("USER_TOKEN_RETAIL_AI")
                );
                localStorage.setItem("USER", JSON.stringify(decoded));
                var currentSeconds = Math.floor(Date.now() / 1000);
                // console.warn("decoded.exp", decoded.exp);
                // console.warn("currentSeconds", currentSeconds);
                var secondsLeft = decoded.exp - currentSeconds;
                // console.warn("secondsLeft", secondsLeft);
                var timer = Math.floor(secondsLeft / 60) - 5;
                // console.warn("timer", timer);
                // console.warn("Next token generation after", timer + " mins");

                timer = timer * 60 * 1000;
                setTokenRefreshTimer(timer);
                // navigate(REACT_PEOPLECOUNTING_SUMMARY);
                loadCallBack();
              } else {
                enqueueSnackbar(response.data.message, { variant: "error" });
              }
            }
            if (response?.data?.code === "LVLE0001") {
              setError({
                ...error,
                password: t("LVL0010"),
              });
            } else {
              setError({
                userName: response?.data?.message,
              });
            }
          });
        }

        if (userNameType !== "Password" && passwordType === "Otp") {
          let payload = {
            phoneNumber: state.userName,
            otp: state.password,
          };
          await UserService.verifyOtp(payload).then((response) => {
            if (response?.data.code === "UASI0011") {
              if (response?.data?.data?.access_token !== null) {
                localStorage.setItem(
                  "USER_TOKEN_RETAIL_AI",
                  "Bearer " + response?.data?.data?.access_token
                );
                localStorage.setItem(
                  "REFRESH_TOKEN_RETAIL_AI",
                  "Bearer " + response?.data?.data?.refresh_token
                );

                // CommonUtil.setCookie(
                //   "USER_TOKEN_RETAIL_AI",
                //   "Bearer " + response?.data?.data?.access_token,
                //   1
                // );
                // CommonUtil.setCookie(
                //   "REFRESH_TOKEN_RETAIL_AI",
                //   "Bearer " + response?.data?.data?.refresh_token,
                //   1
                // );
              } else {
                enqueueSnackbar(response.data.message, { variant: "error" });
              }
            } else if (response?.data?.code === "UASE0077") {
              setError({
                ...error,
                userName: "",
                password: t("LVL0015"),
              });
            } else if (response?.data?.code === "UASE0078") {
              setError({
                ...error,
                userName: "",
                password: t("LVL0016"),
              });
            } else if (response?.data?.code === "LVLE0000") {
              enqueueSnackbar(response.data.message, { variant: "error" });
            } else {
              setError({
                ...error,
                userName: response.data.message,
              });
            }
          });
        }
      }
    } else {
      enqueueSnackbar(t("PCS112"), {
        variant: "warning",
      });
    }
  };
  const forgotPasswordSubmit = () => {
    navigate(REACT_FORGOT_PASSWORD);
  };

  return (
    <OnboardLayout>
      <Box
        sx={{
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignContent: "center",
          pb: 3,
        }}
      >
        {/* <Typography variant="title">Welcome</Typography> */}
      </Box>
      <Box
        sx={{
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignContent: "center",
          pb: 2,
        }}
      >
        <UserNameField
          required
          marginLeft="-54px"
          setUserNameType={(type) => setUserNameType(type)}
          error={error.userName}
          value={state.userName}
          onChange={(value) => handleChange(value, "userName")}
          helperText={error.userName}
          name="userName"
          countryCode={countryCode}
          onCountryCodeChange={(code) => {
            setCountryCode(code);
            setError({ ...error, userName: "" });
          }}
        />
      </Box>

      <Box
        sx={{
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignContent: "center",
          pb: 2,
        }}
      >
        <PasswordField
          userNameType={userNameType}
          setPasswordType={(type) => setPasswordType(type)}
          passwordType={passwordType}
          phoneNumber={state.userName}
          countryCode={countryCode}
          error={error.password}
          value={state.password}
          onChange={(value) => handleChange(value, "password")}
          helperText={error.password}
          handleSendOtp={() => handleSendOtp()}
          counter={counter}
          sendOtp={sendOtp}
        />
      </Box>
      {/* <Box
        display={"flex"}
        sx={{
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignContent: "center",
          pb: 2,
        }}
      >
        <Box
          item
          flexGrow={1}
          sx={{
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label="Auto login for 5 days"
            />
          </FormGroup>
        </Box>
        <Box
          item
          sx={{
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignContent: "center",
            pt: "12px",
          }}
          onClick={() => {
            navigate(REACT_FORGOT_PASSWORD);
          }}
        >
          <Typography variant="link" style={{ cursor: "pointer" }}>
            Forgot password?
          </Typography>
        </Box>
      </Box> */}
      <Box
        sx={{
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignContent: "center",
          pt: 1,
          pb: 0.5,
        }}
      >
        <Button
          variant="contained"
          fullWidth={true}
          onClick={() => {
            handleSubmit();
          }}
        >
          {t("LVLDB0030")}
        </Button>
      </Box>
      <Box
        sx={{
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignContent: "center",

          pt: 1,
          pb: 12,
        }}
      >
        <Button
          variant="outlined"
          fullWidth={true}
          onClick={() => {
            forgotPasswordSubmit();
          }}
          sx={{
            color: "black",
          }}
        >
          {t("LVLDAC0034")}?
        </Button>
        <Grid item xs={12} mt={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ color: "#1487CA" }}
                  checked={loginCheckcked}
                  onChange={(event) => {
                    // 设置到缓存中
                    setAgree(event.target.checked);
                    // 状态变更
                    setLoginCheckcked(event.target.checked);
                  }}
                  name="checked"
                  color="primary"
                  size="small"
                />
              }
              label={
                <Typography variant="h7" color="#000">
                  {t("PCS108")}
                  <Link
                    href="#"
                    whiteSpace={"nowrap"}
                    onClick={(e) => {
                      e.preventDefault();
                      setUserOpen(true);
                      // userAgreeRef.current.handleOpen();
                    }}
                  >
                    {t("PCS109")}
                  </Link>
                  {t("PCS113")}
                  <Link
                    href="#"
                    whiteSpace={"nowrap"}
                    onClick={(e) => {
                      e.preventDefault();
                      setPrivateOpen(true);
                      // userAgreeRef.current.handleOpen();
                    }}
                  >
                    {t("PCS110")}
                  </Link>
                </Typography>
              }
            />
          </Stack>
        </Grid>
        {/* <Box sx={{ mt: 2, textAlign: "center" }}>
          {t("PLACE013")}
          <Button
            onClick={() => {
              navigate(REACT_REGISTER);
            }}
            variant="text"
          >
            {t("LVLDAC0035")}
          </Button>
        </Box> */}
      </Box>

      <UserAgree open={userOpen} handleClose={handleClose} />
      <UserPrivateAgree open={privateOpen} handleClose={handlePrivateClose} />
    </OnboardLayout>
  );
}
