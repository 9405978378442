import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Button } from "@mui/material";
import CustomInput from "@/components/CustomInput";
import CommonUtil from "@/util/CommonUtils";
import { useSnackbar } from "notistack";
import { changePassword } from "../../../services/PersonService";
import { ReactComponent as VisibilityIcon } from "@/assets/images/icon_viewoff.svg";
import { ReactComponent as VisibilityOffIcon } from "@/assets/images/icon_viewon.svg";
import { useTranslation } from "react-i18next";
function ResetPassWord(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const [visibility, setVisibility] = useState(true); //是否显示输入的旧密码
  const [confirmVisibility, setConfirmVisibility] = useState(true); //是否显示输入的新密码

  const [payload, setPayload] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const [error, setError] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  const validateForm = () => {
    if (!CommonUtil.isEmptyString(payload.oldPassword)) {
      if (CommonUtil.isEmptyString(payload.oldPassword)) {
        setError({
          ...error,
          oldPassword: `${t("LVLRCP025")}`,
        });
        return;
      }
    }
    if (!CommonUtil.isEmptyString(payload.newPassword)) {
      if (CommonUtil.isEmptyString(payload.newPassword)) {
        setError({
          ...error,
          newPassword: `${t("LVLRCP026")}`,
        });
        return;
      }
    }

    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      changePassword(props.rowId, { ...payload }).then((res) => {
        if (res.data.code === "LVLE0000") {
          enqueueSnackbar(res.data.message, {
            variant: "error",
          });
          props.setPassWordOpen(false);
          setPayload({
            oldPassword: "",
            newPassword: "",
          });
          return;
        }

        if (res.data.code === "LVLI0000") {
          enqueueSnackbar(res.data.message, {
            variant: "success",
          });
          props.setPassWordOpen(false);
          setPayload({
            oldPassword: "",
            newPassword: "",
          });
        }
      });
    }
  };

  const cloneOpenDialog = () => {
    props.setPassWordOpen(false);
    setPayload({
      oldPassword: "",
      newPassword: "",
    });
  };
  return (
    <React.Fragment>
      <Dialog onClose={cloneOpenDialog} open={props.passWordOpen}>
        <DialogTitle
          sx={{ m: 0, p: 2, fontFamily: "Myriad Pro" }}
          fontSize={22}
          fontWeight={600}
        >
          {t("LVLDB0039")}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={cloneOpenDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ width: "400px" }}>
          <Grid item md={12} xs={12}>
            <CustomInput
              required
              label={t("LVLRCP027")}
              size="small"
              name="oldPassword"
              type={visibility ? "password" : "text"}
              value={payload.oldPassword}
              helperText={error.oldPassword}
              inputProps={{
                maxLength: 36,
              }}
              validation="oldPassword"
              handleChange={handleChange}
              autoComplete="off"
              InputProps={{
                endAdornment: visibility ? (
                  <VisibilityOffIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setVisibility(!visibility);
                    }}
                  />
                ) : (
                  <VisibilityIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setVisibility(!visibility);
                    }}
                  />
                ),
              }}
            />
          </Grid>

          <Grid item md={12} xs={12} marginTop={2}>
            <CustomInput
              required
              label={t("LVLRCP028")}
              size="small"
              name="newPassword"
              value={payload.newPassword}
              error={error.newPassword}
              helperText={error.newPassword}
              inputProps={{
                maxLength: 36,
              }}
              validation="newPassword"
              handleChange={handleChange}
              type={confirmVisibility ? "password" : "text"}
              autoComplete="off"
              InputProps={{
                endAdornment: confirmVisibility ? (
                  <VisibilityOffIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setConfirmVisibility(!confirmVisibility);
                    }}
                  />
                ) : (
                  <VisibilityIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setConfirmVisibility(!confirmVisibility);
                    }}
                  />
                ),
              }}
            />
          </Grid>

          <Button
            className="text-transform-none"
            variant="contained"
            onClick={handleSubmit}
            sx={{ width: "353px", marginTop: "15px" }}
          >
            {t("LVLDB0039")}
          </Button>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default ResetPassWord;
