import { Grid, Button } from "@mui/material";
import RenderingFromItem from "./RenderingFromItem";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const CmpFormik = (props) => {
  const {
    formik,
    formConfig,
    labelPostion = "top",
    itemSpacing = 2,
    sx = 12,
    children,
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Grid container sx={{ color: "green" }} spacing={itemSpacing}>
        <RenderingFromItem
          sx={sx}
          labelPostion={labelPostion}
          config={formConfig}
          formik={formik}
        ></RenderingFromItem>
        {children}
      </Grid>
    </form>
  );
};

export default CmpFormik;
