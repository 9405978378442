import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getStoreOutlet } from "@/services/DashboardService";
import { REACT_DEVICE_LIST } from "../../router/ReactEndPoints";
import AppContext from "../../context/AppContext";
import { addDevice } from "@/services/DeviceService";
import CommonUtil from "../../util/CommonUtils";
import { selectCityZone } from "../../services/Location";
import { getOutletTypeList } from "../../services/OutletTypeService";
import { getClinetsList } from "../../services/CompanyService";
import { selectCountry, selectState, selectCity } from "@/services/Location";
import AccessControl from "@/components/AccessControl";

const AddDevice = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { selectedClient, setSelectedClient } = useContext(AppContext);

  const [country, setCountry] = useState({});

  const [timezone, setTimezone] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});
  const [cityZone, setCityZone] = useState({});

  const [organizationType, setOrganizationType] = useState({});
  const [client, setClient] = useState({});
  //   const [outletType, setOutletType] = useState({});
  const [outlet, setOutlet] = useState({});
  const [countries, setCountries] = useState([]);
  const [cityZones, setCityZones] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [outletTypes, setOutletTypes] = useState([]);
  const [clients, setClients] = useState([]);
  const [channel, setChannel] = useState({});
  const [channelList, setChannelList] = useState([]);

  // const deviceType = [
  //   {
  //     value: 0,
  //     name: t("MATADATA03"),
  //   },
  //   {
  //     value: 1,
  //     name: t("MATADATA02"),
  //   },
  // ];
  // const [selectDeviceType, setSelectDeviceType] = useState(deviceType[0] || {});
  const [filters, setFilters] = useState({
    page: 0,
    rowsPerPage: 5,
    country: "",
    state: "",
    city: "",
    cityZone: "",
    outletType: "",
    mainOrSub: "",
    ids: [],
    companyIds:
      CommonUtil.isEmptyString(localStorage.getItem("selectedClientId")) ||
      localStorage.getItem("selectedClientId") === "-1"
        ? []
        : [localStorage.getItem("selectedClientId")],
  });

  const [error, setError] = React.useState({
    deviceId: "",
    sn: "",
    siteId: "",
    timeZone: "",
    companyId: "",
    country: "",
    mainOrSub: "",
  });

  useEffect(() => {
    selectCountry().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCountries(res?.data?.data);
      } else {
        setCountries([]);
      }
    });

    selectCityZone().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCityZones(res?.data?.data || []);
      }
    });

    loadOutlets();

    getOutletTypeList().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setOutletTypes(res?.data?.data?.outletTypes || []);
      }
    });
  }, []);
  const [shouldLoadData, setShouldLoadData] = useState(false);
  useEffect(() => {
    if (country && state && city) {
      setShouldLoadData(true);
    }
  }, [country, state, city]);

  useEffect(() => {
    if (shouldLoadData) {
      loadClientsData();
      setShouldLoadData(false);
    }
  }, [shouldLoadData]);
  const loadClientsData = () => {
    const params = {
      pageNumber: 0,
      pageSize: 0,
      type: "RETAIL_CLIENT",
      countryId: country?.id,
      stateId: state?.id,
      cityId: city?.id,
    };
    getClinetsList(params).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setClients(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    loadOutlets();
    setOutlet({});
  }, [client]);
  useEffect(() => {
    setState({});
    setCity({});
    if (!CommonUtil.isEmpty(country)) {
      // let ID = country.id;
      const { id } = country;
      selectState({
        countryId: id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setStateList(res?.data?.data);
        }
      });
    } else {
      setStateList([]);
    }
  }, [country]);

  useEffect(() => {
    setCity({});
    if (!CommonUtil.isEmpty(state)) {
      const { id } = state;
      selectCity({ stateId: id }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setCityList(res?.data?.data);
        }
      });
    } else {
      setCityList([]);
    }
  }, [state]);

  //获取门店数据
  const loadOutlets = () => {
    let UerInfo = JSON.parse(localStorage.getItem("USER"));
    let params = {
      userId: UerInfo.userId,
    };

    if (UerInfo.roleCode !== "super_admin") {
      if (client?.id === undefined || client?.id === null) {
        return;
      }
      params.clientId = client.id;
    }

    getStoreOutlet(params).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setOutlets(res.data.data);
      } else {
        setStoreList([]);
      }
    });
  };
  const handleSubmit = () => {
    const params = {
      deviceId: props.state?.deviceId,
      sn: props.state?.sn,
      clientId: client?.id || "text",
      outletId: outlet?.id || "text",
      siteId: outlet?.id || "text",
      countryId: country?.id,
      stateId: state?.id,
      cityId: city?.id,
      // channelId: "",
      cityZoneId: cityZone?.id,
      clientTypeId: "",
      areaId: "",
      clientTypeName: organizationType.name,
      outletTimeZoneId: "",
      protocolType: props.protocolType,
      deviceType: props.deviceType,
      mainOrSub: props.selectSence,
    };

    if (CommonUtil.isEmpty(country)) {
      setError({
        country: `${t("LVL0001")}`,
      });
      return;
    }

    if (CommonUtil.isEmptyString(params.clientId)) {
      setError({
        clientId: `${t("LVL0001")}`,
      });
      return;
    }

    if (CommonUtil.isEmptyString(params.outletId)) {
      setError({
        outletId: `${t("LVL0001")}`,
      });
      return;
    }

    addDevice(params).then((res) => {
      if (res != null && res?.data?.code === "LVLI0009") {
        enqueueSnackbar(`${t("LVLDV0046")}`, {
          variant: "success",
        });
        navigate(REACT_DEVICE_LIST);
      } else {
        enqueueSnackbar(res?.data?.message, {
          variant: "error",
        });
      }
    });
  };

  const organizationTypeOpt = [
    {
      value: "0",
      name: "Retail Client",
    },
    {
      value: "1",
      name: "Principle",
    },
  ];

  return (
    <>
      <Grid
        container
        my={1}
        sx={{
          width: "100%",
        }}
        p={2}
        component={Paper}
        elevation={0}
        alignItems={"center"}
      >
        <Grid container width={"100%"}>
          <Grid item lg={6} sm={6} xs={12} py={1} px={0.5}>
            <Typography sx={{ fontSize: 13 }}>
              {t("LVLGF0012")}
              <span className="required"> *</span>
            </Typography>
            <Autocomplete
              options={countries}
              value={country}
              onChange={(e, v) => {
                setCountry(v);
                setError({ ...error, country: "" });
                // if (!CommonUtil.isEmpty(v)) {
                //   setFilters({ ...filters, country: v?.id });
                // } else {
                //   setOutlet(null);
                //   setOutlets([]);
                // }
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  error={error?.country}
                  helperText={error?.country}
                />
              )}
            ></Autocomplete>
          </Grid>

          <Grid item lg={6} sm={6} xs={12} py={1} px={0.5}>
            <Typography sx={{ fontSize: 13 }}>{t("LVLGF0002")}</Typography>
            <Autocomplete
              options={stateList}
              value={state}
              onChange={(e, v) => {
                setState(v);
                if (!CommonUtil.isEmpty(v)) {
                  setFilters({ ...filters, state: v?.id });
                } else {
                  setFilters({ ...filters, state: "" });
                }
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={6} xs={12} py={1} px={0.5}>
          <Typography sx={{ fontSize: 13 }}>{t("LVLGF0003")}</Typography>
          <Autocomplete
            options={cityList}
            value={city}
            onChange={(e, v) => {
              setCity(v);
              if (!CommonUtil.isEmpty(v)) {
                setFilters({ ...filters, city: v?.id });
              } else {
                setFilters({ ...filters, city: "" });
              }
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => <TextField {...params} size="small" />}
          ></Autocomplete>
        </Grid>

        <Grid item lg={6} sm={6} xs={12} py={1} px={0.5}>
          <Typography sx={{ fontSize: 13 }}>{t("LVLGF0004")}</Typography>
          <Autocomplete
            options={cityZones}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            value={cityZone}
            onChange={(e, v) => {
              setCityZone(v);
              if (!CommonUtil.isEmpty(v)) {
                setFilters({ ...filters, cityZone: v?.id });
              } else {
                setFilters({ ...filters, cityZone: "" });
              }
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          ></Autocomplete>
        </Grid>
        <Grid item lg={6} sm={6} xs={12} py={1} px={0.5}>
          <Typography sx={{ fontSize: 13 }}>{t("RTMT0014")}</Typography>
          <Autocomplete
            options={organizationTypeOpt}
            value={organizationType}
            onChange={(e, v) => {
              setOrganizationType(v);
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => <TextField {...params} size="small" />}
          ></Autocomplete>
        </Grid>

        <Grid item lg={6} sm={6} xs={12} py={1} px={0.5}>
          <Typography sx={{ fontSize: 13 }}>
            {t("LVLDV0043")}
            <span className="required"> *</span>
          </Typography>
          <Autocomplete
            options={clients}
            value={client}
            onChange={(e, v) => {
              setClient(v);
              setError({ ...error, companyId: "" });
              if (!CommonUtil.isEmpty(v)) {
                setFilters({ ...filters, companyIds: [v?.id] });
              } else {
                setFilters({ ...filters, companyIds: [] });
              }
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error?.companyId}
                helperText={error?.companyId}
              />
            )}
          ></Autocomplete>
        </Grid>

        <Grid item lg={6} sm={6} xs={12} py={1} px={0.5}>
          <Typography sx={{ fontSize: 13 }}>
            {t("LVLGF0013")}
            <span className="required"> *</span>
          </Typography>
          <Autocomplete
            options={outlets}
            value={outlet}
            onChange={(e, v) => {
              setOutlet(v);
              setError();
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error?.siteId}
                helperText={error?.siteId}
              />
            )}
          ></Autocomplete>
        </Grid>
        {/* <Grid item lg={6} sm={6} xs={12} py={1} px={0.5}>
          <Typography sx={{ fontSize: 13 }}>{t("LVLDV0044")}</Typography>
          <Autocomplete
            // disabled
            options={timeZoneList}
            value={timezone}
            onChange={(e, v) => {
              setTimezone(v);
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => <TextField {...params} size="small" />}
          ></Autocomplete>
        </Grid> */}

        <Box
          display={"flex"}
          width={"100%"}
          flexDirection={"row-reverse"}
          py={1}
          px={0.5}
        >
          <Box item>
            <AccessControl authorization={"1018"}></AccessControl>
            <Button
              variant="contained"
              sx={{ marginLeft: 1 }}
              onClick={handleSubmit}
            >
              {t("LVL0013")}
            </Button>
          </Box>
          <Box item px={1}>
            <Button variant="outlined" onClick={props.onClose}>
              {t("LVLDAC0006")}
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

AddDevice.defaultProps = {
  onClientChange: () => {},
};

export default AddDevice;

{
  /* <Grid item lg={6} sm={6} xs={12} py={1} px={0.5}>
            <Typography sx={{ fontSize: 13 }}>
              {t("Room/Area")}
              <span className="required"> *</span>
            </Typography>
            <Autocomplete
              options={outlets}
              value={outlet}
              onChange={(e, v) => {
                setOutlet(v);
                setError();
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  error={error?.siteId}
                  helperText={error?.siteId}
                />
              )}
            ></Autocomplete>
          </Grid> */
}

{
  /* <Grid item lg={6} sm={6} xs={12} py={1} px={0.5}>
          <Typography sx={{ fontSize: 13 }}>{t("Channel")}</Typography>
          <Autocomplete
            options={channelList}
            value={channel}
            onChange={(e, v) => {}}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => <TextField {...params} size="small" />}
          ></Autocomplete>
        </Grid> */
}

{
  /* <Grid item lg={6} sm={6} xs={12} py={1} px={0.5}>
          <Typography sx={{ fontSize: 13 }}>{t("RTMT0013")}</Typography>
          <Autocomplete
            options={deviceType}
            value={selectDeviceType}
            onChange={(e, v) => {
              setSelectDeviceType(v);
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => <TextField {...params} size="small" />}
          ></Autocomplete>
        </Grid> */
}
