import React, { useState, useEffect } from "react";
import { Box, Grid, Paper, Typography, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useTranslation } from "react-i18next";
import AllStore from "./AllStore";
import SelectOutlet from "../../Component/SelectOutlet";
import DialogTime from "./DialogTime";
import { parseNumber } from "@/util/parseNumber";
function SelectTimeRange(props) {
  const {
    storeName,
    setStoreName,
    selectTime,
    setSelectTime,
    handlerApply,
    totalCaptures,
    storeList,
  } = props;
  const { t } = useTranslation();
  const [storeOpen, setStoreOpen] = useState(false); //所有门店下拉弹窗

  const [openTime, setOpenTime] = useState(false); // 打开事件选择弹窗

  const time = new Date();
  const year = time.getFullYear(); // 获取年份
  const month = time.getMonth() + 1; // 获取月份（注意月份是从 0 开始计数的，所以需要加1）
  let day = time.getDate() - 1; // 获取前一天的日期
  let currentDate;
  if (day === 0) {
    // 获取上个月的年份和月份
    const lastMonthYear = month === 1 ? year - 1 : year;
    const lastMonth = month === 1 ? 12 : month - 1;
    // 获取上个月的最后一天
    day = new Date(lastMonthYear, lastMonth, 0).getDate();
    currentDate = `${year}-${lastMonth < 10 ? "0" + lastMonth : lastMonth}-${
      day < 10 ? "0" + day : day
    }`;
  } else {
    currentDate = `${year}/${month < 10 ? "0" + month : month}/${
      day < 10 ? "0" + day : day
    }`;
  }

  useEffect(() => {
    setSelectTime(currentDate);
  }, []);

  const handlerApplyData = () => {
    handlerApply();
  };

  const totalPhoto = parseNumber(totalCaptures);

  return (
    <>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ fontSize: "1.2rem" }}
      >
        <Grid display={"flex"}>
          {/* 时间选择组件 */}
          <Box
            sx={{
              background: "#fff",
              borderRadius: "10px",
              height: "1.8rem",
              padding: "0.2rem 0.2rem",
            }}
            borderRadius={2}
            onClick={() => setOpenTime(true)}
          >
            <Box
              sx={{
                fontSize: "0.4rem",
              }}
            >
              {t("PCS37")}
            </Box>
            <Grid
              display={"flex"}
              fontWeight={700}
              whiteSpace={"nowrap"}
              sx={{
                fontSize: "0.4rem",
              }}
              xs={4}
            >
              <Box> {selectTime || currentDate} </Box>
              <Box ml={2}>
                {openTime ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </Box>
            </Grid>
          </Box>

          {/*Store 门店选择组件  */}
          <Box
            sx={{
              background: "#fff",
              borderRadius: "10px",
              height: "1.8rem",
              padding: "0.2rem 0.2rem",
            }}
            borderRadius={2}
            ml={2}
          >
            <Box
              sx={{
                fontSize: "0.4rem",
              }}
            >
              {t("PCS08")}
            </Box>

            <Grid
              sx={{
                fontSize: "0.4rem",
                display: "flex",
                whiteSpace: "nowrap",
                fontWeight: 700,
              }}
              xs={4}
              onClick={() => setStoreOpen(true)}
            >
              <Box>{storeName.name}</Box>
              <Box ml={2}>
                {storeOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </Box>
            </Grid>
          </Box>

          <Button
            variant="contained"
            xs={3}
            sx={{
              borderRadius: "1rem",
              height: "1.4rem",
              width: "3.2rem",
              margin: "auto 1vw",
              fontSize: "0.35rem",
            }}
            onClick={handlerApplyData}
          >
            {t("PCS38")}
          </Button>
        </Grid>

        <Grid display={"flex"}>
          <Box
            ml={2}
            mr={2}
            fontWeight={700}
            sx={{ fontSize: "0.4rem" }}
            mt={1}
          >
            <span dangerouslySetInnerHTML={{ __html: t("PCS39") }} />
          </Box>
          <Box
            sx={{
              background: "#fff",
              padding: "0vh 1vw",
              color: "#6bb47e",
              borderRadius: "15px",
              textAlign: "center",
              fontWeight: 700,
            }}
          >
            <span
              style={{
                background: `linear-gradient(to right, #71b652, #3599d7)`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "1.2rem",
              }}
            >
              {totalPhoto}
            </span>
          </Box>
        </Grid>
      </Grid>
      {/* 门店弹窗组件 */}
      {/* <AllStore
        storeOpen={storeOpen}
        setStoreOpen={setStoreOpen}
        setStoreName={setStoreName}
        storeName={storeName}
        storeList={storeList}
      ></AllStore> */}

      <SelectOutlet
        storeOpen={storeOpen}
        setStoreOpen={setStoreOpen}
        setStoreName={setStoreName}
        storeName={storeName}
        storeList={storeList}
      ></SelectOutlet>

      {/*时间选择弹窗组件  */}
      <DialogTime
        openTime={openTime}
        setOpenTime={setOpenTime}
        setSelectTime={setSelectTime}
      ></DialogTime>
    </>
  );
}

export default SelectTimeRange;

{
  /* COMPARE  WITH组件 */
}
{
  /* <Box
        sx={{ background: "#fff", width: "13vw", height: "10vh" }}
        m={1}
        borderRadius={2}
      >
        <Box fontSize={22} m={1} marginLeft={2}>
          COMPARE WITH
        </Box>

        <Grid display={"flex"}>
          <Box fontWeight={700} fontSize={24} marginLeft={2}>
            NONE
          </Box>
          <Box sx={{ margin: "0.5vh 0 0 1.5vw" }}>
            {open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            <ExpandMoreIcon />
          </Box>
        </Grid>
      </Box> */
}
