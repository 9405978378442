import api from "../configurations/http-common";

export const getAllCities = async () => {
    return api
      .securedAxios()
      .get("web/cities");
};

export const filterCity = async (pageNumber, pageSize, stateId) => {
    var params = "?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    if(stateId) params = params + "&stateId=" + stateId;
    return api
    .securedAxios()
    .get("web/cities/list" + params);
}

export const getCityById = async (id) => {
    return api
    .securedAxios()
    .get("web/city" + "?id=" + id);
}

export const createCity = async (payload) => {
    return api
    .securedAxios()
    .post("web/city", payload);
}

export const editCity = async (payload) => {
    return api
    .securedAxios()
    .put("web/city", payload);
}

export const deleteCity = async (id) => {
    return api
    .securedAxios()
    .delete("web/city" + "?id=" + id);
}



export const getCityListByState = async (params) => {
    return api
    .securedAxios()
    .get("web/cities/list",{
        params
    });
}
