import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

export default function HoursSelect(props) {
  const [hoursOptions, setHoursOptions] = useState([]);
  const [hourObj, setHourObj] = useState([]);

  useEffect(() => {
    setHoursOptions([
      { name: "1H", value: 1 },
      { name: "2H", value: 1 },
      { name: "24H", value: 24 },
    ]);
  }, [props.deviceId]);

  useEffect(() => {
    let slect = hoursOptions.filter((item) => {
      if (item.value === props.hour) {
        return true;
      } else {
        return false;
      }
    });
    if (slect && slect.length > 0) {
      setHourObj(slect[0]);
    }
  }, [props.hour, hoursOptions]);

  const changeHour = (v) => {
    if (props.change) {
      props.change(v);
    }
  };
  return (
    <Autocomplete
      options={hoursOptions}
      value={hourObj}
      fullWidth={true}
      onChange={(e, v) => {
        let value = v ? v.value : "";
        changeHour(value);
      }}
      getOptionLabel={(option) => (option.name ? option.name : "")}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          error={""}
          helperText={""}
          style={{ backgroundColor: "#FFFFFF" }}
        />
      )}
    ></Autocomplete>
  );
}
