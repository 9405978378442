import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/KeyboardArrowUp";

export default function SidebarMenuItem(props) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(props.link);
  };

  return (
    <Box
      sx={{
        mb: 1,
        height: { md: "auto" },
        py: { md: 0 },
        px: { md: 0 },
      }}
      onClick={() => handleClick()}
    >
      {props.isActive && (
        <Box
          component={Paper}
          variant="outlined"
          elevation={0}
          display={"flex"}
          alignItems={"center"}
          borderRadius={"2"}
          style={{
            cursor: "pointer",
            border: "none",
            backgroundImage: "linear-gradient(to right, #74b74b, #2e96e6)",
          }}
        >
          <Box px={2} py={1}>
            <props.activeMenu />
          </Box>
          <Box>
            <Typography
              variant="menuItem"
              color={"#FFFFFF"}
              sx={{ display: { md: "inline" } }}
            >
              {props.label}
            </Typography>
          </Box>
        </Box>
      )}
      {!props.isActive && (
        <Box
          display={"flex"}
          alignItems={"center"}
          style={{ cursor: "pointer" }}
        >
          <Box px={2} py={1}>
            <props.inActiveMenu />
          </Box>
          <Box>
            <Typography variant="menuItem" sx={{ display: { md: "inline" } }}>
              {props.label}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
