// third-party
import { combineReducers } from "redux";

import user from "./user";
// import map from './map';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ user });

export default reducers;
