import CommonUtil from "@/util/CommonUtils";
import { selectCountry, selectState, selectCity } from "@/services/Location";
import { getZoonTime, getUnit, uploadData } from "@/services/subscription";
import dayjs from "dayjs";
//加载国家接口数据
export const loadCountryData = (setCountries) => {
  selectCountry().then((res) => {
    if (res?.data?.code === "LVLI0000") {
      setCountries(res?.data?.data);
    }
  });
};

//加载省份接口数据
export const loadStateData = (addFormik, setStateList) => {
  addFormik.setFieldValue("stateId", "");
  addFormik.setFieldValue("cityId", "");
  if (!CommonUtil.isEmpty(addFormik?.values["countryId"])) {
    selectState({
      countryId: addFormik?.values["countryId"],
    }).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setStateList(res?.data?.data);
      }
    });
  }
};

//加载城市接口数据
export const loadCityData = (addFormik, setCityList) => {
  addFormik.setFieldValue("cityId", "");
  if (!CommonUtil.isEmpty(addFormik?.values["stateId"])) {
    selectCity({ stateId: addFormik?.values["stateId"] }).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCityList(res?.data?.data);
      }
    });
  }
};

//加载时区接口数据
export const loadZoonTime = (setTimeZoon) => {
  getZoonTime().then((res) => {
    setTimeZoon(res?.data?.data);
  });
};

//加载货币单位接口数据
export const loadUnitData = (setUnitData) => {
  getUnit().then((res) => {
    setUnitData(res?.data?.data);
  });
};
//上传合同或者logo
export const handleComtractUpload = (
  data,
  type,
  addFormik,
  enqueueSnackbar
) => {
  let file = data[0];
  let fileSize = file.size;
  if (fileSize > 1024 * 1024 * 30) {
    toast.error(
      t("subscription.limtFileSize", {
        tip: "30M",
      })
    );
    return false;
  }

  var bodyFormData = new FormData();
  bodyFormData.append("type", type);
  bodyFormData.append("file", data[0]);
  uploadData(bodyFormData).then((res) => {
    if (res.data?.code === "LVLI0000") {
      enqueueSnackbar("上传成功", {
        variant: "success",
      });
      let url = res.data.data;
      if (type === 0) {
        addFormik.setFieldValue("merchantUrl", url);
      } else {
        addFormik.setFieldValue("attachmentUrl", url);
      }
    }
  });
};

// 时间计算公共方法
export const computerTime = (num, type, startTime) => {
  return dayjs(startTime)
    .add(type === "0" ? 30 * num : 365 * num, "day")
    .format("YYYY-MM-DD");
};
