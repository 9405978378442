export const tablistStyle = {
  borderRadius: "10px",
  background: "#FFF",
  marginTop: "2vh",
  scrollbarWidth: "thin",
  padding: "0.2rem 0.5rem",
  scrollbarColor: "#d1e7c3 transparent",
};

tablistStyle["&::-webkit-scrollbar"] = {
  width: "2px",
};

tablistStyle["&::-webkit-scrollbar-thumb"] = {
  background: "#d1e7c3",
  borderRadius: "2px",
};

tablistStyle["&::-webkit-scrollbar-track"] = {
  background: "transparent",
};

export const pieStyle = {
  overflow: "hidden",
  // overflowX: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "#d1e7c3 transparent",
};

pieStyle["&::-webkit-scrollbar"] = {
  width: "2px",
};

pieStyle["&::-webkit-scrollbar-thumb"] = {
  background: "#d1e7c3",
  borderRadius: "2px",
};

pieStyle["&::-webkit-scrollbar-track"] = {
  background: "transparent",
};
