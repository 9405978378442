import CommonUtil from "@/util/CommonUtils";
import { Tooltip, IconButton } from "@mui/material";
import { getDataList, handlerDeleteData } from "@/services/openAPI";
import { confirmAlert } from "react-confirm-alert";
import { REACT_OPEN_API_ADD } from "@/router/ReactEndPoints";
import { ReactComponent as VisibilityOffIcon } from "@/assets/images/icon_viewon.svg";
import { ReactComponent as VisibilityIcon } from "@/assets/images/icon_viewoff.svg";
import IconHandaler from "@/components/IconHandaler";
import ConfirmModal from "@/components/ConfirmModel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AccessControl from "@/components/AccessControl";

export const getColumns = (
  columsWidth,
  t,
  showSecretKey,
  setSecretKey,
  setColumWidth,
  enqueueSnackbar,
  navigate,
  textRef,
  handleCopyClick
) => {
  return [
    {
      field: "name",
      headerName: t("LVLDB0019"),
      headerAlign: "center",
      align: "center",
      sortable: false,
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.name)}</span>
        </Tooltip>
      ),
    },
    {
      field: "code",
      headerName: t("LVLDAC0038"),
      headerAlign: "center",
      align: "center",
      maxWidth: 1500,
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.companyName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.companyName)}</span>
        </Tooltip>
      ),
    },

    {
      field: "SecretKey",
      headerName: t("LVLDAC0036"),
      headerAlign: "center",
      align: "center",
      flex: columsWidth ? 3 : 1,
      sortable: false,
      renderCell: (e) => {
        return (
          <Tooltip
            title={showSecretKey[e.row.id] ? t("PCS76") : t("PCS77")}
            arrow
            placement="bottom"
          >
            <span>
              {showSecretKey[e.row.id] ? (
                <span
                  ref={textRef}
                  onClick={handleCopyClick}
                  style={{ cursor: "pointer" }}
                >
                  {e.row.appSecret}
                </span>
              ) : (
                <span>********</span>
              )}
              <IconButton
                onClick={() =>
                  handleTogglePassword(e.row.id, setSecretKey, setColumWidth)
                }
                size="small"
              >
                {showSecretKey[e.row.id] ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },

    {
      field: "appid",
      headerName: t("LVLDAC0037"),
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.appId} arrow placement="bottom">
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {CommonUtil.formatLongText(e.row.appId)}
          </span>
        </Tooltip>
      ),
    },

    {
      headerName: t("LVLOT0010"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (e) => (
        <IconHandaler>
          {/* <Tooltip title={t("LVLDB0020")} arrow>
            <VisibilityIcon
              onClick={() =>
                handleActions("View", e, enqueueSnackbar, t, navigate)
              }
              style={{
                alignSelf: "center",
                paddingTop: "0px",
                fontSize: "16px",
                color: "#A2A3A3",
              }}
            />
          </Tooltip> */}

          <AccessControl authorization={"1056"}>
            <Tooltip title={t("LVLDB0021")} arrow sx={{ marginLeft: 1 }}>
              <EditIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "16px",
                  color: "#A2A3A3",
                }}
                onClick={() =>
                  handleActions("Edit", e, enqueueSnackbar, t, navigate)
                }
              />
            </Tooltip>
          </AccessControl>
          <AccessControl authorization={"1057"}>
            <Tooltip title={t("LVLDB0022")} arrow sx={{ marginLeft: 1 }}>
              <DeleteIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  fontSize: "16px",
                  color: "#A2A3A3",
                }}
                onClick={() =>
                  handleActions("Delete", e, enqueueSnackbar, t, navigate)
                }
              />
            </Tooltip>
          </AccessControl>
        </IconHandaler>
      ),
    },
  ];
};

//判断点击的是新增还是删除
export const handleActions = (action, data, enqueueSnackbar, t, navigate) => {
  if (action === "View") {
    sessionStorage.setItem("editOpenAPI", JSON.stringify(data.row));
    navigate(REACT_OUTLET_VIEW + data.id);
  } else if (action === "Edit") {
    sessionStorage.setItem("editOpenAPI", JSON.stringify(data.row));
    navigate(REACT_OPEN_API_ADD, {
      state: data.id,
    });
  } else if (action === "Delete") {
    delteItem(data.id, enqueueSnackbar, t);
  }
};
/**
 * 加载 表格数据方法
 */
export const loadData = (
  filters,
  setIsLoading,
  setRecords,
  setTotalRecords
) => {
  let params = {
    ...filters,
  };
  setIsLoading(true);
  getDataList(params).then((res) => {
    if (res.data.code === "LVLI0000") {
      setRecords(res?.data?.data?.objects);
      setTotalRecords(res?.data?.data?.totalCount);
      setIsLoading(false);
    }
  });
};

export const delteItem = (id, enqueueSnackbar, t) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <ConfirmModal
          open={true}
          text={t("LVLLOC0004")}
          //   text={"Are you sure want to delete selected Record?"}
          onConfirm={() => {
            handlerDeleteData(id).then((response) => {
              if (response.data.code === "LVLI0002") {
                enqueueSnackbar(response.data.message, {
                  variant: "success",
                });
                window.location.reload();
              }
            });
            onClose();
          }}
          onClose={() => onClose()}
        />
      );
    },
  });
};

export const handleTogglePassword = (rowId, setSecretKey, setColumWidth) => {
  setSecretKey((prevState) => ({
    ...prevState,
    [rowId]: !prevState[rowId],
  }));
  setColumWidth((prevState) => ({
    ...prevState,
    [rowId]: !prevState[rowId],
  }));
};

/**
 * 顶部根据名称查询方法
 */
export const handleSubmit = (
  setIsLoading,
  storeName,
  setRecords,
  setTotalRecords,
  filters
) => {
  setIsLoading(true);
  getDataList({
    pageNumber: 1,
    pageSize: filters.pageSize,
    name: storeName,
  }).then((response) => {
    if (response?.data?.code === "LVLI0000") {
      setRecords(response?.data?.data?.objects);
      setTotalRecords(response?.data?.data?.totalCount);
      setIsLoading(false);
    } else {
      setRecords([]);
      setTotalRecords(0);
      setIsLoading(false);
    }
  });
};

export const handlePageChange = (e, setIsLoading, setFilters, filters) => {
  setIsLoading(true);
  setFilters({
    ...filters,
    pageNumber: e + 1,
  });
  setIsLoading(false);
};

export const handlePageSize = (e, setIsLoading, setFilters, filters) => {
  setIsLoading(true);
  setFilters({
    ...filters,
    pageNumber: filters.pageNumber,
    pageSize: e,
  });
  setIsLoading(false);
};
