import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import DeviceIcon from "@/assets/images/icon_device.png";
import CustomInput from "../../../components/CustomInput";
import SimpleDailogBox from "../../../components/SimpleDailogBox";
import TitleBar from "../../../components/TitleBar";
import { REACT_DEVICE_LIST } from "../../../router/ReactEndPoints";
import { listCompanies } from "../../../services/CompanyService";
import { bindDevice, findDeviceBySn } from "@/services/DeviceService";
import { filterOutlet } from "../../../services/OutletService";
import CommonUtil from "../../../util/CommonUtils";
import { timeZoneList } from "../../../util/TimeZone";
import AddDevice from "../AddDevice";
import { useTranslation } from "react-i18next";

const BindDevice = () => {
  const [open, setOpen] = useState(false);
  const [site, setSite] = useState([]);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});
  const [zone, setZone] = useState([]);
  const [selectedSite, setSelectedSite] = useState({});
  const [timeZone, setTimeZone] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [protocolType, setProtocolType] = useState("");
  const [deviceType, setDeviceType] = useState("acc");
  const { t } = useTranslation();
  const [selectSence, setSelectSence] = useState(0); //选择设备场景 店内 或者 店外
  const [state, setState] = React.useState({
    deviceId: "",
    sn: "",
    siteId: "",
    companyId: "",
    timeZone: "",
  });
  const [error, setError] = React.useState({
    deviceId: "",
    sn: "",
    siteId: "",
    timeZone: "",
    companyId: "",
  });

  useEffect(() => {
    if (open === true) {
      setClients([]);
      setSite([]);
      setClient({});
      loadCompanies();
    }
  }, [open]);

  useEffect(() => {
    setTimeZone("");
    setSelectedSite({});
    setState({
      ...state,
      siteId: "",
    });
    setSite([]);
    if (client?.id) {
      filterOutlet(1, 1000, { companyIds: client?.id }).then((response) => {
        if (response?.data?.code === "LMSI6000" && response.data.data) {
          setSite(response?.data?.data?.sites);
        }
      });
    }
  }, [client]);

  const loadCompanies = () => {
    listCompanies({ pageNumber: 1, pageSize: 1000 }).then((res) => {
      if (
        res?.data?.code === "UASI0029" &&
        res?.data?.data &&
        res?.data?.data?.companies
      ) {
        setClients(res?.data?.data?.companies);
      }
    });
  };

  useEffect(() => {
    if (!CommonUtil.isEmpty(selectedSite)) {
      const payload = {
        pageNumber: 1,
        pageSize: 0,
        filter: { siteId: selectedSite },
      };
    }
  }, [selectedSite]);

  const handleClear = () => {
    setState({
      ...state,
      timeZone: "",
      siteId: "",
      companyId: "",
    });
    setTimeZone("");
  };
  const handleClose = () => {
    setOpen(false);
  };

  const findDevice = () => {
    if (CommonUtil.isEmptyString(state.sn)) {
      setError({
        sn: t("LVLDV0102"),
      });
      return;
    }

    findDeviceBySn(state.sn, { mainOrSub: selectSence }).then((res) => {
      if (res.data.code === "LVLE0000") {
        enqueueSnackbar(res?.data?.message, {
          variant: "error",
        });
        return;
      }
      if (res?.data?.code === "DMSI0000") {
        setState({
          ...state,
          deviceId: res?.data?.data?.deviceId,
        });
        setProtocolType(res?.data?.data?.protocolType);
        setDeviceType(res?.data?.data?.deviceType);
        setOpen(true);
      } else if (res?.data?.code === "LVLI0000") {
        setState({
          ...state,
          deviceId: res?.data?.data?.deviceId,
          sn: res?.data?.data?.sn,
        });
        setOpen(true);
      } else {
        enqueueSnackbar(res?.data?.message, {
          variant: "error",
        });
      }
    });
  };
  const getTimezoneName = (value) => {
    var name = value;
    timeZoneList.map((t) => {
      if (value == t.value) {
        name = t.name;
      }
    });
    return name;
  };

  //   const handleSubmit = () => {
  //     if (CommonUtil.isEmptyString(state.companyId)) {
  //       setError({
  //         companyId: `${t("LVLDV0020")}`,
  //       });
  //       return;
  //     }

  //     if (CommonUtil.isEmptyString(state.siteId)) {
  //       setError({
  //         siteId: `${t("LVLDV0020")}`,
  //       });
  //       return;
  //     }
  //
  //     bindDevice(state, (res) => {
  //       if (res != null && res?.data?.code === "DMSI0000") {
  //         enqueueSnackbar(`${t("LVLDV0021")}`, {
  //           variant: "success",
  //         });
  //         navigate(REACT_DEVICE_LIST);
  //       } else {
  //         enqueueSnackbar(res?.data?.message, {
  //           variant: "error",
  //         });
  //       }
  //     });
  //   };

  const findZone = (id) => {
    let zoneObj = { id: "", name: "" };
    zone.map((data) => {
      if (data.id === id) zoneObj = data;
    });
    return zoneObj;
  };

  const findSite = (id) => {
    let siteObj = { id: "", name: "" };
    site.map((data) => {
      if (data.id === id) siteObj = data;
    });
    return siteObj;
  };

  const zoneField = () => (
    <Autocomplete
      required
      error={error.zoneId}
      fullWidth={true}
      disablePortal
      value={state.zoneId ? findZone(state.zoneId) : { id: "", name: "" }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      id="zone bind list"
      getOptionLabel={(option) => option.name}
      options={zone}
      renderInput={(params) => (
        <CustomInput
          placeholder={t("LVLDV0022")}
          {...params}
          helperText={error.zoneId}
          error={error.zoneId}
          required
          label={t("LVLDV0032")}
          size="large"
        />
      )}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setState({
            ...state,
            zoneId: newValue.id,
          });
        }
      }}
      ListboxProps={{ style: { maxHeight: "90px" } }}
    />
  );

  const siteField = (key) => (
    <Autocomplete
      fullWidth={true}
      error={error.siteId}
      disablePortal
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={state.siteId ? findSite(state.siteId) : { id: "", name: "" }}
      id="site bind list"
      getOptionLabel={(option) => option.name}
      options={site}
      sx={{ margin: "20px 0px" }}
      renderInput={(params) => (
        <CustomInput
          {...params}
          required
          helperText={error.siteId}
          error={error.siteId}
          label={t("LVLDV0033")}
          size="small"
        />
      )}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setState({
            ...state,
            siteId: newValue.id,
          });
          setTimeZone(newValue.address.timeZone);
          setSelectedSite(newValue.id);
        }
      }}
      ListboxProps={{ style: { maxHeight: "150px" } }}
    />
  );

  return (
    <>
      <TitleBar navigateBack={-1} title={t("LVLDV0023")} />
      <Grid container>
        <Grid item xs={12} mb={1}>
          <Typography
            variant="title"
            style={{
              marginBottom: "1rem",
              fontWeight: "450",
              color: "#474B4F",
              fontSize: "20px",
              opacity: "80%",
            }}
          >
            {t("LVLDV0024")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            padding: "1rem",
            borderRadius: "8px",
            backgroundColor: "#FFFF",
            border: "0px solid #DDDDDD",
          }}
        >
          <Typography
            variant="title"
            style={{ fontSize: "16px", fontWeight: 450, color: "#474B4F" }}
          >
            {t("LVLDV0025")}
          </Typography>
          <Box margin={"10px 0px 0px 0px"}>
            <Typography marginBottom={"20px"} color={"#a0a0a0"}>
              {t("LVLDV0026")}
            </Typography>
            <Typography marginBottom={"20px"} color={"#a0a0a0"}>
              {t("LVLDV0027")}
            </Typography>
            <Typography marginBottom={"20px"} color={"#a0a0a0"}>
              {t("LVLDV0028")}
            </Typography>
            <Typography marginBottom={"20px"} color={"#a0a0a0"}>
              {t("LVLDV0029")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} py={2}>
          <Typography
            variant="title"
            style={{
              margin: "1rem 0rem",
              fontSize: "18px",
              fontWeight: "450",
              color: "#474B4F",
              opacity: "80%",
            }}
          >
            {t("LVLDV0094")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          backgroundColor: "#FFFF",
          padding: "1rem",
          borderRadius: "8px",
          border: "0px solid #DDDDDD",
          display: "flex",
        }}
      >
        <Grid item sm={4} paddingRight={1}>
          <TextField
            error={!CommonUtil.isEmptyString(error.sn)}
            required
            fullWidth={true}
            name="sn"
            placeholder={t("LVLDV0095")}
            helperText={error.sn}
            variant="outlined"
            size="small"
            value={state.sn}
            onChange={(e) => {
              setState({ sn: e.target.value });
              setError({ sn: "" });
            }}
          />
        </Grid>
        <Grid marginLeft={5}>
          <RadioGroup
            row
            value={selectSence}
            onChange={(e) => setSelectSence(e.target.value)}
            // setSelectSence(e.target.value);
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={t("RTMT0034")}
            />
            <FormControlLabel
              value="0"
              control={<Radio />}
              label={t("RTMT0035")}
            />
          </RadioGroup>
          <Typography
            fontSize={14}
            sx={{ color: "#bebfc0", marginTop: "-1vh" }}
          >
            {t("PLACE024")}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        py={2}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          marginTop: "1rem",
        }}
      >
        <Button
          onClick={() => findDevice()}
          variant="contained"
          style={{ width: "160px", height: "48px", marginLeft: "auto" }}
        >
          {t("LVLDV0096")}
        </Button>
      </Grid>
      <SimpleDailogBox open={open} onClose={handleClose} title={t("LVLDV0097")}>
        <Grid container>
          <Grid item xs={12} padding={1}>
            <Box
              sx={{
                border: "1px solid #DDDDDD",
                borderRadius: "5px",
                display: "flex",
                padding: 1,
              }}
            >
              {/* {deviceType === "cam" && (
                <img
                  alt="complex"
                  src={DeviceIcon}
                  style={{ height: "60px", width: "60px" }}
                />
              )}
              {deviceType === "acc" && <DeviceIcon style={{ width: "60px" }} />}
              {deviceType === "att" && <DeviceIcon style={{ width: "60px" }} />} */}
              <img
                alt="complex"
                src={DeviceIcon}
                style={{ height: "60px", width: "60px" }}
              />
              <Box
                style={{
                  margin: "0rem 0.5rem",
                  alignSelf: "center",
                }}
              >
                <Typography>{state.deviceModel}</Typography>
                <Typography variant="fieldLabel">SN:{state.sn}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} px={2}>
            <Typography variant="dashboardDeviceLabel">
              {t("LVLDV0098")}
            </Typography>
          </Grid>
          <Grid item xs={12} px={2}>
            <Typography variant="menuItem" style={{ color: "#1487CA" }}>
              {t("LVLDV0099")}
            </Typography>
          </Grid>
          {!CommonUtil.isEmpty(selectedSite) && (
            <Grid item xs={12} px={2} mt={1}>
              <Typography
                style={{
                  color: "#36c96d",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
              >
                {timeZone !== undefined &&
                  timeZone !== "" &&
                  `${t("LVLDV0100")} ${getTimezoneName(timeZone)} ${t(
                    "LVLDV0101"
                  )}.`}
              </Typography>
            </Grid>
          )}
          <AddDevice
            state={state}
            selectSence={selectSence}
            protocolType={protocolType}
            deviceType={deviceType}
            onClose={() => {
              setOpen(false);
            }}
          />
          {/* <Grid item xs={12} px={2} py={2}>
            <Grid item>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ paddingLeft: "0px" }}
              >
                Client <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Autocomplete
                options={clients}
                value={client}
                onChange={(e, v) => {
                  setClient(v);
                  setState({ ...state, companyId: v?.id });
                  setError({ ...error, companyId: "" });
                }}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={!CommonUtil.isEmptyString(error.companyId)}
                    helperText={error.companyId}
                  />
                )}
              ></Autocomplete>
            </Grid>
          </Grid>
          <Grid item xs={12} px={2}>
            {siteField()}
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="flex-end"
            p={2}
            mt={3}
          >
            <Button
              onClick={() => handleClear()}
              variant="outlined"
              style={{ width: "160px", height: "48px", marginRight: "15px" }}
            >
              {"Clear"}
            </Button>
            <Button
              onClick={() => handleSubmit()}
              variant="contained"
              style={{ width: "160px", height: "48px" }}
            >
              {"Bind"}
            </Button>
          </Grid> */}
        </Grid>
      </SimpleDailogBox>
    </>
  );
};

export default BindDevice;
