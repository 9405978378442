/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";
import RightViewLayout from "@/components/RighViewLayout";

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  InputLabel,
  Autocomplete,
  TextField,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { REACT_DEVICE_LIST } from "@/router/ReactEndPoints";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DeviceIcon from "@/assets/images/icon_device.png";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
// import DeviceSetPersonCounting from "./DeviceSetPersonCounting";
import DeviceSetShopShelves from "../Submenu/DeviceSetShopShelves";
import { getDeviceChannel, getDeviceDetail } from "@/services/DeviceService";
export default function DeviceDetails() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [device, setDevice] = useState([]);
  const [deviceID, setDeviceId] = useState("");
  const [deviceOutLetId, setDeviceOutLetId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [clientID, setClientID] = useState(null);
  const [devicePicture, setDevicePicture] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setClientID(state?.clientId);
    setDeviceId(state?.id);
    setDeviceOutLetId(state?.outletId);
    getDeviceDetail(state?.id).then((res) => {
      setDevice(res?.data?.data);
      setDevicePicture(res?.data?.data?.photo);
    });
  }, []);

  const [channelNum1, setChannelNum1] = useState(null);
  const [channelIp, setChannelIp] = useState("");
  const [channelName, setChannelName] = useState("");
  const [channelmainStream, setChannelmainStream] = useState("");
  const [channeAlgType, setChanneAlgType] = useState(null);
  const [captureInterval, setCaptureInterval] = useState(0);
  const [data, setData] = useState([]); //所有通道数据
  const [selectedChannelName, setSelectedChannelName] = useState({}); //选中的通道名称

  const getDeviceNumber = () => {
    setIsLoading(true);
    getDeviceChannel(state?.id)
      .then((res) => {
        switch (res?.data?.code) {
          case "DMSI0000":
            setData(res?.data?.data?.srChannels);
            setSelectedChannelName(res?.data?.data?.srChannels[0]);
            enqueueSnackbar(t("LVLDV0104"), {
              variant: "success",
            });
            break;
          case "DMSE9999":
            enqueueSnackbar(res?.data?.message, {
              variant: "warning",
            });
            break;
          case "LVLE0000":
            enqueueSnackbar(t("PCS97"), {
              variant: "info",
            });
            break;
          case "DMSE0055":
            enqueueSnackbar(res?.data?.message, {
              variant: "info",
            });
            break;
          case "LVLE0067":
            enqueueSnackbar(res?.data?.message, {
              variant: "info",
            });
            break;
          default:
            console.log(t("LVLDV0106"));
        }

        setIsLoading(false);
        // if (res?.data?.code === "DMSI0000") {
        //   enqueueSnackbar(res.data.message, {
        //     variant: "success",
        //     anchorOrigin: {
        //       horizontal: "center",
        //       vertical: "top",
        //     },
        //     style: {
        //       marginTop: "300px",
        //     },
        //   });
        //   setData(res?.data?.data?.srChannels);
        //   setSelectedChannelName(res?.data?.data?.srChannels[0]);
        //   setIsLoading(false);
        //   return;
        // }
        // switch (res?.data?.code) {
        //   case "DMSE9999":
        //     enqueueSnackbar(res.data.message, { variant: "error" });
        //     setIsLoading(false);
        //     break;
        //   case "LVLE0000":
        //     enqueueSnackbar(t("PCS97"), {
        //       variant: "error",
        //     });
        //     setIsLoading(false);
        //     break;
        //   case "DMSE0055":
        //     enqueueSnackbar(res.data.message, { variant: "error" });
        //     setIsLoading(false);
        //     break;
        //   default:
        //     enqueueSnackbar(res.data.message, { variant: "error" });
        //     setIsLoading(false);
        // }
      })

      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (state?.id !== "") {
      getDeviceNumber();
    }
  }, []);

  useEffect(() => {
    setChannelName(selectedChannelName?.channelName);
    setChannelNum1(selectedChannelName?.channelNum);
    setChannelIp(selectedChannelName?.ip);
    setChannelmainStream(selectedChannelName?.mainStream);
    setChanneAlgType(selectedChannelName?.algType);
    setCaptureInterval(selectedChannelName?.captureInterval);
  }, [selectedChannelName]);

  const handleChange = (event) => {
    const selectedChannelValue = event.target.value;
    const selectedChannelData = data?.find(
      (channel) => channel.channelNum === selectedChannelValue.channelNum
    );
    setSelectedChannelName(selectedChannelValue);
    setChannelName(selectedChannelData?.channelName);
    setChannelNum1(selectedChannelData?.channelNum);
    setChannelIp(selectedChannelData?.ip);
    setChannelmainStream(selectedChannelData?.mainStream);
    setChanneAlgType(selectedChannelData?.algType);
    setCaptureInterval(selectedChannelData?.captureInterval);
  };

  return (
    <RightViewLayout navigateBack={REACT_DEVICE_LIST} title={t("LVLDV0067")}>
      <Grid Container>
        <Grid item xs={12} mb={4} style={{ backgroundColor: "#FFFFFF" }}>
          <Card sx={{ display: "flex" }} elevation={0}>
            <CardContent>
              <Grid container xs={12} alignItems={"center"}>
                <Grid item xs={2} justifySelf={"center"}>
                  <img
                    alt="complex"
                    src={devicePicture || DeviceIcon}
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                    }}
                  />
                </Grid>
                <Grid item container xs justifyContent="center" spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body2" gutterBottom color="gray">
                      {t("LVLDV0068")}
                    </Typography>
                    <Typography>{device?.id}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" gutterBottom color="gray">
                      {t("LVLDV0003")}
                    </Typography>
                    <Typography>{device?.deviceModel}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" gutterBottom color="gray">
                      {t("LVLGF0006")}
                    </Typography>
                    <Typography>{device?.outletName}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" gutterBottom color="gray">
                      {t("LVLDV0054")}
                    </Typography>
                    <Typography>{device?.sn}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" gutterBottom color="gray">
                      {t("LVLDV0004")}
                    </Typography>
                    <Typography>{device?.deviceType}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" gutterBottom color="gray">
                      {t("LVLDV0055")}
                    </Typography>
                    <Typography>{device?.outletId}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" gutterBottom color="gray">
                      {t("LVLDV0002")}
                    </Typography>
                    <Typography>{device?.deviceAlias}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" gutterBottom color="gray">
                      {t("LVLDV0056")}
                    </Typography>
                    <Typography>{device?.clientName}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" gutterBottom color="gray">
                      {t("LVLDV0006")}
                    </Typography>
                    <Typography>
                      {device?.status}
                      {/* {device.status === 1 ? t("LVLDV0091") : t("LVLDV0092")} */}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid container xs={12} mb={4} style={{ position: "relative" }}>
          <Grid item xs={6}>
            <span style={{ fontWeight: "bold" }}>{t("LVLDV0069")}</span>
          </Grid>
          {isLoading ? (
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box sx={{ minWidth: 150 }}>
                <FormControl fullWidth>
                  <Select
                    id="demo-simple-select"
                    value={selectedChannelName}
                    onChange={handleChange}
                  >
                    {data?.map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.channelName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid container xs={12}>
          {
            <DeviceSetShopShelves
              deviceID={deviceID}
              deviceOutLetId={deviceOutLetId}
              channelNum1={channelNum1}
              channelIp={channelIp}
              channelName={channelName}
              channelmainStream={channelmainStream}
              channeAlgType={channeAlgType}
              captureInterval={captureInterval}
              clientID={clientID}
            />
          }
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
