import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ListLayout from "../../components/ListLayout";
import CustomInput from "../../components/CustomInput";
import { getStoreOutlet } from "@/services/DashboardService";
import { getClinetsList } from "@/services/CompanyService";
import {
  updataProductList,
  getProductList,
  getProductPhoto,
} from "../../services/ProductService";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { REACT_PRODUCT_LIST } from "@/router/ReactEndPoints";
import AccessControl from "@/components/AccessControl";
import CommonUtil from "../../util/CommonUtils";
import PhotoIcon from "../../assets/images/photo-icon.svg";
export default function ProductList() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});
  const [outlets, setOutlets] = useState([]);
  const [outlet, setOutlet] = useState({});
  const [isEntity, setIsEntity] = useState(null);
  const [productImage, setProductImage] = useState(null);
  let mb = 2;

  useEffect(() => {
    setPayload({
      ...payload,
      ...location.state,
    });

    if (location.state.isEmpty === null) {
      setIsEntity(null);
    } else {
      setIsEntity(
        entity.find((option) => option?.id === location?.state?.isEmpty)
      );
    }

    getClients();
    getStores();
    getPhotoData();
  }, []);

  const getPhotoData = () => {
    let params = {
      pageNumber: 1,
      pageSize: 10,
      productId: location?.state?.id,
      outletId: location?.state?.outletId,
    };
    getProductPhoto(params).then((res) => {
      setProductImage(res?.data?.data?.objects[0]?.photoUrl);
    });
  };

  useEffect(() => {
    getStores();
  }, [client]);

  const entity = [
    {
      id: "0",
      value: "否",
    },
    {
      id: "1",
      value: "是",
    },
  ];
  const [payload, setPayload] = useState({
    id: id,
    productName: "",
    barCode: "",
    categoryLevel1: "",
    categoryLevel2: "",
    clientId: client?.id,
    outletId: outlet?.id,
  });

  const [error, setError] = useState({
    productName: "",
    barCode: "",
    categoryLevel1: "",
    categoryLevel2: "",
    clientId: client?.id,
    outletId: outlet?.id,
  });

  const fileInputRef = useRef(null);
  const imageRef = useRef(null);
  const [photoBase64, setPhotoBase64] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setPhotoBase64(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const imageDataURL = e.target.result;
      imageRef.current.src = imageDataURL;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const clickUploadImage = () => {
    fileInputRef.current.click();
  };

  const getClients = () => {
    const params = {
      pageNumber: 0,
      pageSize: 0,
      type: "RETAIL_CLIENT",
    };
    getClinetsList(params).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setClients(res?.data?.data);
        const clinetValue = res?.data?.data.find(
          (item) => item.id === location.state.clientId
        );
        setClient(clinetValue);
      }
    });
  };

  const getStores = () => {
    let UerInfo = JSON.parse(localStorage.getItem("USER"));
    let params = {
      clientId: client?.id,
      userId: UerInfo.userId,
    };

    if (client?.id) {
      getStoreOutlet(params).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setOutlets(res.data.data);
          const outletValue = res?.data?.data.find(
            (item) => item.id === location.state.outletId
          );
          setOutlet(outletValue);
        } else {
          setStoreList([]);
        }
      });
    } else {
      setOutlets([]);
      setOutlet({});
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
    });
  };

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.productName)) {
      setError({
        ...error,
        productName: `${t("LVL0001")}`,
      });
      return;
    }

    if (CommonUtil.isEmpty(payload.barCode)) {
      setError({
        ...error,
        barCode: `${t("LVL0001")}`,
      });
      return;
    }

    if (CommonUtil.isEmpty(payload.categoryLevel1)) {
      setError({
        ...error,
        categoryLevel1: `${t("LVL0001")}`,
      });
      return;
    }

    return true;
  };

  let saveSubmit = () => {
    if (validateForm()) {
      let params = {
        ...payload,
        clientId: client?.id,
        outletId: outlet?.id,
        isEmpty: isEntity?.id,
      };

      if (client === undefined || client === null) {
        enqueueSnackbar(t("PCS95"), {
          variant: "warning",
        });
        return;
      }

      if (outlet === null || outlet === undefined) {
        enqueueSnackbar(t("PCS114"), {
          variant: "warning",
        });
        return;
      }

      if (isEntity === undefined || isEntity === null) {
        enqueueSnackbar(t("PCS118"), {
          variant: "warning",
        });
        return;
      }

      let formData = new FormData();
      formData.append(
        "productDTO",
        new Blob([JSON.stringify(params)], { type: "application/json" })
      );
      formData.append("imageFile", photoBase64);
      updataProductList(formData).then((res) => {
        if (res?.data?.code === "LVLE0073") {
          enqueueSnackbar(res.data.message, {
            variant: "warning",
          });
          return;
        }

        if (res?.data?.code === "LVLE0000") {
          enqueueSnackbar(res.data.message, {
            variant: "warning",
          });
          return;
        }
        if (res.data.code === "LVLI0003") {
          enqueueSnackbar(t("PLACE08"), {
            variant: "success",
          });
          navigate(REACT_PRODUCT_LIST);
        }
      });
    }
  };

  let backClick = () => {
    navigate(REACT_PRODUCT_LIST);
  };

  return (
    <ListLayout navigateBack={REACT_PRODUCT_LIST} title={t("RTPD0003")}>
      <Grid sx={{ p: 2, mt: 2 }} component={Paper} container>
        <Grid item xs={3}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              cursor: "pointer",
            }}
          >
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <div
              onClick={clickUploadImage}
              style={{
                width: 180,
                height: 180,
                borderRadius: "50%",
                border: "1px dashed #ccc",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <img
                ref={imageRef}
                src={productImage || PhotoIcon}
                style={{ width: 160, height: 160, borderRadius: "50%" }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "14px",
              color: "#aaa",
              marginTop: "40px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <span style={{ display: "block", marginTop: "20px" }}>
                {t("Allowed only *JPEG, *JPG, *PNG")}
              </span>
              <span style={{ display: "block", marginTop: "20px" }}>
                {t("Maximum size of 3MB")}
              </span>
            </div>
          </div>
        </Grid>
        <Grid lg={4} sm={6} xs={18} sx={{ flexGrow: 1, pl: 8 }}>
          <Grid sx={{ mb: mb }} item md={12} xs={12}>
            <CustomInput
              required
              label={t("LVLDV0063")}
              size="small"
              name="productName"
              error={error.productName}
              value={payload.productName}
              handleChange={handleChange}
              inputProps={{
                maxLength: 60,
              }}
              helperText={error.name}
            />
          </Grid>
          <Grid sx={{ mb: mb }} item md={12} xs={12}>
            <CustomInput
              required
              label={t("LVLDV0074")}
              size="small"
              name="barCode"
              error={error.barCode}
              value={payload.barCode}
              handleChange={handleChange}
              inputProps={{
                maxLength: 60,
              }}
              helperText={error.barCode}
            />
          </Grid>
          <Grid sx={{ mb: mb }} item md={12} xs={12}>
            <CustomInput
              required
              label={t("LVLDV0075")}
              size="small"
              name="categoryLevel1"
              error={error.categoryLevel1}
              value={payload.categoryLevel1}
              handleChange={handleChange}
              inputProps={{
                maxLength: 60,
              }}
              helperText={error.categoryLevel1}
            />
          </Grid>
          <Grid sx={{ mb: mb }} item md={12} xs={12}>
            <CustomInput
              label={t("LVLDV0076")}
              size="small"
              name="categoryLevel2"
              error={error.categoryLevel2}
              value={payload.categoryLevel2}
              handleChange={handleChange}
              inputProps={{
                maxLength: 60,
              }}
              helperText={error.categoryLevel2}
            />
          </Grid>
          <Grid sx={{ mb: mb }} item md={12} xs={12}>
            <Typography sx={{ fontSize: 13 }}>
              {t("LVLGF0005")}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              noOptionsText={t("LVLGF0011")}
              options={clients}
              value={client}
              onChange={(e, v) => {
                setClient(v);
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>
          <Grid sx={{ mb: mb }} item md={12} xs={12}>
            <Typography sx={{ fontSize: 13 }}>
              {t("LVLGF0006")}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              noOptionsText={t("LVLGF0011")}
              options={outlets}
              value={outlet}
              onChange={(e, v) => {
                setOutlet(v);
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>

          <Grid sx={{ mb: mb }} item md={12} xs={12}>
            <Typography fontSize={11}>
              {t("PCS115")} <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              noOptionsText={t("LVLGF0011")}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              options={entity}
              value={isEntity}
              onChange={(e, v) => {
                setIsEntity(v);
              }}
              getOptionLabel={(option) => (option.value ? option.value : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>

          <Grid
            container
            sx={{ mt: 2, justifyContent: "flex-end" }}
            item
            md={12}
            xs={12}
          >
            <Button
              onClick={() => {
                backClick();
              }}
              variant="outlined"
            >
              {t("LVLLOC0037")}
            </Button>
            <AccessControl authorization={"1030"}>
              <Button
                onClick={() => {
                  saveSubmit();
                }}
                sx={{ ml: 3 }}
                variant="contained"
              >
                {t("LVL0013")}
              </Button>
            </AccessControl>
          </Grid>
        </Grid>
      </Grid>
    </ListLayout>
  );
}

{
  /* <Grid
          container
          lg={6}
          sm={6}
          xs={12}
          sx={{ flexGrow: 1, flexDirection: "column" }}
        >
          <Box m={1}>
            <Typography variant="subtitle3">
              Template Photo of This Product
            </Typography>
            <Typography variant="caption">
              (Please upload thi picture)
            </Typography>
          </Box>

          <Dragger {...props}>
            <div
              style={{
                minHeight: "200px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p className="ant-upload-drag-icon">
                <FileUpload />
              </p>
              <p className="ant-upload-text">
                Drag and Drop file here or choose file
              </p>
              <p className="ant-upload-hint">(upload .png .jpg)</p>
            </div>
          </Dragger>

          <Grid sx={{ mt: 2 }} container>
            {fileList?.map((item) => {
              return (
                <Box sx={{ m: 1, position: "relative" }}>
                  <CloseIcon
                    onClick={() => {
                      deleteFile(item);
                    }}
                    sx={{
                      position: "absolute",
                      fontSize: "16px",
                      top: "0px",
                      right: "0px",
                      zIndex: 10,
                      color: "#ffff",
                      backgroundColor: "#000000",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0,0,0,0.5)",
                      },
                    }}
                  />
                  <Image preview={false} width={100} src={item.url} />
                </Box>
              );
            })}
          </Grid>
        </Grid> */
}
