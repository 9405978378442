import React, { useEffect, useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import CheckboxIcon from "@mui/icons-material/CheckBoxOutlineBlank"; // 未选中状态图标
import CheckboxCheckedIcon from "@mui/icons-material/CheckBox"; // 选中状态图标
import RequirePoint from "./RequirePoint";
function CmpRadioGrounp(props) {
  const {
    formik = null,
    placeholder = "",
    handleBlur,
    handleChange,
    label,
    name,
    error,
    options,
    disabled = false,
    isClear = true,
    labelPostion,
    spacing = 1,
    width,
    height,
    inputType,
    fontSize = "16px",
    defaultValue,

    color = "#78bc27",
    ...orther
  } = props;
  const CustomRadio = ({ value, label }) => (
    <FormControlLabel
      value={value}
      control={
        <Radio
          icon={<CheckboxIcon />}
          name={name}
          disabled={disabled}
          checkedIcon={<CheckboxCheckedIcon />}
          sx={{
            "&.Mui-checked": {
              color: color,
            },
          }}
        />
      }
      label={label}
    />
  );

  return (
    <FormControl>
      <FormLabel
        id="demo-radio-buttons-group-label"
        sx={{
          marginTop: labelPostion === "left" ? "12px" : "",
          width: width,
          fontSize: fontSize,
        }}
        htmlFor={"CmpInput_" + name}
      >
        {props.required && <RequirePoint></RequirePoint>} {label}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={defaultValue}
        name={name}
        value={formik.values[name]}
        onChange={formik?.handleChange}
        row
      >
        {options?.map((item) => {
          return (
            <CustomRadio value={item?.value} label={item?.label}></CustomRadio>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

export default CmpRadioGrounp;
