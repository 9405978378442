// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

const typography = (fontFamily) => ({
  htmlFontSize: 14,
  fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: 1.5,
  },
  h2: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: 1.57,
  },
  h3: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: 1.57,
  },
  h4: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: 1.7,
  },

  h5: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: 1.57,
  },

  h6: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: 1.57,
  },

  caption: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.66,
  },
  body1: {
    fontSize: "14px",
    lineHeight: 1.57,
  },
  body2: {
    fontSize: "12px",
    lineHeight: 1.66,
  },
  subtitle1: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: 1.57,
  },
  subtitle2: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.66,
  },
  overline: {
    lineHeight: 1.66,
  },
  button: {
    textTransform: "capitalize",
  },
  mainContent: {
    backgroundColor: "#f0f2f5",
  },

  allVariants: {
    color: "#304669",
  },
  menuItem: {
    color: "#A2A3A3",
    letterSpacing: "0px",
    font: "normal normal normal 14px/19px Roboto",
    opacity: "1",
  },
  title: {
    color: "#304669",
    letterSpacing: "0.4px",
    font: "normal normal normal 20px/29px Roboto",
    opacity: "1",
    fontWeight: 500,
  },
  subtitle3: {
    color: "#304669",
    letterSpacing: "0px",
    font: "normal normal normal 16px/19px Roboto",
    opacity: "1",
    fontWeight: "400",
  },
  link: {
    color: "#304669",
    letterSpacing: "0.4px",
    font: "normal normal normal 16px/19px Roboto",
    opacity: "1",
    fontWeight: "400",
  },
  subtitle4: {
    color: "#304669",
    letterSpacing: "0.4px",
    font: "normal normal normal 16px/19px Roboto",
    fontWeight: "400",
    opacity: "0.8",
  },
  label: {
    color: "#304669",
    letterSpacing: "0",
    font: "normal normal normal 14px/26px Roboto",
    fontWeight: "400",
    opacity: "0.5",
  },
  value: {
    color: "#304669",
    letterSpacing: "0",
    font: "normal normal normal 14px/26px Roboto",
    fontWeight: "400",
    opacity: "1",
  },
  fieldLabel: {
    color: "#304669",
    letterSpacing: "0.9px",
    font: "normal normal normal 14px/22px Roboto",
    fontWeight: "500",
    opacity: "0.5",
  },
  fieldValue: {
    color: "#304669",
    letterSpacing: "0.9px",
    font: "normal normal normal 14px/22px Roboto",
    fontWeight: "500",
    opacity: "1",
  },
  dropdownLabel: {
    color: "#304669",
    letterSpacing: "0px",
    font: "normal normal normal 12px/20px Roboto",
    opacity: "1",
    fontWeight: "500",
  },
  count: {
    color: "#474B4F",
    letterSpacing: "0px",
    font: "normal normal normal 30px/36px Roboto",
    opacity: "1",
  },
  countDescription: {
    color: "#474B4F",
    letterSpacing: "0.4px",
    font: "normal normal normal 12px/20px Roboto",
    opacity: "0.8",
  },
  countSubDescription: {
    color: "#474B4F",
    letterSpacing: "0.3px",
    font: "normal normal normal 10px/16px Roboto",
    opacity: "0.5",
  },
  dashboardDeviceLabel: {
    color: "#474B4F",
    letterSpacing: "0px",
    font: "normal normal normal 12px/19px Roboto",
    opacity: "0.8",
  },
});

export default typography;
