import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import { Avatar, Box, MenuItem, Tooltip } from "@mui/material";
// import PricingDialog from "./PricingDialog";
import HighQualityIcon from "@mui/icons-material/HighQuality";
import DefaultOrgPic from "@/assets/images/default_org_pic.png";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import UpdataTenant from "./UpdataTenant";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ValidityDate from "./ValidityDate";
import {
  REACT_VIEW_COMPANY_PROFILE,
  REACT_VIEW_USER_PROFILE,
} from "@/router/ReactEndPoints";
import CommonUtil from "../../util/CommonUtils";
import { getCompany } from "@/services/CompanyService";

export default function CompanyProfileMenu() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [validityOpen, setValidityOpen] = useState(false);
  const handleView = () => {
    navigate(REACT_VIEW_COMPANY_PROFILE);
  };

  const handlePricing = () => {
    setOpen(true);
  };

  const handlerValidity = () => {
    setValidityOpen(true);
  };
  const isToken = localStorage.getItem("USER_TOKEN_RETAIL_AI");
  const [companyName, setCompanyName] = useState("");
  useEffect(() => {
    getCompany().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCompanyName(res?.data?.data?.companyName);
      }
    });
  }, []);

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });
  return (
    <div>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        style={{ cursor: "pointer" }}
        {...bindTrigger(popupState)}
        sx={{ pl: { xs: 0, md: 2 }, pr: 0, my: { xs: 0, md: 1 } }}
      >
        <Box sx={{ py: { xs: 2, md: 0.5 } }} pl={2} pr={1}>
          <Avatar src={DefaultOrgPic} sx={{ width: 24, height: 24 }} />
        </Box>
        <Box flexGrow={1} sx={{ display: { md: "flex" } }}>
          <Tooltip title={companyName} arrow placement="bottom">
            <Typography variant="menuItem">
              {CommonUtil.formatLongText(companyName.slice(0, 20))}
            </Typography>
          </Tooltip>
        </Box>
        <Box py={0.5} px={2} sx={{ display: { md: "flex" } }}>
          <Typography variant="menuItem">
            {popupState.isOpen ? (
              <KeyboardArrowLeftIcon fontSize="small" />
            ) : (
              <KeyboardArrowRightIcon fontSize="small" />
            )}
          </Typography>
        </Box>
      </Box>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            popupState.close();
            handleView();
          }}
        >
          {t("LVL0024")}
        </MenuItem>

        {/* <MenuItem
          onClick={() => {
            popupState.close();
            handlePricing();
          }}
        >
          {t("LVL0027")}
        </MenuItem> */}

        {localStorage.getItem("ROLE_KEY") === "SUPER_ADMIN" && (
          <MenuItem
            onClick={() => {
              popupState.close();
              handlePricing();
            }}
          >
            {t("LVL0034")}
          </MenuItem>
        )}

        {localStorage.getItem("ROLE_KEY") === "CLIENT_ADMIN" && (
          <MenuItem
            onClick={() => {
              popupState.close();
              handlerValidity();
            }}
          >
            {t("system.system_about")}
          </MenuItem>
        )}
      </Popover>

      {/* <PricingDialog setOpen={setOpen} open={open}></PricingDialog> */}
      {localStorage.getItem("ROLE_KEY") === "SUPER_ADMIN" && (
        <UpdataTenant setOpen={setOpen} open={open}></UpdataTenant>
      )}

      <ValidityDate
        setValidityOpen={setValidityOpen}
        validityOpen={validityOpen}
      ></ValidityDate>
    </div>
  );
}
