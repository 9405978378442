import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import { REACT_AUTHORITATION } from "@/router/ReactEndPoints";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ListLayout from "@/components/ListLayout";
import { useTranslation } from "react-i18next";
import CommonUtil from "@/util/CommonUtils";
import { useSnackbar } from "notistack";
import {
  getResourceList,
  addRoleData,
  detailRole,
} from "../../services/authoritation";
function ViewAuthoritation() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [menus, setMenus] = useState([]);
  const [authName, setAuthName] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    getResourceList().then((res) => {
      setMenus(res?.data?.data);
    });
    detailRole(state.id).then((res) => {
      // console.log("GGGGGGGGGGGGGGGGGGGGGG", res);
      setAuthName(res?.data?.data?.roleName);
      setSelectedPermissions(res?.data?.data?.checkedIdList);
    });
  }, []);

  const handleCheckboxChange = (values, isParent) => {
    setSelectedPermissions((prevSelectedPermissions) => {
      if (prevSelectedPermissions.includes(values)) {
        return prevSelectedPermissions.filter((p) => p !== values);
      } else {
        return [...prevSelectedPermissions, values];
      }
    });
  };

  //新增角色
  const handleSubmit = () => {
    let params = {
      name: authName,
      resourceIds: selectedPermissions,
    };

    if (!CommonUtil.isEmpty(params.name)) {
      addRoleData(params).then((res) => {
        if (res.data.code === "LVLI0000") {
          enqueueSnackbar("successfully added", {
            variant: "success",
          });
          navigate(REACT_AUTHORITATION);
        }
      });
    } else {
      enqueueSnackbar("Role name cannot be empty.", {
        variant: "warning",
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <ListLayout navigateBack={REACT_AUTHORITATION} title={t("LVLDB0037")}>
          <Grid>
            <Grid
              display={"flex"}
              sx={{
                background: "#FFF",
                height: "100px",
                width: "100%",
                borderRadius: "15px",
              }}
            >
              <Box
                sx={{
                  margin: "30px 30px",
                }}
              >
                <TextField
                  size="small"
                  label={t("LVLDB0003")}
                  placeholder={t("LVLDV0107")}
                  value={authName}
                  disabled={true}
                  onChange={(e) => setAuthName(e.target.value)}
                ></TextField>
              </Box>
            </Grid>

            {menus?.map((menu) => (
              <Grid key={menu.id} pt={1.5}>
                <Card
                  elevation={0}
                  sx={{
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    fontWeight: "bold",
                    borderRadius: "8px",
                  }}
                >
                  <FormGroup
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      ml: 4, // 设置左边距
                    }}
                  >
                    <Grid lg={2}>
                      <FormControlLabel
                        key={menu.id}
                        control={
                          <Checkbox
                            id={`permission-checkbox-${menu.id}`}
                            checked={selectedPermissions?.includes(menu.id)}
                            disabled={true}
                            onChange={() =>
                              handleCheckboxChange(menu.id, menu.parentId)
                            }
                          />
                        }
                        label={menu.name}
                        sx={{
                          textAlign: "left",
                          font: "normal normal medium 28px/38px Roboto",
                          fontWeight: "bold",
                          letterSpacing: "0px",

                          color: "#474B4F",
                        }}
                      />
                    </Grid>
                  </FormGroup>
                  <CardContent>
                    {menu?.resourceList.map((item) => (
                      <Grid
                        key={item.id}
                        sx={{
                          textAlign: "left",
                          font: "normal normal medium 32px/38px Roboto",
                          letterSpacing: "0px",

                          color: "#474B4F",
                        }}
                      >
                        <FormGroup
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            ml: 6, // 设置左边距
                          }}
                        >
                          <Grid lg={2}>
                            {item.name !== null ? (
                              <FormControlLabel
                                key={item.id}
                                disabled={true}
                                control={
                                  <Checkbox
                                    id={`permission-checkbox-${item.id}`}
                                    checked={selectedPermissions?.includes(
                                      item.id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        item.id,
                                        item.parentId
                                      )
                                    }
                                  />
                                }
                                label={item.name}
                                sx={{
                                  textAlign: "left",
                                  font: "normal normal medium 18px/24px Roboto",
                                  fontWeight: "bold",
                                  letterSpacing: "0px",

                                  color: "#474B4F",
                                }}
                              />
                            ) : null}
                          </Grid>
                        </FormGroup>

                        <FormGroup
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            ml: 12,
                          }}
                        >
                          {item?.resourceList.map((permission) => (
                            <Grid lg={2}>
                              <FormControlLabel
                                key={permission.id}
                                control={
                                  <Checkbox
                                    id={`permission-checkbox-${permission.id}`}
                                    disabled={true}
                                    checked={selectedPermissions?.includes(
                                      permission.id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        permission.id,
                                        permission.parentId
                                      )
                                    }
                                  />
                                }
                                label={permission.name}
                                sx={{
                                  width: 200,
                                  mt: 1,
                                }}
                              />
                            </Grid>
                          ))}
                        </FormGroup>
                      </Grid>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </ListLayout>
      )}
    </>
  );
}

export default ViewAuthoritation;
