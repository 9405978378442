import { store } from "@/store";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider as ReduxProvider } from "react-redux";
import "typeface-roboto";
import App from "./App";
import i18n from "./lang/i18n";
import ThemeCustomization from "./themes";
import "amfe-flexible";
import "./plugin/flexible/index";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReduxProvider store={store}>
    <ThemeCustomization>
      <I18nextProvider i18n={i18n}>
        <App props={i18n} />
      </I18nextProvider>
    </ThemeCustomization>
  </ReduxProvider>
);
