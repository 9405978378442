import { Box, Typography } from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import ViewPage from "../../components/ViewPage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCompanyByIdOrCode } from "../../services/CompanyService";
import { REACT_RETAIL_CLIENT_LIST } from "@/router/ReactEndPoints";
import {
  getAllCities,
  getAllCountries,
  getAllStates,
} from "../../services/LocationService";
import OverFlowText from "../../components/OverFlowText";
import { useTranslation } from "react-i18next";

export default function ViewRetailClient() {
  const { t } = useTranslation();
  const { code } = useParams();
  const [payload, setPayload] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    code: "",
    country: "",
    create_requested_from: "",
    created_at: "",
    created_by: "",
    id: "",
    latitude: "",
    longitude: "",
    name: "",
    pincode: "",
    state: "",
    status: "",
    type: "RETAIL_CLIENT",
    update_requested_from: "",
    updated_at: "",
    updated_by: "",
  });

  useEffect(() => {
    let current = sessionStorage.getItem("currentClinet");
    let obj = JSON.parse(current);

    if (current) {
      setPayload({
        ...payload,
        ...obj,
      });
    }
  }, []);

  return (
    <RightViewLayout navigateBack={"-1"} title={t("LVLRC0019")}>
      <ViewPage>
        <Box>
          <Typography variant="fieldLabel">{t("LVLRC0023")}:</Typography>
        </Box>

        <Typography variant="fieldValue">
          {payload && payload.name ? payload.name : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLRC0025")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.code ? payload.code : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLRC0004")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.email ? payload.email : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLRC0005")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.mobile ? payload.mobile : "-"}
        </Typography>
        <Box>
          <Typography variant="fieldLabel">{t("LVLRC0006")}:</Typography>
        </Box>
        <OverFlowText variant="fieldValue" maxLength="100">
          {payload && payload.addressLine ? payload.addressLine : "-"}
        </OverFlowText>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLRC0007")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.countryName ? payload?.countryName : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLRC0008")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.stateName ? payload?.stateName : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLRC0009")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.cityName ? payload?.cityName : "-"}
        </Typography>
      </ViewPage>
    </RightViewLayout>
  );
}
