import api from "../configurations/http-common";

export const getMetaDataList = async (payload) => {
  return api.securedAxios().get("/web/device_events", {
    params: payload,
  });
};

export const exportExcelData = async (payload) => {
  return api.securedAxios().get("/web/device_events/export", {
    params: payload,
    responseType: "blob",
  });
};

export const getPhotoData = async (payload) => {
  return api.securedAxios().get("/web/device_events/image", {
    params: payload,
  });
};
