import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  InputLabel,
  Select,
  Box,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useSnackbar } from "notistack";
import { getStoreOutlet } from "@/services/DashboardService";
import { getClinetsList } from "@/services/CompanyService";
import { useTranslation } from "react-i18next";
import DeviceIcon from "@/assets/images/icon_device.png";
import { useNavigate } from "react-router-dom";
import ListLayout from "../../components/ListLayout";
import { message, Upload, Image } from "antd";
import CustomInput from "../../components/CustomInput";
import { addProductList } from "../../services/ProductService";
import { REACT_PRODUCT_LIST } from "@/router/ReactEndPoints";
import LoadingButton from "@mui/lab/LoadingButton";
import CommonUtil from "../../util/CommonUtils";
import AccessControl from "@/components/AccessControl";
import PhotoIcon from "../../assets/images/photo-icon.svg";
export default function ProductList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});
  const [outlets, setOutlets] = useState([]);
  const [outlet, setOutlet] = useState({});
  const [isEntity, setIsEntity] = useState(null);
  const [payload, setPayload] = useState({
    productName: "",
    barCode: "",
    categoryLevel1: "",
    categoryLevel2: "",
    clientId: "",
    outletId: "",
  });

  const entity = [
    {
      id: 0,
      value: "否",
    },
    {
      id: 1,
      value: "是",
    },
  ];

  const [error, setError] = useState({
    productName: "",
    barCode: "",
    categoryLevel1: "",
    categoryLevel2: "",
    clientId: "",
    outletId: "",
  });

  useEffect(() => {
    const params = {
      pageNumber: 0,
      pageSize: 0,
      type: "RETAIL_CLIENT",
    };
    getClinetsList(params).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data) {
        setClients(res?.data?.data);
      }
    });
    getStores();
  }, []);

  useEffect(() => {
    getStores();
    setOutlet({});
  }, [client]);

  const getStores = () => {
    let UerInfo = JSON.parse(localStorage.getItem("USER"));
    let params = {
      clientId: client?.id,
      userId: UerInfo.userId,
    };

    getStoreOutlet(params).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setOutlets(res.data.data);
      } else {
        setStoreList([]);
      }
    });
  };

  const fileInputRef = useRef(null);
  const imageRef = useRef(null);
  const [photoBase64, setPhotoBase64] = useState({});
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setPhotoBase64(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const imageDataURL = e.target.result;
      imageRef.current.src = imageDataURL;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const clickUploadImage = () => {
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
    });
  };

  let mb = 2;

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.productName)) {
      setError({
        ...error,
        productName: `${t("LVL0001")}`,
      });
      return;
    }

    if (CommonUtil.isEmpty(payload.barCode)) {
      setError({
        ...error,
        barCode: `${t("LVL0001")}`,
      });
      return;
    }

    if (CommonUtil.isEmpty(payload.categoryLevel1)) {
      setError({
        ...error,
        categoryLevel1: `${t("LVL0001")}`,
      });
      return;
    }

    return true;
  };

  const saveSubmit = () => {
    let params = {
      ...payload,
      clientId: client?.id,
      outletId: outlet?.id,
      isEmpty: isEntity?.id,
    };

    if (validateForm()) {
      if (
        client === undefined ||
        client === null ||
        Object.keys(client).length === 0
      ) {
        enqueueSnackbar(t("PCS95"), {
          variant: "warning",
        });
        return;
      }

      if (
        outlet === undefined ||
        outlet === null ||
        Object.keys(outlet).length === 0
      ) {
        enqueueSnackbar(t("PCS114"), {
          variant: "warning",
        });
        return;
      }

      if (isEntity === undefined || isEntity === null) {
        enqueueSnackbar(t("PCS118"), {
          variant: "warning",
        });
        return;
      }

      let formData = new FormData();
      formData.append(
        "productDTO",
        new Blob([JSON.stringify(params)], { type: "application/json" })
      );
      formData.append("imageFile", photoBase64);
      addProductList(formData)
        .then((res) => {
          if (res.data.code === "LVLI0008") {
            enqueueSnackbar(res.data.message, {
              variant: "success",
            });
            navigate(REACT_PRODUCT_LIST);
            setError();
          } else {
            enqueueSnackbar(res.data.message, {
              variant: "error",
            });
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  let backClick = () => {
    navigate(REACT_PRODUCT_LIST);
  };

  return (
    <ListLayout navigateBack={REACT_PRODUCT_LIST} title={t("RTPD0002")}>
      <Grid sx={{ p: 2, mt: 2 }} component={Paper} container>
        <Grid item xs={3} ml={10}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              cursor: "pointer",
            }}
          >
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              name="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <div
              onClick={clickUploadImage}
              style={{
                width: 180,
                height: 180,
                borderRadius: "50%",
                border: "1px dashed #ccc",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <img
                ref={imageRef}
                accept="image/*"
                name="file"
                src={PhotoIcon}
                style={{ width: 160, height: 160, borderRadius: "50%" }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "14px",
              color: "#aaa",
              marginTop: "40px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <span style={{ display: "block", marginTop: "20px" }}>
                {t("Allowed only *JPEG, *JPG, *PNG")}
              </span>
              <span style={{ display: "block", marginTop: "20px" }}>
                {t("Maximum size of 3MB")}
              </span>
            </div>
          </div>
        </Grid>
        <Grid lg={4} sm={6} xs={18} sx={{ flexGrow: 1, pl: 8 }}>
          <Grid sx={{ mb: mb }} item md={12} xs={12}>
            <CustomInput
              required
              label={t("LVLDV0063")}
              size="small"
              name="productName"
              error={error.productName}
              value={payload.productName}
              handleChange={handleChange}
              inputProps={{
                maxLength: 60,
              }}
              helperText={error.productName}
            />
          </Grid>
          <Grid sx={{ mb: mb }} item md={12} xs={12}>
            <CustomInput
              required
              label={t("LVLDV0074")}
              size="small"
              name="barCode"
              error={error.barCode}
              value={payload.barCode}
              handleChange={handleChange}
              inputProps={{
                maxLength: 60,
              }}
              helperText={error.barCode}
            />
          </Grid>
          <Grid sx={{ mb: mb }} item md={12} xs={12}>
            <CustomInput
              required
              label={t("LVLDV0075")}
              size="small"
              name="categoryLevel1"
              error={error.categoryLevel1}
              value={payload.categoryLevel1}
              handleChange={handleChange}
              inputProps={{
                maxLength: 60,
              }}
              helperText={error.categoryLevel1}
            />
          </Grid>
          <Grid sx={{ mb: mb }} item md={12} xs={12}>
            <CustomInput
              label={t("LVLDV0076")}
              size="small"
              name="categoryLevel2"
              error={error.categoryLevel2}
              value={payload.categoryLevel2}
              handleChange={handleChange}
              inputProps={{
                maxLength: 60,
              }}
              helperText={error.categoryLevel2}
            />
          </Grid>
          <Grid sx={{ mb: mb }} item md={12} xs={12}>
            <Typography fontSize={11}>
              {t("LVLGF0005")} <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              noOptionsText={t("LVLGF0011")}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={clients}
              value={client}
              onChange={(e, v) => {
                setClient(v);
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>
          <Grid sx={{ mb: mb }} item md={12} xs={12}>
            <Typography fontSize={11}>
              {t("LVLGF0006")} <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              noOptionsText={t("LVLGF0011")}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={outlets}
              value={outlet}
              onChange={(e, v) => {
                setOutlet(v);
              }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>
          <Grid sx={{ mb: mb }} item md={12} xs={12}>
            <Typography fontSize={11}>
              {t("PCS115")} <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              noOptionsText={t("LVLGF0011")}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={entity}
              value={isEntity}
              onChange={(e, v) => {
                setIsEntity(v);
              }}
              getOptionLabel={(option) => (option.value ? option.value : "")}
              renderInput={(params) => <TextField {...params} size="small" />}
            ></Autocomplete>
          </Grid>

          <Grid
            container
            sx={{ mt: 2, justifyContent: "flex-end" }}
            item
            md={12}
            xs={12}
          >
            <Button
              onClick={() => {
                backClick();
              }}
              variant="outlined"
            >
              {t("LVLLOC0037")}
            </Button>
            <AccessControl authorization={"1029"}>
              <LoadingButton
                onClick={() => {
                  saveSubmit();
                }}
                sx={{ ml: 3 }}
                loading={isLoading}
                variant="contained"
              >
                {t("LVL0013")}
              </LoadingButton>
            </AccessControl>
          </Grid>
        </Grid>
      </Grid>
    </ListLayout>
  );
}
