//列 数据组件

import { Avatar, Tooltip, Popover, Button } from "@mui/material";
import CommonUtil from "@/util/CommonUtils";
import { useState, useEffect, useMemo } from "react";
import AccessControl from "@/components/AccessControl";
export const getcolumns = (t, handleImageClick, selectedValue) => {
  const [selectList, setSelectList] = useState(1);
  useEffect(() => {
    const handleSelectedValue = (value) => {
      const options = {
        "354296b825141de9645c65612181496": 1,
        "364296b825141de9645c65612181496": 2,
        "334296b825121de96c5c6561a181496": 3,
      };
      setSelectList(options[value] || 4);
    };
    handleSelectedValue(selectedValue);
  }, [selectedValue]);

  const renderTooltipCell = (value) => (
    <Tooltip title={value} arrow placement="bottom">
      <span>{CommonUtil.formatLongText(value)}</span>
    </Tooltip>
  );

  // const getEventPhotoUrl = (row) => {
  //   if (row.eventBodyLogVO !== null) {
  //     return row.eventBodyLogVO.imagePath;
  //   } else if (row.eventFaceLogVO !== null) {
  //     return row.eventFaceLogVO.imagePath;
  //   } else {
  //     return row.eventShelfLogVO.imagePath;
  //   }
  // };

  /**
   * 初始字段 所有类型都拥有
   */
  let columns = [
    {
      field: "eventTime",
      headerName: t("RTMT0004"),
      width: 130,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => {
        const eventTime = e.row?.eventTime || "";
        return renderTooltipCell(eventTime);
      },
    },
    {
      field: "target",
      headerName: t("RTMT0007"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => {
        const [eventType, setEventType] = useState("");
        useEffect(() => {
          if (e.row.eventBodyLogVO !== null) {
            setEventType(e.row.eventBodyLogVO.recognitionTarget);
          } else if (e.row.eventFaceLogVO !== null) {
            setEventType(e.row.eventFaceLogVO.recognitionTarget);
          } else if (e.row.eventOverLogVO !== null) {
            setEventType(e.row.eventOverLogVO.recognitionTarget);
          } else if (e.row.eventShelfLogVO !== null) {
            setEventType(e.row.eventShelfLogVO.recognitionTarget);
          } else {
            setEventType("");
          }
        }, [e.row]);
        return renderTooltipCell(eventType);
      },
    },
  ];

  /**
   * Body 、 face 、Crocess the line 应该有的字段
   */
  if (selectList === 1 || selectList === 2 || selectList === 3) {
    columns.push({
      field: "trackId",
      headerName: t("RTMT0020"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => {
        const [trackId, setTrackId] = useState(0);
        useEffect(() => {
          if (e.row.eventBodyLogVO !== null) {
            setTrackId(e.row.eventBodyLogVO.trackId);
          } else if (e.row.eventFaceLogVO !== null) {
            setTrackId(e.row.eventFaceLogVO.trackId);
          } else if (e.row.eventOverLogVO !== null) {
            setTrackId(e.row.eventOverLogVO.trackId);
          } else {
            setTrackId("");
          }
        }, [e.row]);
        return renderTooltipCell(trackId);
      },
    });
  }

  /**
   * Body 应该有的字段
   */
  if (selectList === 1) {
    columns.push(
      {
        field: "upperColor",
        headerName: t("RTMT0025"),
        flex: 1,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: (e) => {
          const upperColor = e.row.eventBodyLogVO?.upperColor || "";
          return renderTooltipCell(upperColor);
        },
      },

      {
        field: "lowerColor",
        headerName: t("RTMT0022"),
        flex: 1,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: (e) => {
          const upperTexture = e.row.eventBodyLogVO?.lowerColor || "";
          return renderTooltipCell(upperTexture);
        },
      },
      {
        field: "rideBike",
        headerName: t("RTMT0023"),
        flex: 1,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: (e) => {
          const rideBike = e.row.eventBodyLogVO?.rideBike || "";
          return renderTooltipCell(rideBike);
        },
      },
      {
        field: "umbrella",
        headerName: t("RTMT0024"),
        flex: 1,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: (e) => {
          const umbrella = e.row.eventBodyLogVO?.umbrella || "";
          return renderTooltipCell(umbrella);
        },
      },
      {
        field: "bag",
        headerName: t("RTMT0028"),
        flex: 1,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: (e) => {
          const bag = e.row.eventBodyLogVO?.bag || "";
          return renderTooltipCell(bag);
        },
      }
    );
  }

  /**
   * body 货架共同拥有的字段
   */
  if (selectList === 1 || selectList === 4) {
    columns.push({
      field: "channelNum",
      headerName: t("RTMT0021"),
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (e) => {
        const channelNum =
          e.row[`event${selectList === 1 ? "Body" : "Shelf"}LogVO`]
            ?.channelNum || "";
        return renderTooltipCell(channelNum);
      },
    });
  }

  /**
   * face 应该有的字段
   */
  if (selectList === 2) {
    columns.push({
      field: "maskStatus",
      headerName: t("RTMT0019"),
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => {
        const maskStatus = e.row.eventFaceLogVO?.maskStatus || "";
        return renderTooltipCell(maskStatus);
      },
    });
  }
  /**
   * face 、 Body 、 货架分析共同拥有的字段
   */
  // selectList === 1 || selectList === 2 ||
  if (selectList === 4) {
    columns.push({
      field: "eventPhoto",
      headerName: t("RTMT0006"),
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => {
        // const [eventPhotoUrl, setEventPhotoUrl] = useState("");
        // const [eventID, setEventID] = useState("");
        // useEffect(() => {
        //   // setEventPhotoUrl(getEventPhotoUrl(e.row));
        //   setEventID(e.row.id);
        // }, [e.row]);
        return (
          // <Avatar
          //   sx={{
          //     width: 40,
          //     height: 40,
          //     cursor: "pointer", // 添加指针样式，表示可点击
          //   }}
          //   alt="Event Photo"
          //   src={eventPhotoUrl}
          //   variant="square"
          //   onClick={() => handleImageClick(eventPhotoUrl)} // 点击事件调用放大函数
          // />
          <AccessControl authorization={"1034"}>
            <Button onClick={() => handleImageClick(e.row.id, 2)}>
              {t("MATADATA04")}
            </Button>
          </AccessControl>
        );
      },
    });
  }

  /**
   * face 、 Body共同拥有的字段
   */
  if (selectList === 1 || selectList === 2) {
    columns.push(
      {
        field: "age",
        headerName: t("RTMT0015"),
        flex: 1,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: (e) => {
          const age =
            e.row[`event${selectList === 1 ? "Body" : "Face"}LogVO`]?.age || "";
          return renderTooltipCell(age);
        },
      },
      {
        field: "gender",
        headerName: t("RTMT0016"),
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => {
          const gender =
            e.row[`event${selectList === 1 ? "Body" : "Face"}LogVO`]?.gender ||
            "";
          return renderTooltipCell(gender);
        },
      },
      {
        field: "glasses",
        headerName: t("RTMT0017"),
        flex: 1,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: (e) => {
          const glasses =
            e.row[`event${selectList === 1 ? "Body" : "Face"}LogVO`]?.glasses ||
            "";
          return renderTooltipCell(glasses);
        },
      },
      {
        field: "hat",
        headerName: t("RTMT0018"),
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => {
          const hat =
            e.row[`event${selectList === 1 ? "Body" : "Face"}LogVO`]?.hat || "";
          return renderTooltipCell(hat);
        },
      },
      {
        field: "scene",
        headerName: t("RTMT0032"),
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (e) => {
          const scene =
            e.row[`event${selectList === 1 ? "Body" : "Face"}LogVO`]?.scene ||
            "NA";
          return renderTooltipCell(scene);
        },
      }
    );
  }

  /**
   * Crocess the line 应该拥有的字段
   */

  if (selectList === 3) {
    columns.push(
      {
        field: "height",
        headerName: t("RTMT0026"),
        flex: 1,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: (e) => {
          const height = e.row.eventOverLogVO?.height || "";
          return renderTooltipCell(height);
        },
      },
      {
        field: "eventType",
        headerName: t("RTMT0027"),
        flex: 1,
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: (e) => {
          const eventType = e.row.eventOverLogVO?.eventType || "";
          return renderTooltipCell(eventType);
        },
      }
    );
  }

  /**
   * 货架分析应该有的字段
   */
  if (selectList === 4) {
    columns.push(
      {
        field: "registerPhoto",
        headerName: t("RTMT0005"),
        flex: 1,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: (e) => {
          // const registerPhotoUrl = e.row.eventShelfLogVO?.registerPhoto || "";
          return (
            // <Avatar
            //   sx={{
            //     width: 40,
            //     height: 40,
            //     cursor: "pointer",
            //   }}
            //   alt="Remy Sharp"
            //   src={registerPhotoUrl}
            //   variant="square"
            //   onClick={() => handleImageClick(registerPhotoUrl)}
            // />

            <AccessControl authorization={"1034"}>
              <Button onClick={() => handleImageClick(e.row.id, 1)}>
                {t("MATADATA04")}
              </Button>
            </AccessControl>
          );
        },
      },

      {
        field: "typeName",
        headerName: t("RTMT0029"),
        flex: 1,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: (e) => {
          const typeName = e.row.eventShelfLogVO?.shelfEventType || "";
          return renderTooltipCell(typeName);
        },
      },
      {
        field: "ProductName",
        headerName: t("RTMT0030"),
        flex: 1,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: (e) => {
          const typeName = e.row.eventShelfLogVO?.productName || "";
          return renderTooltipCell(typeName);
        },
      }
    );
  }

  return columns;

  return columns;
};
