import React from "react";
import RightViewLayout from "../../components/RighViewLayout";
import { Grid, Box, Typography } from "@mui/material";
import CustomInput from "../../components/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import {
  REACT_DASHBOARD,
  REACT_VIEW_USER_PROFILE,
} from "@/router/ReactEndPoints";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import UserService from "../../services/UserService";
import ViewPage from "../../components/ViewPage";
import CommonUtil from "../../util/CommonUtils";
export default function ViewUserProfile() {
  const { t } = useTranslation();
  var data = localStorage.getItem("USER");
  var parseData = JSON.parse(data);
  const navigate = useNavigate();
  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    address: "",
    phoneCountryCode: "+91-",
  });

  useEffect(() => {
    let user = CommonUtil.decodeToken();
    if (user) {
      getUser(user?.id, true);
    }
  }, []);

  const getUser = async (id, loadProfileImage) => {
    await UserService.getUserDetails(id, loadProfileImage).then((res) => {
      // console.log(res?.data?.data);

      setPayload({
        ...payload,
        ...res?.data?.data,
      });
    });
  };

  return (
    <RightViewLayout navigateBack={"-1"} title={t("LVLDAC0033")}>
      <ViewPage>
        <Box>
          <Typography variant="fieldLabel">{t("LVLRCP014")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload.firstName ? payload.firstName : "-"}
          {/* {parseData && parseData.firstName ? parseData.firstName : "-"} */}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLRCP015")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload.lastName ? payload.lastName : "-"}
          {/* {parseData && parseData.lastName ? parseData.lastName : "-"} */}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLRCP004")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload.email ? payload.email : "-"}
          {/* {parseData && parseData.email ? parseData.email : "-"} */}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVL0018")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload.phone ? payload.phone : "-"}
          {/* {parseData && parseData.phone ? parseData.phone : "-"} */}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLDAC0023")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload.country ? payload.country : "-"}
          {/* {parseData && parseData.country ? parseData.country : "-"} */}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLDAC0024")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload.state ? payload.state : "-"}
          {/* {parseData && parseData.state ? parseData.state : "-"} */}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLDAC0025")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload.city ? payload.city : "-"}
          {/* {parseData && parseData.city ? parseData.city : "-"} */}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLOT0012")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload.addressLine1 ? payload.addressLine1 : "-"}
          {/* {parseData && parseData.address ? parseData.address : "-"}  */}
        </Typography>
      </ViewPage>
    </RightViewLayout>
  );
}
