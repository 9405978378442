/**
 * 路由守卫校验
 */
import React, { useEffect } from "react";
import {
  Route,
  Redirect,
  Routes,
  useRoutes,
  useNavigate,
} from "react-router-dom";
import Login from "@/pages/onboard/Login";
function RouterWaiter({ routerConfig }) {
  const navigate = useNavigate();
  useEffect(() => {
    const isToken = localStorage.getItem("USER_TOKEN_RETAIL_AI");
    if (!isToken && window.location.pathname !== "/jump") {
      navigate("/");
      return <Route path="/" element={<Login />} />;
    }
  }, []);
  return (
    <Routes>
      {routerConfig.map((item) => {
        return (
          <Route key={item.path} path={item.path} element={item.comment} />
        );
      })}
    </Routes>
  );
}

export default RouterWaiter;
