import { Box, Grid, Button } from "@mui/material";
import { useState, useEffect, useRef, useContext } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FormatPreValue from "../../demographic/Component/FormatPreValue";
import { Tooltip, Typography } from "@mui/material";
import { VisitorDemographicContext } from "../DemographicSummary";
import {
  headerText,
  buttonStyles,
  timeBoxStyle,
  singleBoxStyle,
  selectMenuStyle,
  getBgStyle,
  tbodyStyle,
  outerBoxStyle,
  totalStyle,
} from "../../css/DemoGraphicDataAnaysis"; // 引入样式对象
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { parseNumber } from "@/util/parseNumber";

function DataAnaysis() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [compareType, setCompareType] = useState("none");
  const [openTime, setOpenTime] = useState(false); // 是否打开TIme 按钮下拉选择
  const [hiddenButton, setHiddenButton] = useState(1);
  const { visitorDemographic, setSelectedTime, selectTime } = useContext(
    VisitorDemographicContext
  );
  const viewTime = Object.keys(visitorDemographic);
  useEffect(() => {
    if (selectTime == 1) {
      setHiddenButton(1);
    } else {
      setHiddenButton(2);
    }
  }, [selectTime]);

  const columns = [
    {
      field: "F0",
      headerName: "F0",
      age: t("PCS69") + " 0 -11",
    },
    {
      field: "F1",
      headerName: "F1",
      age: t("PCS69") + " 12 - 17",
    },
    {
      field: "F2",
      headerName: "F2",
      age: t("PCS69") + " 18 -34",
    },
    {
      field: "F3",
      headerName: "F3",
      age: t("PCS69") + " 35 -54",
    },
    {
      field: "F4",
      headerName: "F4",
      age: t("PCS69") + " 55+",
    },

    {
      field: "M0",
      headerName: "M0",
      age: t("PCS69") + " 0 -11",
    },
    {
      field: "M1",
      headerName: "M1",
      age: t("PCS69") + " 12 - 17",
    },
    {
      field: "M2",
      headerName: "M2",
      age: t("PCS69") + "18 -34",
    },
    {
      field: "M3",
      headerName: "M3",
      age: t("PCS69") + "35 -54",
    },
    {
      field: "M4",
      headerName: "M4",
      age: t("PCS69") + " 55+",
    },
    {
      field: "total",
      headerName: t("PCS11"),
    },
  ];

  //Time 按钮下拉选择 2 4 6小时
  const handleSelectValue = (value) => {
    setSelectedTime(value);
    setOpenTime(false);
  };

  const box1Ref = useRef(null);
  const box2Ref = useRef(null);

  const handleScroll = (e) => {
    if (e.target === box1Ref.current) {
      // 如果是第一个盒子的滚动，应用到第二个盒子上
      box2Ref.current.scrollTop = e.target.scrollTop;
    } else if (e.target === box2Ref.current) {
      // 如果是第二个盒子的滚动，应用到第一个盒子上
      box1Ref.current.scrollTop = e.target.scrollTop;
    }
  };

  return (
    <Grid
      display={"flex"}
      position={"relative"}
      justifyContent={"space-evenly"}
      container
    >
      {/* 右侧时间展示栏 */}
      <Box xs={2} item>
        {hiddenButton === 1 ? (
          <Button
            variant="contained"
            sx={buttonStyles}
            onClick={() => setOpenTime((prevOpenTime) => !prevOpenTime)}
          >
            <Box sx={{ margin: "0vh 0 0 2vw" }}>{t("PCS65")}</Box>
            <Box sx={{ margin: "1vh 0 0 1.5vw" }}>
              {openTime ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Box>
          </Button>
        ) : (
          <Button variant="contained" sx={buttonStyles} fontSize={22}>
            {t("PCS32")}
          </Button>
        )}

        {hiddenButton === 1 && openTime ? (
          <Box
            width={210}
            sx={selectMenuStyle}
            fontSize={26}
            zIndex={1111111111}
          >
            <Box sx={outerBoxStyle}>
              <Box
                sx={singleBoxStyle}
                value={1}
                onClick={() => handleSelectValue(1)}
              >
                2 Hours
              </Box>
            </Box>
            <Box sx={outerBoxStyle}>
              <Box
                sx={singleBoxStyle}
                value={2}
                onClick={() => handleSelectValue(2)}
              >
                4 Hours
              </Box>
            </Box>
            <Box sx={outerBoxStyle}>
              <Box
                sx={singleBoxStyle}
                value={3}
                onClick={() => handleSelectValue(3)}
              >
                6 Hours
              </Box>
            </Box>
          </Box>
        ) : null}

        <Box sx={timeBoxStyle} ref={box1Ref} onScroll={handleScroll}>
          {viewTime?.map((tiem, index) => {
            return (
              <Box key={index} sx={singleBoxStyle} fontSize={20} ml={4}>
                {tiem?.replace(/-/g, "/")}
              </Box>
            );
          })}
        </Box>
      </Box>

      {/* 左侧数据分析的表格 */}
      <Grid mt={2} xs={10} item overflowX={"auto"}>
        <table>
          <Box
            sx={{
              background: "#FFF",
              boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.4)",
              borderRadius: "10px",
              whiteSpace: "nowrap",
              width: "100%",
            }}
          >
            <thead>
              <tr>
                {columns.map((header) => {
                  if (compareType === "none") {
                    return (
                      <th
                        style={{
                          ...headerText,
                        }}
                        key={header.headerName}
                      >
                        <Box
                          sx={{
                            fontWeight: 700,
                            display: "flex",
                            whiteSpace: "nowrap",
                            fontSize: "0.3rem",
                            width: "5.8vw",
                            justifyContent: "space-around",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: header.headerName,
                          }}
                        ></Box>
                        <div
                          style={{
                            fontSize: "0.3rem",
                          }}
                        >
                          {header.age}
                        </div>
                      </th>
                    );
                  }
                })}
              </tr>
            </thead>
          </Box>

          <Box ref={box2Ref} sx={tbodyStyle} onScroll={handleScroll}>
            <tbody>
              {Object.keys(visitorDemographic).map((row, index) => {
                let col = visitorDemographic[row];
                return (
                  <tr key={index}>
                    {col?.map((item, index) => {
                      if (item.totalCount !== null) {
                        return (
                          <td
                            style={{
                              backgroundColor: "#ffffff",
                              textAlign: "center",
                              // overflowX: "auto",
                            }}
                            key={index}
                          >
                            <Grid display={"flex"} sx={totalStyle}>
                              <Box
                                sx={{
                                  fontSize: "0.3rem",
                                }}
                                fontWeight={600}
                                lineHeight={2}
                                ml={2}
                              >
                                {parseNumber(item.totalCount)}
                              </Box>
                              <Box
                                sx={{
                                  fontSize: "0.2rem",
                                }}
                                fontWeight={600}
                                mt={-1}
                                ml={-4}
                              >
                                <FormatPreValue value={item.totalGrowth}>
                                  {item.totalGrowth}
                                </FormatPreValue>
                              </Box>
                            </Grid>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            style={{
                              ...getBgStyle(item.growth),
                            }}
                            key={index}
                          >
                            <Box
                              display={"flex"}
                              textAlign={"center"}
                              width={"5.8vw"}
                            >
                              <Box
                                fontWeight={600}
                                sx={{
                                  fontSize: "0.3rem",
                                }}
                                lineHeight={3.3}
                                ml={2}
                              >
                                {item.percentage}%
                              </Box>
                              <Box
                                sx={{
                                  fontSize: "0.2rem",
                                }}
                                fontWeight={700}
                                mt={0.5}
                                ml={-2}
                              >
                                <FormatPreValue
                                  value={item.growth}
                                ></FormatPreValue>
                              </Box>
                            </Box>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Box>
        </table>
      </Grid>
    </Grid>
  );
}

export default DataAnaysis;

// else {
//   if (expendRow === header.field) {
//     return (
//       <th key={header.headerName}>
//         <div
//           style={{
//             height: "30px",
//             justifyContent: "center",
//             alignItems: "center",
//             ...headerText,
//           }}
//         >
//           <div>{header.headerName}</div>
//           <div>
//             {expendRow === header.field ? (
//               <IconIcafe
//                 onClick={() => {
//                   clickExpend(header.field);
//                 }}
//                 style={expenBtn}
//                 height={6}
//                 width={18}
//               />
//             ) : (
//               <IconIcafeFd
//                 onClick={() => {
//                   clickExpend(header.field);
//                 }}
//                 style={expenBtn}
//                 height={6}
//                 width={18}
//               />
//             )}
//           </div>
//         </div>
//         <div style={{ ...headerText }}>{header.age}</div>
//         <div
//           style={{
//             borderTop: "1px solid rgb(255, 255, 255)",
//             height: "40px",
//             justifyContent: "center",
//             alignItems: "center",
//             ...headerText,
//           }}
//         >
//           <div
//             style={{
//               ...valueExpendStyle,
//               width: "40%",
//             }}
//           >
//             Select Date Range
//           </div>
//           <div
//             style={{
//               ...valueExpendStyle,
//               width: "30%",
//             }}
//           >
//             Previous Period
//           </div>
//           <div
//             style={{
//               ...valueExpendStyle,
//               width: "30%",
//             }}
//           >
//             Change %
//           </div>
//         </div>
//       </th>
//     );
//   } else {
//     return (
//       <th key={header.headerName}>
//         <div style={{ height: "80px" }}>
//           <div
//             style={{
//               justifyContent: "center",
//               alignItems: "center",
//               ...headerText,
//               height: "40px",
//               lineHeight: "40px",
//             }}
//           >
//             <div>{header.headerName}</div>
//             {header.field !== "time" &&
//               header.field !== "total" && (
//                 <div>
//                   {expendRow === header.field ? (
//                     <IconIcafe
//                       onClick={() => {
//                         clickExpend(header.field);
//                       }}
//                       style={expenBtn}
//                       height={6}
//                       width={18}
//                     />
//                   ) : (
//                     <IconIcafeFd
//                       style={expenBtn}
//                       onClick={() => {
//                         clickExpend(header.field);
//                       }}
//                       height={6}
//                       width={18}
//                     />
//                   )}
//                 </div>
//               )}
//           </div>

//           <div style={{ ...headerText, fontSize: "0.4rem" }}>
//             {header.age}
//           </div>
//         </div>
//       </th>
//     );
//   }
// }
