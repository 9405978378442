import { useFormik } from "formik";
import CmpFormik from "./CmpFormik";
import { Button, Grid } from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
const SearchForm = forwardRef((props, ref) => {
  const {
    formConfig,
    searchFn = () => {},
    loadData,
    setQueryParams,
    ...other
  } = props;

  let initParams = {};
  const { t } = useTranslation();

  formConfig?.forEach((item) => {
    if (item.type === "dataRange") {
      initParams[item.startKey] = item.defaultStart ? item.defaultStart : "";
      initParams[item.endKey] = item.defaultEnd ? item.defaultEnd : "";
    } else {
      initParams[item.name] = item.defaultValue ? item.defaultValue : "";
    }
  });

  const searchFormik = useFormik({
    initialValues: {
      ...initParams,
    },
    onSubmit: (values) => {
      if (searchFn) {
        searchFn(values);
      }
    },
  });

  return (
    <CmpFormik
      sx={2}
      {...other}
      labelPostion="left"
      formik={searchFormik}
      formConfig={formConfig}
    >
      <Grid mt={2.5} ml={5}>
        <Button type="submit" variant="contained">
          {t("LVLGF0009")}
        </Button>
        <Button
          variant="outlined"
          sx={{
            ml: 2,
          }}
          onClick={() => {
            searchFormik.resetForm();
            setQueryParams({});
          }}
        >
          {t("LVLGF0008")}
        </Button>
      </Grid>
    </CmpFormik>
  );
});

SearchForm.displayName = SearchForm;
export default SearchForm;
