import api from "../configurations/http-common";

//获取套餐包列表
export const getPackageList = () => {
  return api.securedAxios().get(`/web/package/list`);
};

//获取货币单位
export const getUnit = () => {
  return api.securedAxios().get(`/web/sub/currency/unit`);
};

//保存订阅
export const SaveSubscription = (params) => {
  return api.securedAxios().post(`/web/sub/save`, params);
};

//获取时区接口
export const getZoonTime = () => {
  return api.securedAxios().get("/web/timezone");
};

//上传logo或者合同接口
export const uploadData = (formData) => {
  return api.securedAxios().post(`/web/sub/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
//获取订阅记录列表接口
export const loadRecordsList = (params) => {
  return api.securedAxios().get("/web/sub/page", {
    params,
  });
};

//升级订阅接口
export const UpdateRecord = (payload) => {
  return api.securedAxios().post(`/web/sub/upgrade`, payload);
};

//增加设备数量接口
export const addDeviceNum = (payload) => {
  return api.securedAxios().post("/web/sub/device_num", payload);
};

//增加套餐有效期
export const addExtend = (payload) => {
  return api.securedAxios().post("/web/sub/extend", payload);
};

//查看零售商设备数量
export const userSubscribeInfo = () => {
  return api.securedAxios().get("/web/sub/package/info");
};
