import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Typography,
  Autocomplete,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PasswordField from "../../components/PasswordField";
import OnboardLayout from "@/layout/components/OnboardLayout";
import CustomInput from "../../components/CustomInput";
import { ReactComponent as VisibilityIcon } from "@/assets/images/icon_viewoff.svg";
import { ReactComponent as VisibilityOffIcon } from "@/assets/images/icon_viewon.svg";
import CommonUtil from "../../util/CommonUtils";
import { ReactComponent as Phone } from "@/assets/images/icon_phone.svg";
import { ReactComponent as Email } from "@/assets/images/icon_email.svg";

import CustomePhoneFiled from "../../components/CustomePhoneFiled";

export default function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const forgotPasswordSubmit = () => {};

  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    organization: "",
    account: "",
    country: "",
    password: "",
    countryCode: "",
    confirmPassowrd: "",
  });

  const [organizationList, setOrganizationList] = useState([
    {
      name: "org1",
      value: "org1",
    },
    {
      name: "org2",
      value: "org2",
    },
  ]);
  const [organization, setOrganization] = useState({});

  const [countryForClient, setCountryForClient] = useState({});
  const [countries, setCountries] = useState([]);

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    organization: "",
    account: "",
    country: "",
    password: "",
    confirmPassowrd: "",
    countryCode: "",
  });

  const [visibility, setVisibility] = React.useState(true);
  const [comfirmVisibility, setComfirmVisibility] = React.useState(true);

  const [userNameType, setUserNameType] = useState("Email");

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  const fmt = 1.5;

  const validatePayload = () => {
    if (userNameType === "Email") {
      if (CommonUtil.isEmptyString(payload.userName)) {
        setError({
          ...error,
          userName: t("LVL0001"),
        });
        return false;
      }
      if (!CommonUtil.isValidEmail(payload.userName)) {
        setError({
          ...error,
          userName: t("LVL0003"),
        });
        return false;
      }
    } else {
      if (CommonUtil.isEmptyString(payload.userName)) {
        setError({
          ...error,
          userName: t("LVL0001"),
        });
        return false;
      } else {
        // if (CommonUtil.isEmptyString(countryCode) || countryCode === "-") {
        //   setError({
        //     ...error,
        //     userName: t("LVL0007"),
        //   });
        //   return false;
        // }
        if (payload.userName.length < 8 || payload.userName.length > 25) {
          setError({
            ...error,
            userName: t("LVL0008"),
          });
          return false;
        }
      }
    }

    if (CommonUtil.isEmptyString(payload.password)) {
      setError({
        ...error,
        password: t("LVL0001"),
      });
      return false;
    }

    return true;
  };

  const handleSubmit = () => {};

  const onCountryCodeChange = () => {};

  return (
    <OnboardLayout>
      <Box
        sx={{
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignContent: "center",
          pb: 1,
        }}
      >
        <Typography variant="title">{t("LVLDB0031")}</Typography>
      </Box>

      <Box
        sx={{
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignContent: "center",
        }}
      >
        <Typography variant="body1">{t("PLACE014")}</Typography>
      </Box>

      <Box sx={{ mt: fmt }}>
        <CustomInput
          required
          label={t("LVLRCP014")}
          size="small"
          name="firstName"
          error={error.firstName}
          value={payload.firstName}
          handleChange={handleChange}
          inputProps={{
            maxLength: 60,
          }}
          helperText={error.firstName}
        />
      </Box>

      <Box sx={{ mt: fmt }}>
        <CustomInput
          required
          label={t("LVLRCP015")}
          size="small"
          name="lastName"
          error={error.lastName}
          value={payload.lastName}
          handleChange={handleChange}
          inputProps={{
            maxLength: 60,
          }}
          helperText={error.lastName}
        />
      </Box>

      <Box sx={{ mt: fmt }}>
        <Typography sx={{ fontSize: 13, mb: 1 }}>
          {t("RTMT0014")} <span style={{ color: "red" }}>*</span>
        </Typography>
        <Autocomplete
          noOptionsText={"LVLRCP021"}
          options={organizationList}
          value={organization}
          onChange={(e, v) => {
            setPayload({
              ...payload,
              [organization]: v.value,
            });
            setError({
              ...error,
              [organization]: "",
            });

            setOrganization(v);
          }}
          getOptionLabel={(option) => (option.name ? option.name : "")}
          renderInput={(params) => <TextField {...params} size="small" />}
        ></Autocomplete>
      </Box>

      <Box
        component="form"
        noValidate
        sx={{
          mt: fmt,
          display: "flex",
          gridTemplateColumns: { sm: "1fr 1fr" },
          gap: 2,
        }}
      >
        {userNameType === "Email" ? (
          <>
            <CustomInput
              validation="email"
              inputProps={{
                maxLength: 36,
              }}
              label={t("LVLRCP004")}
              size="small"
              required
              name="account"
              error={error.account}
              value={payload.account}
              placeholder="Email"
              id="bootstrap-input"
              fullWidth={true}
              handleChange={handleChange}
            />
            <Tooltip title={t("PLACE015")}>
              <Phone
                onClick={() => setUserNameType("Phone")}
                style={{
                  height: "45px",
                  width: "45px",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </>
        ) : (
          <>
            <Box>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ marginBottom: "-14px" }}
              >
                <span style={{ fontSize: "16px" }}>{t("LVLDAC0021")}</span>
                &nbsp;
                {
                  <span
                    style={{
                      fontSize: "19px",
                      color: "red",
                      marginLeft: "-4px",
                    }}
                  >
                    *
                  </span>
                }
              </InputLabel>

              <CustomePhoneFiled
                error={error.account}
                handleCountryCode={(data) => {
                  onCountryCodeChange(data.dialCode);
                }}
                countryCode={payload.countryCode}
                placeholder={t("LVLDAC0021")}
                phoneNumber={payload.account}
                name={"phoneNumber"}
                size={"small"}
                handleChange={(e) => {
                  setPayload({
                    ...payload,
                    account: e,
                  });
                  setError({
                    ...error,
                    account: "",
                  });
                }}
                label={t("LVLDAC0021")}
                // marginLeft={props.marginLeft}
              />
            </Box>

            <Tooltip title={t("PLACE016")}>
              <Phone
                onClick={() => setUserNameType("Email")}
                style={{
                  height: "45px",
                  width: "45px",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </>
        )}
      </Box>

      <Box sx={{ mt: fmt }}>
        <Typography sx={{ fontSize: 13, mb: 1 }}>
          {t("LVLDAC0023")} <span style={{ color: "red" }}>*</span>
        </Typography>
        <Autocomplete
          noOptionsText={t("LVLGF0011")}
          options={countries}
          value={countryForClient}
          onChange={(e, v) => {
            setCountryForClient(v);
          }}
          getOptionLabel={(option) => (option.name ? option.name : "")}
          renderInput={(params) => <TextField {...params} size="small" />}
        ></Autocomplete>
      </Box>

      <Box sx={{ mt: fmt }}>
        <CustomInput
          label={t("LVL0019")}
          size="small"
          required
          placeholder={t("LVL0020")}
          id="bootstrap-input"
          name="password"
          error={error.password}
          value={payload.password}
          handleChange={handleChange}
          type={visibility ? "password" : "text"}
          InputProps={{
            endAdornment: visibility ? (
              <VisibilityOffIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setVisibility(!visibility);
                }}
              />
            ) : (
              <VisibilityIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setVisibility(!visibility);
                }}
              />
            ),
          }}
        />
      </Box>

      <Box sx={{ mt: fmt }}>
        <CustomInput
          label={t("LVLDAC0030")}
          size="small"
          required
          placeholder={t("LVL0021")}
          id="bootstrap-input"
          name="confirmPassowrd"
          error={error.confirmPassowrd}
          value={payload.confirmPassowrd}
          handleChange={handleChange}
          type={comfirmVisibility ? "password" : "text"}
          InputProps={{
            endAdornment: comfirmVisibility ? (
              <VisibilityOffIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setComfirmVisibility(!comfirmVisibility);
                }}
              />
            ) : (
              <VisibilityIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setComfirmVisibility(!comfirmVisibility);
                }}
              />
            ),
          }}
        />
      </Box>

      <Box
        sx={{
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignContent: "center",

          pt: 1,
          pb: 0.5,
        }}
      >
        <Button
          variant="contained"
          fullWidth={true}
          onClick={() => {
            handleSubmit();
          }}
        >
          {t("LVLDB0031")}
        </Button>
      </Box>

      <Box pt={1} pb={2} sx={{ mb: 4 }}>
        <Button
          fullWidth={true}
          variant="outlined"
          sx={{ color: "black" }}
          onClick={() => navigate("/")}
        >
          {t("LVLDB0029")}
        </Button>
      </Box>
    </OnboardLayout>
  );
}
