import api from "@/configurations/http-common";
/**
 *
 * @param {列表查询接口} payload
 * @returns
 */
export const getDataList = (payload) => {
  return api.securedAxios().get("/web/thirdApplication", {
    params: payload,
  });
};

/**
 * @param {新增接口} payload
 * @returns
 */
export const handlerAddData = (payload) => {
  return api.securedAxios().post("/web/thirdApplication", payload);
};

export const handlerDeleteData = (id) => {
  return api.securedAxios().delete(`/web/thirdApplication/${id}`);
};

export const hanlerUpdata = (payload) => {
  return api.securedAxios().put("/web/thirdApplication", payload);
};
