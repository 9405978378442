import React, { useState, useEffect, useContext } from "react";
import { Box, Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import SelectOutlet from "../../Component/SelectOutlet";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AllStore from "./AllStore";
import DialogTime from "./CurrentTime";
import CompareTime from "./CompareTime";
import { headerStyle } from "../../css/peopleCounting";
import CustomCards from "../../PeopleCountingSummary/Component/CustomCards";
import { VisitorDemographicContext } from "../DemographicSummary";
import { parseNumber } from "@/util/parseNumber";
function SelectTimeRange(props) {
  const { t } = useTranslation();
  const [storeOpen, setStoreOpen] = useState(false); //所有门店下拉弹窗
  const [open, setOpen] = React.useState(false); //打开第一个时间弹窗
  const [open1, setOpen1] = useState(false);
  const [hiddenButton, setHiddenButton] = useState(1);
  const {
    startTimes,
    setStartTimes,
    endTimes,
    setEndTimes,
    timeType,
    startValueTime,
    endValueTime,
    setStartValueTime,
    setEndValueTime,
    selectTime,
    storeName,
    setStoreName,
    setSelectTime,
    setTimeType,
    loadData,
    totalNumber,
    storeList,
    isShowAllStore,
  } = useContext(VisitorDemographicContext);
  useEffect(() => {
    if (selectTime == 1) {
      setHiddenButton(1);
    } else {
      setHiddenButton(2);
    }
  }, [selectTime]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const totalValue = parseNumber(totalNumber);
  return (
    <>
      <Grid display={"flex"} justifyContent={"space-between"} xs={12}>
        <Grid display={"flex"}>
          <CustomCards
            title={t("PCS03")}
            startTimes={startTimes}
            endTimes={endTimes}
            startName={t("PCS05")}
            endName={t("PCS06")}
            onClick={handleClickOpen}
            open={open}
          ></CustomCards>

          <CustomCards
            title={t("PCS04")}
            startTimes={startValueTime}
            endTimes={endValueTime}
            startName={t("PCS05")}
            endName={t("PCS06")}
            onClick={handleClickOpen1}
            open={open1}
          ></CustomCards>

          <CustomCards
            title={t("PCS08")}
            startTimes={startTimes}
            endTimes={endTimes}
            store={true}
            storeName={storeName}
            isShowAllStore={isShowAllStore}
            onClick={() => setStoreOpen(true)}
            open={storeOpen}
          ></CustomCards>

          <Button
            variant="contained"
            sx={{
              height: "70%",
              borderRadius: "50px",
              fontWeight: 600,
              fontSize: "0.3rem",
              padding: "0 0.7rem",
              maxHeight: "80px",
              mt: 2,
            }}
            onClick={loadData}
          >
            <span dangerouslySetInnerHTML={{ __html: t("PCS10") }}></span>
          </Button>
        </Grid>

        <Grid display={"flex"}>
          <Box
            ml={2}
            fontWeight={700}
            sx={{ fontSize: "0.4rem", color: "#878787" }}
          >
            <span dangerouslySetInnerHTML={{ __html: t("PCS64") }} />
          </Box>
          <Box
            sx={{
              background: "#fff",
              padding: "0vh 1vw",
              color: "#fff",
              borderRadius: "15px",
              textAlign: "center",
              fontWeight: 700,
              ml: 2,
            }}
          >
            <span
              style={{
                background: `linear-gradient(to right, #71b652, #3599d7)`,
                WebkitBackgroundClip: "text",
                fontWeight: 700,
                WebkitTextFillColor: "transparent",
                fontSize: "1.2rem",
              }}
            >
              {totalValue}
            </span>
          </Box>
        </Grid>
      </Grid>

      {storeName && (
        <SelectOutlet
          storeOpen={storeOpen}
          setStoreOpen={setStoreOpen}
          setStoreName={setStoreName}
          storeList={storeList}
          isShowAllStore={isShowAllStore}
        />
      )}

      {/* 第一个时间选择组件 */}
      <DialogTime
        open={open}
        timeType={timeType}
        setOpen={setOpen}
        setStartTimes={setStartTimes}
        endTimes={endTimes}
        setTimeType={setTimeType}
        setEndTimes={setEndTimes}
        setStartValueTime={setStartValueTime}
        setEndValueTime={setEndValueTime}
        selectTime={selectTime}
        setSelectTime={setSelectTime}
      ></DialogTime>

      {/* 第二个时间范围选择组件 */}
      <CompareTime
        open1={open1}
        endTimes={endTimes}
        setOpen1={setOpen1}
        startTimes={startTimes}
        selectTime={selectTime}
        setStartValueTime={setStartValueTime}
        setEndValueTime={setEndValueTime}
      ></CompareTime>
    </>
  );
}

export default SelectTimeRange;

{
  /* COMPARE  WITH组件 */
}
{
  /* <Box
          sx={{ background: "#fff", whiteSpace: "nowrap", height: "1.8rem" }}
          m={1}
          borderRadius={2}
          onClick={handleClickOpen1}
        >
          <Box sx={{ fontSize: "0.4rem", padding: "0.2rem 0.2rem" }}>
            {t("PCS04")}
          </Box>
          <Grid
            display={"flex"}
            ml={1}
            xs={6}
            whiteSpace={"nowrap"}
            fontWeight={700}
            sx={{
              fontSize: "0.3rem",
            }}
          >
            <Box> {startValueTime || "START DATE"}</Box>

            {hiddenButton === 2 ? (
              <Box ml={1}>
                ~
                <span style={{ paddingLeft: "0.2rem" }}>
                  {endValueTime || "END DATE"}
                </span>
              </Box>
            ) : null}

            <Box ml={2}>{open1 ? <ExpandMoreIcon /> : <ExpandLessIcon />}</Box>
          </Grid>
        </Box> */
}

{
  /*Store 门店选择组件  */
}
{
  /* <Box
          sx={{ background: "#fff", whiteSpace: "nowrap" }}
          m={1}
          borderRadius={2}
        >
          <Box
            sx={{
              fontSize: "0.4rem",
              padding: "0.2rem 0.2rem",
            }}
          >
            {t("PCS08")}
          </Box>

          <Grid display={"flex"} onClick={() => setStoreOpen(true)}>
            <Box
              display={"flex"}
              ml={1}
              xs={4}
              whiteSpace={"nowrap"}
              fontWeight={700}
              sx={{
                fontSize: "0.3rem",
              }}
            >
              {storeName === "All" ? t("PCS09") : storeName?.name}
            </Box>
            <Box ml={2}>
              {storeOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Box>
          </Grid>
        </Box> */
}

{
  /* <Box
          sx={{ background: "#fff", whiteSpace: "nowrap", height: "1.8rem" }}
          m={1}
          borderRadius={2}
          onClick={handleClickOpen}
        >
          <Box sx={{ fontSize: "0.4rem", padding: "0.2rem 0.2rem" }}>
            {t("PCS03")}
          </Box>
          <Grid
            display={"flex"}
            ml={1}
            whiteSpace={"nowrap"}
            fontWeight={700}
            xs={4}
            sx={{
              fontSize: "0.3rem",
            }}
          >
            <Box display={"flex"}>
              {startTimes || "START DATE"}
              {hiddenButton === 2 ? (
                <Box ml={1}>
                  ~
                  <span style={{ paddingLeft: "0.2rem" }}>
                    {endTimes || "END DATE"}
                  </span>
                </Box>
              ) : null}
              <Box ml={2}>{open ? <ExpandMoreIcon /> : <ExpandLessIcon />}</Box>
            </Box>
          </Grid>
        </Box> */
}

{
  /* Total 总数展示组件 */
}
//    <Box display={"flex"}>
//    <Box ml={2} mr={2} fontWeight={700} sx={{ fontSize: "0.4rem" }}>
//      <span dangerouslySetInnerHTML={{ __html: t("PCS64") }} />
//    </Box>

//    <Box
//      sx={{
//        background: "#fff",
//        padding: "0vh 1vw",
//        color: "#6bb47e",
//        borderRadius: "15px",
//        textAlign: "center",
//        fontWeight: 700,
//      }}
//    >
//      <span
//        style={{
//          background: `linear-gradient(to right, #71b652, #3599d7)`,
//          WebkitBackgroundClip: "text",
//          fontWeight: 700,
//          WebkitTextFillColor: "transparent",
//          fontSize: "1.2rem",
//        }}
//      >
//        {totalValue}
//      </span>
//    </Box>
//  </Box>
