import React, { useState, useRef, useEffect } from "react";
import { Grid, FormHelperText } from "@mui/material";
import FileUpload from "@/components/Retail-components/FileUpload";
import { useTranslation } from "react-i18next";
import { ReactComponent as InActivePersontMenu } from "@/assets/images/UploadLogo.svg";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { handleComtractUpload } from "../js/index";
import { useSnackbar } from "notistack";
function Attachment(props) {
  const { addFormik } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Grid item xs={6}>
      <Grid
        sx={{
          marginTop: "30px",
          display: "flex",
          justifyContent: "flex-start",
          whiteSpace: "nowrap",
        }}
      >
        {addFormik.values.attachmentUrl ? (
          <>
            <a
              sx={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "flex-start",
                textDecoration: "none",
                color: "#595959",
                whiteSpace: "nowrap",
              }}
              target="_blank"
              href={addFormik.values.attachmentUrl}
              rel="noreferrer"
            >
              {t("subscription.contractFile")}
            </a>
            <DeleteForeverIcon
              onClick={() => {
                addFormik.setFieldValue("attachmentUrl", "");
              }}
            ></DeleteForeverIcon>
          </>
        ) : (
          <FileUpload
            accept={{
              "image/*": [".jpg", ".jpeg", ".png"], // 只接受图片类型
              "application/pdf": [".pdf"], // 接受 PDF 文件
              "application/msword": [".doc"], // 接受 .doc 文件（Microsoft Word 97-2003）
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                [".docx"], // 接受 .docx 文件（Microsoft Word）
            }}
            onUpload={(data) => {
              handleComtractUpload(data, 1, addFormik, enqueueSnackbar);
            }}
          >
            <Grid
              sx={{
                height: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {t("subscription.uploadContract")}
              <Grid ml={2}>
                <InActivePersontMenu></InActivePersontMenu>
              </Grid>
            </Grid>
          </FileUpload>
        )}

        {addFormik.touched.attachmentUrl && addFormik.errors.attachmentUrl && (
          <FormHelperText error id="name-error">
            {addFormik.errors.attachmentUrl}
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
}

export default Attachment;
