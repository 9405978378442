import React, { useState, useRef, useEffect, useCallback } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_DEVICE_LIST } from "@/router/ReactEndPoints";
import { useTranslation } from "react-i18next";
import CustomInput from "../../components/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import DeviceIcon from "@/assets/images/icon_device.png";
import { debounce } from "lodash";
import {
  getDeviceBySn,
  editDevice,
  getDeviceDetail,
} from "@/services/DeviceService";
import { useLocation } from "react-router-dom";
import {
  Grid,
  InputLabel,
  Autocomplete,
  TextField,
  Box,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
export default function EditDevice() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const [photoBase64, setPhotoBase64] = useState(null);
  //上传图片定义
  const fileInputRef = useRef(null);
  const imageRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (e) => {
      const imageDataURL = e.target.result;
      imageRef.current.src = imageDataURL;
      setPhotoBase64(imageDataURL);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const clickUploadImage = () => {
    fileInputRef.current.click();
  };
  //-------------------------------------------
  const [error, setError] = useState({
    // name: "",
    // addressLine1: "",
    // city: "",
    // state: "",
    // country: "",
    // areaCode: "",
    // latitude: "",
    // longitude: "",
    // timeZone: "",
    // companyId: "",
    // outletType: "",
    // cityZone: "",
  });

  const [outletName, setOutLetName] = useState(null);

  const { state } = useLocation();
  const [device, setDevice] = useState({});
  const [deviceAlias, setDeviceAlias] = useState("");
  useEffect(() => {
    setOutLetName(state?.outletName);
    setDeviceAlias(state?.deviceAlias);
  }, []);

  useEffect(() => {
    // setDevice(state);
    getDeviceDetail(state.id).then((res) => {
      setDevice(res?.data?.data);
    });
  }, []);

  const handleSubmit = async () => {
    let params = {
      id: device?.id,
      photo: photoBase64?.split(";base64,").pop(),
      deviceAlias: deviceAlias,
    };
    const res = await editDevice(params);
    if (res?.data?.code === "LVLI0000") {
      enqueueSnackbar(res.data.message, {
        variant: "success",
      });
      navigate(REACT_DEVICE_LIST);
    } else {
      enqueueSnackbar(t("LVL0028"), {
        variant: "error",
      });
    }
  };

  return (
    <RightViewLayout navigateBack={REACT_DEVICE_LIST} title={t("LVLDV0081")}>
      <Grid container style={{ backgroundColor: "#ffffff" }}>
        <Grid item xs={3}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              cursor: "pointer",
            }}
          >
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <div
              onClick={clickUploadImage}
              style={{
                width: 180,
                height: 180,
                borderRadius: "50%",
                border: "1px dashed #ccc",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <img
                ref={imageRef}
                src={state?.photo || DeviceIcon}
                style={{ width: 160, height: 160, borderRadius: "50%" }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "14px",
              color: "#aaa",
              marginTop: "40px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <span style={{ display: "block", marginTop: "20px" }}>
                {t("Allowed only *JPEG, *JPG, *PNG")}
              </span>
              <span style={{ display: "block", marginTop: "20px" }}>
                {t("Maximum size of 3MB")}
              </span>
            </div>
          </div>
        </Grid>
        <Grid item xs={9}>
          <Grid mt={1} container spacing={2} px={2}>
            <Grid item md={6} xs={12}>
              <CustomInput
                disabled
                label={t("LVLDV0082")}
                size="small"
                name="name"
                // error={error.name}
                value={device?.id || ""}
                inputProps={{
                  maxLength: 60,
                }}
                helperText={error.name}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInput
                disabled
                label={t("LVLDV0083")}
                size="small"
                name="name"
                // error={error.name}
                value={device?.sn || ""}
                inputProps={{
                  maxLength: 60,
                }}
                helperText={error.name}
              />
            </Grid>
          </Grid>
          <Grid mt={1} container spacing={2} px={2}>
            <Grid item md={6} xs={12}>
              <CustomInput
                label={t("LVLDV0084")}
                size="small"
                name="name"
                // error={error.name}
                value={deviceAlias || ""}
                inputProps={{
                  maxLength: 60,
                }}
                handleChange={(e) => setDeviceAlias(e.target.value)}
                helperText={error.name}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInput
                disabled
                label={t("LVLDV0085")}
                size="small"
                name="name"
                // error={error.name}
                value={device?.deviceModel || ""}
                inputProps={{
                  maxLength: 60,
                }}
                helperText={error.name}
              />
            </Grid>
          </Grid>
          <Grid mt={1} container spacing={2} px={2}>
            <Grid item md={6} xs={12}>
              {/* <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ paddingLeft: "0px" }}
              >
                {t("Device Type")}
                <span style={{ color: "red" }}>*</span>
              </InputLabel> */}

              <CustomInput
                label={t("LVLDV0086")}
                size="small"
                name="name"
                disabled
                // error={error.name}
                value={device?.deviceType || ""}
                inputProps={{
                  maxLength: 60,
                }}
                helperText={error.name}
              />

              {/* <Autocomplete
                options={[]}
                value={[]}
                onChange={(e, v) => {}}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                renderInput={(params) => {
                  
                  return (
                    <TextField
                      {...params}
                      size="small"
                      error={""}
                      helperText={""}
                    />
                  );
                }}
              ></Autocomplete> */}
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInput
                label={t("LVLDV0087")}
                size="small"
                name="name"
                disabled
                // error={error.name}
                value={device?.clientName || ""}
                inputProps={{
                  maxLength: 60,
                }}
                helperText={error.name}
              />
            </Grid>
          </Grid>
          <Grid mt={1} container spacing={2} px={2}>
            <Grid item md={6} xs={12}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ paddingLeft: "0px" }}
              >
                {t("LVLDV0088")}
                <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Autocomplete
                options={[]}
                value={outletName || ""}
                disabled
                onChange={(e, v) => {
                  setOutLetName(v);
                }}
                getOptionLabel={(option) => (option ? option : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={""}
                    helperText={""}
                  />
                )}
              ></Autocomplete>
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInput
                disabled
                label={t("LVLDV0089")}
                size="small"
                name="name"
                // error={error.name}
                value={device?.outletId || ""}
                inputProps={{
                  maxLength: 60,
                }}
                helperText={error.name}
              />
            </Grid>
          </Grid>
          <Grid mt={1} container spacing={2} px={2}>
            <Grid item md={12} xs={12}>
              <CustomInput
                disabled
                label={t("LVLDV0090")}
                size="small"
                name="name"
                // error={error.name}
                value={device?.status}
                inputProps={{
                  maxLength: 120,
                }}
                helperText={error.name}
              />
            </Grid>
          </Grid>

          <Box display={"flex"} flexDirection={"row-reverse"} m={3}>
            <Button
              variant="contained"
              size="large"
              className="text-transform-none"
              onClick={debounce(handleSubmit, 1000)}
              sx={{ marginLeft: "1vw" }}
            >
              {t("LVL0013")}
            </Button>

            <Button
              className="text-transform-none"
              variant="outlined"
              onClick={() => navigate(REACT_DEVICE_LIST)}
              size="large"
            >
              {t("LVLDB0012")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
