import {
  InputLabel,
  Stack,
  Autocomplete,
  TextField,
  FormHelperText,
} from "@mui/material";
import RequirePoint from "@/components/Retail-components/RequirePoint";
import { useEffect, useState } from "react";

function PersonAutocomplete(props) {
  const {
    formik = null,
    placeholder = "",
    handleBlur,
    handleChange,
    label,
    name,
    error,
    disabled = false,
    isClear = true,
    labelPostion,
    spacing = 1,
    width,
    height = "40px",
    inputType,
    options,
    fontSize = "22px",
    readonly,
    typeValue = "0",
    ...orther
  } = props;

  const [data, setData] = useState(null);

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
    if (userInfo?.clientId) {
      const list = options.find((item) => item.id === userInfo?.clientId);
      setData(list);
      formik.setFieldValue(name, userInfo?.clientId);
    } else {
      const list = options.find((item) => item.id === formik.values[name]);

      setData(list);
      // formik.setFieldValue(name, list);
    }
  }, [options]);
  return (
    <Stack>
      <Stack
        direction={labelPostion === "left" ? "row" : "column"}
        sx={{
          alignItems: labelPostion === "left" ? "flex-start" : "",
        }}
        spacing={spacing}
      ></Stack>
      <InputLabel
        shrink
        htmlFor={"CmpAutoComPlete_" + name}
        style={{
          marginTop: labelPostion === "left" ? "12px" : "",
          width: width,
          fontSize: fontSize,
        }}
      >
        {props.required && <RequirePoint></RequirePoint>}
        {label}
      </InputLabel>
      <Stack
        sx={{
          flexGrow: 1,
          width: "100%",
        }}
      >
        <Autocomplete
          disablePortal
          id={props.id}
          fullWidth
          options={options || []}
          onChange={(event, newValue) => {
            formik.setFieldValue(name, newValue?.id);
            setData(newValue);
          }}
          value={data}
          disabled={disabled}
          name={name}
          isOptionEqualToValue={(option, value) => (option.id ? option.id : "")}
          getOptionLabel={(option) => (option ? option?.name : "")}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              readonly={readonly}
              placeholder={placeholder}
            />
          )}
          {...orther}
        />
        {((formik?.touched[name] && formik?.errors[name]) || error) && (
          <FormHelperText error id={`standard-weight-helper-text-${name}`}>
            {formik?.errors[name] || error}
          </FormHelperText>
        )}
      </Stack>
    </Stack>
  );
}

export default PersonAutocomplete;
