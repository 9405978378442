import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/onboard/Login";
// import AIMainLayout from "../components/layout-components/AIMainLayout";
import AIMainLayout from "../layout/AIMainLayout";

// import AiDashboard from "./pages/dashboard/AiDashboard";
import {
  REACT_DASHBOARD,
  REACT_DEVICE_ADD,
  REACT_DEVICE_ADD_INSTRUCTIONS,
  REACT_DEVICE_LIST,
  REACT_OUTLET_ADD,
  REACT_OUTLET_EDIT,
  REACT_OUTLET_LIST,
  REACT_OUTLET_VIEW,
  REACT_PERSON_LIST,
  REACT_PRINCIPAL_ADD,
  REACT_PRINCIPAL_MANAGEMENT,
  REACT_RETAIL_CLIENT_ADD,
  REACT_RETAIL_CLIENT_LIST,
  REACT_RETAIL_CLIENT_UPDATE,
  REACT_RETAIL_CLIENT_VIEW,
  REACT_VIEW_COMPANY_PROFILE,
  REACT_VIEW_USER_PROFILE,
  REACT_UPDATE_USER_PROFILE,
  RETAIL_CLIENT,
  REACT_METADATA_LIST,
  REACT_PERSON_ADD,
  REACT_PERSON_VIEW,
  REACT_PERSON_EDIT,
  REACT_PRODUCT_LIST,
  REACT_PRODUCT_Add,
  REACT_PRODUCT_EDIT,
  REACT_DEVICE_DETAILS,
  REACT_DEVICE_EDIT,
  REACT_DEVICE_SETTING,
  REACT_DEVICE_SKU,
  REACT_REGISTER,
  REACT_FORGOT_PASSWORD,
  REACT_PEOPLECOUNTING_SUMMARY,
  REACT_SHELFMONITORING_SUMMARY,
  REACT_DEMOGRAPHIC_SUMMARY,
  REACT_PEOPLECOUNTING,
  REACT_SHELFMONITORING,
  REACT_DEMOGRAPHIC,
  REACT_PRINCIPAL_VIEW,
  REACT_PRINCIPAL_UPDATE,
  REACT_LOCATION,
  REACT_AUTHORITATION,
  REACT_ADD_AUTHORITATION,
  REACT_VIEW_AUTHORITATION,
  REACT_EDIT_AUTHORITATION,
  REACT_CHANNEL_SETTING,
  REACT_OPEN_API_LIST,
  REACT_OPEN_API_ADD,
  REACT_JUMP,
  REACT_TENANT_MANAGER_LIST,
  REACT_TENANT_MANAGER_ADD,
  REACT_PERSON_MANAGER_LIST,
  REACT_PERSON_MANAGER_ADD,
  REACT_PERSON_MANAGER_VIEW,
  REACT_RERSON_MANAGER_EDIT,
  REACT_SUB_HISTORY_LIST,
  REACT_SUB_HISTORY_ADD,
  REACT_SUB_HANDLER_LIST,
  REACT_SUB_HANDLER_ADD,
} from "@/router/ReactEndPoints";

import RetailClient from "../pages/company/RetailClient";
import AddRetailClient from "../pages/company/AddRetailClient";
import ViewRetailClient from "../pages/company/ViewRetailClient";
import UpdateRetailClient from "../pages/company/UpdateRetailClient";
import ForgotPassword from "../pages/onboard/ForgotPassword";
import Register from "../pages/onboard/Register";
import Person from "../pages/person/Person";
import AddPerson from "../pages/person/AddPerson";
import ViewPerson from "../pages/person/ViewPerson";

import Outlet from "../pages/outlet/Outlet";
import AddOutlet from "../pages/outlet/AddOutlet";
import EditOutlet from "../pages/outlet/EditOutlet";
import ViewOutlet from "../pages/outlet/ViewOutlet";
import Device from "../pages/device/Device";
import EditDevice from "../pages/device/EditDevice";
import SettingDevice from "../pages/device/Submenu/DeviceSetting";
import DeviceSKU from "../pages/device/Component/DeviceSKU";
import DeviceDetails from "../pages/device/Submenu/DeviceDetails";
import DeviceInstructionPage from "../pages/device/Component/DeviceInstructionPage";
import BindDevice from "../pages/device/Submenu/BindDevice";
import AddPrincipal from "../pages/principal/AddPrincipal";
import PrincipalManagement from "../pages/principal/PrincipalManagement";
import ViewPrincipal from "../pages/principal/ViewPrincipal";
import UpdatePrincipal from "../pages/principal/UpdatePrincipal";

import UpdateUserProfile from "../layout/leftMenu/UpdateUserProfile";
import ViewUserProfile from "../layout/leftMenu/ViewUserProfile";
import ViewCompanyProfile from "../layout/leftMenu/ViewCompanyProfile";

import MetadataList from "../pages/metadata/MetadataList";
import ProductList from "../pages/product/ProductList";
import AddProduct from "../pages/product/AddProduct";
import EditProduct from "../pages/product/EditProduct";

import PeopleCountingSummary from "../pages/dashboard/PeopleCountingSummary/PeopleCountingSummary";
import ShelfMonitoringSummary from "../pages/dashboard/ShelfMonitoringSummary/ShelfMonitoringSummary";
import DemographicSummary from "../pages/dashboard/DemographicSummary/DemographicSummary";

import PeopleCounting from "../pages/dashboard/peopleCounting/PeopleCounting";
import ShelfMonitoring from "../pages/dashboard/ShelfMonitoring/ShelfMonitoring";
import Demographic from "../pages/dashboard/demographic/Demographic";
import Location from "../pages/location/Location";
import Authoritation from "../pages/Authoritation/Authoritation";
import AddAuthoritation from "../pages/Authoritation/AddAuthoritation";
import ViewAuthoritation from "../pages/Authoritation/ViewAuthoritation";
import EditAuthoritation from "../pages/Authoritation/EditAuthoritation";
import ChannelSetting from "../pages/device/Submenu/ChannelSetting";
import OpenAPI from "../pages/OpenAPI/index";
import OpenAdd from "../pages/OpenAPI/OpenAdd";
import RouterWaiter from "./Component/RouterWaiter";
import Jump from "@/pages/jump/index";

import TenantList from "@/pages/tenant/TenantList";
import AddTenant from "@/pages/tenant/AddTenant";

import Subscription from "@/pages/subscription/Subscription";
import AddSubscription from "@/pages/subscription/AddSubscription";

import SubscriptionRecords from "@/pages/SubscriptionRecords/SubscriptionRecords";
import ScaleContainer from "@/components/ScaleContainer";
// import PersonManager from "@/pages/PersonManager/PersonManager";
// import AddPerson from "@/pages/PersonManager/AddPerson";
// import ViewPerson from "@/pages/PersonManager/ViewPerson";
export default function AppRoutes() {
  let routerConfig = [
    {
      path: "/",
      comment: <Login />,
      needLogin: false,
    },
    // {
    //   path: REACT_DASHBOARD,
    //   comment: (
    //     <AIMainLayout>
    //       <AiDashboard />
    //     </AIMainLayout>
    //   ),
    // },

    {
      path: REACT_LOCATION,
      comment: (
        <AIMainLayout>
          <Location />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_DEMOGRAPHIC_SUMMARY,
      comment: (
        // <ScaleContainer width={window.innerWidth} height={window.innerHeight}>
        <AIMainLayout>
          <DemographicSummary />
        </AIMainLayout>
        // </ScaleContainer>
      ),
    },
    {
      path: REACT_PEOPLECOUNTING_SUMMARY,
      comment: (
        // <ScaleContainer width={window.innerWidth} height={window.innerHeight}>
        <AIMainLayout>
          <PeopleCountingSummary />
        </AIMainLayout>
        // </ScaleContainer>
      ),
    },
    {
      path: REACT_SHELFMONITORING_SUMMARY,
      comment: (
        // <ScaleContainer width={window.innerWidth} height={window.innerHeight}>
        <AIMainLayout>
          <ShelfMonitoringSummary />
        </AIMainLayout>
        // </ScaleContainer>
      ),
    },
    {
      path: REACT_DEMOGRAPHIC_SUMMARY,
      comment: (
        <AIMainLayout>
          <DemographicSummary />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_PEOPLECOUNTING,
      comment: (
        <AIMainLayout>
          <PeopleCounting />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_SHELFMONITORING,
      comment: (
        <AIMainLayout>
          <ShelfMonitoring />
        </AIMainLayout>
      ),
    },

    {
      path: REACT_DEMOGRAPHIC,
      comment: (
        <AIMainLayout>
          <Demographic />
        </AIMainLayout>
      ),
    },

    {
      path: REACT_RETAIL_CLIENT_LIST,
      comment: (
        <AIMainLayout>
          <RetailClient />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_RETAIL_CLIENT_ADD,
      comment: (
        <AIMainLayout>
          <AddRetailClient />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_RETAIL_CLIENT_VIEW + ":code",
      comment: (
        <AIMainLayout>
          <ViewRetailClient />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_RETAIL_CLIENT_UPDATE + ":code",
      comment: (
        <AIMainLayout>
          <UpdateRetailClient />
        </AIMainLayout>
      ),
    },
    {
      // path: RETAIL_CLIENT + ":name/:id/:code" + REACT_PERSON_LIST,
      path: REACT_PERSON_MANAGER_LIST,
      comment: (
        <AIMainLayout>
          <Person />
        </AIMainLayout>
      ),
    },

    {
      path: REACT_PERSON_MANAGER_ADD,
      comment: (
        <AIMainLayout>
          <AddPerson />
        </AIMainLayout>
      ),
    },

    {
      // path: RETAIL_CLIENT + ":name/:id" + REACT_PERSON_VIEW + ":data",
      path: REACT_PERSON_MANAGER_VIEW,
      comment: (
        <AIMainLayout>
          <ViewPerson />
        </AIMainLayout>
      ),
    },

    {
      path: REACT_DEVICE_DETAILS + ":sn",
      comment: (
        <AIMainLayout>
          <DeviceDetails />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_DEVICE_ADD_INSTRUCTIONS,
      comment: (
        <AIMainLayout>
          <DeviceInstructionPage />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_DEVICE_ADD,
      comment: (
        <AIMainLayout>
          <BindDevice />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_DEVICE_EDIT + ":sn",
      comment: (
        <AIMainLayout>
          <EditDevice />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_DEVICE_SETTING + ":sn",
      comment: (
        <AIMainLayout>
          <SettingDevice />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_CHANNEL_SETTING,
      comment: (
        <AIMainLayout>
          <ChannelSetting />
        </AIMainLayout>
      ),
    },

    {
      path: REACT_OUTLET_LIST,
      comment: (
        <AIMainLayout>
          <Outlet />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_OUTLET_ADD,
      comment: (
        <AIMainLayout>
          <AddOutlet />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_OUTLET_EDIT + ":id",
      comment: (
        <AIMainLayout>
          <EditOutlet />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_OUTLET_VIEW + ":id",
      comment: (
        <AIMainLayout>
          <ViewOutlet />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_DEVICE_SKU,
      comment: (
        <AIMainLayout>
          <DeviceSKU />
        </AIMainLayout>
      ),
    },

    {
      path: REACT_PRINCIPAL_MANAGEMENT,
      comment: (
        <AIMainLayout>
          <PrincipalManagement />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_PRINCIPAL_VIEW + ":code",
      comment: (
        <AIMainLayout>
          <ViewPrincipal />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_PRINCIPAL_UPDATE + ":code",
      comment: (
        <AIMainLayout>
          <UpdatePrincipal />
        </AIMainLayout>
      ),
    },

    {
      path: REACT_DEVICE_LIST,
      comment: (
        <AIMainLayout>
          <Device />
        </AIMainLayout>
      ),
    },

    {
      path: REACT_METADATA_LIST,
      comment: (
        <AIMainLayout>
          <MetadataList />
        </AIMainLayout>
      ),
    },

    {
      path: REACT_PRODUCT_LIST,
      comment: (
        <AIMainLayout>
          <ProductList />
        </AIMainLayout>
      ),
    },

    {
      path: REACT_PRODUCT_Add,
      comment: (
        <AIMainLayout>
          <AddProduct />
        </AIMainLayout>
      ),
    },

    {
      path: REACT_PRODUCT_EDIT + ":id",
      comment: (
        <AIMainLayout>
          <EditProduct />
        </AIMainLayout>
      ),
    },

    {
      path: REACT_PRINCIPAL_ADD,
      comment: (
        <AIMainLayout>
          <AddPrincipal />
        </AIMainLayout>
      ),
    },

    {
      path: REACT_UPDATE_USER_PROFILE,
      comment: (
        <AIMainLayout>
          <UpdateUserProfile />
        </AIMainLayout>
      ),
    },

    {
      path: REACT_VIEW_USER_PROFILE,
      comment: (
        <AIMainLayout>
          <ViewUserProfile />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_VIEW_COMPANY_PROFILE,
      comment: (
        <AIMainLayout>
          <ViewCompanyProfile />
        </AIMainLayout>
      ),
    },
    {
      path: REACT_FORGOT_PASSWORD,
      comment: <ForgotPassword></ForgotPassword>,
    },

    {
      path: REACT_REGISTER,
      comment: <Register></Register>,
    },

    {
      path: REACT_AUTHORITATION,
      comment: (
        <AIMainLayout>
          <Authoritation></Authoritation>
        </AIMainLayout>
      ),
    },
    {
      path: REACT_ADD_AUTHORITATION,
      comment: (
        <AIMainLayout>
          <AddAuthoritation></AddAuthoritation>
        </AIMainLayout>
      ),
    },
    {
      path: REACT_VIEW_AUTHORITATION,
      comment: (
        <AIMainLayout>
          <ViewAuthoritation></ViewAuthoritation>
        </AIMainLayout>
      ),
    },
    {
      path: REACT_EDIT_AUTHORITATION,
      comment: (
        <AIMainLayout>
          <EditAuthoritation></EditAuthoritation>
        </AIMainLayout>
      ),
    },
    {
      path: REACT_OPEN_API_LIST,
      comment: (
        <AIMainLayout>
          <OpenAPI></OpenAPI>
        </AIMainLayout>
      ),
    },
    {
      path: REACT_OPEN_API_ADD,
      comment: (
        <AIMainLayout>
          <OpenAdd></OpenAdd>
        </AIMainLayout>
      ),
    },
    {
      path: REACT_JUMP,
      comment: <Jump></Jump>,
      needLogin: false,
    },
    {
      path: REACT_TENANT_MANAGER_LIST,
      comment: (
        <AIMainLayout>
          <TenantList></TenantList>
        </AIMainLayout>
      ),
    },
    {
      path: REACT_TENANT_MANAGER_ADD,
      comment: (
        <AIMainLayout>
          <AddTenant></AddTenant>
        </AIMainLayout>
      ),
    },

    {
      path: REACT_SUB_HISTORY_LIST,
      comment: (
        <AIMainLayout>
          <SubscriptionRecords></SubscriptionRecords>
        </AIMainLayout>
      ),
    },

    {
      path: REACT_SUB_HANDLER_LIST,
      comment: (
        <AIMainLayout>
          <Subscription></Subscription>
        </AIMainLayout>
      ),
    },

    {
      path: REACT_SUB_HANDLER_ADD,
      comment: (
        <AIMainLayout>
          <AddSubscription></AddSubscription>
        </AIMainLayout>
      ),
    },
  ];

  return <RouterWaiter routerConfig={routerConfig}></RouterWaiter>;
}
