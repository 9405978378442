import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import CmpFormik from "@/components/Retail-components/CmpFormik";
import { useLocation } from "react-router-dom";
import { loadCountryData, loadStateData, loadCityData } from "../js/index";
import { getAountInfoConfig } from "../js/Config";
function AccountInfo(props) {
  const { addFormik, accountConfig, setAccountConfig } = props;
  const { t } = useTranslation();
  const { state } = useLocation();
  const [countries, setCountries] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    loadCountryData(setCountries);
  }, []);

  useEffect(() => {
    loadStateData(addFormik, setStateList);
  }, [addFormik?.values["countryId"]]);

  useEffect(() => {
    loadCityData(addFormik, setCityList);
  }, [addFormik?.values["stateId"]]);

  useEffect(() => {
    let config = getAountInfoConfig(
      t,
      state?.type,
      countries,
      stateList,
      cityList
    );
    setAccountConfig(config);
  }, [countries, stateList, cityList]);

  return (
    <Grid bgcolor={"#f7fbfe"} mt={4}>
      <Typography fontSize={16} ml={4} pt={2}>
        {t("subscription.Account_Information")}
      </Typography>

      <Grid pl={10} mr={4} mt={2} pb={2} pr={6}>
        <CmpFormik
          itemSpacing={4}
          sx={6}
          formik={addFormik}
          formConfig={accountConfig}
        ></CmpFormik>
      </Grid>
    </Grid>
  );
}

export default AccountInfo;
