import React, { useEffect, useState } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import ViewPage from "../../components/ViewPage";
import { Box, Typography } from "@mui/material";
import { getCompany } from "../../services/CompanyService";
import { useTranslation } from "react-i18next";
export default function ViewCompanyProfile() {
  const { t } = useTranslation();
  const [company, setCompany] = useState({
    name: "",
    email: "",
    phone: "",
    code: "",
  });

  useEffect(() => {
    getCompany().then((response) => {
      if (response?.data?.code === "LVLI0000") {
        const companyData = response?.data.data;
        setCompany(companyData);
      }
    });
  }, []);

  return (
    <RightViewLayout navigateBack={"-1"} title={t("LVLRC0032")}>
      <ViewPage>
        <Box>
          <Typography variant="fieldLabel">{t("LVLRC0033") + ":"}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {company?.companyName || "-"}
        </Typography>

        {/* <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLRC0034") + ":"}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {company && company.code ? company.code : "-"}
        </Typography> */}

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLRC0035") + ":"}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {company?.contactEmail || "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLRC0036") + ":"}</Typography>
        </Box>
        <Typography variant="fieldValue">
          {company?.contactPhone || "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">
            {t("tenant.address") + ":"}
          </Typography>
        </Box>
        <Typography variant="fieldValue">{company?.address || "-"}</Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("tenant.info") + ":"}</Typography>
        </Box>
        <Typography variant="fieldValue">{company?.info || "-"}</Typography>
      </ViewPage>
    </RightViewLayout>
  );
}
