import { DatePicker, ConfigProvider } from 'antd'
import { useState, useEffect } from 'react'
import {
  FormHelperText,
  InputLabel,
  OutlinedInput,
  IconButton,
  Stack,
} from '@mui/material'
import dayjs from 'dayjs'
import RequirePoint from './RequirePoint'
const { RangePicker } = DatePicker
const CmpRangePicker = (props) => {
  const {
    dateFormat = 'YYYY/MM/DD',
    formik,
    label,
    name,
    error,
    disabled = false,
    isClear = true,
    labelPostion,
    ...orther
  } = props

  const [value, setValue] = useState([])

  const onChange = (data, dataString) => {
    if (formik && data && data.length === 2) {
      let startTime = data[0].startOf('day').valueOf()
      let endTime = data[1].endOf('day').valueOf()
      formik.setFieldValue(props.startKey, startTime)
      formik.setFieldValue(props.endKey, endTime)
      setValue([dayjs(startTime), dayjs(endTime)])
    } else {
      formik.setFieldValue(props.startKey, '')
      formik.setFieldValue(props.endKey, '')
      setValue([])
    }
  }

  useEffect(() => {
    let values = formik.values
    let startValue = values[props.startKey]
    let endValue = values[props.endKey]
    if (startValue && endValue) {
      setValue([dayjs(startValue), dayjs(endValue)])
    } else {
      setValue([])
    }
  }, [formik])

  return (
    <Stack
      sx={{
        alignItems: 'center',
        mt: '5px',
      }}
      spacing={1}
    >
      <Stack
        direction={labelPostion === 'left' ? 'row' : 'column'}
        sx={{
          alignItems: labelPostion === 'left' ? 'flex-start' : '',
        }}
        spacing={1}
      >
        <InputLabel
          style={{
            marginTop: labelPostion === 'left' ? '10px' : '',
          }}
          htmlFor={'CmpInput_' + name}
        >
          {props.required && <RequirePoint></RequirePoint>} {label}
        </InputLabel>
        <Stack
          sx={{
            flexGrow: 1,
            width: '100%',
          }}
        >
          <ConfigProvider
            theme={{
              components: {
                DatePicker: {
                  colorPrimary: '#00b96b',
                  activeBorderColor: '#4DB22F',
                  hoverBorderColor: '#4DB22F',
                },
              },
            }}
          >
            <RangePicker
              activeBorderColor="red"
              style={{
                height: '35px',
              }}
              onChange={onChange}
              value={value}
              format={dateFormat}
            />
          </ConfigProvider>
        </Stack>
      </Stack>
    </Stack>
  )
}
export default CmpRangePicker
