import { Card, Grid, Typography, FormControlLabel, Radio } from "@mui/material";
import { useTranslation } from "react-i18next";
function RolesCards(props) {
  const { t } = useTranslation();
  const { Arrary, addFormik, name } = props;

  return (
    <Card elevation={0} sx={{ borderRadius: "10px", marginTop: "20px" }}>
      <Grid pt={2} marginLeft={2}>
        <Typography pb={2} fontSize={18} fontWeight={500}>
          {t("PCS74")}
        </Typography>
        <Grid container columnSpacing={2}>
          {Arrary?.map((item, index) => (
            <Grid item key={index} xs={6} sm={4} md={3} lg={2}>
              <FormControlLabel
                control={
                  <Radio
                    id={`role-radio-${index}`}
                    checked={addFormik.values[name] === item.id}
                    onChange={addFormik.handleChange}
                    value={item.id}
                    name={name}
                  />
                }
                label={item.name}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
}

export default RolesCards;
