import { Grid, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { purple } from "@mui/material/colors";
function CustomButton(props) {
  const [value, setValue] = useState("");
  const {
    variant,
    disabled,
    color,
    startIcon,
    children,
    onClick,
    type,
    label,
    sx,
  } = props;
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    "&:hover": {
      backgroundColor: purple[700],
    },
  }));
  useEffect(() => {
    if (variant === 1) {
      setValue("text");
    } else if (variant === 2) {
      setValue("contained");
    } else {
      setValue("outlined");
    }
  }, [variant]);
  return (
    <>
      <ColorButton
        type={type}
        variant={value}
        disabled={disabled}
        color={color}
        sx={sx}
        onClick={onClick}
        startIcon={startIcon}
      >
        {label}
      </ColorButton>
    </>
  );
}

export default CustomButton;
