import { Avatar, Tooltip, Grid, Box, TextField, Button } from "@mui/material";
import IconHandaler from "../../components/IconHandaler";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../components/DataTable";
import CommonUtil from "../../util/CommonUtils";
import ResetPassWord from "./Component/ResetPassWord";
import { ReactComponent as AddIcon } from "@/assets/images/icon_add.svg";
import { ReactComponent as RefreshIcon } from "@/assets/images/icon_refresh.svg";
import {
  REACT_PERSON_VIEW,
  RETAIL_CLIENT,
  REACT_PERSON_MANAGER_ADD,
} from "@/router/ReactEndPoints";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ListLayout from "../../components/ListLayout";
import { useState } from "react";
import { deletePerson, personList } from "../../services/PersonService";
import { useEffect } from "react";
import ConfirmModal from "../../components/ConfirmModel";
import { confirmAlert } from "react-confirm-alert";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { ReactComponent as ResetPassword } from "@/assets/images/ResetPassword.svg";
import {
  sendActivationMail,
  getPersonList,
} from "../../services/PersonService";
import RingLoader from "react-spinners/RingLoader";
import AccessControl from "../../components/AccessControl";
export default function Person() {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const roleCode = CommonUtil.getRoleCode();
  const [passWordOpen, setPassWordOpen] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 5,
    clientId: params.id,
  });

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 5,
    clientId: "",
  };
  const override = {
    display: "block",
    margin: "10% auto",
    borderColor: "#b37feb",
  };

  useEffect(() => {
    loadData();
  }, [filters]);

  const loadData = () => {
    getPersonList(filters).then((res) => {
      if (
        res?.data?.code === "LVLI0000" &&
        res?.data?.data &&
        res?.data?.data?.objects
      ) {
        setRecords(res?.data?.data?.objects);
        setTotalRecords(res?.data?.data?.totalCount);
      }
    });
  };

  const columns = [
    {
      field: "profilePhoto",
      headerName: `${t("LVLRCP029")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Avatar
          className="ImageContent"
          imgProps={{ draggable: "false" }}
          alt="--"
          src={e?.row?.profilePhoto}
        />
      ),
    },

    {
      field: "firstName",
      headerName: `${t("LVLDB0019")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.firstName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.firstName || "-")}</span>
        </Tooltip>
      ),
    },
    {
      field: "clientName",
      headerName: `${t("LVLDB0003")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.clientName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.clientName || "-")}</span>
        </Tooltip>
      ),
    },
    {
      field: "email",
      headerName: `${t("LVLRCP004")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.email} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.email)}</span>
        </Tooltip>
      ),
    },
    {
      field: "phone",
      headerName: `${t("LVLRCP005")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.phone} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.phone || "-")}</span>
        </Tooltip>
      ),
    },
    {
      field: "joinDate",
      headerName: `${t("LVLRCP006")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.joinDate} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.joinDate || "-")}</span>
        </Tooltip>
      ),
    },
    {
      headerName: `${t("LVLRC0010")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <IconHandaler>
          <AccessControl authorization={"1008"}>
            <Tooltip title={t("LVLDB0020")} arrow>
              <VisibilityIcon
                onClick={() => handleActions("View", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "20px",
                  color: "#A2A3A3",
                }}
              />
            </Tooltip>
          </AccessControl>

          <AccessControl authorization={"1010"}>
            {roleCode !== "EMPLOYEE" ? (
              <Tooltip title={t("LVLDB0021")} sx={{ pl: 1 }}>
                <EditIcon
                  onClick={() => handleActions("Edit", e)}
                  style={{
                    alignSelf: "center",
                    paddingTop: "0px",
                    paddingLeft: "5px",
                    opacity: "1",
                    fontSize: "20px",
                    color: "#A2A3A3",
                  }}
                />
              </Tooltip>
            ) : null}
          </AccessControl>
          {/* <AccessControl authorization={"user:delete"}>
            <Tooltip title={t("LVLDB0022")} sx={{ pl: 1 }}>
              <DeleteIcon
                onClick={() => handleActions("Delete", e.row.id)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "20px",
                  color: "#A2A3A3",
                }}
              />
            </Tooltip>
          </AccessControl> */}

          {/* <AccessControl authorization={true}>
            <Tooltip title={t("LVLDB0023")} arrow sx={{ pl: 1 }}>
              <ForwardToInboxIcon
                onClick={() => handleActions("ResendEmail", e)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "20px",
                  color: "#A2A3A3",
                }}
              />
            </Tooltip>
          </AccessControl> */}

          <AccessControl authorization={"1011"}>
            <Tooltip title={t("LVLDB0039")} arrow sx={{ pl: 1 }}>
              <ResetPassword
                onClick={() => handleActions("ResetPassword", e)}
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "20px",
                  color: "#A2A3A3",
                }}
              />
            </Tooltip>
          </AccessControl>
        </IconHandaler>
      ),
    },
  ];

  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      clientId: params.id,
      pageNumber: e + 1,
    });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      clientId: params.id,
      pageSize: e,
      toggleKeyToURL: true,
    });
  };

  const handleDelete = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t("LVLLOC0004")}
            onConfirm={() => {
              deletePerson(id, params.id).then((response) => {
                if (response.data) {
                  enqueueSnackbar(response.data.message, {
                    variant: "success",
                  });
                  navigate(REACT_DATA_ACCESS_CONTROL);
                }
              });
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const [rowId, setRowId] = useState(null); //当前行Id
  const handleActions = (action, data) => {
    if (action === "View") {
      navigate("/person/view", {
        state: { currentItem: data },
      });
    }
    if (action === "Edit") {
      navigate(REACT_PERSON_MANAGER_ADD, {
        state: { currentItem: data.row, type: action },
      });
    }
    if (action === "Delete") {
      handleDelete(data);
    }
    if (action === "ResendEmail") {
      sendEmail(data);
    }

    if (action === "ResetPassword") {
      setPassWordOpen(true);
      setRowId(data?.row?.id);
    }
  };

  const sendEmail = (data) => {
    let request = {
      companyId: data.row.companyId,
      firstName: data.row.firstName,
      email: data.row.email,
      code: data.row.code,
      personType: data.row.personType,
      status: data.row.status,
      companyCode: params.code,
    };
    sendActivationMail(request).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        enqueueSnackbar("Activation mail sent successfully", {
          variant: "success",
        });
      }
    });
  };

  const refreshClick = () => {
    loadData();
    enqueueSnackbar(t("LVLWS0005"), {
      variant: "info",
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    try {
      getPersonList({
        pageNumber: 1,
        pageSize: filters.pageSize,
        firstName: storeName,
      }).then((response) => {
        if (response?.data?.code === "LVLI0000") {
          setRecords(response?.data?.data?.objects);
          setTotalRecords(response?.data?.data?.totalCount);
          setIsLoading(false);
        } else if (response?.data?.code === "LVLE0054") {
          setRecords([]);
          setTotalRecords(0);
          setIsLoading(false);
        }
      });
    } catch (error) {
      enqueueSnackbar("Fail loading..." + error, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setStoreName("");
    loadData();
  };
  return (
    <ListLayout title={t("LVLRCP001")}>
      <Grid
        display={"flex"}
        sx={{
          width: "100%",
          background: "#FFF",
          height: "100px",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            margin: "30px 30px",
          }}
        >
          <TextField
            size="small"
            label={t("LVLDB0019")}
            placeholder={t("PLACE01")}
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
          ></TextField>
        </Box>

        <Box sx={{ margin: "35px 35px" }}>
          <Button
            variant="contained"
            sx={{ marginLeft: 1, p: 0.5 }}
            style={{
              height: "28px",
            }}
            onClick={handleSubmit}
          >
            {t("LVLGF0009")}
          </Button>

          <Button
            style={{
              height: "28px",
            }}
            sx={{ marginLeft: 1, p: 0.5 }}
            variant="outlined"
            onClick={handleReset}
          >
            {t("LVLGF0008")}
          </Button>
        </Box>
      </Grid>
      <Grid>
        <Box display={"flex"} my={2} justifyContent={"flex-end"}>
          <AccessControl authorization={"1009"}>
            <Box
              item
              pr={2}
              onClick={() =>
                navigate("/person/add", {
                  state: { currentItem: null, type: "add" },
                })
              }
            >
              <AddIcon width={"35"} height={"35"} className="pointer" />
            </Box>
          </AccessControl>
          <Box item pr={2}>
            <RefreshIcon
              width={"35"}
              height={"35"}
              className="pointer"
              onClick={refreshClick}
            />
          </Box>
        </Box>
      </Grid>

      {isLoading ? (
        <RingLoader
          color={"#597ef7"}
          loading={isLoading}
          cssOverride={override}
          size={60}
          speedMultiplier={3}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <AccessControl authorization={"1007"}>
          <DataTable
            columns={columns}
            rows={records}
            page={filters.pageNumber - 1}
            totalRecords={totalRecords}
            rowsPerPage={filters.pageSize}
            onPageChange={(pn) => handlePageChange(pn)}
            onPageSizeChange={(ps) => handlePageSize(ps)}
            checkboxSelection={false}
            // onSelection={(id) => handleSelection(id)}
          />
        </AccessControl>
      )}

      <ResetPassWord
        passWordOpen={passWordOpen}
        setPassWordOpen={setPassWordOpen}
        rowId={rowId}
      ></ResetPassWord>
    </ListLayout>
  );
}
