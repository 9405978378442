const removePermissions = (permissions, idsToRemove) => {
  return permissions.filter((p) => !idsToRemove.includes(p));
};

const addPermissions = (permissions, idsToAdd) => {
  return [...permissions, ...idsToAdd];
};

export const handleCheckboxChange = (
  menus,
  setSelectedPermissions,
  values,
  isParent,
  type
) => {
  //获取到所有的一级id
  const getAllID = menus.find((item) => item.id === values);

  //获取到所有的二级id
  const firstID = menus.find((item) =>
    item.resourceList.find((list) => list.id === isParent)
  );
  //根据父ID 寻找上级ID
  const secondID = firstID?.resourceList.find((item) => item.id === isParent);

  // twoID 点击看板的二级菜单选项,返回当前选中项的父级数据
  const twoID = menus.find((item) =>
    item.resourceList.find((list) => list.id === values)
  );

  //选中的数据
  setSelectedPermissions((prevSelectedPermissions) => {
    let updatedPermissions = [...prevSelectedPermissions];
    //清除选中的数据
    const removeFromPermissions = (ids) => {
      ids.forEach((id) => {
        updatedPermissions = removePermissions(updatedPermissions, [id]);
      });
    };
    //增加选中的数据
    const addToPermissions = (ids) => {
      ids.forEach((id) => {
        updatedPermissions = addPermissions(updatedPermissions, [id]);
      });
    };

    if (updatedPermissions.includes(values)) {
      removeFromPermissions([values]);
      //  如果type ===1 也就是选中的是第一级权限  就清除这个模块下面的所有的ID 达到取消第一级ID 那么下面所有的层级都不能选中的效果
      if (type === "1") {
        if (getAllID) {
          removeFromPermissions([getAllID.id]);
          getAllID?.resourceList.forEach((item) => {
            removeFromPermissions([item.id]);
            item.resourceList.forEach((list) => {
              removeFromPermissions([list.id]);
            });
          });
        }
      } else {
        removeFromPermissions([values]);
        //如果type === 3 也就是清除了所有的三级选中项，那么第一级和第二级的选中项也要跟着取消掉
        //点击三级ID
        let isExist = false;
        let twoIDExist = false;
        firstID?.resourceList.forEach((item) => {
          item.resourceList.forEach((data) => {
            if (updatedPermissions.includes(data.id)) isExist = true;
          });
        });

        twoID?.resourceList.forEach((item) => {
          if (updatedPermissions.includes(item.id)) twoIDExist = true;
        });

        if (!twoIDExist) {
          removeFromPermissions([twoID?.id]);
        }

        if (!isExist) {
          removeFromPermissions([firstID?.id, secondID?.id]);
        }
      }
    } else {
      //这个下面就是新增
      //如果选中的类型为 3 也就是选中的第三层级的权限自动带上第一级和第二级的菜单一起选中
      if (type === "3") {
        if (!updatedPermissions.includes(firstID?.id)) {
          addToPermissions([values, firstID?.id, secondID?.id]);
        } else {
          addToPermissions([values]);
        }
      } else if (type === "2") {
        if (!updatedPermissions.includes(firstID?.id)) {
          addToPermissions([values, twoID?.id]);
        } else {
          addToPermissions([values]);
        }
      } else {
        //选中第一级菜单
        updatedPermissions.push(values);
        //直接默认选中所有的子菜单
        getAllID?.resourceList.forEach((item) => {
          addToPermissions([getAllID.id, item.id]);
          item.resourceList.forEach((list) => {
            updatedPermissions.push(list.id);
          });
        });
      }
    }
    // console.log("..........................", updatedPermissions);
    return updatedPermissions;
  });
};
