import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Button,
  Tooltip,
  Grid,
  Radio,
  Dialog,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  TextField,
  DialogContentText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "@/components/DataTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import CommonUtil from "@/util/CommonUtils";
import IconHandaler from "@/components/IconHandaler";
import RingLoader from "react-spinners/RingLoader";
import { useSnackbar } from "notistack";
import { getOutletList } from "@/services/OutletService";
const DialogChange = forwardRef((props, ref) => {
  const {
    open,
    setOpen,
    deviceOutLetId,
    clientID,
    productListRef,
    isShowAllStore,
    storeName,
    setStoreName,
    setStoreOpen,
    storeOpen,
    storeList,
    countryInfo,
    stateInfo,
    cityInfo,
    resetPage,
    setResetPage,
  } = props;

  const { t } = useTranslation();
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  //模糊搜索的值
  const [inputValue, setInputValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const override = {
    display: "block",
    margin: "10% auto",
    borderColor: "#b37feb",
  };
  const defaultFilters = {
    pageNumber: 1,
    pageSize: 5,
  };

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 5,
  });

  // 使用 useImperativeHandle 将方法暴露给父组件
  useImperativeHandle(ref, () => ({
    getloadData: getloadData,
  }));

  /**
   * @method getloadData
   * 加载表格数据
   */
  const getloadData = () => {
    // setIsLoading(true);
    try {
      const { pageNumber, pageSize } = filters;
      getOutletList({
        pageNumber,
        pageSize,
        country: countryInfo?.id,
        state: stateInfo?.id,
        city: cityInfo?.id,
      }).then((response) => {
        if (response?.data?.code === "LVLI0000") {
          const list = response?.data?.data?.objects || [];
          if (pageNumber === 1 && isShowAllStore) {
            list.unshift({ name: t("PCS09"), id: "All" });
          }
          setRecords(list);
          setTotalRecords(response?.data?.data?.totalCount);
          setIsLoading(false);
        } else if (response?.data?.code === "LVLE0054") {
          setRecords([]);
          setTotalRecords(0);
          setIsLoading(false);
        }
      });
    } catch (error) {
      enqueueSnackbar("Fail loading..." + error, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!storeOpen) {
      return;
    }
    getloadData();
  }, [filters, storeOpen]);

  useEffect(() => {
    if (resetPage) {
      setFilters({ pageNumber: 1, pageSize: filters.pageSize });
      setResetPage(false);
    }
  }, [resetPage, filters.pageSize]);

  /**
   * @method handleAgreeButtonClick
   * 点击确定插入数据
   */
  const handleAgreeButtonClick = () => {
    if (selectedValue) {
      let filterResult = records.filter((item) => {
        if (item.id === selectedValue) {
          return true;
        } else {
          return false;
        }
      });

      if (filterResult && filterResult.length > 0) {
        setStoreName(filterResult[0]);
      }
    }
    setStoreOpen(false);
    setInputValue("");
  };

  /**
   * @method handleChange
   * 根据id 保存选中行id
   */
  const [selectedValue, setSelectedValue] = React.useState("");
  const handleChange = (event, rowId) => {
    setSelectedValue(rowId);
  };

  /**
   * @method renderRadioColumn
   * 单选框 渲染方法
   */
  const renderRadioColumn = (params) => {
    const rowId = getRowId(params.row);

    return (
      <Radio
        checked={selectedValue === rowId}
        onChange={(event) => handleChange(event, rowId)}
        value={rowId}
        name="radio-buttons"
        inputProps={{ "aria-label": rowId }}
      />
    );
  };

  const getRowId = (data) => data.id;
  const columns = [
    {
      field: "radio",
      headerName: "",
      width: 50,
      sortable: false,
      renderCell: renderRadioColumn,
    },
    {
      field: "clientName",
      headerName: t("LVLOT0002"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => {
        return (
          <Tooltip title={e.row.clientName} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.clientName)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "name",
      headerName: t("LVLOT0003"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.name)}</span>
        </Tooltip>
      ),
    },
    {
      field: "outletType",
      headerName: t("LVLOT0004"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => {
        return (
          <Tooltip title={e.row.outletTypeName} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(e.row.outletTypeName)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "addressLine",
      headerName: t("LVLDAC0022"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (e) => (
        <Tooltip title={e.row.addressLine} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.addressLine)}</span>
        </Tooltip>
      ),
    },
  ];
  /**
   * @method handlePageChange
   * 切换当前页
   */
  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      pageNumber: e + 1,
    });
  };

  /**
   * @method handlePageSize
   * 切换每页数量
   */
  const handlePageSize = (e) => {
    setFilters({
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  /**
   * @method 关闭弹窗
   */
  const handlerCancel = () => {
    setStoreOpen(false);
    setInputValue("");
  };
  // PCS119
  const handleSearch = () => {
    setIsLoading(true);
    if (inputValue === "") {
      enqueueSnackbar(t("PCS119"), {
        variant: "info",
      });
      getloadData();
      return;
    }
    try {
      setIsLoading(true);
      getOutletList({
        pageNumber: 1,
        pageSize: filters.pageSize,
        name: inputValue,
      }).then((response) => {
        if (response?.data?.code === "LVLI0000") {
          setRecords(response?.data?.data?.objects);
          setTotalRecords(response?.data?.data?.totalCount);
          setIsLoading(false);
        } else if (response?.data?.code === "LVLE0054") {
          setRecords([]);
          setTotalRecords(0);
          setIsLoading(false);
        }
      });
    } catch (error) {
      enqueueSnackbar("Fail loading..." + error, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={storeOpen}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid>
              <TextField
                size="small"
                label={t("LVLRC0042")}
                variant="outlined"
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: "10px",
              height: "410px",
              width: "700px",
              overflowY: "auto",
            }}
          >
            {isLoading ? (
              <RingLoader
                color={"#597ef7"}
                loading={isLoading}
                cssOverride={override}
                size={60}
                speedMultiplier={3}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <DataTable
                height="auto"
                rows={records || []}
                columns={columns}
                rowCount={totalRecords}
                getRowId={getRowId}
                pageSizeOptions={[5, 10, 20, 30, 50]}
                page={filters.pageNumber - 1}
                disableColumnMenu
                totalRecords={totalRecords}
                rowsPerPage={filters.pageSize}
                onPageChange={(pn) => handlePageChange(pn)}
                onPageSizeChange={(ps) => handlePageSize(ps)}
                checkboxSelection={false}
              />
            )}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlerCancel}>{t("LVLDB0012")}</Button>
        <Button onClick={handleAgreeButtonClick} autoFocus variant="contained">
          {t("LVLDB0011")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default DialogChange;
