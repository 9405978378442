import React, { useState, useRef } from "react";
import {
  Button,
  Stack,
  Typography,
  Grid,
  Alert,
  InputLabel,
  Box,
  FormHelperText,
  AlertTitle,
  TextField,
} from "@mui/material";
import {
  BootstrapActions,
  BootstrapContent,
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../../components/dialog";
import { getProductPhoto } from "../../services/ProductService";
import { useTranslation } from "react-i18next";
function ViewPhoto(props) {
  const { open, setOpen, productImage } = props;
  const { t } = useTranslation();

  return (
    <>
      <BootstrapDialog
        open={open}
        fullWidth={true}
        onClose={() => setOpen(false)}
      >
        <BootstrapDialogTitle onClose={() => setOpen(false)}>
          <Typography variant="h4" component="p">
            {t("查看图片")}
          </Typography>
        </BootstrapDialogTitle>
        <BootstrapContent>
          <img
            id="snapImage"
            src={productImage}
            style={{ width: "100%", height: "100%" }}
          />
        </BootstrapContent>
        <BootstrapActions></BootstrapActions>
      </BootstrapDialog>
    </>
  );
}

export default ViewPhoto;
