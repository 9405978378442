import React, { useEffect, useState } from "react";
import ListLayout from "../../../../components/ListLayout";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { ReactComponent as PeopleCountingBlueImg } from "@/assets/images/ai_people_counting_blue.svg";
import { ReactComponent as TickMarkImg } from "@/assets/images/icon_tickmark.svg";
import { ReactComponent as DeviceDefaultImg } from "@/assets/images/device_default_img.svg";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { ReactComponent as PeopleCountingGreenImg } from "@/assets/images/ai_people_counting_green.svg";
import CustomDatePicker from "../../../../components/CustomDatePicker";

import { deviceList, filterDeviceEvents } from "@/services/DeviceService";
import CommonUtil from "../../../../util/CommonUtils";
import ChannelSelect from "../../../../components/ChannelSelect";
export default function TotalPeopleEntering(props) {
  const [count, setCount] = useState(0);
  const [date, setDate] = useState(new Date());
  const { t } = useTranslation();
  useEffect(() => {
    if (props.DeviceId && !CommonUtil.isEmptyString(props.DeviceId)) {
      loadDeviceEvents({ deviceId: props.DeviceId });
    }
  }, [date, props.DeviceId]);

  const loadDeviceEvents = (filter) => {
    let currentStr = CommonUtil.dateFormat(date, "yyyy-MM-dd");
    filterDeviceEvents({
      pageNumber: 1,
      pageSize: 10000,
      startDateTime: currentStr + " 00:00:00",
      endDateTime: currentStr + " 23:00:00",
      deviceId: filter.deviceId,
    }).then((res) => {
      if (res?.data?.data) {
        let events = res?.data?.data?.list || [];
        setCount(events.length);
      } else {
        setCount(0);
      }
    });
  };

  const changeDate = (e) => {
    setDate(e);
  };

  return (
    <>
      <Box
        component={Paper}
        elevation={0}
        variant="elevation"
        display={"flex"}
        width={"100%"}
        bgcolor={props.variant === "blue" ? "#ebf5fb" : "#f0f8f3"}
      >
        <Box sx={{ p: 1, m: 1 }}>
          {props.variant === "blue" ? (
            <PeopleCountingBlueImg />
          ) : (
            <PeopleCountingGreenImg />
          )}
        </Box>
        <Box sx={{ p: 1, m: 1, width: "60%" }}>
          <Box display={"block"} justifyContent={"flex-start"}>
            <Box p="0" m="0">
              <Typography variant="count">{count}</Typography>
            </Box>
            <Box mt={1} p="0">
              <Typography variant="countDescription">
                {props.variant === "blue"
                  ? "Total Number of People Entering"
                  : "Total Number of People Passing by"}
              </Typography>
            </Box>
            <Box p="0" m="0">
              <Typography variant="countSubDescription">
                Base on ReID Algorithm
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display={"block"}
          alignItems={"flex-start"}
          style={{
            position: "relative",
          }}
          sx={{ p: 1, m: 1, display: "flex" }}
        >
          <Box>
            <ChannelSelect deviceId={props.DeviceId}></ChannelSelect>
          </Box>
          <Box sx={{ ml: 2 }}>
            <CustomDatePicker
              size={"small"}
              disableFuture={true}
              date={date}
              disabled={false}
              fullWidth={true}
              label={""}
              placeholder={t("LVLRCP016")}
              SelectedDate={(e) => changeDate(e)}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
