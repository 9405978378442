import api from "../configurations/http-common";

export const getCountryList = async (payload) => {
  return api.securedAxios().get("/web/countries/list", {
    params: payload,
  });
};

export const getStateList = async (payload) => {
  return api.securedAxios().get("/web/states/list", {
    params: payload,
  });
};

export const getCityList = async (payload) => {
  return api.securedAxios().get("/web/cities/list", {
    params: payload,
  });
};

export const getOutletType = async (payload) => {
  return api.securedAxios().get("/web/outlet_type/list", {
    params: payload,
  });
};

export const getCityZone = async (params) => {
  return api.securedAxios().get("/web/city_zones/list", {
    params,
  });
};

export const selectCountry = async () => {
  return api.securedAxios().get("/web/countries");
};

export const selectState = async (countryId) => {
  return api.securedAxios().get(`/web/states`, {
    params: countryId,
  });
};

export const selectCity = async (stateId) => {
  return api.securedAxios().get(`/web/cities`, {
    params: stateId,
  });
};

export const selectOutLet = async () => {
  return api.securedAxios().get("/web/outlet_types/list");
};

export const selectCityZone = async (params) => {
  return api.securedAxios().get("/web/city_zones", {
    params,
  });
};

/**
 *
 * @returns 同步数据到L3
 */
export const getDataList = async () => {
  return api.securedAxios().put("/web/countries/synchronize");
};
