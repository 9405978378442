import React from "react";
import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
const BootstrapActions = (props) => {
  const { children, ...other } = props;
  return <DialogActions {...other}>{children}</DialogActions>;
};
BootstrapActions.propTypes = {
  children: PropTypes.node,
};

export default BootstrapActions;
