import { Avatar, Grid, IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import FileBase64 from "react-file-base64";
import { useTranslation } from "react-i18next";
import cameraplus2 from "@/assets/images/AddPhotoIcon.svg";

export default function ProfileUplod(props) {
  const { t } = useTranslation();
  const [profile, setProfile] = React.useState(cameraplus2);
  const [file, setFile] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = React.useState({
    profilePhoto: "",
  });

  const handleProfile = (file) => {
    if (
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/bmp"
    ) {
      let fileSize = file.size.split(" ")[0];
      let size = parseInt(fileSize);
      if (size <= 3072) {
        setFile(file);
        setState({
          ...state,
          profilePhoto: file.base64.split(",")[1],
        });
        props.onsetProfile(file);
        setProfile(file.base64);
      } else {
        setFile("");
        enqueueSnackbar("File size can not exceed 3MB", {
          variant: "error",
        });
      }
    } else {
      setFile("");
      //setProfile(state.profilePhoto);
      enqueueSnackbar("Invalid file format", {
        variant: "error",
      });
    }
  };

  return (
    <Grid
      conatiner
      display={"flex"}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <div
        style={{
          borderStyle: props.style ? props.style : "dashed",
          borderRadius: "50%",
          padding: "5px ",
          color: "#D2D2D2",
        }}
      >
        <Tooltip title={props.profilePhoto ? t("LVLRCP022") : ""}>
          <IconButton
            variant="contained"
            component="label"
            style={{
              position: "relative",
              width: "200px",
              height: "200px",
              font: "normal normal normal 18px/24px Roboto",
              letterSpacing: "0px",
            }}
            disabled={props.disabled}
          >
            {props.profilePhoto ? (
              <Avatar
                className="ImageContent"
                imgProps={{ draggable: "false" }}
                alt=""
                src={props.profilePhoto}
                style={{
                  height: "200px",
                  width: "200px",
                  zIndex: "99",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <Grid
                sx={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "50%",
                  backgroundColor: "#F4F6F8",
                }}
              >
                <Grid>
                  <Avatar
                    className="ImageContent"
                    alt=""
                    imgProps={{ draggable: "false" }}
                    src={profile}
                    style={{
                      height: "100%",
                      width: "100%",
                      marginTop: "30%",
                      marginBottom: "10%",
                    }}
                    sx={[
                      () => ({
                        "& .MuiAvatar-img": {
                          width: "49px",
                          height: "42px",
                          textAlign: "center",
                          objectFit: "cover",
                          color: "transparent",
                          textIndent: "10000px",
                          borderStyle: "none !important",
                        },
                      }),
                    ]}
                  />
                </Grid>

                {t("PCS96")}

                <Grid style={{ display: "none" }}>
                  <FileBase64
                    multiple={false}
                    onDone={(file) => handleProfile(file)}
                  />
                </Grid>
              </Grid>
            )}
            <Grid style={{ display: "none" }}>
              <FileBase64
                multiple={false}
                onDone={(file) => handleProfile(file)}
              />
            </Grid>
          </IconButton>
        </Tooltip>
      </div>
      <Grid
        style={{
          color: "#C3C5C7",
          opacity: 1,
          fontSize: "14px",
          paddingTop: "34px",
        }}
      >
        {/* Allowed *.jpeg, *.jpg, *.png */}
        {t(props.imageInfo)}
      </Grid>

      <Grid mt={1} sx={{ color: "#C3C5C7", opacity: 1, fontSize: "14px" }}>
        {/*max size of 3 MB */}
        {t(props.imageSize)}
      </Grid>
    </Grid>
  );
}

ProfileUplod.defaultProps = {
  disabled: false,
};
