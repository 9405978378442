import { getOutletList } from "@/services/OutletService";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}/${month}/${day}`;
};

//People Counting 看板时间选择
export const getTimeData = (
  setStartTimes,
  setEndTimes,
  setStartValueTime,
  setEndValueTime
) => {
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  // 设置本月起止时间
  setStartTimes(formatDate(firstDayOfMonth));
  setEndTimes(formatDate(lastDayOfMonth));

  // 上个月的第一天和最后一天
  const lastMonthSameDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const firstDayOfLastMonth = new Date(
    lastMonthSameDay.getFullYear(),
    lastMonthSameDay.getMonth() - 1,
    1
  );
  const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  // 设置上个月起止时间
  setStartValueTime(formatDate(firstDayOfLastMonth));
  setEndValueTime(formatDate(lastDayOfLastMonth));
};

//DemograPhic Summary 看板时间选择
export const handlerTimeData = (
  setStartTimes,
  setEndTimes,
  setEndValueTime,
  setStartValueTime
) => {
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  setStartTimes(formatDate(firstDayOfMonth));
  setEndTimes(formatDate(lastDayOfMonth));

  const lastMonthSameDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const firstDayOfLastMonth = new Date(
    lastMonthSameDay.getFullYear(),
    lastMonthSameDay.getMonth() - 1,
    1
  );

  setStartValueTime(formatDate(firstDayOfLastMonth));

  const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  setEndValueTime(formatDate(lastDayOfLastMonth));
};

//获取看板门店数据方法
export const getStoreData = (
  setStoreName,
  setStoreList,
  countryInfo,
  stateInfo,
  cityInfo
) => {
  let params = {
    pageNumber: 1,
    pageSize: 1,
    countryName: countryInfo?.id,
    stateName: stateInfo?.id,
    cityName: cityInfo?.id,
  };
  getOutletList(params).then((res) => {
    if (res?.data?.code === "LVLI0000") {
      setStoreName(res?.data?.data?.objects[0]);
      setStoreList(res?.data?.data?.objects);
    } else {
      setStoreList([]);
    }
  });
};
