import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
export default function SubMenuItem(props) {
  const navigate = useNavigate();
  const path = window.location.pathname;
  const handleClick = () => {
    navigate(props.link);
  };

  return (
    <Box
      fullWidth={true}
      sx={{
        mb: 1,
        height: { xs: "100%", md: "auto" },
        py: { md: 0 },
        px: { md: 0 },
      }}
      onClick={() => handleClick()}
    >
      {props.isActive && (
        <Box
          component={Paper}
          variant="outlined"
          elevation={0}
          display={"flex"}
          alignItems={"center"}
          bgcolor={"#1487CA"}
          borderRadius={"2"}
          style={{
            cursor: "pointer",
            border: "none",
            backgroundImage: "linear-gradient(to right, #74b74b, #2e96e6)",
          }}
        >
          <ListItemButton>
            <Typography
              variant="menuItem"
              color={"#FFFFFF"}
              sx={{ pl: 4, display: { md: "inline" } }}
            >
              {props.label}
            </Typography>
          </ListItemButton>
        </Box>
      )}

      {!props.isActive ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          style={{ cursor: "pointer" }}
        >
          <ListItemButton>
            <Typography
              variant="menuItem"
              sx={{ pl: 4, display: { md: "inline" } }}
            >
              {props.label}
            </Typography>
          </ListItemButton>
        </Box>
      ) : null}
    </Box>
  );
}
