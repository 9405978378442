import { ReactComponent as AddIcon } from "@/assets/images/icon_add.svg";
import { ReactComponent as RefreshIcon } from "@/assets/images/icon_refresh.svg";
import AccessControl from "@/components/AccessControl";
import QueryButton from "@/components/TableButton/QueryButton";
import MainCard from "@/components/Retail-components/MainCard";
import SearchForm from "@/components/Retail-components/SearchForm";
import { REACT_TENANT_MANAGER_ADD } from "@/router/ReactEndPoints";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ListLayout from "../../components/ListLayout";
import MaterialTable from "./MaterialTable";
import { getTenantList } from "@/services/TenantService";
import { useSnackbar } from "notistack";
function TenantList(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [records, setRecords] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [queryParams, setQueryParams] = useState({});

  // 分页的参数
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  // 构建参数
  const buildParams = () => {
    const params = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      ...queryParams,
    };
    return params;
  };

  useEffect(() => {
    loadData();
  }, [pagination.pageNumber, pagination.pageSize, queryParams]);

  const loadData = () => {
    setIsLoading(true);
    try {
      getTenantList(buildParams()).then((response) => {
        if (response?.data?.code === "LVLI0000") {
          setRecords(response?.data?.data?.objects);
          setRowCount(response?.data?.data?.totalCount);
          setIsLoading(false);
        } else if (response?.data?.code === "LVLE0054") {
          setRecords([]);
          setRowCount(0);
          setIsLoading(false);
        }
      });
    } catch (error) {
      enqueueSnackbar("Fail loading..." + error, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const searchLoadData = (data) => {
    setPagination({
      ...pagination,
      pageIndex: 1,
    });
    setQueryParams({
      ...data,
    });
  };

  const formConfig = [
    {
      name: "companyName",
      label: t("LVLDAC0038"),
      type: "input",
      width: "200px",
      height: "40px",
    },
  ];

  return (
    <ListLayout navigateBack={false} title={t("tenant.tenant_mangerment")}>
      <MainCard border={false} height={"120px"}>
        <Grid mt={3}>
          <SearchForm
            formConfig={formConfig}
            searchFn={searchLoadData}
          ></SearchForm>
        </Grid>
      </MainCard>

      <QueryButton
        route={REACT_TENANT_MANAGER_ADD}
        authID={"1063"}
        refresh={loadData}
        width={35}
        height={35}
      ></QueryButton>

      <Grid mt={2}>
        <MaterialTable
          pagination={pagination}
          setPagination={setPagination}
          rowCount={rowCount}
          setCurrentItem={setCurrentItem}
          isLoading={isLoading}
          records={records}
        ></MaterialTable>
      </Grid>
    </ListLayout>
  );
}

export default TenantList;
