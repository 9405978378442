import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable";
import ListLayout from "../../components/ListLayout";
import { CheckBox } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../../components/ConfirmModel";
import AccessControl from "@/components/AccessControl";
import { ReactComponent as AddIcon } from "@/assets/images/icon_add.svg";
import { ReactComponent as RefreshIcon } from "@/assets/images/icon_refresh.svg";
import { confirmAlert } from "react-confirm-alert";
import {
  REACT_PERSON_LIST,
  REACT_PRINCIPAL_ADD,
  RETAIL_CLIENT,
  REACT_PRINCIPAL_VIEW,
  REACT_PRINCIPAL_UPDATE,
} from "@/router/ReactEndPoints";
import {
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  getCompanyByIdOrCode,
  listPrincipalCompanies,
  sendActivationMail,
  getClinetsList,
  deleteClientById,
} from "../../services/CompanyService";
import SearchIcon from "@mui/icons-material/Search";
import CommonUtil from "../../util/CommonUtils";
import IconHandaler from "../../components/IconHandaler";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { useSnackbar } from "notistack";

export default function PrincipalManagement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [page, setPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { enqueueSnackbar } = useSnackbar();
  const roleCode = CommonUtil.getRoleCode();
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 5,
    name: "",
    code: "",
    country: "",
    operator: "",
  });

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 5,
    name: "",
    code: "",
    country: "",
    operator: "",
  };

  useEffect(() => {
    loadData();
  }, [filters]);
  const loadData = () => {
    getClinetsList({
      type: "PRINCIPAL",
      name: "",
      code: "",
      ...filters,
    }).then((res) => {
      // console.log("...................", res);
      if (
        res?.data?.code === "LVLI0000" &&
        res?.data?.data &&
        res?.data?.data?.client
      ) {
        setRecords(res?.data?.data?.client);
        setTotalRecords(res?.data?.data?.totalCount);
        setPage(res?.data?.data?.currentPage);
        setRowsPerPage(res?.data?.data?.totalCount);
      }
    });
  };

  const toolbarProps = {
    add: CommonUtil.getLoginType() === "HQ",
    filter: false,
    refresh: true,
    onAdd: (data) => {
      navigate(REACT_PRINCIPAL_ADD);
    },
    onRefresh: (data) => {
      loadData();
      enqueueSnackbar(t("LVLWS0005"), {
        variant: "success",
      });
    },
  };

  const toAdd = () => {
    navigate(REACT_PRINCIPAL_ADD);
  };
  const refreshClick = () => {
    loadData();
    enqueueSnackbar(t("LVLWS0005"), {
      variant: "success",
    });
  };

  const handlePageChange = (e) => {
    setFilters({ ...filters, pageNumber: e + 1 });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  const delteItem = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t("LVLLOC0004")}
            onConfirm={() => {
              deleteClientById(id).then((response) => {
                if (response.data) {
                  enqueueSnackbar(response.data.message, {
                    variant: "success",
                  });
                  loadData();
                }
              });
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const columns = [
    {
      field: "name",
      headerName: t("LVLDAC0031"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.name} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.name)}</span>
        </Tooltip>
      ),
    },
    {
      field: "email",
      headerName: t("LVLRC0004"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.email} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.email)}</span>
        </Tooltip>
      ),
    },
    {
      field: "mobile",
      headerName: t("LVLRCP005"),
      flex: 1,
      renderCell: (e) => {
        const containsNumericDigits = /\d/.test(e.row.mobile);
        const phoneToDisplay = containsNumericDigits ? e.row.mobile : "-";
        return (
          <Tooltip title={phoneToDisplay} arrow placement="bottom">
            <span>{CommonUtil.formatLongText(phoneToDisplay)}</span>
          </Tooltip>
        );
      },
    },

    {
      field: "country",
      headerName: t("LVLRC0007"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.countryName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.countryName)}</span>
        </Tooltip>
      ),
    },
    {
      field: "state",
      headerName: t("LVLOT0006"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.stateName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.stateName)}</span>
        </Tooltip>
      ),
    },
    {
      field: "city",
      headerName: t("LVLOT0007"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.cityName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.cityName)}</span>
        </Tooltip>
      ),
    },
    {
      field: "address",
      headerName: t("LVLOT0012"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip
          title={e.row.addressLine || e.row.addressLine2}
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(e.row.addressLine || e.row.addressLine2)}
          </span>
        </Tooltip>
      ),
    },
    {
      headerName: t("LVLRC0010"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1.5,
      renderCell: (e) => (
        <IconHandaler>
          <Tooltip title={t("LVLDB0020")} arrow>
            <VisibilityIcon
              onClick={() => handleActions("View", e)}
              style={{
                alignSelf: "center",
                paddingTop: "0px",
                paddingLeft: "5px",
                opacity: "1",
                fontSize: "20px",
                color: "#A2A3A3",
              }}
            />
          </Tooltip>

          {roleCode !== "EMPLOYEE" ? (
            <Tooltip title={t("LVLDB0021")} arrow sx={{ marginLeft: 1 }}>
              <EditIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "20px",
                  color: "#A2A3A3",
                }}
                onClick={() => handleActions("Edit", e)}
              />
            </Tooltip>
          ) : null}
          <AccessControl authorization={"1038"}>
            <Tooltip title={t("LVLRCP001")} arrow sx={{ marginLeft: 1 }}>
              <PeopleAltIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "20px",
                  color: "#A2A3A3",
                }}
                onClick={() =>
                  navigate(
                    RETAIL_CLIENT +
                      "principal" +
                      "/" +
                      e.row.id +
                      "/" +
                      e.row.code +
                      REACT_PERSON_LIST
                  )
                }
              />
            </Tooltip>
          </AccessControl>

          {/* <Tooltip title="Edit" arrow sx={{ marginLeft: 1 }}>
            <EditIcon
              style={{
                alignSelf: "center",
                paddingTop: "0px",
                paddingLeft: "5px",
                opacity: "1",
              }}
              //    onClick={() => handleActions("Edit", e)}
            />
          </Tooltip> */}

          {/* <Tooltip title="ResendEmail" arrow sx={{ marginLeft: 1 }}>
            <ForwardToInboxIcon
              onClick={() => handleActions("ResendEmail", e)}
              style={{
                alignSelf: "center",
                paddingTop: "0px",
                paddingLeft: "5px",
                opacity: "1",
                fontSize: "20px",
                color: "#A2A3A3",
              }}
            />
          </Tooltip> */}
          {/* <Tooltip title="Delete" arrow sx={{ marginLeft: 1 }}>
            <DeleteIcon
              style={{
                alignSelf: "center",
                paddingTop: "0px",
                fontSize: "16px",
                color: "#A2A3A3",
              }}
              onClick={() => handleActions("Delete", e)}
            />
          </Tooltip> */}
        </IconHandaler>
      ),
    },
  ];

  const sendEmail = (data) => {
    getCompanyByIdOrCode(data.row.code).then((response) => {
      let request = {
        name: data.row.name,
        code: data.row.code,
        ownerEmail: response.data.data.ownerEmail,
        phone: data.row.phone,
        address: data.row.address,
        city: data.row.city,
        state: data.row.state,
      };

      sendActivationMail(request).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          enqueueSnackbar("Activation mail sent successfully", {
            variant: "success",
          });
        }
      });
    });
  };

  const handleActions = (action, data) => {
    if (action === "Delete") {
      delteItem(data.row.id);
    } else if (action === "View") {
      sessionStorage.setItem("currentClinet", JSON.stringify(data.row));
      navigate(REACT_PRINCIPAL_VIEW + data.row.code);
    } else if (action === "Edit") {
      sessionStorage.setItem("eidtPrinciple", JSON.stringify(data.row));
      navigate(REACT_PRINCIPAL_UPDATE + data.row.code);
    }

    // if (action === "ResendEmail") {
    //   sendEmail(data);
    // } else
  };

  return (
    <ListLayout
      navigateBack={false}
      title={t("LVLDB0013")}
      globalFilterProps={false}
      // toolbarProps={toolbarProps}
    >
      <Grid container>
        <Box>
          <Box display={"flex"} my={2} marginLeft={185}>
            <AccessControl authorization={"1037"}>
              <Box
                item
                pr={2}
                onClick={() => {
                  toAdd();
                }}
              >
                <AddIcon width={"35"} height={"35"} className="pointer" />
              </Box>
            </AccessControl>
            <Box item pr={2}>
              <RefreshIcon
                width={"35"}
                height={"35"}
                className="pointer"
                onClick={refreshClick}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <AccessControl authorization={"1036"}>
        <DataTable
          columns={columns}
          rows={records}
          onSelection={() => console.log()}
          page={filters.pageNumber - 1}
          totalRecords={totalRecords}
          rowsPerPage={filters.pageSize}
          onPageChange={(pn) => handlePageChange(pn)}
          onPageSizeChange={(ps) => handlePageSize(ps)}
        />
      </AccessControl>
    </ListLayout>
  );
}
