import React, { createContext } from "react";

const AppContext = createContext({
    tokenRefreshTimer: "",
    selectedClient: localStorage.getItem("selectedCLient") && localStorage.getItem("selectedCLient") !== "{}"? JSON.parse(localStorage.getItem("selectedCLient")) : null,
    selectedApplication: localStorage.getItem("selectedApplication") && localStorage.getItem("selectedApplication") !== "{}" ? JSON.parse(localStorage.getItem("selectedApplication")) : null,
    setTokenRefreshTimer: () => {},
    setSelectedClient: () => {},
    setSelectedApplication: () => {},
  });

  export default AppContext;