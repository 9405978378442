export const contentStyle = {
  background: "#fff",
  borderRadius: "10px",
  paddingBottom: "2vh",
  overflowX: "auto",
  // overflow: "visible",
  scrollbarWidth: "thin",
  boxSizing: "border-box",
  scrollbarColor: "#c8c8c8 transparent",
};

contentStyle["&::-webkit-scrollbar"] = {
  width: "2px",
};

contentStyle["&::-webkit-scrollbar-thumb"] = {
  background: "#d1e7c3",
  borderRadius: "2px",
};

contentStyle["&::-webkit-scrollbar-track"] = {
  background: "transparent",
};

// export const bottomStyle = {
//   overflow: "auto",
//   scrollbarWidth: "thin",
//   scrollbarColor: "rgba(222, 222, 222, 1) transparent",
// };

// bottomStyle["&::-webkit-scrollbar"] = {
//   width: "2px",
// };

// bottomStyle["&::-webkit-scrollbar-thumb"] = {
//   background: "#d1e7c3",
//   borderRadius: "2px",
// };

// bottomStyle["&::-webkit-scrollbar-track"] = {
//   background: "transparent",
// };
