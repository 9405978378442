import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button } from "@mui/material";
import CmpFormik from "@/components/Retail-components/CmpFormik";
import Upload from "./Attachment";
import { loadUnitData } from "../js/index";
import { getContractInfoConfig } from "../js/Config";
function ContractInfo(props) {
  const { addFormik, contractConfig, setContractConfig, type } = props;
  const { t } = useTranslation();
  const [unitData, setUnitData] = useState([]);

  useEffect(() => {
    loadUnitData(setUnitData);
  }, []);

  useEffect(() => {
    if (unitData) {
      let contractConfigInfo = getContractInfoConfig(t, type, unitData);
      setContractConfig(contractConfigInfo);
    }
  }, [unitData]);

  return (
    <Grid bgcolor={"#f7fbfe"} mt={4}>
      <Typography fontSize={16} ml={4} pt={2}>
        {t("subscription.contract_information")}
      </Typography>

      <Grid pl={10} mr={4} mt={2} pb={2} pr={6}>
        <CmpFormik
          itemSpacing={4}
          sx={6}
          formik={addFormik}
          formConfig={contractConfig}
        >
          <Grid
            sx={{
              mt: "30px",
              ml: 5,
            }}
          >
            <Upload addFormik={addFormik}></Upload>
          </Grid>
        </CmpFormik>
      </Grid>
    </Grid>
  );
}

export default ContractInfo;
