import { ReactComponent as TickMarkImg } from "@/assets/images/icon_tickmark.svg";
import AccessControl from "@/components/AccessControl";
import DataTable from "@/components/DataTable";
import ListLayout from "@/components/ListLayout";
import AppLanguage from "@/lang/AppLanguages";
import { getChannelType, getDeviceList } from "@/services/DeviceService";
import { getMetaDataList, getPhotoData } from "@/services/MetaDataService";
import { Button, Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { exportExcelData } from "@/services/MetaDataService";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ConfigProvider, DatePicker } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import RingLoader from "react-spinners/RingLoader";

import CommonUtil from "../../util/CommonUtils";

import { getcolumns } from "./Columns";
import SelectDialog from "./SelectDialog";
import DeviceList from "./DeviceList";
const { RangePicker } = DatePicker;

export default function MetadataList() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(null);
  useEffect(() => {
    let language = AppLanguage.getDefaultLanguage();
    setLanguage(language);
  }, []);

  const locale = {
    DatePicker: {
      lang: {
        placeholder: t("MATADATA05"),
        rangePlaceholder: [t("MATADATA06"), t("MATADATA07")],
        ...zhCN.DatePicker.lang, // 导入中文语言包
      },
    },
    RangePicker: {
      lang: {
        ...zhCN.DatePicker.lang,
      },
    },
  };
  //Start Promotion
  const [startDayDate, setStartDayDate] = useState(null); //年月日
  const [startTimeSate, setStartTimeDate] = useState(null); //时分秒
  // End Promotion *
  const [endDayDate, setEndDayDate] = useState(null); //年月日
  const [endTimeDate, setEndTimeDate] = useState(null); //时分秒
  const [devices, setDevices] = useState([]);
  const [records, setRecords] = useState([]); //matadata 列表数据
  const [totalRecords, setTotalRecords] = useState(0); // matadata 列表数据 总数
  const [selectedDevice, setSelectedDevice] = useState(""); //develist id
  const [selectEventType, setSelectEventType] = useState(1); // device List 事件类型
  const [channelOptions, setChannelOptions] = useState([]); //数据过滤类型
  const [selectedValue, setSelectedValue] = useState(
    "354296b825141de9645c65612181496"
  ); //下拉框选中的数据
  const [open, setOpen] = useState(false);
  const [startTimes, setStartTimes] = useState(null); //选中的开始时间
  const [endTimes, setEndTimes] = useState(null); //选中的结束时间
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [serachSn, setSerachSn] = useState("");
  const [serachDevice, setSerachDevice] = useState(0);
  const deviceNum = localStorage.getItem("DEVICENUM");

  const defaultFilters = {
    pageNumber: 1,
    pageSize: 10,
  };

  const override = {
    display: "block",
    margin: "20% auto",
    borderColor: "#b37feb",
  };

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  /**
   * eventType 下拉框选择 接口数据
   */
  useEffect(() => {
    getChannelType().then((res) => {
      if (res?.data?.data) {
        const channelOptionsData = res?.data?.data?.map((item) => ({
          id: item.id,
          name: item.name,
        }));

        if (selectEventType === 1) {
          const filteredChannelOptions = channelOptionsData.filter(
            (item) => item.id !== "374296b825121de96c5c6561a181496"
          );
          setChannelOptions(filteredChannelOptions);
        }
      }
    });
  }, [selectedValue, selectEventType]);

  /**
   * 判断是否重新调loadMetaData() 方法
   */
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (selectedDevice && !CommonUtil.isEmptyString(selectedDevice.id)) {
      setFilters({
        pageNumber: 1,
        pageSize: 10,
      });
      setIsFiltersChanged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimes, endTimes, selectedDevice, selectedValue, selectEventType]);

  const [isFiltersChanged, setIsFiltersChanged] = useState(false);
  // 分页 切换上下页
  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      pageNumber: e + 1,
    });
    setIsFiltersChanged(true);
  };

  //修改每页的数量
  const handlePageSize = (e) => {
    setFilters({
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
    setIsFiltersChanged(true);
  };

  useEffect(() => {
    if (isFiltersChanged) {
      loadMetaData();
      setIsFiltersChanged(false);
    }
  }, [isFiltersChanged]);

  //加载表格数据
  const loadMetaData = () => {
    setIsLoading(true);
    // 判断是否为切换设备的操作
    const params = {
      deviceId: selectedDevice?.id,
      deviceType: "sr-aibox", //selectedDevice.deviceType,
      eventType:
        selectEventType === 1
          ? selectedValue
          : "374296b825121de96c5c6561a181496",
      startDateTime: startTimes,
      endDateTime: endTimes,
      ...filters,
    };

    getMetaDataList(params)
      .then((res) => {
        if (res?.data?.data) {
          let data = res?.data?.data;
          setRecords(data?.events || []);
          setTotalRecords(data?.totalCount);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
        setIsFiltersChanged(false);
      });
  };

  const getRowId = (data) => data.id;
  //修改下拉选择框方法
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(""); // 点击查看事件图
  /**
   * 图片放大方法
   * @param {*} imageUrl
   */

  const handleImageClick = (eventID, imageUrl) => {
    getPhotoData({ id: eventID }).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        if (imageUrl === 1) {
          setSelectedImage(res?.data?.data?.registerPhoto);
          setLightboxOpen(true);
        } else {
          setSelectedImage(res?.data?.data?.eventPhoto);
          setLightboxOpen(true);
        }
      }
    });
  };

  // 表格加载
  const columns = getcolumns(t, handleImageClick, selectedValue);

  /**
   * @param {*} current
   * @method disabledDate
   *禁用当前日期和时间之后的所有日期和时间
   */
  const disabledDate = (current) => {
    return current && current > moment();
  };

  // 获取选定的时间范围
  const handleDateChange = (e) => {
    if (e === null || e.length === 0) {
      // 用户点击了清除按钮
      setStartTimes("");
      setEndTimes("");
      loadMetaData();
      return;
    }

    // 检查e是否为null或空数组
    if (e && e.length > 0) {
      // 将第一个moment对象存储在startMoment中
      const startMoment = e[0];
      // 将第二个moment对象存储在endMoment中
      const endMoment = e[1];
      // 分别将两个moment对象转换为所需的日期时间格式并存储起来
      const formattedStartDate = startMoment.format("YYYY-MM-DD HH:mm:ss");
      const formattedEndDate = endMoment.format("YYYY-MM-DD HH:mm:ss");
      setStartTimes(formattedStartDate);
      setEndTimes(formattedEndDate);
    } else {
      console.log("没有选择日期");
    }
  };

  const sn = devices.filter((item) => item.id === selectedDevice?.id);

  // 导出为ExCel 表格方法
  const clickExportData = () => {
    if (selectEventType === 2) {
      setOpen(true);
    } else {
      const params = {
        deviceId: selectedDevice?.id,
        deviceType: "sr-aibox",
        eventType: selectedValue,
        startDateTime: startTimes,
        endDateTime: endTimes,
        exportType: 1,
        deviceSn: sn[0].sn,
        ...filters,
      };

      setIsLoading(true);

      if (startTimes === null && endTimes === null) {
        enqueueSnackbar(t("PCS91"), {
          variant: "info",
        });
        return;
      }
      exportExcelData(params)
        .then((res) => {
          if (res?.headers?.code === "LVLE0000") {
            let message = decodeURIComponent(res?.headers?.message);
            message = message.replace(/\+/g, " ");
            enqueueSnackbar(message, {
              variant: "info",
            });
            setIsLoading(false);

            return;
          } else {
            const link = document.createElement("a");
            let blob = new Blob([res.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            });
            link.style.display = "none";
            link.download = res.headers["content-disposition"]; //下载后文件名
            // link.download = "Export Data"; //下载的文件名
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setOpen(false);

            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  return (
    <ListLayout navigateBack={false} title={t("RTMT0001")}>
      <Grid container={true} spacing={2}>
        <Grid container justifyContent="space-between" sx={{ marginTop: 2 }}>
          <Grid item>
            {/* 这里放置两个按钮 */}
            <Grid container spacing={2} sx={{ marginLeft: 0 }}>
              <Grid item>
                <AccessControl authorization={"1032"}>
                  <Button
                    style={{
                      width: "220px",
                      height: "40px",
                      background: "#fff",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      setSelectedValue("354296b825141de9645c65612181496");
                      setSelectEventType(1);
                    }}
                  >
                    {t("RTMT0011")}
                    {selectEventType === 1 && (
                      <TickMarkImg
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                        }}
                        height={12}
                      />
                    )}
                  </Button>
                </AccessControl>
              </Grid>
              <Grid item>
                <AccessControl authorization={"1058"}>
                  <Button
                    style={{
                      width: "200px",
                      height: "40px",
                      background: "#fff",
                    }}
                    onClick={() => {
                      setSelectedValue("374296b825121de96c5c6561a181496");
                      setSelectEventType(2);
                    }}
                  >
                    {t("RTMT0012")}
                    {selectEventType === 2 && (
                      <TickMarkImg
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                        }}
                        height={12}
                      />
                    )}
                  </Button>
                </AccessControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {/* 这里放置日期选择器、导出按钮和下拉框选择组件 */}
            <Grid container spacing={2} alignItems="center" display={"flex"}>
              {/* 使用中文语言  */}

              <ConfigProvider locale={language !== "en" ? locale : null}>
                <RangePicker
                  allowClear
                  showTime
                  locale={language !== "en" ? locale : null}
                  // disabledDate={disabledDate}
                  onChange={handleDateChange}
                  popupStyle={{ zIndex: 99999999999, marginTop: "165px" }}
                />
              </ConfigProvider>

              {/* <Box>
                <InputLabel shrink htmlFor="bootstrap-input">
                  {t("开始时间")}
                  <span style={{ color: "red" }}>*</span>
                </InputLabel>

                <Grid display={"flex"}>
                  <Box width={130}>
                    <CustomDatePicker
                      SelectedDate={(e) =>
                        setStartDayDate(dayjs(e).format("YYYY-MM-DD"))
                      }
                      value={startDayDate}
                    ></CustomDatePicker>
                  </Box>

                  <Box width={130} ml={1}>
                    <TimePickerDate
                      onChange={(e) => setStartTimeDate(e)}
                      value={startTimeSate}
                    ></TimePickerDate>
                  </Box>
                </Grid>
              </Box>
              <Box>
                <InputLabel shrink htmlFor="bootstrap-input">
                  {t("结束时间")} <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Grid display={"flex"} ml={2}>
                  <Box width={130}>
                    <CustomDatePicker
                      SelectedDate={(e) =>
                        setEndDayDate(dayjs(e).format("YYYY-MM-DD"))
                      }
                      value={endDayDate}
                    ></CustomDatePicker>
                  </Box>

                  <Box width={130} ml={1}>
                    <TimePickerDate
                      onChange={(e) => setEndTimeDate(e)}
                      value={endTimeDate}
                    ></TimePickerDate>
                  </Box>
                </Grid>
              </Box> */}

              <Grid item>
                <AccessControl authorization={"1035"}>
                  <Button
                    type="primary"
                    onClick={clickExportData}
                    style={{ borderRadius: "5px" }}
                  >
                    {t("MATADATA01")}
                  </Button>
                </AccessControl>
              </Grid>
              <Grid item>
                <FormControl sx={{ minWidth: 140 }}>
                  {selectEventType !== 2 ? (
                    <Select
                      value={selectedValue}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {channelOptions?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : null}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={2} sm={10} display={"flex"}>
          {/* 客群/客流 事件 */}
          <DeviceList
            devices={devices}
            setDevices={setDevices}
            selectedDevice={selectedDevice}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setSelectedDevice={setSelectedDevice}
          ></DeviceList>
        </Grid>

        <Grid item sx={{ p: 1 }} lg={10} sm={10}>
          {/*  表格组件  */}
          {isLoading ? (
            <RingLoader
              color={"#597ef7"}
              loading={isLoading}
              cssOverride={override}
              size={60}
              speedMultiplier={3}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            // <AccessControl authorization={"1032"}>
            <DataTable
              height="auto"
              columns={columns || []}
              rows={records}
              getRowId={getRowId}
              onSelection={() => console.log("1111111")}
              page={filters.pageNumber - 1}
              totalRecords={totalRecords}
              rowsPerPage={filters.pageSize}
              onPageChange={(pn) => handlePageChange(pn)}
              onPageSizeChange={(ps) => handlePageSize(ps)}
              checkboxSelection={false}
            />
            // </AccessControl>
          )}
          {lightboxOpen && (
            <Lightbox
              mainSrc={selectedImage}
              onCloseRequest={() => setLightboxOpen(false)}
            />
          )}
        </Grid>

        {/* 导出弹窗组件 */}
        <Grid>
          {selectEventType === 2 ? (
            <SelectDialog
              open={open}
              setOpen={setOpen}
              selectedDevice={selectedDevice}
              selectedValue={selectedValue}
              selectEventType={selectEventType}
              setStartTimes={setStartTimes}
              setEndTimes={setEndTimes}
              filters={filters}
              startTimes={startTimes}
              endTimes={endTimes}
              devices={devices}
            ></SelectDialog>
          ) : null}
        </Grid>
      </Grid>
    </ListLayout>
  );
}
