import { FormHelperText, InputLabel, Stack, Switch } from '@mui/material'
import RequirePoint from './RequirePoint'
const CmpSwitch = (props) => {
  const {
    formik = null,
    placeholder = '',
    handleBlur,
    handleChange,
    label,
    name,
    error,
    labelPostion,
    ...orther
  } = props

  const changeFn = (e) => {
    if (formik?.handleChange) {
      formik?.handleChange(e)
    }
    formik.setFieldValue(name, e.target.checked)
    if (handleBlur) {
      handleBlur(e)
    }
  }

  return (
    <Stack spacing={1}>
      <Stack
        sx={{
          alignItems: labelPostion === 'left' ? 'flex-start' : '',
        }}
        direction={labelPostion === 'left' ? 'row' : 'column'}
        spacing={1}
      >
        <InputLabel
          style={{
            marginTop: labelPostion === 'left' ? '12px' : '',
          }}
          htmlFor={'cmpswitch_' + name}
        >
          {props.required && <RequirePoint></RequirePoint>} {label}
        </InputLabel>
        <Stack
          sx={{
            flexGrow: 1,
            width: '100%',
          }}
        >
          <Switch
            id={'cmpswitch_' + name}
            checked={formik?.values[name]}
            onChange={changeFn}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          {((formik?.touched[name] && formik?.errors[name]) || error) && (
            <FormHelperText error id={`standard-weight-helper-text-${name}`}>
              {formik?.errors[name] || error}
            </FormHelperText>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CmpSwitch
