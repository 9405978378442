//套餐显示弹窗
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import enLogo from "@/assets/logo/about_logo_en.svg";
import zhLogo from "@/assets/logo/about_logo_zh.svg";
import { useTheme } from "@mui/material/styles";
import AppLanguage from "@/lang/AppLanguages";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Skeleton,
  CircularProgress,
  Grid,
  Stack,
  Card,
} from "@mui/material";
import { getVersionCode } from "@/services/OnboardService";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { userSubscribeInfo } from "@/services/subscription";
import useLoading from "@/hooks/useLoading";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
    // backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
const ValidityDate = ({ validityOpen, setValidityOpen }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [version, setVersion] = useState(null);
  const [subData, setSubData] = useState({});
  const [loading, toggleLoading] = useLoading();
  const [subLoading, setSubLoading] = useState(true);
  const [logoSrc, setLogoSrc] = useState();
  useEffect(() => {
    let language = AppLanguage.getDefaultLanguage();
    if (language == "en" || language == "es") {
      setLogoSrc(enLogo);
    } else {
      setLogoSrc(zhLogo);
    }
  }, []);

  useEffect(() => {
    getVersionCode().then((res) => {
      setVersion(res?.data?.data?.version || "V1.0.0");
    });
  }, []);

  useEffect(() => {
    if (validityOpen) {
      setSubLoading(true);
      userSubscribeInfo()
        .then((res) => {
          if (res?.data?.code === "LVLI0000") {
            setSubData(res?.data?.data);
            toggleLoading();
            setSubLoading(false);
          }
        })
        .catch(() => {
          toggleLoading();
          setSubLoading(false);
        });
    }
  }, [validityOpen]);
  // 加载图标的样式
  const loadingStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(400px)",
  };
  const getEffectexpireDayBarColor = () => {
    const value =
      subData.usedExpireDay === -1
        ? 0
        : subData.usedExpireDay == subData.maxExpiredDay
        ? 100
        : (subData.usedExpireDay / subData.maxExpiredDay) * 100;
    console.log(theme.palette.primary.main);

    let color = theme.palette.primary.main; // 默认为绿色
    if (value > 50) {
      color = "yellow"; // 如果超过 50%，则将颜色设置为黄色
    }
    if (value > 80) {
      color = "red"; // 如果超过 80%，则将颜色设置为红色
    }
    return color;
  };
  const getEffectdeviceCountBarColor = () => {
    const value =
      subData.usedDeviceCount === -1
        ? 0
        : subData.usedDeviceCount >= subData.maxDeviceCount
        ? 100
        : (subData.usedDeviceCount / subData.maxDeviceCount) * 100;
    let color = theme.palette.primary.main; // 默认为绿色
    if (value > 50) {
      color = "yellow"; // 如果超过 50%，则将颜色设置为黄色
    }
    if (value > 80) {
      color = "red"; // 如果超过 80%，则将颜色设置为红色
    }
    return color;
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={() => setValidityOpen(false)}
        open={validityOpen}
        sx={{
          maxWidth: { xs: 1400, lg: 2000 },
        }}
        overflowX={"auto"}
      >
        <DialogTitle>
          <Typography variant="h4" component="p">
            {t("about.media_personal_about")}
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setValidityOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers={true}>
          <div style={loading ? loadingStyle : null}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Grid container spacing={0} sx={{ minHeight: 350 }}>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <img src={logoSrc} height={40} alt="" srcset="" />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container sx={{ width: "100%" }} spacing={[0, 1]}>
                        <Grid item xs={12}>
                          <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}
                          >
                            <Typography
                              variant="body1"
                              display="block"
                              sx={{ fontWeight: "bold" }}
                              component="p"
                            >
                              {t("about.common_system_version")}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {version}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}
                          >
                            <Typography
                              variant="body1"
                              display="block"
                              sx={{ fontWeight: "bold" }}
                              component="p"
                            >
                              {t("about.base_system_browsers")}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              Firefox 27+/Chrome 33+/Edge
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}
                          >
                            <Typography
                              variant="body1"
                              display="block"
                              sx={{ fontWeight: "bold" }}
                              component="p"
                            >
                              {t("about.base_system_resolution")}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {t("about.common_system_resoltion_recommond")}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container sx={{ width: "100%" }} spacing={[0, 1]}>
                    <Grid item xs={12}>
                      <div>
                        <Typography
                          variant="h4"
                          display="block"
                          sx={{ fontWeight: "bold" }}
                          component="p"
                        >
                          {t("about.common_package_details")}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container sx={{ width: "100%" }} spacing={[0, 1]}>
                        <Grid item xs={12}>
                          <Card
                            variant="outlined"
                            sx={{ padding: "10px", height: 90 }}
                          >
                            {subLoading ? (
                              <Stack spacing={0}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                              </Stack>
                            ) : (
                              <Stack spacing={1}>
                                <Typography
                                  variant="h5"
                                  display="block"
                                  sx={{ fontWeight: "bold" }}
                                  component="p"
                                >
                                  {t("about.common_remaining_day")}
                                </Typography>
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={
                                    subData.usedExpireDay === -1
                                      ? 0
                                      : subData.usedExpireDay >=
                                        subData.maxExpiredDay
                                      ? 100
                                      : (subData.usedExpireDay /
                                          subData.maxExpiredDay) *
                                        100
                                  }
                                  sx={{
                                    marginBottom: 0.3,
                                    "& .MuiLinearProgress-bar": {
                                      backgroundColor:
                                        getEffectexpireDayBarColor(),
                                    },
                                  }}
                                />

                                <Stack
                                  justifyContent={"space-between"}
                                  flexDirection={"row"}
                                >
                                  <Typography>
                                    {subData.maxExpiredDay === -1
                                      ? t("about.common_no_limit")
                                      : t("about.common_used_day", {
                                          day: subData.usedExpireDay,
                                        })}
                                  </Typography>
                                  <Typography>
                                    {t("about.common_gross")}
                                    {subData.maxExpiredDay === -1
                                      ? t("about.common_no_limit")
                                      : ` ${subData.maxExpiredDay}${t(
                                          "about.common_day"
                                        )}`}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}
                          </Card>
                        </Grid>
                        <Grid item xs={12}>
                          <Card
                            variant="outlined"
                            sx={{ padding: "10px", height: 90 }}
                          >
                            {subLoading ? (
                              <Stack spacing={0}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                              </Stack>
                            ) : (
                              <Stack spacing={1}>
                                <Typography
                                  variant="h5"
                                  display="block"
                                  sx={{ fontWeight: "bold" }}
                                  component="p"
                                >
                                  {t("about.common_remaining_device_count")}
                                </Typography>
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={
                                    subData.usedDeviceCount === -1
                                      ? 0
                                      : subData.usedDeviceCount >=
                                        subData.maxDeviceCount
                                      ? 100
                                      : (subData.usedDeviceCount /
                                          subData.maxDeviceCount) *
                                        100
                                  }
                                  sx={{
                                    marginBottom: 0.3,
                                    "& .MuiLinearProgress-bar": {
                                      backgroundColor:
                                        getEffectdeviceCountBarColor(),
                                    },
                                  }}
                                />
                                <Stack
                                  justifyContent={"space-between"}
                                  flexDirection={"row"}
                                >
                                  <Typography>
                                    {subData.usedDeviceCount === -1
                                      ? t("about.common_no_limit")
                                      : t("about.common_add_device_num", {
                                          count: subData.usedDeviceCount,
                                        })}
                                  </Typography>
                                  <Typography>
                                    {t("about.common_gross")}
                                    {subData.maxDeviceCount === -1
                                      ? t("about.common_no_limit")
                                      : ` ${subData.maxDeviceCount}${t(
                                          "about.common_add_device_dot"
                                        )}`}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <img src={logoSrc} alt="" height={20} srcset="" />
                <Typography display="block" gutterBottom variant="h6">
                  Copyright 2023
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};
export default ValidityDate;
