import { Box, Typography } from "@mui/material";
import RightViewLayout from "../../components/RighViewLayout";
import ViewPage from "../../components/ViewPage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { REACT_OUTLET_LIST } from "@/router/ReactEndPoints";
import OverFlowText from "../../components/OverFlowText";
import { findOutletById } from "../../services/OutletService";
import { listCompanies, loadAllCompanies } from "../../services/CompanyService";
import CommonUtil from "../../util/CommonUtils";
import { useTranslation } from "react-i18next";

export default function ViewOutlet() {
  const { t } = useTranslation();

  const [payload, setPayload] = useState({
    name: "",
    clientName: "",
    outletTypeName: "",
    cityZone: "",
    timeZone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    let editRow = sessionStorage.getItem("editOutlet");
    if (editRow) {
      let current = JSON.parse(editRow);
      setPayload({ ...payload, ...current?.address, ...current });
    }
  }, []);

  return (
    <RightViewLayout navigateBack={REACT_OUTLET_LIST} title={t("LVLOT0018")}>
      <ViewPage>
        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLOT0002")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.clientName ? payload.clientName : "-"}
        </Typography>
        <Box>
          <Typography variant="fieldLabel">{t("LVLOT0003")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.name ? payload.name : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLOT0004")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.outletTypeName ? payload.outletTypeName : "-"}
        </Typography>

        {/* <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLOT0009")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {CommonUtil.getTimeZoneNameByValue(payload?.address?.timeZone)}
        </Typography> */}
        <Box>
          <Typography variant="fieldLabel">{t("LVLOT0012")}:</Typography>
        </Box>
        <OverFlowText variant="fieldValue" maxLength="100">
          {payload?.addressLine}
        </OverFlowText>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLOT0005")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.countryName ? payload.countryName : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLOT0006")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.stateName ? payload.stateName : "-"}
        </Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLOT0007")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.cityName ? payload.cityName : "-"}
        </Typography>

        {/* <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLOT0008")}:</Typography>
        </Box>
        <Typography variant="fieldValue">
          {payload && payload.cityZone ? payload.cityZone : "-"}
        </Typography> */}

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLOT0013")}:</Typography>
        </Box>
        <Typography variant="fieldValue">{payload?.latitude}</Typography>

        <Box mt={2}>
          <Typography variant="fieldLabel">{t("LVLOT0014")}:</Typography>
        </Box>
        <Typography variant="fieldValue">{payload?.longitude}</Typography>
      </ViewPage>
    </RightViewLayout>
  );
}
