import React, { useEffect, useState } from "react";
import ListLayout from "../../../components/ListLayout";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { ReactComponent as PeopleCountingBlueImg } from "@/assets/images/ai_people_counting_blue.svg";
import { ReactComponent as TickMarkImg } from "@/assets/images/icon_tickmark.svg";
import { ReactComponent as DeviceDefaultImg } from "@/assets/images/device_default_img.svg";
import { deviceList, filterDeviceEvents } from "@/services/DeviceService";
import VisitorDemographic from "../demographic/Component/VisitorDemographic";
import GeneralDistribution from "../demographic/Component/GeneralDistribution";
import AgeDistribution from "../demographic/Component/AgeDistribution";
import EntryFlowTrend from "../demographic/Component/EntryFlowTrend";
import ConversionRate from "../demographic/Component/ConversionRate";

export default function Demographic() {
  const [records, setRecords] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    deviceList({ pageNumber: 1, pageSize: 100 }).then((res) => {
      if (res?.data?.data) {
        let devices = res?.data?.data?.devices || [];
        setRecords(devices);
        if (devices?.length > 0) {
          setSelectedDevice(devices[0].id);
        }
      }
    });
  };

  return (
    <ListLayout
      navigateBack={false}
      title="Demographic"
      globalFilterProps={{}}
      toolbarProps={{}}
    >
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={1}>
          <Grid item lg="2" sm="12">
            <Box
              container
              spacing={1}
              flexDirection={"column"}
              component={Paper}
              elevation={1}
              variant="elevation"
              display={"flex"}
              width={"100%"}
              sx={{
                minHeight: "",
                maxHeight: "100%",
                overflowY: "auto",
              }}
            >
              <Box m={1}>
                <Typography variant="subtitle3">Device List</Typography>
              </Box>

              {records?.map((device) => {
                return (
                  <Box
                    key={device?.id}
                    component={Paper}
                    elevation={1}
                    variant="elevation"
                    m={1}
                    p={0.5}
                    style={{
                      position: "relative",
                    }}
                    border={"1px solid #1487CA"}
                    onClick={() => setSelectedDevice(device?.id)}
                  >
                    <Box
                      style={{
                        position: "absolute",
                        right: "0px",
                        top: "-4px",
                      }}
                      display={"flex"}
                      flexDirection={"row-reverse"}
                    >
                      <Box item mt={-1} mr={-1}>
                        {selectedDevice === device?.id && (
                          <TickMarkImg height={12} />
                        )}
                      </Box>
                    </Box>
                    <Box
                      style={{
                        width: "100%",
                      }}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Box item>
                        <Typography variant="dashboardDeviceLabel">
                          {device?.sn}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Grid>
          <Grid item lg="10" sm="12">
            <VisitorDemographic></VisitorDemographic>
            <Grid mt={2} container spacing={1}>
              <Grid lg="6" sm="12" item>
                <GeneralDistribution></GeneralDistribution>
              </Grid>
              <Grid lg="6" sm="12" item>
                <AgeDistribution></AgeDistribution>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <EntryFlowTrend></EntryFlowTrend>
            </Box>
            <Box sx={{ mt: 2 }}>
              <ConversionRate></ConversionRate>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ListLayout>
  );
}
