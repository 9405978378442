import React from "react";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
const BootstrapContent = (props) => {
  const { children, ...other } = props;
  return (
    <DialogContent dividers={true} {...other}>
      {children}
    </DialogContent>
  );
};
BootstrapContent.propTypes = {
  children: PropTypes.node,
};
export default BootstrapContent;
