export const headerStyle = {
  // overflow: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "#c8c8c8 transparent",
};

headerStyle["&::-webkit-scrollbar"] = {
  width: "2px",
};

headerStyle["&::-webkit-scrollbar-thumb"] = {
  background: "#d1e7c3",
  borderRadius: "2px",
};

headerStyle["&::-webkit-scrollbar-track"] = {
  background: "transparent",
};
