import { Box, Grid } from "@mui/material";
import { ReactComponent as AddIcon } from "@/assets/images/icon_add.svg";
import { ReactComponent as RefreshIcon } from "@/assets/images/icon_refresh.svg";
import AccessControl from "@/components/AccessControl";
import { useNavigate } from "react-router-dom";
function QueryButton(props) {
  const { route, refresh, height, width, authID } = props;
  const navigate = useNavigate();
  return (
    <Grid display={"flex"} justifyContent={"flex-end"} marginTop={2}>
      <AccessControl authorization={authID}>
        <Box
          pr={2}
          onClick={() =>
            navigate(route, {
              state: { isAdd: true },
            })
          }
        >
          <AddIcon width={width} height={height} className="pointer" />
        </Box>
      </AccessControl>
      <Box pr={2}>
        <RefreshIcon
          width={width}
          height={height}
          className="pointer"
          onClick={refresh}
        />
      </Box>
    </Grid>
  );
}

export default QueryButton;
