import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./css/App.css";
import AppRoutes from "@/router/AppRoutes";
import { SnackbarProvider } from "notistack";
import i18n from "i18next";

import AppLanguage from "./lang/AppLanguages";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.min.css";

function App() {
  useEffect(() => {
    let language = AppLanguage.getDefaultLanguage();
    language = language === null ? "en" : language;
    i18n.changeLanguage(language);
  }, []);

  // const [viewportWidth, setViewportWidth] = useState(0);
  // const [viewportHeight, setViewportHeight] = useState(0);

  // useEffect(() => {
  //   const viewportWidth = window.innerWidth;
  //   const viewportHeight = window.innerHeight;
  //   setViewportWidth(viewportWidth);
  //   setViewportHeight(viewportHeight);
  // }, [viewportWidth, viewportHeight]);

  return (
    <BrowserRouter basename="/">
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{
          marginTop: "300px",
        }}
      >
        <AppRoutes />

        {/* <ToastContainer
          autoClose={2000}
          position="top-center"
          hideProgressBar
          className="toast-container"
          toastClassName="dark-toast"
        /> */}
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
