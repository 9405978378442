import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  Grid,
  Box,
  DialogActions,
  InputLabel,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function AllStore({
  storeOpen,
  setStoreOpen,
  storeName,
  setStoreName,
  storeList,
}) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog open={storeOpen} onClose={() => setStoreOpen(false)}>
        <DialogTitle fontSize={24} sx={{ minWidth: 300 }}>
          {t("PCS28")}
        </DialogTitle>
        <DialogContent>
          <Grid
            item
            xs={10}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box sx={{ minWidth: 200 }}>
              <FormControl fullWidth={true}>
                <InputLabel sx={{ marginTop: "-10px" }}>
                  {t("PCS29")}
                </InputLabel>
                <Select
                  id="demo-simple-select"
                  value={storeName}
                  label="Store Name"
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    if (selectedValue === 1) {
                      setStoreName("");
                    } else {
                      setStoreName(selectedValue);
                    }
                    setStoreOpen(false);
                  }}
                >
                  {/* <MenuItem value={1}>{t("PCS30")}</MenuItem> */}
                  {storeList?.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
export default AllStore;
