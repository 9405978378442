/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Box, Grid, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CustomDatePicker from "./StartTimeSelect";
import CommonUtil from "@/util/CommonUtils";
import { useTranslation } from "react-i18next";
import { startOfMonth, endOfMonth, format, subMonths, add } from "date-fns";
const CompareTime = ({
  open1,
  setOpen1,
  startTimes,
  setStartValueTime,
  setEndValueTime,
  selectTime,
  endTimes,
}) => {
  const { t } = useTranslation();
  //现在选择的时间
  const [startWeekTime, setStartWeekTime] = useState("");
  const [endWeekTime, setEndWeekTime] = useState("");
  //为了做点击确定再回显时间
  const [startTime, setStartTime] = useState("");
  const [endTime, seEndTime] = useState("");
  useEffect(() => {
    const previousMonth = subMonths(new Date(), 1);
    const firstOfMonth = startOfMonth(previousMonth);
    const formattedFirstOfMonth = format(firstOfMonth, "yyyy-MM-dd");
    setStartTime(formattedFirstOfMonth);

    const endOfMonthDay = endOfMonth(previousMonth);
    const formattedEndOfMonthDay = format(endOfMonthDay, "yyyy-MM-dd");
    seEndTime(formattedEndOfMonthDay);
  }, [selectTime]);
  const startChangeDate = (e) => {
    if (selectTime == 1) {
      let currentStr = CommonUtil.dateFormat(e, "yyyy-MM-dd");
      setStartTime(currentStr);

      // 限制掉 Week 时间选择范围
      setStartWeekTime(currentStr);
      const maxDate = add(new Date(e), { days: 30 }); // 将开始时间推迟六天
      setEndWeekTime(maxDate);
    } else {
      const firstDayOfMonth = startOfMonth(new Date(e));

      const formattedDate1 = format(firstDayOfMonth, "yyyy-MM-dd");
      setStartTime(formattedDate1);

      // 限制掉 Week 时间选择范围
      setStartWeekTime(formattedDate1);
      const lastDayOfMonth = endOfMonth(new Date(e));
      const formattedDate = format(lastDayOfMonth, "yyyy-MM-dd");
      seEndTime(formattedDate);
    }
  };

  const endChangeDate = (e) => {
    let currentStr = CommonUtil.dateFormat(e, "yyyy-MM-dd");

    seEndTime(currentStr);
  };

  const handklerConfirm = () => {
    setStartValueTime(startTime);
    setEndValueTime(endTime);
    setOpen1(false);
  };
  return (
    <React.Fragment>
      <Dialog open={open1} onClose={() => setOpen1(false)}>
        <DialogTitle fontSize={24}>{t("PCS25")}</DialogTitle>
        <DialogContent>
          <Grid display={"flex"}>
            <Box sx={{ width: "200px", marginRight: "30px" }}>
              <CustomDatePicker
                size={"small"}
                disableFuture={true}
                // date={date}
                disabled={false}
                fullWidth={true}
                label={""}
                placeholder={t("LVLRCP016")}
                maxDate={startTimes}
                type={selectTime}
                SelectedDate={(e) => startChangeDate(e)}
              />
            </Box>

            {selectTime == 1 ? (
              <Grid>
                <Box sx={{ lineHeight: "40px" }}>——</Box>
                <Box
                  sx={{
                    width: "200px",
                    marginLeft: "50px",
                    marginTop: "-38px",
                  }}
                >
                  <CustomDatePicker
                    size={"small"}
                    disableFuture={true}
                    // date={date}
                    fullWidth={true}
                    type={selectTime}
                    label={""}
                    minDate={startWeekTime ? startWeekTime : ""}
                    maxDate={endWeekTime}
                    disabled={false}
                    placeholder={t("LVLRCP016")}
                    SelectedDate={(e) => endChangeDate(e)}
                  />
                </Box>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen1(false)}>{t("LVLDB0012")}</Button>
          <Button autoFocus onClick={handklerConfirm}>
            {t("LVLDV0096")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CompareTime;
