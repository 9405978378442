import React, { useState, useRef } from "react";
import RightViewLayout from "../../../components/RighViewLayout";
import { REACT_DEVICE_SETTING } from "@/router/ReactEndPoints";
import { useTranslation } from "react-i18next";
import CustomInput from "../../../components/CustomInput";
import { useNavigate } from "react-router-dom";
import DeviceIcon from "@/assets/images/icon_device.png";
import {
  Grid,
  InputLabel,
  Autocomplete,
  TextField,
  Box,
  Button,
} from "@mui/material";
export default function DeviceSKU() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  //-------------------------------------------
  const [error, setError] = useState({
    // name: "",
    // addressLine1: "",
    // city: "",
    // state: "",
    // country: "",
    // areaCode: "",
    // latitude: "",
    // longitude: "",
    // timeZone: "",
    // companyId: "",
    // outletType: "",
    // cityZone: "",
  });

  const handleSubmit = () => {};
  const categoryOpt = [
    {
      value: "0",
      name: "Category 1",
    },
    {
      value: "1",
      name: "Category 2",
    },
  ];
  const [category, setCategoryOpt] = useState(categoryOpt[0]);

  return (
    <RightViewLayout navigateBack={REACT_DEVICE_SETTING} title={t("DSKU001")}>
      <Grid container style={{ backgroundColor: "#ffffff" }}>
        <Grid item xs={12}>
          <Grid container spacing={2} px={2}>
            <Grid item md={6} xs={12}>
              <CustomInput
                required
                disabled
                label={t("DSKU002")}
                size="small"
                name="position"
                // error={error.name}
                value={"1"}
                inputProps={{
                  maxLength: 60,
                }}
                helperText={error.name}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInput
                required
                disabled
                label={t("DSKU003")}
                size="small"
                name="recognitionTime"
                // error={error.name}
                value={"2023.09.20 10:20:10"}
                inputProps={{
                  maxLength: 60,
                }}
                helperText={error.name}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} px={2}>
            <Grid item md={6} xs={12}>
              <CustomInput
                required
                label={t("DSKU004")}
                size="small"
                name="productName"
                // error={error.name}
                value={"DUIUMA"}
                inputProps={{
                  maxLength: 60,
                }}
                helperText={error.name}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInput
                required
                label={t("DSKU005")}
                size="small"
                name="barcode"
                // error={error.name}
                value={"11"}
                inputProps={{
                  maxLength: 60,
                }}
                helperText={error.name}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} px={2}>
            <Grid item md={6} xs={12}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ paddingLeft: "0px" }}
              >
                {t("DSKU006")}
                <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Autocomplete
                options={categoryOpt}
                value={category}
                onChange={(e, v) => {
                  setCategoryOpt(v);
                }}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={""}
                    helperText={""}
                  />
                )}
              ></Autocomplete>
            </Grid>
          </Grid>
          <Grid container spacing={2} px={2}>
            <Grid item md={6} xs={12}>
              {t("DSKU007")}
            </Grid>
          </Grid>
          <Grid container md={6} xs={12} px={2}>
            <Grid xs={2}>
              <img src={DeviceIcon} style={{ width: "100%", height: "100%" }} />
            </Grid>
            <Grid xs={2}>
              <img src={DeviceIcon} style={{ width: "100%", height: "100%" }} />
            </Grid>
            <Grid xs={2}>
              <img src={DeviceIcon} style={{ width: "100%", height: "100%" }} />
            </Grid>
            <Grid xs={2}>
              <img src={DeviceIcon} style={{ width: "100%", height: "100%" }} />
            </Grid>
            <Grid xs={2}>
              <img src={DeviceIcon} style={{ width: "100%", height: "100%" }} />
            </Grid>
            <Grid xs={2}>
              <img src={DeviceIcon} style={{ width: "100%", height: "100%" }} />
            </Grid>
          </Grid>
          <Grid container spacing={2} px={2}>
            <Grid item xs={12}>
              <Box display={"flex"} flexDirection={"row-reverse"}>
                <Box item pl={2}>
                  <Button
                    variant="contained"
                    size="large"
                    className="text-transform-none"
                    onClick={handleSubmit}
                  >
                    {t("LVL0013")}
                  </Button>
                </Box>
                <Box item>
                  <Button
                    className="text-transform-none"
                    variant="outlined"
                    onClick={() => navigate(REACT_DEVICE_SETTING)}
                    size="large"
                  >
                    {t("LVLDB0012")}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
