import React, { useEffect, useState } from "react";
import ListLayout from "../../../components/ListLayout";
import ChannelSelect from "../../../components/ChannelSelect";
import { deviceList } from "@/services/DeviceService";
import { getOutPhotoList } from "../../../services/DashboardService";
import DataTable from "../../../components/DataTable";
import { useTranslation } from "react-i18next";
import demo from "@/assets/test/demo.png";
import { ReactComponent as TickMarkImg } from "@/assets/images/icon_tickmark.svg";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Autocomplete,
  TextField,
  Tooltip,
} from "@mui/material";
import CommonUtil from "../../../util/CommonUtils";
export default function AiDashboard() {
  const [photoList, setPhotoList] = useState([]);
  const { t } = useTranslation();

  const [records, setRecords] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  useEffect(() => {
    if (selectedDevice && !CommonUtil.isEmptyString(selectedDevice)) {
      // todo
      getOutPhotoList().then((list) => {
        setPhotoList(list);
      });
      //   getChannelList().then((list) => {
      //     setChannelList(list);
      //   });
    }
  }, [selectedDevice]);

  //   const [channelId, setChannelId] = useState({});
  //   const [channelList, setChannelList] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    deviceList({ pageNumber: 1, pageSize: 100 }).then((res) => {
      if (res?.data?.data) {
        let devices = res?.data?.data?.devices || [];
        setRecords(devices);
        if (devices?.length > 0) {
          setSelectedDevice(devices[0].id);
        }
      }
    });
  };

  const columns = [
    {
      field: "number",
      headerName: "Number",
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.number} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.number)}</span>
        </Tooltip>
      ),
    },
    {
      field: "productName",
      headerName: t("LVLDV0063"),
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.productName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.productName)}</span>
        </Tooltip>
      ),
    },

    {
      field: "status",
      headerName: "Capture photo status",
      flex: 1,
      renderCell: (e) => (
        <Tooltip title={e.row.status} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.status)}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <ListLayout
      navigateBack={false}
      title="Shelf Monitoring"
      globalFilterProps={{}}
      toolbarProps={{}}
    >
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={1}>
          <Grid mt={1} item lg="2" sm="12">
            <Box
              container
              spacing={1}
              flexDirection={"column"}
              component={Paper}
              elevation={1}
              variant="elevation"
              display={"flex"}
              width={"100%"}
              sx={{
                minHeight: "",
                maxHeight: "100%",
                overflowY: "auto",
              }}
            >
              <Box m={1}>
                <Typography variant="subtitle3">Device List</Typography>
              </Box>

              {records?.map((device) => {
                return (
                  <Box
                    key={device?.id}
                    component={Paper}
                    elevation={1}
                    variant="elevation"
                    m={1}
                    p={0.5}
                    style={{
                      position: "relative",
                    }}
                    border={"1px solid #1487CA"}
                    onClick={() => setSelectedDevice(device?.id)}
                  >
                    <Box
                      style={{
                        position: "absolute",
                        right: "0px",
                        top: "-4px",
                      }}
                      display={"flex"}
                      flexDirection={"row-reverse"}
                    >
                      <Box item mt={-1} mr={-1}>
                        {selectedDevice === device?.id && (
                          <TickMarkImg height={12} />
                        )}
                      </Box>
                    </Box>
                    {/* <Box
                    bgcolor={"#f8f8f8"}
                    display={"flex"}
                    sx={{
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box item>
                      <DeviceDefaultImg />
                    </Box>
                  </Box> */}
                    <Box
                      style={{
                        width: "100%",
                      }}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Box item>
                        <Typography variant="dashboardDeviceLabel">
                          {device?.sn}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Grid>

          {selectedDevice && (
            <Grid container item lg="10" sm="12">
              <Grid item lg="6" sm="6">
                <Box component={Paper} p={1} m={1}>
                  <Grid
                    sx={{
                      justifyContent: "flex-end",
                    }}
                    className="customAutocomplete"
                    container
                  >
                    <Box
                      style={{
                        width: "200px",
                      }}
                    >
                      <ChannelSelect deviceId={selectedDevice}></ChannelSelect>
                    </Box>
                  </Grid>

                  <Grid container>
                    <Grid p={1} item lg="6" sm="6">
                      <Typography variant="subtitle3">
                        Registration Photo
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <img style={{ width: "100%" }} alt="xxx" src={demo} />
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Box>
                          <Typography variant="subtitle3">
                            Registration time:
                          </Typography>
                        </Box>
                        <Typography variant="body1">
                          2023-10-24 14:30:56
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid p={1} item lg="6" sm="6">
                      <Typography variant="subtitle3">Capture Photo</Typography>
                      <Box sx={{ mt: 2 }}>
                        <img style={{ width: "100%" }} alt="xxx" src={demo} />
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Box>
                          <Typography variant="subtitle3">
                            Capture time:
                          </Typography>
                        </Box>
                        <Typography variant="body1">
                          2023-10-24 16:50:56
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      mt: 2,
                      textAlign: "right",
                    }}
                  >
                    <Button variant="contained">Re-Capture</Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg="6" sm="6">
                <Box component={Paper} p={1} m={1}>
                  <Typography variant="subtitle3">
                    Capture photo vs. Recistration photo
                  </Typography>
                  <Grid sx={{ mt: 2 }}>
                    <DataTable
                      height="auto"
                      columns={columns}
                      rows={photoList}
                      onSelection={() => console.log()}
                      checkboxSelection={false}
                      hidePagination={true}
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </ListLayout>
  );
}
