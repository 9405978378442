import api from "../configurations/http-common";
export const getAuthorList = async () => {
  return api.securedAxios().get("/web/roleResource/detail");
};

export const getResourceList = async () => {
  return api.securedAxios().get("/web/roleResource/resourceList");
};

export const addRoleData = async (payload) => {
  return api.securedAxios().post("/web/roleResource/add", payload);
};

export const getRoleList = async (payload) => {
  return api.securedAxios().get("/web/roleResource/rolePageList", {
    params: payload,
  });
};

export const deleteRole = async (id) => {
  return api
    .securedAxios()
    .delete(`/web/roleResource/delete` + "?" + "roleId=" + id);
};

export const detailRole = async (id) => {
  return api
    .securedAxios()
    .get("/web/roleResource/detail" + "?" + "roleId=" + id);
};

export const editRole = async (payload) => {
  return api.securedAxios().put("/web/roleResource/update", payload);
};

// export const getRolesList = async (clientId) => {
//   return api
//     .securedAxios()
//     .get("/web/roleResource/roleList" + "?" + "clientId=" + clientId);
// };

export const getRolesList = async (clientId) => {
  let url = "/web/roleResource/roleList" + "?clientId=";
  if (clientId) {
    url += clientId;
  }
  return api.securedAxios().get(url);
};
export const getRolesPermission = async () => {
  return api.securedAxios().get("/web/roleResource/rolePermissionList");
};

export const getStoreOutlet = (clientId) => {
  return api.securedAxios().get("/web/outlets" + "?" + "clientId=" + clientId);
};
