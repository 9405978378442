import React, { useEffect, useState } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import { REACT_RETAIL_CLIENT_LIST } from "@/router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import { formatDateFromString } from "../../util/utils";
import CustomDatePicker from "../../components/CustomDatePicker";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  FormGroup,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  FormControlLabel,
  Radio,
  Checkbox,
  FormHelperText,
} from "@mui/material";

import AccessControl from "../../components/AccessControl";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CommonUtil from "../../util/CommonUtils";
import { selectCountry, selectState, selectCity } from "@/services/Location";
import { useTranslation } from "react-i18next";
import { updateClient, getPriceList } from "../../services/CompanyService";
import { useSnackbar } from "notistack";
import { getRolesList, getStoreOutlet } from "../../services/authoritation";
import { de } from "date-fns/locale";
export default function UpdateRetailClient() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { code } = useParams();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState("");
  const [createDate, setCreateDate] = useState(""); //订阅设备开始时间
  const [expiredDate, setExpiredDate] = useState(""); //设备有效期
  const [payload, setPayload] = useState({
    name: "",
    code: "",
    email: "",
    mobile: "",
    addressLine: "",
    cityName: "",
    stateName: "",
    countryName: "",
    pincode: "",
    type: "RETAIL_CLIENT",
    ownerEmail: "",
    firstName: "",
    lastName: "",
    roleId: "",
    deviceNum: "",
  });

  const [error, setError] = useState({
    name: "",
    code: "",
    email: "",
    mobile: "",
    addressLine: "",
    cityName: "",
    stateName: "",
    countryName: "",
    pincode: "",
    type: "RETAIL_CLIENT",
    ownerEmail: "",
    firstName: "",
    lastName: "",
    roleId: "",
    deviceNum: "",
  });
  let roleType = JSON.parse(localStorage.getItem("USER_INFO"))?.roleCode;
  let roleClientId = JSON.parse(localStorage.getItem("USER_INFO"))?.clientId;
  useEffect(() => {
    if (!roleClientId) {
      getRolesList().then((res) => {
        setRoles(res?.data?.data);
      });
    }
  }, []);

  const handleRoleSelection = (role) => {
    // console.log("GGGGGGGGGGGGGGGGG", role);
    setSelectedRoles(role);
  };

  useEffect(() => {
    selectCountry().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCountries(res?.data?.data);
      } else {
        setCountries([]);
      }
    });

    if (location?.state) {
      let current = location?.state;
      setPayload({ ...payload, ...current });
      setCountry(current.countryName);
      setCreateDate(current?.createDate);
      setExpiredDate(current?.expiredDate);
      setSelectedRoles(current?.roleId);
      setState(current.stateName);
      setCity(current.cityName);
    }
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(country)) {
      // let ID = country.id;
      const { id } = country;
      selectState({
        countryId: id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setStateList(res?.data?.data);
        }
      });
    } else {
      setStateList([]);
    }
  }, [country]);

  useEffect(() => {
    if (!CommonUtil.isEmpty(state)) {
      const { id } = state;
      selectCity({ stateId: id }).then((res) => {
        // console.log(">>>>>>>>>>>>>>>>>>Dfsdfs", res);
        if (res?.data?.code === "LVLI0000") {
          setCityList(res?.data?.data);
        }
      });
    } else {
      setCityList([]);
    }
  }, [state]);

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: t("LVL0001"),
      });
      return;
    }

    // if (!CommonUtil?.isDeviceNumber(payload?.deviceNum)) {
    //   setError({
    //     ...error,
    //     deviceNum: t("LVL0031"),
    //   });
    //   return;
    // }

    return true;
  };

  /**
   * 订阅 查询套餐id
   */

  const [priceValue, setPriceValue] = useState([]);
  const [priceStrategy, serPriceStrategy] = useState("");
  useEffect(() => {
    getPriceList().then((res) => {
      setPriceValue(res?.data?.data);
    });
  }, []);

  const handleSubmit = () => {
    // if (selectedRoles === "") {
    //   enqueueSnackbar(t("PCS78"), {
    //     variant: "warning",
    //   });
    //   return;
    // }

    // if (createDate === "") {
    //   enqueueSnackbar(t("PCS122"), {
    //     variant: "warning",
    //   });
    //   return;
    // }

    // if (expiredDate === "") {
    //   enqueueSnackbar(t("PCS120"), {
    //     variant: "warning",
    //   });
    //   return;
    // }

    if (validateForm()) {
      var request = {
        id: payload.id,
        name: payload.name,
        addressLine: payload.addressLine,
        // roleId: selectedRoles,
        // priceStrategyId: priceStrategy,
        // deviceNum: payload.deviceNum,
        // createDate: formatDateFromString(createDate),
        // expiredDate: formatDateFromString(expiredDate),
      };

      updateClient(request).then((response) => {
        if (response?.data?.code === "LVLI0000") {
          enqueueSnackbar(t("PLACE020"), {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
          navigate(REACT_RETAIL_CLIENT_LIST);
          return;
        }

        switch (response?.data?.code) {
          case "UASE0060":
            setError({
              ...error,
              name: response.data.message,
            });
            break;
          case "UASE0020":
            setError({
              ...error,
              ownerEmail: response.data.message,
            });
            break;
          case "UASE0011":
            setError({
              ...error,
              code: response.data.message,
            });
            break;
          default:
            enqueueSnackbar(response?.data?.message, { variant: "error" });
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  return (
    <RightViewLayout
      navigateBack={REACT_RETAIL_CLIENT_LIST}
      title={t("LVLRC0018")}
    >
      <Card elevation={0} sx={{ borderRadius: "10px" }}>
        <Grid container px={4} marginTop={2} marginBottom={5}>
          <Grid container spacing={2} px={2}>
            <Grid item md={6} xs={12}>
              <CustomInput
                required
                label={t("LVLRC0023")}
                size="small"
                name="name"
                error={error.name}
                value={payload.name}
                handleChange={handleChange}
                inputProps={{
                  maxLength: 60,
                }}
                helperText={error.name}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomInput
                required
                label={t("LVLRC0025")}
                size="small"
                name="code"
                error={error.code}
                value={payload.code}
                handleChange={handleChange}
                inputProps={{
                  maxLength: 30,
                }}
                helperText={error.code}
                validation={"xxcode"}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} px={2} mt={0}>
            <Grid item md={6} xs={12}>
              <CustomInput
                required
                label={t("LVLRC0004")}
                size="small"
                name="email"
                disabled
                value={payload.email}
                error={error.email}
                helperText={error.email}
                inputProps={{
                  maxLength: 36,
                }}
                validation="email"
                handleChange={handleChange}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <CustomInput
                required
                label={t("LVLRC0013")}
                size="small"
                name="firstName"
                value={payload.firstName}
                error={error.firstName}
                helperText={error.firstName}
                inputProps={{
                  maxLength: 100,
                }}
                validation="alpha-numeric"
                handleChange={handleChange}
                disabled
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ marginBottom: "-10px" }}
          >
            {t("LVLRC0005")}
          </InputLabel>
          <CustomePhoneFiled
            disabled
            error={error.mobile}
            handleCountryCode={(data) => {
              setCountryCode(data.dialCode);
              setError({ ...error, mobile: "" });
            }}
            countryCode={countryCode}
            placeholder={t("LVLRC0005")}
            phoneNumber={phoneNumber}
            name={"phoneNumber"}
            size={"small"}
            handleChange={(e) => {
              setPhoneNumber(e);
              setError({ ...error, mobile: "" });
            }}
            label={"Mobile"}
          />
        </Grid> */}
          </Grid>

          <Grid container spacing={2} px={2} mt={0}>
            <Grid item md={6} xs={12}>
              <CustomInput
                label={t("LVLRC0014")}
                size="small"
                name="lastName"
                value={payload.lastName}
                error={error.lastName}
                helperText={error.lastName}
                inputProps={{
                  maxLength: 100,
                }}
                validation="alpha-numeric"
                handleChange={handleChange}
                disabled
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ paddingLeft: "0px" }}
              >
                {t("LVLRC0007")} <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Autocomplete
                disabled
                options={countries}
                value={country}
                onChange={(e, v) => {
                  setCountry(v);
                  setPayload({
                    ...payload,
                    countryName: v,
                    stateName: "",
                    cityName: "",
                  });
                  setState({});
                  setCity({});
                  setError({ ...error, countryName: "" });
                }}
                getOptionLabel={(option) => {
                  return option ? option : "";
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={!CommonUtil.isEmptyString(error.countryName)}
                    helperText={error.countryName}
                  />
                )}
              ></Autocomplete>
            </Grid>
          </Grid>
          <Grid container spacing={2} px={2} mt={0}>
            {/* <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLRC0015")}
            size="small"
            name="ownerEmail"
            value={payload.ownerEmail}
            error={error.ownerEmail}
            helperText={error.ownerEmail}
            inputProps={{
              maxLength: 36,
            }}
            validation="email"
            handleChange={handleChange}
            disabled
          />
        </Grid> */}

            <Grid item md={6} xs={12}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ paddingLeft: "0px" }}
              >
                {t("LVLRC0008")} <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Autocomplete
                error={error.stateName}
                helperText={error.stateName}
                options={stateList}
                disabled
                value={state}
                onChange={(e, v) => {
                  setState(v);
                  setCity({});
                  setError({ ...error, stateName: false });
                }}
                getOptionLabel={(option) => (option ? option : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    helperText={error.stateName}
                    error={error.stateName}
                  />
                )}
              ></Autocomplete>
            </Grid>

            <Grid item md={6} xs={12}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                style={{ paddingLeft: "0px" }}
              >
                {t("LVLRC0009")} <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Autocomplete
                disabled
                error={error.cityName}
                helperText={error.cityName}
                options={cityList}
                value={city}
                onChange={(e, v) => {
                  setCity(v);
                  setError({ ...error, cityName: false });
                }}
                getOptionLabel={(option) => (option ? option : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={error.cityName}
                    helperText={error.cityName}
                  />
                )}
              ></Autocomplete>
            </Grid>
          </Grid>

          <Grid container spacing={2} px={2} mt={0}>
            <Grid item xs={12}>
              <CustomInput
                label={t("LVLRC0006")}
                size="small"
                name="addressLine"
                value={payload.addressLine}
                error={error.addressLine}
                helperText={error.addressLine}
                inputProps={{ maxLength: 255 }}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>

      {/* <Card elevation={0} sx={{ borderRadius: "10px", mt: 2 }}>
        <Grid m={2}>
          <Typography fontSize={"0.4rem"} fontWeight={500}>
            Subscription
          </Typography>
        </Grid>
        <Grid container spacing={2} px={6} mt={2} mb={2}>
          <Grid item md={6} xs={12}>
            <CustomInput
              required
              label={t("LVLRC0047")}
              size="small"
              name="deviceNum"
              error={error.deviceNum}
              value={payload.deviceNum}
              disabled={!roleType.endsWith("ADMIN")}
              handleChange={handleChange}
              inputProps={{
                maxLength: 60,
              }}
              validation={"Devicenumber"}
              helperText={error.deviceNum}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomDatePicker
              size={"small"}
              disableFuture={false}
              disabled={true}
              fullWidth
              required={true}
              type={1}
              date={createDate}
              label={t("LVLRC0049")}
              SelectedDate={(e) => setCreateDate(e)}
            />
            {(createDate === "" ||
              createDate === null ||
              createDate === undefined) && (
              <FormHelperText>{t("LVL0001")}</FormHelperText>
            )}
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomDatePicker
              size={"small"}
              disableFuture={false}
              disabled={true}
              fullWidth
              required={true}
              type={1}
              date={expiredDate}
              label={t("LVLRC0048")}
              SelectedDate={(e) => setExpiredDate(e)}
            />
            {(expiredDate === "" ||
              expiredDate === null ||
              expiredDate === undefined) && (
              <FormHelperText>{t("LVL0001")}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </Card> */}
      {/* 
      {!roleClientId ? (
        <Card elevation={0} sx={{ borderRadius: "10px", marginTop: "20px" }}>
          <Grid pt={2} marginLeft={2}>
            <Typography pb={2} fontSize={"0.4rem"} fontWeight={500}>
              {t("PCS74")}
            </Typography>
            <Grid container columnSpacing={2}>
              {roles?.map((role, index) => (
                <Grid item key={role.id} xs={6} sm={4} md={3} lg={2}>
                  <FormControlLabel
                    control={
                      <Radio
                        id={`role-radio-${index}`}
                        checked={selectedRoles === role.id}
                        onChange={() => handleRoleSelection(role.id)}
                        value={selectedRoles}
                        name="role-selection"
                      />
                    }
                    label={role.name}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Card>
      ) : null} */}

      <Grid mt={2} container spacing={2} px={2}>
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"row-reverse"}>
            <Box item pl={2}>
              <AccessControl authorization={"1006"}>
                <Button
                  variant="contained"
                  size="large"
                  className="text-transform-none"
                  onClick={handleSubmit}
                >
                  {t("LVLRC0016")}
                </Button>
              </AccessControl>
            </Box>
            <Box item>
              <Button
                className="text-transform-none"
                variant="outlined"
                onClick={() => navigate(REACT_RETAIL_CLIENT_LIST)}
                size="large"
              >
                {t("LVLRC0017")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
