import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import CommonUtil from '../util/CommonUtils';


export default function OverFlowText(props) {
  return (
    <Tooltip title={props.children}>
      <Typography variant={props.variant}>{CommonUtil.handleTextOverflow(props.children, props.maxLength)}</Typography>
    </Tooltip>
  );
}
