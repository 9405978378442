import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Paper, Typography, Card } from "@mui/material";
import BarCharts from "./BarChart";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useTranslation } from "react-i18next";
import AppLanguage from "@/lang/AppLanguages";
import { parseNumber } from "@/util/parseNumber";
import { styled } from "@mui/material/styles";
function ContentBoard(props) {
  const {
    highEst,
    highEstData,
    overAll,
    barChartsData,
    previousPeriodsData,
    startValueTime,
    startTimes,
    storeName,
    animationValue,
    setAnimationValue,
  } = props;
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    const compareTime = new Date(startValueTime);
    const times = new Date(startTimes);

    const language = AppLanguage.getPrevLanguage();
    let startMonth;
    let endMonth;
    if ("zh" === language) {
      startMonth = compareTime.toLocaleString("zh-CN", {
        month: "short",
      });
      endMonth = times.toLocaleString("zh-CN", { month: "short" });
    } else {
      startMonth = compareTime
        .toLocaleString("en-US", {
          month: "short",
        })
        .toUpperCase();
      endMonth = times
        .toLocaleString("en-US", { month: "short" })
        .toUpperCase();
    }
    setStartMonth(startMonth);
    setEndMonth(endMonth);
  }, [startTimes, startValueTime]);

  return (
    <Grid
      sx={{
        bgcolor: "#fff",
        border: "1px solid #eaebed",
        borderRadius: "0.2rem",
        padding: "0.5rem 0.6rem",
      }}
    >
      <Typography variant="h2" component="h2">
        {t("PCS12")}
      </Typography>

      <Grid display={"flex"} columns={24} flexGrow={1} mt={2}>
        <Grid size={6}>
          <Grid
            sx={{
              background: "#fff",
              textAlign: "center",
              borderRadius: "15px",
              marginLeft: "1vw",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.4)",
              padding: "0.2rem 0.4rem",
            }}
          >
            <Box
              sx={{
                padding: "10px 20px",
                fontSize: "0.4rem",
                whiteSpace: "nowrap",
              }}
            >
              {t("PCS13")}
            </Box>
            <Box
              sx={{ fontWeight: 700, whiteSpace: "nowrap", fontSize: "0.4rem" }}
            >
              {startMonth} - {endMonth}
            </Box>
            <Box
              sx={{
                whiteSpace: "nowrap",
                color: "#fff",
                padding: "0.6rem 0",
                fontSize: "0.5rem",
                borderRadius: "8px",
                background:
                  overAll < 0
                    ? "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);"
                    : "linear-gradient(to right, #37ecba 0%, #72afd3 100%);",

                textAlign: "center",
              }}
            >
              {overAll < 0 ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
              {overAll}%
            </Box>
          </Grid>

          <Grid
            sx={{
              background: "#fff",
              textAlign: "center",
              borderRadius: "15px",
              marginLeft: "1vw",
              marginTop: "2vh",
              padding: "0.2rem 0.4rem",
              boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.4)",
            }}
          >
            <Box
              sx={{
                padding: "10px 20px",
                fontSize: "0.4rem",
                whiteSpace: "nowrap",
              }}
              mt={1}
            >
              {t("PCS14")}
            </Box>
            <Box
              fontWeight={700}
              sx={{ fontSize: "0.4rem", whiteSpace: "nowrap" }}
            >
              {highEstData}
            </Box>
            <Box
              sx={{
                color: "#fff",
                borderRadius: "8px",
                background:
                  "linear-gradient(to right, #4facfe 0%, #00f2fe 100%)",
                textAlign: "center",
                padding: "0.4rem 0",
              }}
            >
              <Box sx={{ fontSize: "0.5rem" }}>{parseNumber(highEst)}</Box>
              <Box sx={{ fontSize: "0.3rem" }}>{t("PCS31")}</Box>
            </Box>
          </Grid>
        </Grid>

        <Grid ml={2} flex={1}>
          <BarCharts
            barChartsData={barChartsData}
            previousPeriodsData={previousPeriodsData}
            storeName={storeName}
            animationValue={animationValue}
            setAnimationValue={setAnimationValue}
          ></BarCharts>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContentBoard;
