import DefaultUserPic from "@/assets/images/default_user_pic.svg";
import { useDispatchUser } from "@/hooks/user";
import {
  REACT_UPDATE_USER_PROFILE,
  REACT_VIEW_USER_PROFILE,
} from "@/router/ReactEndPoints";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Avatar, Box, MenuItem, Tooltip } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import CommonUtil from "../../util/CommonUtils";

export default function UserProfileMenu() {
  const navigate = useNavigate();
  const { stateSetUser } = useDispatchUser();
  const handleView = () => {
    navigate(REACT_VIEW_USER_PROFILE);
  };

  const handleUpdate = () => {
    navigate(REACT_UPDATE_USER_PROFILE);
  };

  const [user, setUser] = useState({});
  useEffect(() => {
    let user = CommonUtil.decodeToken();
    if (user) {
      getUser(user?.id, true);
    }
  }, []);

  const getUser = async (id, loadProfileImage) => {
    await UserService.getUserDetails(id, loadProfileImage).then((res) => {
      if (!res?.data?.data) {
        navigate("/");
        return;
      }
      if (res?.data?.data) {
        stateSetUser(res.data.data);
        localStorage.setItem("USER_INFO", JSON.stringify(res?.data?.data));
        setUser(res?.data?.data);
      }
    });
  };

  const logout = () => {
    CommonUtil.resetUserSession();
    window.location = "/";
  };

  var lastName = !CommonUtil.isEmptyString(user?.lastName)
    ? user?.lastName
    : "";
  var userName = user?.firstName + " " + lastName;

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });
  return (
    <div>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        style={{ cursor: "pointer" }}
        {...bindTrigger(popupState)}
        sx={{ pl: { xs: 0, md: 2 }, pr: 0, my: { xs: 0, md: 1 } }}
      >
        <Box sx={{ py: { xs: 2, md: 0.5 } }} pl={2} pr={1}>
          <Avatar src={DefaultUserPic} sx={{ width: 24, height: 24 }} />
        </Box>
        <Box flexGrow={1} sx={{ display: { md: "flex" } }}>
          <Tooltip title={userName} arrow placement="bottom">
            <Typography variant="menuItem">
              {CommonUtil.formatLongText(userName.slice(0, 20))}
            </Typography>
          </Tooltip>
        </Box>
        <Box py={0.5} px={2} sx={{ display: { md: "flex" } }}>
          <Typography variant="menuItem">
            {" "}
            {popupState.isOpen ? (
              <KeyboardArrowLeftIcon fontSize="small" />
            ) : (
              <KeyboardArrowRightIcon fontSize="small" />
            )}
          </Typography>
        </Box>
      </Box>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        {/* <MenuItem onClick={popupState.close}>Edit Profile</MenuItem> */}

        <MenuItem
          onClick={() => {
            popupState.close();
            handleView();
          }}
        >
          {t("LVL0025")}
        </MenuItem>
        {}
        <MenuItem
          onClick={() => {
            popupState.close();
            handleUpdate();
          }}
        >
          {t("LVLDB0021")}
        </MenuItem>

        <MenuItem
          onClick={() => {
            popupState.close();
            logout();
          }}
        >
          {t("LVLDB0034")}
        </MenuItem>
      </Popover>
    </div>
  );
}
