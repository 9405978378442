export const REACT_DASHBOARD = "/dashboard";

export const REACT_PEOPLECOUNTING_SUMMARY = "/SummaryPeopleCounting";
export const REACT_SHELFMONITORING_SUMMARY = "/SummaryShelfMonitoring";
export const REACT_DEMOGRAPHIC_SUMMARY = "/SummaryDemographic";
export const REACT_PEOPLECOUNTING = "/PeopleCounting";
export const REACT_SHELFMONITORING = "/ShelfMonitoring";
export const REACT_DEMOGRAPHIC = "/Demographic";
export const REACT_LOCATION = "/location";

export const REACT_RETAIL_CLIENT_LIST = "/retail-client";
export const REACT_RETAIL_CLIENT_ADD = "/retail-client/add";
export const REACT_RETAIL_CLIENT_UPDATE = "/retail-client/update/";
export const REACT_RETAIL_CLIENT_VIEW = "/retail-client/";

export const REACT_OUTLET_LIST = "/outlet";
export const REACT_OUTLET_ADD = "/outlet/add";
export const REACT_OUTLET_EDIT = "/outlet/update/";
export const REACT_OUTLET_VIEW = "/outlet/";

export const REACT_DEVICE_LIST = "/device";
export const REACT_DEVICE_ADD_INSTRUCTIONS = "/device/add/instructions";
export const REACT_DEVICE_ADD = "/device/add";
export const REACT_DEVICE_EDIT = "/device/edit/";
export const REACT_DEVICE_SETTING = "/device/set/";
export const REACT_DEVICE_DETAILS = "/device/view/";
export const REACT_DEVICE_SKU = "/device/sku";
export const REACT_CHANNEL_SETTING = "/device/channel/setting";

export const REACT_FORGOT_PASSWORD = "/partner-portal/forgot-password";
export const REACT_REGISTER = "/register";

export const REACT_METADATA_LIST = "/metadata";

export const REACT_PRODUCT_LIST = "/product";
export const REACT_PRODUCT_Add = "/product/add";
export const REACT_PRODUCT_EDIT = "/product/update/";

export const REACT_PRINCIPAL_MANAGEMENT = "/principal";
export const REACT_PRINCIPAL_ADD = "/principal/add";
export const REACT_PRINCIPAL_VIEW = "/principal/view/";
export const REACT_PRINCIPAL_UPDATE = "/principal/view/UpdatePrincipal/";

export const REACT_VIEW_USER_PROFILE = "/view-user-profile";
export const REACT_UPDATE_USER_PROFILE = "/update-user-profile";

export const RETAIL_CLIENT = "/company/";
export const REACT_PERSON_LIST = "/person";
export const REACT_PERSON_ADD = "/person/add";
export const REACT_PERSON_VIEW = "/person/";
export const REACT_PERSON_EDIT = "/person/edit/";

export const REACT_VIEW_COMPANY_PROFILE = "/view-company-profile";
export const REACT_RESET_COMPANY_PASSWORD = "/company/activate-account";
export const REACT_RESET_PERSON_PASSWORD = "/person/activate-account";

export const REACT_AUTHORITATION = "/Authoritation";
export const REACT_ADD_AUTHORITATION = "/Authoritation/add";
export const REACT_VIEW_AUTHORITATION = "/Authoritation/view";
export const REACT_EDIT_AUTHORITATION = "/Authoritation/edit";

export const REACT_OPEN_API_LIST = "/OpenAPI/list";
export const REACT_OPEN_API_ADD = "/OpenAPI/Add";
export const REACT_JUMP = "/jump";

export const REACT_TENANT_MANAGER_LIST = "/tenant/list";
export const REACT_TENANT_MANAGER_ADD = "/tenant/Add";

export const REACT_PERSON_MANAGER_LIST = "/person/list";
export const REACT_PERSON_MANAGER_ADD = "/person/add";
export const REACT_PERSON_MANAGER_VIEW = "/person/view";
export const REACT_RERSON_MANAGER_EDIT = "/person/edit";

export const REACT_SUB_HISTORY_LIST = "/SubRecord";
export const REACT_SUB_HISTORY_ADD = "/subscriptionRecords/add";

export const REACT_SUB_HANDLER_LIST = "/Subscription";
export const REACT_SUB_HANDLER_ADD = "/subscription/add";
