import React, { useEffect, useState } from "react";
import RightViewLayout from "../../components/RighViewLayout";
import {
  REACT_PRINCIPAL_MANAGEMENT,
  REACT_RETAIL_CLIENT_LIST,
} from "@/router/ReactEndPoints";
import CustomInput from "../../components/CustomInput";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomePhoneFiled from "../../components/CustomePhoneFiled";
import {
  getAllCities,
  getAllCountries,
  getAllStates,
} from "../../services/LocationService";
import CommonUtil from "../../util/CommonUtils";
import { useTranslation } from "react-i18next";
import { createClient } from "../../services/CompanyService";
import { useSnackbar } from "notistack";
import { getCityZoneList } from "../../services/CityZoneService";
import { getOutletTypeList } from "../../services/OutletTypeService";
import { getStateListByCountryId } from "../../services/StateService";
import { getCityListByState } from "../../services/CityService";
import { selectCountry, selectState, selectCity } from "@/services/Location";
export default function AddPrincipal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [countries, setCountries] = useState([]);

  const [country, setCountry] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});
  const [stateList, setStateList] = useState({});
  const [cityList, setCityList] = useState({});
  //   const [mobileArea, setmobileArea] = useState("");
  //   const [mobile, setmobile] = useState("");

  const [mobileArea, setMobileArea] = useState("");
  const [mobile, setMobile] = useState("");

  const [payload, setPayload] = useState({
    addressLine: "",
    // addressLine2: "",
    city: "",
    code: "",
    country: "",
    createRequestedFrom: "",
    createdAt: "",
    createdBy: "",
    id: "",
    latitude: "",
    longitude: "",
    name: "",
    pincode: "",
    state: "",
    status: "",
    type: "PRINCIPAL",
    updateRequestedFrom: "",
    updatedAt: "",
    updatedBy: "",
  });

  const [error, setError] = useState({
    addressLine: "",
    // addressLine2: "",
    city: "",
    code: "",
    country: "",
    createRequestedFrom: "",
    createdAt: "",
    createdBy: "",
    id: "",
    latitude: "",
    longitude: "",
    name: "",
    pincode: "",
    state: "",
    status: "",
    type: "PRINCIPAL",
    updateRequestedFrom: "",
    updatedAt: "",
    updatedBy: "",
  });

  useEffect(() => {
    selectCountry().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCountries(res?.data?.data);
      } else {
        setCountries([]);
      }
    });
  }, []);

  useEffect(() => {
    setState({});
    setCity({});
    if (!CommonUtil.isEmpty(country)) {
      // let ID = country.id;
      const { id } = country;
      selectState({
        countryId: id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setStateList(res?.data?.data);
        }
      });
    } else {
      setStateList([]);
    }
  }, [country]);

  useEffect(() => {
    setCity({});
    if (!CommonUtil.isEmpty(state)) {
      const { id } = state;
      selectCity({ stateId: id }).then((res) => {
        // console.log(">>>>>>>>>>>>>>>>>>Dfsdfs", res);
        if (res?.data?.code === "LVLI0000") {
          setCityList(res?.data?.data);
        }
      });
    } else {
      setCityList([]);
    }
  }, [state]);

  const validateForm = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({
        ...error,
        name: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmptyString(payload.code)) {
      setError({
        ...error,
        code: t("LVL0001"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.email)) {
      setError({
        ...error,
        email: t("LVL0001"),
      });
      return;
    } else if (!CommonUtil.isValidEmail(payload.email)) {
      setError({
        ...error,
        email: t("LVL0003"),
      });
      return;
    }
    if (!CommonUtil.isEmptyString(mobile)) {
      if (CommonUtil.isEmptyString(mobileArea)) {
        setError({
          ...error,
          phone: t("LVL0007"),
        });
        return;
      }
      var mobileStr = "+" + mobileArea.replace("+", "") + "-" + mobile;
      if (mobileStr.length < 10 || mobileStr.length > 25) {
        setError({
          ...error,
          phone: t("LVL0008"),
        });
        return false;
      }
    }
    if (!CommonUtil.isEmptyString(mobileArea)) {
      if (CommonUtil.isEmptyString(mobile)) {
        setError({
          ...error,
          phone: t("LVLRCP010"),
        });
        return;
      }
    }

    if (CommonUtil.isEmptyString(payload.firstName)) {
      setError({
        ...error,
        firstName: t("LVL0001"),
      });
      return;
    }

    if (CommonUtil.isEmptyString(payload.countryId)) {
      setError({
        ...error,
        countryId: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmpty(state)) {
      setError({
        ...error,
        state: t("LVL0001"),
      });
      return;
    }
    if (CommonUtil.isEmpty(city)) {
      setError({
        ...error,
        city: t("LVL0001"),
      });
      return;
    }
    return true;
  };
  const handleSubmit = () => {
    if (validateForm()) {
      var request = {
        ...payload,
        countryId: country.id,
        stateId: state.id ? state.id : "",
        cityId: city.id ? city.id : "",
        mobileArea,
        mobile,
      };
      createClient(request).then((response) => {
        if (response?.data?.code === "LVLI0008") {
          enqueueSnackbar(response.data.message, {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            style: {
              marginTop: "300px",
            },
          });
          navigate(REACT_PRINCIPAL_MANAGEMENT);
          return;
        }
        switch (response?.data?.code) {
          case "UASE0060":
            setError({
              ...error,
              name: response?.data?.message,
            });
            break;
          case "UASE0020":
            setError({
              ...error,
              superAdminEmail: t("PLACE05"),
            });
            break;
          case "UASE0011":
            setError({
              ...error,
              code: response?.data?.message,
            });
            break;
          default:
            enqueueSnackbar(response?.data?.message, { variant: "error" });
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: "",
      common: "",
    });
  };

  return (
    <RightViewLayout
      navigateBack={REACT_PRINCIPAL_MANAGEMENT}
      title={t("LVLDB0024")}
    >
      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLRC0002")}
            size="small"
            name="name"
            error={error.name}
            value={payload.name}
            handleChange={handleChange}
            inputProps={{
              maxLength: 60,
            }}
            helperText={error.name}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLRC0003")}
            size="small"
            name="code"
            error={error.code}
            value={payload.code}
            handleChange={handleChange}
            inputProps={{
              maxLength: 30,
            }}
            helperText={error.code}
            validation={"code"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLRC0004")}
            size="small"
            name="email"
            value={payload.email}
            error={error.email}
            helperText={error.email}
            inputProps={{
              maxLength: 36,
            }}
            validation="email"
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ marginBottom: "-10px" }}
          >
            {t("LVLRC0005")}
          </InputLabel>
          <CustomePhoneFiled
            error={error.phone}
            handleCountryCode={(data) => {
              setMobileArea(data.dialCode);
              setError({ ...error, mobileArea: "" });
            }}
            countryCode={mobileArea}
            placeholder={t("LVLRC0005")}
            phoneNumber={mobile}
            name={"phoneNumber"}
            size={"small"}
            handleChange={(e) => {
              setMobile(e);
              setError({ ...error, mobile: "" });
            }}
            label={"Mobile"}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLRC0013")}
            size="small"
            name="firstName"
            value={payload.firstName}
            error={error.firstName}
            helperText={error.firstName}
            inputProps={{
              maxLength: 100,
            }}
            validation="alpha-numeric"
            handleChange={handleChange}
          />
        </Grid>
        {/* <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLRC0013")}
            size="small"
            name="superAdminFirstName"
            value={payload.superAdminFirstName}
            error={error.superAdminFirstName}
            helperText={error.superAdminFirstName}
            inputProps={{
              maxLength: 100,
            }}
            validation="alpha-numeric"
            handleChange={handleChange}
          />
        </Grid> */}
        <Grid item md={6} xs={12}>
          <CustomInput
            label={t("LVLRC0014")}
            size="small"
            name="lastName"
            value={payload.lastName}
            error={error.lastName}
            helperText={error.lastName}
            inputProps={{
              maxLength: 100,
            }}
            validation="alpha-numeric"
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2}>
        {/* <Grid item md={6} xs={12}>
          <CustomInput
            required
            label={t("LVLRC0015")}
            size="small"
            name="superAdminEmail"
            value={payload.superAdminEmail}
            error={error.superAdminEmail}
            helperText={error.superAdminEmail}
            inputProps={{
              maxLength: 36,
            }}
            validation="email"
            handleChange={handleChange}
          />
        </Grid> */}
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLRC0007")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            options={countries}
            value={country}
            onChange={(e, v) => {
              setCountry(v);
              setPayload({ ...payload, countryId: v ? v.id : "" });
              setError({ ...error, countryId: "" });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={!CommonUtil.isEmptyString(error.countryId)}
                helperText={error.countryId}
              />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2}>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLRC0008")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            error={error.state}
            helperText={error.state}
            options={stateList}
            value={state}
            onChange={(e, v) => {
              setState(v);
              setError({ ...error, state: false });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.state}
                helperText={error.state}
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            style={{ paddingLeft: "0px" }}
          >
            {t("LVLRC0009")} <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            error={error.city}
            helperText={error.city}
            options={cityList}
            value={city}
            onChange={(e, v) => {
              setCity(v);
              setError({ ...error, city: false });
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                error={error.city}
                helperText={error.city}
              />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
      <Grid container spacing={2} px={2}>
        <Grid item xs={12}>
          <CustomInput
            label={t("LVLDB0025")}
            size="small"
            name="addressLine"
            value={payload.addressLine}
            error={error.addressLine}
            helperText={error.addressLine}
            inputProps={{ maxLength: 255 }}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>

      {/* <Grid container spacing={2} px={2}>
        <Grid item xs={12}>
          <CustomInput
            label={t("Address Line2")}
            size="small"
            name="addressLine2"
            value={payload.addressLine2}
            error={error.addressLine2}
            helperText={error.addressLine2}
            inputProps={{ maxLength: 255 }}
            handleChange={handleChange}
          />
        </Grid>
      </Grid> */}

      <Grid mt={2} container spacing={2} px={2}>
        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"row-reverse"}>
            <Box item pl={2}>
              <Button
                variant="contained"
                size="large"
                className="text-transform-none"
                onClick={handleSubmit}
              >
                {t("LVLRC0016")}
              </Button>
            </Box>
            <Box item>
              <Button
                className="text-transform-none"
                variant="outlined"
                onClick={() => navigate(REACT_RETAIL_CLIENT_LIST)}
                size="large"
              >
                {t("LVLRC0017")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </RightViewLayout>
  );
}
