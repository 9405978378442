const agreeKey = "ZK_isReadAgree";

export const getAgree = () => {
  const agreeValue = JSON.parse(window.localStorage.getItem(agreeKey));
  // console.log(typeof agreeValue);
  if (agreeValue && (agreeValue === false || agreeValue === true)) {
    return agreeValue;
  } else {
    return false;
  }
};

export const setAgree = (value) => {
  window.localStorage.setItem(agreeKey, JSON.stringify(value));
};
