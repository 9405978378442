import { createSlice } from "@reduxjs/toolkit";
// 初始化状态
const initialState = {
  userInfor: {},
  permission: [],
};

// 创建分片
const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfor(state, action) {
      state.userInfor = action.payload;
    },
    clearUser(state) {
      state.userInfor = {};
    },
    // 权限字符串
    setPermission(state, action) {
      state.permission = action.payload;
    },
  },
});

// 导出
export default user.reducer;

export const { setUserInfor, clearUser, getUserInfor, setPermission } =
  user.actions;
