import { Box, Tooltip, Typography, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as KeyBoardLeftArrowIcon } from "@/assets/images/KeyboardArrowLeftIcon.svg";
import { t } from "i18next";
const TitleBar = (props) => {
  let navigate = useNavigate();
  return (
    <>
      <Box
        display={"flex"}
        mt={2.0}
        mb={1.0}
        height={"5vh"}
        width="100%"
        alignItems={"center"}
      >
        {props.navigateBack && (
          <Grid pr={0.7} pt={0.6} ml={0.4}>
            <Tooltip title={t("LVLOT0015")}>
              <KeyBoardLeftArrowIcon
                // style={{ color: "#474B4F", fontSize: "30px", fontWeight: "500", cursor:'pointer' }}
                style={{ cursor: "pointer" }}
                width={17}
                height={17}
                fontSize="small"
                onClick={() =>
                  props?.navigateBack === "-1"
                    ? navigate(-1)
                    : navigate(props?.navigateBack)
                }
              />
            </Tooltip>
          </Grid>
        )}
        <Grid flexGrow={1}>
          <Typography variant="title">{props.title}</Typography>
        </Grid>

        {props.children}
      </Box>
    </>
  );
};
export default TitleBar;
