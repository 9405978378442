import { useState } from "react";
import CmpFormik from "@/components/Retail-components/CmpFormik";
import RightViewLayout from "@/components/RighViewLayout";
import { REACT_TENANT_MANAGER_LIST } from "@/router/ReactEndPoints";
import { addTenant, updateTenant } from "@/services/TenantService";
import { Button, Card, Grid } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { createValidation } from "../../util/validationUtils";
import LoadingButton from "@mui/lab/LoadingButton";
export default function AddTenant(props) {
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const location = useLocation();
  const { isAdd, currentItem } = location?.state;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  let formConfig = [
    {
      name: "companyName",
      label: t("LVLDAC0038"),
      type: "input",
      placeholder: t("tenant.enter_company_name"),
      required: true,
      display: true,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("tenant.enter_company_name"),
        },
      ],
    },

    {
      name: "address",
      label: t("tenant.address"),
      type: "input",
      placeholder: t("tenant.enter_address"),
      required: true,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("tenant.enter_address"),
        },
      ],
    },

    {
      name: "info",
      label: t("tenant.info"),
      type: "input",
      placeholder: t("tenant.enter_info"),
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "max",
          value: 2000,
          message: "不能超过2000个字符",
        },
      ],
    },

    {
      name: "contactEmail",
      label: t("tenant.account"),
      type: "input",
      disabled: !isAdd,
      placeholder: t("tenant.enter_account"),
      required: true,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("tenant.enter_account"),
        },
        {
          type: "email",
          message: t("system.system_rule_email"),
        },
      ],
    },
    {
      name: "contactPhone",
      label: t("tenant.phone"),
      type: "input",
      placeholder: t("tenant.enter_phone"),
      required: true,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("tenant.enter_phone"),
        },
      ],
    },

    {
      name: "password",
      label: t("LVLRCP023"),
      type: "input",
      inputType: "password",
      disabled: !isAdd,
      placeholder: t("LVLRCP025"),
      display: isAdd === true ? true : false,
      display: isAdd === true ? true : false,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          value: 6,
          message: t("LVLRCP025"),
        },
        {
          type: "min",
          value: 6,
          message: t("system.system_rule_password_length"),
        },
        {
          type: "max",
          value: 30,
          message: t("system.system_rule_password_length"),
        },
      ],
    },
    {
      name: "confirmPassword",
      label: t("LVLRCP024"),
      type: "input",
      inputType: "password",
      disabled: !isAdd,
      placeholder: t("LVLRCP026"),
      display: isAdd === true ? true : false,
      display: isAdd === true ? true : false,
      validation: [
        {
          type: "string",
          message: "",
        },
        {
          type: "required",
          message: t("LVLRCP026"),
        },
        {
          type: "secondConfirm",
          message: t("LVLDAC0026"),
        },
      ],
    },
  ];

  let initialValues = {};
  if (isAdd) {
    initialValues = {
      contactEmail: "",
      password: "",
      companyName: "",
      address: "",
      info: "",
      contactPhone: "",
      confirmPassword: "",
    };
  } else {
    initialValues = {
      id: currentItem?.id,
      contactEmail: currentItem?.contactEmail,
      companyName: currentItem?.companyName,
      address: currentItem?.address,
      info: currentItem?.info,
      contactPhone: currentItem?.contactPhone,
    };
  }

  const addFormik = useFormik({
    initialValues: initialValues,
    validationSchema: createValidation(formConfig),
    onSubmit: (values) => {
      if (isAdd) {
        setIsLoading(true);
        addTenant(values).then((res) => {
          if (res?.data?.code === "LVLI0000") {
            enqueueSnackbar(t("PCS84"), {
              variant: "success",
            });
            setIsLoading(false);
            navigate(REACT_TENANT_MANAGER_LIST);
          } else {
            enqueueSnackbar(res?.data?.message, {
              variant: "error",
            });
            setIsLoading(false);
          }
        });
      } else {
        setIsLoading(true);
        updateTenant(values).then((res) => {
          if (res?.data?.code === "LVLI0000") {
            enqueueSnackbar(t("PCS86"), {
              variant: "success",
            });
            setIsLoading(false);
            navigate(REACT_TENANT_MANAGER_LIST);
          } else {
            enqueueSnackbar(res?.data?.message, {
              variant: "error",
            });
            setIsLoading(false);
          }
        });
      }
    },
  });

  return (
    <RightViewLayout
      navigateBack={"/tenant/list"}
      title={isAdd ? t("tenant.addTenant") : t("tenant.editTenant")}
    >
      <Card>
        <Grid m={4}>
          <CmpFormik sx={6} formik={addFormik} formConfig={formConfig}>
            <Grid
              item
              xs={12}
              display={"flex"}
              flexDirection={"row-reverse"}
              mt={2}
              container
            >
              <LoadingButton
                variant="contained"
                size="large"
                type="submit"
                loading={isLoading}
                sx={{
                  ml: 3,
                }}
                className="text-transform-none"
              >
                {t("LVLOT0016")}
              </LoadingButton>

              <Button
                className="text-transform-none"
                variant="outlined"
                onClick={() => navigate("/tenant/list")}
                size="large"
              >
                {t("LVLOT0015")}
              </Button>
            </Grid>
          </CmpFormik>
        </Grid>
      </Card>
    </RightViewLayout>
  );
}
