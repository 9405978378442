import * as React from "react";
import Box from "@mui/material/Box";
import { ReactComponent as Phone } from "@/assets/images/icon_phone.svg";
import { ReactComponent as Email } from "@/assets/images/icon_email.svg";
import { Grid, InputLabel, Tooltip } from "@mui/material";
import CustomInput from "./CustomInput";
import { useTranslation } from "react-i18next";
import CustomePhoneFiled from "./CustomePhoneFiled";
import { useEffect } from "react";
import CommonUtil from "../util/CommonUtils";

export default function UserNameField(props) {
  const [fieldType, setFieldType] = React.useState("Email");
  const { t } = useTranslation();
  useEffect(() => {
    props.onChange("");
    props.setUserNameType(fieldType);
    props.onCountryCodeChange("");
  }, [fieldType]);

  const emailFiled = () => (
    <Box
      component="form"
      noValidate
      sx={{
        display: "flex",
        gridTemplateColumns: { sm: "1fr 1fr" },
        gap: 2,
      }}
    >
      <CustomInput
        validation="email"
        inputProps={{
          maxLength: 36,
        }}
        label={t("LVLDAC0020")}
        size="small"
        required
        placeholder={t("LVLDAC0020")}
        id="bootstrap-input"
        fullWidth={true}
        {...props}
        handleChange={(e) => props.onChange(e.target.value)}
      />
      {/* <Tooltip title='Login with mobile'>
        <Phone onClick={() => setFieldType('Phone')} style={{ height: '45px', width: '45px', marginTop: '20px', cursor: 'pointer' }} />
      </Tooltip> */}
    </Box>
  );

  const phoneFiled = () => (
    <Grid sx={{ mb: "-8px", mt: "-1.8px" }}>
      <Box>
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          style={{ marginBottom: "-14px" }}
        >
          <span style={{ fontSize: "16px" }}>{"Mobile"}</span> &nbsp;
          {
            <span
              style={{ fontSize: "19px", color: "red", marginLeft: "-4px" }}
            >
              *
            </span>
          }
        </InputLabel>
        <Grid style={{ display: "flex" }}>
          <Tooltip title="Login with Email">
            <Email
              onClick={() => setFieldType("Email")}
              style={{
                height: "48px",
                marginRight: "15px",
                width: "45px",
                marginTop: "7px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
          <CustomePhoneFiled
            error={props.error}
            handleCountryCode={(data) =>
              props.onCountryCodeChange(data.dialCode)
            }
            countryCode={props.countryCode}
            placeholder="Mobile"
            phoneNumber={props.userName}
            name={"phoneNumber"}
            size={"small"}
            handleChange={(e) => props.onChange(e)}
            label={"Mobile"}
            marginLeft={props.marginLeft}
          />
        </Grid>
      </Box>
    </Grid>
  );

  return fieldType === "Email" ? emailFiled() : phoneFiled();
}
