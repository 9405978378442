import React, { useState, useEffect, useRef } from "react";
import { Box, Card, Grid } from "@mui/material";
import SelectTimeRange from "./Component/SelectTimeRange";
import ShowProduct from "./Component/ShowProduct";
import OverAllAccurAcy from "./Component/OverAllAccurAcy";
import HighestBrandAccurAcy from "./Component/HighestBrandAccurAcy";
import LowestBrandAccurAcy from "./Component/LowestBrandAccurAcy";
import TableList from "./Component/TableList";

import AdditionalInformation from "./Component/AdditionalInformation";
import { getOutletList } from "@/services/OutletService";

import {
  getMonitoring,
  getMonitoringCaptures,
} from "@/services/DashboardService";
import { tablistStyle, pieStyle } from "../css/ShelfMonitoringSummary";
import { useTranslation } from "react-i18next";

function ShelfMonitoringFun() {
  const { t } = useTranslation();
  const productImage = useRef();
  const [selectTime, setSelectTime] = useState(""); //选择的时间
  const [totalCaptures, setTotalCaptures] = useState(0);
  const [highestBrand, setHighestBrand] = useState("");
  const [lowestBrand, setLowestBrand] = useState("");
  const [highestBrandAccuracy, setHighestBrandAccuracy] = useState(0);
  const [lowestBrandAccuracy, setLowestBrandAccuracy] = useState(0);
  const [overall, setOverall] = useState(0);
  const [overallAccuracy, setOverallAccuracy] = useState(0);
  const [matchFrequencyDatas, setMatchFrequencyDatas] = useState([]);
  const [storeList, setStoreList] = useState([]); //所有门店数据
  const [storeName, setStoreName] = useState(""); //选中的门店名称
  const [isLoading, setIsLoading] = useState(false);
  const buttonStyles = {
    borderRadius: "35px",
    width: "11.3vw",
    fontWeight: 700,
    whiteSpace: "nowrap",
  };

  useEffect(() => {
    if (storeName.id) {
      loadData();
      loadDataCaptures();
    }
  }, [selectTime, storeName]);

  //获取门店数据
  useEffect(() => {
    let params = {
      pageNumber: 1,
      pageSize: 1,
    };
    getOutletList(params).then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setStoreList(res.data.data?.objects);
        // setStoreName(res?.data?.data[0] || "");
        if (res.data.data?.objects.length > 0) {
          setStoreName(res.data.data?.objects[0]);
        }
      } else {
        setStoreList([]);
      }
    });
  }, []);

  const loadData = () => {
    let params = {
      outletId: storeName.id,
      startDate: selectTime.replace(/\//g, "-"),
      endDate: selectTime.replace(/\//g, "-"),
    };
    setIsLoading(true);
    getMonitoring(params).then((res) => {
      let data = res?.data?.data;
      if (data !== null) {
        setTotalCaptures(data?.totalCaptures);
        setHighestBrand(data?.highestBrand);
        setLowestBrand(data?.lowestBrand);
        setHighestBrandAccuracy(data?.highestBrandAccuracy);
        setLowestBrandAccuracy(data?.lowestBrandAccuracy);
        setOverall(data?.overall);
        setOverallAccuracy(data?.overallAccuracy);
        setMatchFrequencyDatas(data?.matchFrequencyDatas || []);
        setIsLoading(false);
      }
    });
  };

  const [realTimePicture, setRealTimePicture] = useState();
  const [registerPicture, setRegisterPicture] = useState();
  const [captureTime, setCaptureTime] = useState("");
  const [rectList, setRectList] = useState([]);
  const loadDataCaptures = () => {
    let params = {
      outletId: storeName.id,
      startDate: selectTime.replace(/\//g, "-"),
      endDate: selectTime.replace(/\//g, "-"),
    };
    setIsLoading(true);
    getMonitoringCaptures(params).then((res) => {
      setRealTimePicture(res?.data?.data?.realTimePictureUrl);
      setRegisterPicture(res?.data?.data?.registerPictureUrl);
      setCaptureTime(res?.data?.data?.time);
      setRectList(res?.data?.data?.rectList);
      setIsLoading(false);
    });
  };

  const handlerApply = () => {
    loadData();
    loadDataCaptures();
  };

  return (
    <Grid
      sx={{
        padding: "0 0.5rem",
      }}
    >
      <Box fontSize={28}>{t("MENU03")}</Box>
      <Box sx={{ fontSize: "24px", fontWeight: 700, color: "#000" }}>
        {t("PCS02")}
      </Box>

      {/* 时间范围选择 组件 */}
      <SelectTimeRange
        setStoreName={setStoreName}
        storeName={storeName}
        selectTime={selectTime}
        setSelectTime={setSelectTime}
        handlerApply={handlerApply}
        totalCaptures={totalCaptures}
        storeList={storeList}
      ></SelectTimeRange>

      {/* 商品图展示组件 */}

      <Grid display={"flex"} mb={2} sx={pieStyle}>
        <Box mt={2} ml={1} xs={6}>
          <ShowProduct
            ref={productImage}
            realTimePicture={realTimePicture}
            registerPicture={registerPicture}
            captureTime={captureTime}
            rectList={rectList}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          ></ShowProduct>
        </Box>

        <Grid display={"flex"} justifyContent={"space-between"} flex={1}>
          <Box
            sx={{
              background: "#FFF",
              borderRadius: "15px",
              textAlign: "center",
              ml: 2,
              mt: 2,
              width: "31%",
            }}
            xs={4}
          >
            <OverAllAccurAcy
              overallAccuracy={overallAccuracy}
            ></OverAllAccurAcy>

            <Grid
              justifyContent={"center"}
              alignItems={"center"}
              whiteSpace={"nowrap"}
              sx={{
                fontSize: "0.4rem",
                padding: "0 0.8rem",
              }}
            >
              <Box>{t("PCS51")}</Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                marginTop={2}
              >
                <Box
                  sx={{
                    fontSize: "0.7rem",
                  }}
                  mt={-4}
                >
                  {overall}
                </Box>
                <Box
                  sx={{
                    fontSize: "0.6rem",
                  }}
                >
                  /
                </Box>
                <Box
                  sx={{
                    fontSize: "0.6rem",
                  }}
                >
                  {totalCaptures}
                </Box>
              </Box>
            </Grid>
          </Box>

          <Box
            sx={{
              background: "#FFF",
              borderRadius: "15px",
              fontSize: "0.4rem",
              width: "31%",
            }}
            textAlign={"center"}
            mt={2}
            xs={4}
          >
            <HighestBrandAccurAcy
              highestBrandAccuracy={highestBrandAccuracy}
            ></HighestBrandAccurAcy>

            <Grid
              justifyContent={"center"}
              alignItems={"center"}
              whiteSpace={"nowrap"}
            >
              <Box
                sx={{
                  padding: "0 0.8rem",
                }}
              >
                <Box>{t("PCS52")}</Box>
                <Box>{t("PCS53")}</Box>
                <Box
                  fontWeight={700}
                  sx={{
                    fontSize: "0.4rem",
                  }}
                  textAlign={"center"}
                >
                  {highestBrand}
                </Box>
              </Box>
            </Grid>
          </Box>

          <Box
            sx={{
              background: "#FFF",
              borderRadius: "15px",
              fontSize: "0.4rem",
              width: "31%",
            }}
            textAlign={"center"}
            mt={2}
            xs={4}
          >
            <LowestBrandAccurAcy
              lowestBrandAccuracy={lowestBrandAccuracy}
            ></LowestBrandAccurAcy>

            <Grid
              justifyContent={"center"}
              alignItems={"center"}
              whiteSpace={"nowrap"}
            >
              <Box>
                <Box>{t("PCS54")}</Box>
                <Box>{t("PCS53")}</Box>
                <Box
                  fontWeight={700}
                  sx={{
                    fontSize: "0.4rem",
                    padding: "0 0.8rem",
                  }}
                >
                  {lowestBrand}
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* 底部TableList 组件 */}

      <Grid sx={tablistStyle} m={1}>
        <Box variant="contained" sx={buttonStyles} fontSize={26} ml={3} mt={3}>
          {t("PCS42")}
        </Box>

        <Grid display={"flex"} columns={12}>
          <Grid
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
            }}
            size={8}
          >
            <TableList matchFrequencyDatas={matchFrequencyDatas}></TableList>
          </Grid>
          <Grid size={4} ml={4}>
            <AdditionalInformation></AdditionalInformation>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ShelfMonitoringFun;
