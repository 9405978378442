export const headerText = {
  color: "#304669",
  fontWeight: 400,
};

export const buttonStyles = {
  height: "60px",
  borderRadius: "35px",
  width: "11vw",
  fontSize: "0.4rem",
  fontWeight: 700,
  marginTop: "1.5vh",
  position: "relative",
};

export const timeBoxStyle = {
  width: "11vw",
  background: "#FFF",
  boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.4)",
  borderRadius: "10px",
  marginTop: "2vh",
  maxHeight: "30vh",
  overflowY: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "#d1e7c3 transparent",
};

timeBoxStyle["&::-webkit-scrollbar"] = {
  width: "4px",
};

timeBoxStyle["&::-webkit-scrollbar-thumb"] = {
  background: "#d1e7c3",
  borderRadius: "4px",
};

timeBoxStyle["&::-webkit-scrollbar-track"] = {
  background: "transparent",
};

export const singleBoxStyle = {
  width: "7.6vw",
  lineHeight: "1.4rem",
  textAlign: "center",
  margin: "auto",
  borderBottom: "3px solid #e3e3e3",
  cursor: "pointer",
  fontSize: "0.4rem",
};

export const selectMenuStyle = {
  boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.4)",
  background: "#8fbf9f",

  // background: "#005aa7" /* fallback for old browsers */,
  // background: `-webkit-linear-gradient(to right, #005aa7, #fffde4)` /* Chrome 10-25, Safari 5.1-6 */,
  // background: `linear-gradient(to right, #005aa7, #fffde4)` /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,

  borderRadius: "20px",
  color: "#fff",
  transition: "opacity 0.3s ease-in-out",
  zIndex: 11111111111111,
  float: "left",

  position: "absolute",
  textAlign: "center",
  top: "10vh",
};

export const outerBoxStyle = {
  ":hover": {
    background: "#61bc84", // 修改背景颜色
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)", // 增加阴影效果
    color: "#FFF",
    fontWeight: 700,
    borderRadius: "20px",
  },

  ":active": {
    background: "#61bc84", // 修改背景颜色
    transform: "scale(0.95)", // 缩放效果
  },
};

export const getBgStyle = (val) => {
  if (val < 100) {
    return {
      backgroundColor: "rgb(232, 243, 226)",
    };
  }
  if (val < 300) {
    return {
      backgroundColor: "rgb(209, 231, 195)",
    };
  }

  return {
    backgroundColor: "rgb(232, 243, 226)",
  };
};

export const tbodyStyle = {
  marginTop: "20px",
  background: "#FFF",
  boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.4)",
  borderRadius: "10px",
  overflowY: "auto",
  overflowX: "hidden",
  maxHeight: "30vh",
  scrollbarWidth: "thin",
  scrollbarColor: "#d1e7c3 transparent",
};

tbodyStyle["&::-webkit-scrollbar"] = {
  width: "4px",
};

tbodyStyle["&::-webkit-scrollbar-thumb"] = {
  background: "#d1e7c3",
  borderRadius: "4px",
};

tbodyStyle["&::-webkit-scrollbar-track"] = {
  background: "transparent",
};

export const devicesMateDataStyle = {
  maxHeight: "590px",
  overflowY: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "#f4f7f9 transparent",
};

devicesMateDataStyle["&::-webkit-scrollbar"] = {
  width: "4px",
};

devicesMateDataStyle["&::-webkit-scrollbar-thumb"] = {
  background: "#f4f7f9",
  borderRadius: "4px",
};

devicesMateDataStyle["&::-webkit-scrollbar-track"] = {
  background: "transparent",
};

export const totalStyle = {
  width: {
    xs: "4.2vw",
    sm: "4.8vw",
    md: "6vw",
    lg: "6.3vw",
  },
  // overflowX: "auto",
  // scrollbarWidth: "thin",
  // scrollbarColor: "#f4f7f9 transparent",
};

totalStyle["&::-webkit-scrollbar"] = {
  width: "1px",
};

totalStyle["&::-webkit-scrollbar-thumb"] = {
  background: "#f4f7f9",
  borderRadius: "4px",
};

totalStyle["&::-webkit-scrollbar-track"] = {
  background: "transparent",
};
