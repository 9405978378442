/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState, useMemo } from "react";
import * as echarts from "echarts";
import "echarts-liquidfill";
import { useTranslation } from "react-i18next";
const LineCharts = ({ genderData }) => {
  const chartRef = useRef(null);
  const { t } = useTranslation();
  let maleTime = genderData.male?.map((item) => item.date);
  let maleCount = genderData.male?.map((item) => item.count);
  let femaleCount = genderData.female?.map((item) => item.count);
  // let prevFemaleCount = genderData.prevFemale?.map((item) => item.count);
  // let prevMaleCount = genderData.prevMale?.map((item) => item.count);

  const getOptions = () => {
    let option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
          crossStyle: {
            type: "solid",
            // color: "#999",
          },
        },
      },
      toolbox: {},
      legend: {
        data: [t("PCS62"), t("PCS70")],
        bottom: "0",
        itemWidth: 10,
      },
      xAxis: [
        {
          type: "category",
          data: maleTime?.map((item) => item?.replace(/-/g, "/")),
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          //   name: "Precipitation",
          showSymbol: false,
          axisLabel: {
            formatter: "{value}",
          },
        },
      ],
      series: [
        {
          name: t("PCS62"),
          type: "line",
          showSymbol: false,
          symbol: "line", // 将提示标志设置为线，并且更改颜色
          lineStyle: {
            color: "#4a72ba",
            width: 5,
          },
          data: maleCount,
        },
        {
          name: t("PCS70"),
          type: "line",
          showSymbol: false,
          symbol: "line",
          lineStyle: {
            color: "#91cc75",
            width: 5,
          },
          data: femaleCount,
        },
        // {
        //   name: "Male (prev.period)",
        //   type: "line",
        //   showSymbol: false,
        //   symbol: "line",
        //   lineStyle: {
        //     normal: {
        //       color: "#4e5f74",
        //       width: 5,
        //       type: "dashed",
        //     },
        //   },
        //   data: prevMaleCount,
        // },
        // {
        //   name: "Female (prev.period)",
        //   type: "line",
        //   showSymbol: false,
        //   symbol: "line",
        //   lineStyle: {
        //     normal: {
        //       color: "#8f5558",
        //       width: 5,
        //       type: "dashed",
        //     },
        //   },
        //   data: prevFemaleCount,
        // },
      ],
    };
    return option;
  };

  const options = useMemo(() => getOptions(), [genderData]);
  useEffect(() => {
    let myEcharts = null;
    const initChart = () => {
      myEcharts = echarts.init(chartRef.current, null, { renderer: "svg" });
      // 设置初始大小
      myEcharts.resize();
      // 监听窗口大小变化，自动调整图表大小
      window.addEventListener("resize", handleResize);
      // const options = getOptions();
      myEcharts.setOption(options);
    };
    const handleResize = () => {
      myEcharts.resize();
    };
    // 在组件挂载时进行初始化
    initChart();
    // 在组件卸载时移除事件监听
    return () => {
      window.removeEventListener("resize", handleResize);
      myEcharts.dispose();
    };
  }, [genderData]);
  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        height: "400px",
        minWidth: "40vw",
      }}
    ></div>
  );
};

export default LineCharts;
