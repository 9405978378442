import api from "../configurations/http-common";

// export const getProductList = async (payload) => {
//   let list = localStorage.getItem("productList");
//   if (!list) {
//     list = [];
//   } else {
//     list = JSON.parse(list);
//   }
//   if (list.length < 1) {
//     for (let i = 0; i < 10; i++) {
//       list.push({
//         id: new Date().getTime() + i,
//         name: "Coca-cola" + i,
//         barCode: Math.ceil(Math.random() * 4000),
//         categoryLevel1: "Cloud Drink1" + i,
//         categoryLevel2: "Category2" + i,
//       });
//     }
//   }

//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({
//         list: list,
//         totalCount: 150,
//       });
//     }, 1000);
//   });
// };

// export const bindDevice = async (payload) => {
//   return api.securedAxios().put("/web/device", payload);
// };

// export const addDevice = async (payload) => {
//   return api.securedAxios().put("/web/add_device", payload);
// };

// export const findDeviceBySn = async (sn) => {
//   return api.securedAxios().get(`/web/devices/pre_register/${sn}`);
// };

// export const unBindDevice = async (deviceId) => {
//   let url = `/web/delete_device/${deviceId}`;
//   return api.securedAxios().delete(url);
// };

export const getProductList = async (payload) => {
  return api.securedAxios().get("/web/product", {
    params: payload,
  });
};

export const addProductList = async (payload) => {
  return api.securedAxios().post("/web/product", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteProduct = async (deviceId) => {
  let url = `/web/product/${deviceId}`;
  return api.securedAxios().delete(url);
};

export const updataProductList = (payload) => {
  return api.securedAxios().put("/web/product", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getProductPhoto = (payload) => {
  return api.securedAxios().get("/web/productPhoto", {
    params: payload,
  });
};
