/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import "echarts-liquidfill";
import { useTranslation } from "react-i18next";
const HighestBrandAccurAcy = ({ highestBrandAccuracy }) => {
  const chartRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    let myEcharts = null;
    const initChart = () => {
      myEcharts = echarts.init(chartRef.current, null, { renderer: "svg" });
      // 设置初始大小
      myEcharts.resize();
      // 监听窗口大小变化，自动调整图表大小
      window.addEventListener("resize", handleResize);
      const options = getOptions();
      myEcharts.setOption(options);
      updateChart();
    };

    const updateChart = () => {
      const options = getOptions(highestBrandAccuracy);
      myEcharts.setOption(options);
    };
    const handleResize = () => {
      myEcharts.resize();
    };
    // 在组件挂载时进行初始化
    initChart();

    // 在组件卸载时移除事件监听
    return () => {
      window.removeEventListener("resize", handleResize);
      myEcharts.dispose();
    };
  }, [highestBrandAccuracy]);
  var colorList = ["#5ecb51", "#dcfde7"];
  const getOptions = (data) => {
    let option = {
      title: {
        text: data + "%",
        x: "center",
        y: "center",
        textStyle: {
          fontSize: 28,
          color: "#2A3A77",
        },
      },
      tooltip: {
        trigger: "item",
        show: false,
      },
      series: [
        {
          type: "pie",
          center: ["50%", "50%"],
          radius: ["70%", "95%"],
          clockwise: false,
          avoidLabelOverlap: false,
          hoverOffset: 0,
          itemStyle: {
            color: function (params) {
              return colorList[params.dataIndex];
            },
          },
          label: {
            show: false,
          },
          labelLine: {
            length: 20,
            length2: 30,
            lineStyle: {
              width: 1,
            },
          },
          data: [
            {
              name: "一月",
              value: data,
            },
          ],
        },
      ],
    };

    return option;
  };
  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        height: "200px",
      }}
    ></div>
  );
};

export default HighestBrandAccurAcy;
