import { Box, Grid, Paper, Button, Typography } from "@mui/material";
import { useState } from "react";
import { ReactComponent as IconIcafeFd } from "@/assets/dashboard/ExpandArrow.svg";
import { ReactComponent as IconIcafe } from "@/assets/dashboard/CollapseArrow.svg";

import { ReactComponent as Icon1 } from "@/assets/dashboard/Icon1.svg";
import { ReactComponent as Icon2 } from "@/assets/dashboard/Icon2.svg";
import { ReactComponent as Icon3 } from "@/assets/dashboard/Icon3.svg";
import { ReactComponent as Icon4 } from "@/assets/dashboard/Icon4.svg";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Space } from "antd";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormatPreValue from "./FormatPreValue";
import "./visitor.css";
import { getValue, preRange, preLastYear } from "./utils";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
export default function VisitorDemographic() {
  const columns = [
    {
      field: "time",
      headerName: "Time",
    },
    {
      field: "f0",
      headerName: "F0",
      age: "Age 0 -11",
    },
    {
      field: "f1",
      headerName: "F1",
      age: "Age 12 - 17",
    },
    {
      field: "f2",
      headerName: "F2",
      age: "Age 18 -34",
    },
    {
      field: "f3",
      headerName: "F3",
      age: "Age 35 -54",
    },
    {
      field: "f4",
      headerName: "F4",
      age: "Age 55+",
    },

    {
      field: "m0",
      headerName: "M0",
      age: "Age 0 -11",
    },
    {
      field: "m1",
      headerName: "M1",
      age: "Age 12 - 17",
    },
    {
      field: "m2",
      headerName: "M2",
      age: "Age 18 -34",
    },
    {
      field: "m3",
      headerName: "M3",
      age: "Age 35 -54",
    },
    {
      field: "m4",
      headerName: "M4",
      age: "Age 55+",
    },
    {
      field: "total",
      headerName: "Total Visitors",
    },
  ];

  const [compareType, setCompareType] = useState("none");
  const [expendRow, setExpendRow] = useState("");
  const [rangeValue, setRangeValue] = useState([]);

  const [preRangeValue, setPreRangeValue] = useState([]);

  const data = [
    {
      time: "10:00 - 11:59",
      f0: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f1: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f2: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f3: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f4: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m0: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m1: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m2: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m3: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m4: { value: getValue(), preValue: getValue(), change: getValue(true) },
    },
    {
      time: "12:00 - 13:59",
      f0: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f1: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f2: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f3: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f4: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m0: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m1: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m2: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m3: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m4: { value: getValue(), preValue: getValue(), change: getValue(true) },
    },
    {
      time: "14:00 - 15:59",
      f0: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f1: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f2: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f3: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f4: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m0: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m1: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m2: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m3: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m4: { value: getValue(), preValue: getValue(), change: getValue(true) },
    },
    {
      time: "16:00 - 17:59",
      f0: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f1: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f2: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f3: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f4: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m0: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m1: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m2: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m3: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m4: { value: getValue(), preValue: getValue(), change: getValue(true) },
    },
    {
      time: "18:00 - 19:59",
      f0: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f1: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f2: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f3: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f4: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m0: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m1: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m2: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m3: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m4: { value: getValue(), preValue: getValue(), change: getValue(true) },
    },
    {
      time: "20:00 - 21:59",
      f0: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f1: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f2: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f3: { value: getValue(), preValue: getValue(), change: getValue(true) },
      f4: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m0: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m1: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m2: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m3: { value: getValue(), preValue: getValue(), change: getValue(true) },
      m4: { value: getValue(), preValue: getValue(), change: getValue(true) },
    },
  ];

  const categoryDate = [
    {
      type: "Category",
      f0: "F0",
      f1: "F1",
      f2: "F2",
      f3: "F3",
      f4: "F4",
      m0: "M0",
      m1: "M1",
      m2: "M2",
      m3: "M3",
      m4: "M4",
    },
    {
      type: "Gender",
      f0: "Girl",
      f1: "Female",
      f2: "Female",
      f3: "Female",
      f4: "Female",
      m0: "Boy",
      m1: "Male",
      m2: "Male",
      m3: "Male",
      m4: "Male",
    },
    {
      type: "Age",
      f0: "0-11",
      f1: "12-17",
      f2: "18-34",
      f3: "35-54",
      f4: "55+",
      m0: "0-11",
      m1: "12-17",
      m2: "18-34",
      m3: "35-54",
      m4: "55+",
    },
  ];

  const clickExpend = (key) => {
    if (expendRow === key) {
      setExpendRow("");
    } else {
      setExpendRow(key);
    }
  };

  let cellStyle = {
    display: "flex",
  };

  let valueExpendStyle = {
    flexGrow: 1,
    textAlign: "center",
    borderRight: "1px solid #ffffff",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  };

  let valueCloseStyle = {
    flexGrow: 1,
    textAlign: "center",
    width: "50%",
    alignItems: "center",
  };

  let getBgStyle = (val) => {
    if (val < 10) {
      return {
        backgroundColor: " rgb( 211,228,245)",
      };
    }
    if (val < 30) {
      return {
        backgroundColor: "rgb(161,207,236)",
      };
    }
    return {
      backgroundColor: "rgb(114,183,223)",
    };
  };

  let headerText = {
    color: "#304669",
    fontWeight: 400,
  };

  let expenBtn = {
    cursor: "pointer",
  };

  const handleChange = (e) => {
    let value = e.target.value;
    setCompareType(value);
    if (value === "none") {
      setPreRangeValue([]);
    } else if (value === "previous") {
      setPreRangeValue(preRange(rangeValue));
    } else if (value === "LastYear") {
      setPreRangeValue(preLastYear(rangeValue));
    }
  };

  const RangePickerChange = (e) => {
    setRangeValue(e);
    if (e.length === 2) {
      if (compareType === "none") {
        setPreRangeValue([]);
      } else if (compareType === "previous") {
        setPreRangeValue(preRange(e));
      } else if (compareType === "LastYear") {
        setPreRangeValue(preLastYear(e));
      }
    }
  };

  let visitorsArray = [
    {
      title: "12:00-13:59",
      subTitle: "Highest Visitors by Time",
      icon: <Icon1 height={40} width={40}></Icon1>,
      bgColor: "#ffe2e5",
    },
    {
      title: "10:00 -11:59",
      subTitle: "Least Visitors by Time",
      icon: <Icon2 height={40} width={40}></Icon2>,
      bgColor: "#fff4de",
    },
    {
      title: "Male(Age 35-54)",
      subTitle: "Highest Visitors by Demographic",
      icon: <Icon3 height={40} width={40}></Icon3>,
      bgColor: "#dcfce7",
    },
    {
      title: "Kids (Age 0-17)",
      subTitle: "Least Visitors by Demographic",
      icon: <Icon4 height={40} width={40}></Icon4>,
      bgColor: "#f3e8ff",
    },
  ];

  let comparedPeriodArray = [
    {
      title: "12:00-13:59",
      subTitle: "Biggest Increase by Time",
      icon: <Icon1 height={40} width={40}></Icon1>,
      bgColor: "#ffe2e5",
    },
    {
      title: "18:00 -19:59",
      subTitle: "Biggest Decrease by Time",
      icon: <Icon2 height={40} width={40}></Icon2>,
      bgColor: "#fff4de",
    },
    {
      title: "Female(Age 18-34)",
      subTitle: "Biggest Increase by Demographic",
      icon: <Icon3 height={40} width={40}></Icon3>,
      bgColor: "#dcfce7",
    },
    {
      title: "Female(Age 54+)",
      subTitle: "Biggest Decrease by Demographic",
      icon: <Icon4 height={40} width={40}></Icon4>,
      bgColor: "#f3e8ff",
    },
  ];

  let boxCla = {
    height: "100px",
    borderRadius: "10px",
  };

  return (
    <Box p={2} component={Paper}>
      <Grid m={1}>
        <Typography
          style={{
            fontWeight: 400,
          }}
          variant="title"
        >
          Visitor Demographic Distribution
        </Typography>
      </Grid>
      <Grid
        m={1}
        sx={{
          display: "flex",
          mt: 2,
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography sx={{ mr: 2 }}>Date Range</Typography>
          <RangePicker
            value={rangeValue}
            onChange={(e) => {
              RangePickerChange(e);
            }}
            format="DD/MM/YYYY"
          />
        </Grid>

        <Grid
          ml={2}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography sx={{ mr: 2 }}>Compare with</Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={compareType}
            label=""
            sx={{
              p: 0,
            }}
            onChange={handleChange}
          >
            <MenuItem value={"none"}>None</MenuItem>
            <MenuItem value={"previous"}>Previous Period</MenuItem>
            <MenuItem value={"LastYear"}>Last Year</MenuItem>
          </Select>
        </Grid>

        {
          (compareType !== "none",
          (
            <Grid ml={2}>
              <RangePicker
                value={preRangeValue}
                disabled={true}
                format="DD/MM/YYYY"
              />
            </Grid>
          ))
        }
      </Grid>

      <Grid
        mt={3}
        ml={1}
        mr={1}
        style={{
          backgroundColor: "#80808030",
        }}
        container
      >
        <table style={{ width: "100%", borderColor: "#ffffff" }} border="1px">
          <thead>
            <tr>
              <th colspan={columns.length}>
                <div style={headerText}>Demographic</div>
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              {columns.map((header) => {
                if (compareType === "none") {
                  return (
                    <th style={headerText} key={header.headerName}>
                      <div>{header.headerName}</div>
                      <div>{header.age}</div>
                    </th>
                  );
                } else {
                  if (expendRow === header.field) {
                    return (
                      <th style={{ height: "80px" }} key={header.headerName}>
                        <div
                          style={{
                            ...cellStyle,
                            height: "30px",
                            justifyContent: "center",
                            alignItems: "center",
                            ...headerText,
                          }}
                        >
                          <div>{header.headerName}</div>
                          <div>
                            {expendRow === header.field ? (
                              <IconIcafe
                                onClick={() => {
                                  clickExpend(header.field);
                                }}
                                style={expenBtn}
                                height={6}
                                width={18}
                              />
                            ) : (
                              <IconIcafeFd
                                onClick={() => {
                                  clickExpend(header.field);
                                }}
                                style={expenBtn}
                                height={6}
                                width={18}
                              />
                            )}
                          </div>
                        </div>
                        <div style={{ ...headerText, height: "30px" }}>
                          {header.age}
                        </div>
                        <div
                          style={{
                            ...cellStyle,
                            borderTop: "1px solid rgb(255, 255, 255)",
                            height: "40px",
                            justifyContent: "center",
                            alignItems: "center",
                            ...headerText,
                          }}
                        >
                          <div
                            style={{
                              ...valueExpendStyle,
                              width: "40%",
                            }}
                          >
                            Select Date Range
                          </div>
                          <div
                            style={{
                              ...valueExpendStyle,
                              width: "30%",
                            }}
                          >
                            Previous Period
                          </div>
                          <div
                            style={{
                              ...valueExpendStyle,
                              width: "30%",
                            }}
                          >
                            Change %
                          </div>
                        </div>
                      </th>
                    );
                  } else {
                    return (
                      <th key={header.headerName}>
                        <div style={{ height: "80px" }}>
                          <div
                            style={{
                              ...cellStyle,
                              justifyContent: "center",
                              alignItems: "center",
                              ...headerText,
                              height: "40px",
                              lineHeight: "40px",
                            }}
                          >
                            <div>{header.headerName}</div>
                            {header.field !== "time" &&
                              header.field !== "total" && (
                                <div>
                                  {expendRow === header.field ? (
                                    <IconIcafe
                                      onClick={() => {
                                        clickExpend(header.field);
                                      }}
                                      style={expenBtn}
                                      height={6}
                                      width={18}
                                    />
                                  ) : (
                                    <IconIcafeFd
                                      style={expenBtn}
                                      onClick={() => {
                                        clickExpend(header.field);
                                      }}
                                      height={6}
                                      width={18}
                                    />
                                  )}
                                </div>
                              )}
                          </div>

                          <div style={{ ...headerText, fontSize: "12px" }}>
                            {header.age}
                          </div>
                        </div>
                      </th>
                    );
                  }
                }
              })}
            </tr>
          </thead>

          <tbody>
            {data.map((row, index) => {
              return (
                <tr key={index}>
                  {columns.map((header) => {
                    if (header.field === "total") {
                      return (
                        <td
                          style={{ backgroundColor: "#ffffff" }}
                          key={header.field}
                        >
                          <div
                            style={{
                              display: "flex",
                              height: "100%",
                            }}
                          >
                            <div style={{ width: "50%" }}>
                              {getValue() * 100}
                            </div>
                            <div style={{ width: "50%" }}>
                              <FormatPreValue
                                value={getValue(true)}
                              ></FormatPreValue>
                            </div>
                          </div>
                        </td>
                      );
                    } else {
                      let col = row[header.field];
                      if (header.field === "time") {
                        return (
                          <td
                            style={{ backgroundColor: "#ffffff" }}
                            key={header.field}
                          >
                            {col}
                          </td>
                        );
                      } else {
                        if (compareType === "none") {
                          return (
                            <td
                              style={{
                                height: "100%",
                                ...getBgStyle(col.value),
                              }}
                              key={header.field}
                            >
                              <div>{col.value}%</div>
                            </td>
                          );
                        } else {
                          if (header.field === expendRow) {
                            return (
                              <td key={header.field}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    height: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      ...valueExpendStyle,
                                      width: "40%",
                                      ...getBgStyle(col.value),
                                    }}
                                  >
                                    {col.value}%
                                  </div>
                                  <div
                                    style={{
                                      ...valueExpendStyle,
                                      width: "30%",
                                      ...getBgStyle(col.preValue),
                                    }}
                                  >
                                    {col.preValue}%
                                  </div>
                                  <div
                                    style={{
                                      ...valueExpendStyle,
                                      width: "30%",
                                      ...getBgStyle(col.change),
                                    }}
                                  >
                                    {col.change}%
                                  </div>
                                </div>
                              </td>
                            );
                          } else {
                            return (
                              <td key={header.field}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    height: "100%",
                                    alignItems: "center",
                                    ...getBgStyle(col.value),
                                  }}
                                >
                                  <div style={valueCloseStyle}>
                                    {col.value}%
                                  </div>
                                  <div style={valueCloseStyle}>
                                    <FormatPreValue
                                      value={col.preValue}
                                    ></FormatPreValue>
                                  </div>
                                </div>
                              </td>
                            );
                          }
                        }
                      }
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Grid>

      <Box p={2}>
        <Grid mt={1} container>
          {visitorsArray.map((item) => {
            return (
              <Grid p={1} item xs={3}>
                <Grid
                  p={1}
                  style={{
                    ...boxCla,
                    backgroundColor: item.bgColor,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: "16px",
                      }}
                      variant="title"
                    >
                      {item.title}
                    </Typography>
                    <Box>{item.icon}</Box>
                  </Box>
                  <Box mt={2}>
                    <Typography
                      style={{
                        color: "#474B4F",
                      }}
                    >
                      {item.subTitle}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Grid mt={2} ml={2}>
          Compared to Previous Period
        </Grid>
        <Grid container>
          {comparedPeriodArray.map((item) => {
            return (
              <Grid p={1} item xs={3}>
                <Grid
                  p={1}
                  style={{
                    ...boxCla,
                    backgroundColor: item.bgColor,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: "16px",
                      }}
                      variant="title"
                    >
                      {item.title}
                    </Typography>
                    <Box>{item.icon}</Box>
                  </Box>
                  <Box mt={2}>
                    <Typography
                      style={{
                        color: "#474B4F",
                      }}
                    >
                      {item.subTitle}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Box
        component={Paper}
        sx={{
          ml: 3,
          mr: 3,
          mt: 2,
          backgroundColor: "#f7fbfe",
        }}
      >
        <Box m={2}>
          <Typography
            style={{
              fontWeight: 400,
            }}
            variant="title"
          >
            Additional Information
          </Typography>
        </Box>
        <Box mt={1}>
          <Button variant="contained" sx={{ marginLeft: 1 }}>
            Demographic Category
          </Button>

          <Button ml={2} variant="outlined" sx={{ marginLeft: 1 }}>
            Calculation
          </Button>
        </Box>
        <Box m={2} pb={2}>
          <table
            style={{
              width: "100%",
              borderColor: "#f7fbfe",
              backgroundColor: "#ffffff",
            }}
            border="1px"
          >
            <tbody>
              {categoryDate.map((item) => {
                let keys = Object.keys(item);
                return (
                  <tr>
                    {keys.map((key, index) => {
                      return (
                        <td
                          style={{
                            backgroundColor:
                              index === 0 ? "#ececed" : "#ffffff",
                          }}
                        >
                          {item[key]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
}
