import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  Grid,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CustomDatePicker from "../../PeopleCountingSummary/Component/StartTimeSelect";
import { isToday } from "@/util/utils.js";
import CommonUtil from "@/util/CommonUtils";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
const DialogTime = ({ openTime, setOpenTime, setSelectTime }) => {
  const { t } = useTranslation();
  const [time, setTime] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const startChangeDate = (e) => {
    if (isToday(e)) {
      enqueueSnackbar(t("PCS36"), {
        variant: "warning",
      });
      return;
    }
    let currentStr = CommonUtil.dateFormat(e, "yyyy/MM/dd");
    setTime(currentStr);
  };
  const handklerConfirm = () => {
    setSelectTime(time);
    setOpenTime(false);
  };

  return (
    <React.Fragment>
      <Dialog open={openTime} onClose={() => setOpenTime(false)}>
        <DialogTitle fontSize={24}>{t("PCS25")}</DialogTitle>
        <DialogContent>
          <Grid display={"flex"}>
            <Box sx={{ width: "200px", marginRight: "30px" }}>
              <CustomDatePicker
                size={"small"}
                disableFuture={true}
                // date={date}
                disabled={false}
                fullWidth={true}
                type={1}
                label={""}
                placeholder={t("LVLRCP016")}
                SelectedDate={(e) => startChangeDate(e)}
              />
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTime(false)}>{t("LVLDB0012")}</Button>
          <Button autoFocus onClick={handklerConfirm}>
            {t("LVLDV0096")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DialogTime;
