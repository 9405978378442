import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tooltip, Grid, Box, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import IconHandaler from "../../components/IconHandaler";
import RingLoader from "react-spinners/RingLoader";
import ViewPhoto from "./ViewPhoto";
import { ReactComponent as AddIcon } from "@/assets/images/icon_add.svg";
import { ReactComponent as RefreshIcon } from "@/assets/images/icon_refresh.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/ConfirmModel";
import ListLayout from "../../components/ListLayout";
import { REACT_PRODUCT_Add } from "@/router/ReactEndPoints";
import {
  getProductList,
  deleteProduct,
  getProductPhoto,
} from "../../services/ProductService";
import { REACT_PRODUCT_EDIT } from "@/router/ReactEndPoints";
import CommonUtil from "../../util/CommonUtils";
import AccessControl from "@/components/AccessControl";

export default function ProductList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0); //数据列表总数
  const [open, setOpen] = useState(false);
  const [productImage, setProductImage] = useState(null);
  const defaultFilters = {
    pageNumber: 1,
    pageSize: 10,
  };

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const override = {
    display: "block",
    margin: "10% auto",
    borderColor: "#b37feb",
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      pageNumber: e + 1,
    });
  };

  const handlePageSize = (e) => {
    setFilters({
      ...defaultFilters,
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  const loadData = () => {
    setIsLoading(true);
    getProductList(filters)
      .then((res) => {
        // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", res);
        setRecords(res.data?.data?.products);
        setTotalRecords(res.data?.data?.totalCount);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getRowId = (data) => data.id;

  const handleActions = (tag, e) => {
    if (tag === "Delete") {
      handleDelete(e);
    } else if (tag === "Edit") {
      navigate(REACT_PRODUCT_EDIT + e.id, { state: e.row });
    } else if (tag === "ViewPhoto") {
      setOpen(true);

      let params = {
        pageNumber: 1,
        pageSize: 10,
        productId: e.row.id,
        outletId: e.row.outletId,
      };
      getProductPhoto(params).then((res) => {
        setProductImage(res?.data?.data?.objects[0]?.photoUrl);
      });
    }
  };

  const refreshClick = () => {
    loadData();
  };

  const columns = [
    {
      field: "id",
      headerName: t("RTPD0005"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.id} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.id)}</span>
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: t("RTPD0004"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.productName} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.productName)}</span>
        </Tooltip>
      ),
    },
    {
      field: "barCode",
      headerName: t("RTPD0006"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.barCode} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.barCode)}</span>
        </Tooltip>
      ),
    },
    // {
    //   field: "productPhoto",
    //   headerName: t("RTPD00014"),
    //   flex: 1,
    //   sortable: false,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (e) => {
    //     return (
    //       <Avatar
    //         sx={{
    //           width: 40,
    //           height: 40,
    //           cursor: "pointer", // 添加指针样式，表示可点击
    //         }}
    //         alt={t("RTPD00014")}
    //         src={""}
    //         variant="square"
    //         // onClick={() => handleImageClick("")} // 点击事件调用放大函数
    //       />
    //     );
    //   },
    // },
    {
      field: "CategoryLevel1",
      headerName: t("LVLDV0075"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.categoryLevel1} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.categoryLevel1)}</span>
        </Tooltip>
      ),
    },
    {
      field: "categoryLevel2",
      headerName: t("LVLDV0076"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (e) => (
        <Tooltip title={e.row.categoryLevel2} arrow placement="bottom">
          <span>{CommonUtil.formatLongText(e.row.categoryLevel2)}</span>
        </Tooltip>
      ),
    },

    {
      headerName: t("LVLRC0010"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1.5,
      renderCell: (e) => (
        <IconHandaler>
          <AccessControl authorization={"1030"}>
            <Tooltip title={t("LVLDB0021")} arrow sx={{ pl: 1 }}>
              <EditIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "18px",
                }}
                onClick={() => handleActions("Edit", e)}
              />
            </Tooltip>
          </AccessControl>

          <AccessControl authorization={"1031"}>
            <Tooltip title={t("LVLDB0022")} arrow sx={{ marginLeft: 1 }}>
              <DeleteIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  fontSize: "18px",
                }}
                onClick={() => handleActions("Delete", e)}
              />
            </Tooltip>
          </AccessControl>

          <AccessControl authorization={true}>
            <Tooltip title={t("LVLDB0040")} arrow sx={{ pl: 1 }}>
              <VisibilityIcon
                style={{
                  alignSelf: "center",
                  paddingTop: "0px",
                  paddingLeft: "5px",
                  opacity: "1",
                  fontSize: "18px",
                }}
                onClick={() => handleActions("ViewPhoto", e)}
              />
            </Tooltip>
          </AccessControl>
        </IconHandaler>
      ),
    },
  ];

  const toAdd = () => {
    navigate(REACT_PRODUCT_Add);
  };

  const handleDelete = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            title={t("PLACE09")}
            open={true}
            text={t("PLACE010")}
            onConfirm={() => {
              deleteProduct(e.id) // 调用接口的删除函数，传入要删除的产品ID
                .then((res) => {
                  if (res?.data?.code === "LVLI0002") {
                    // message.success(res.data.message);
                    message.success(t("PLACE018"));
                    loadData();
                  }
                  if (res?.data?.code === "LVLE0054") {
                    message.warn(res.data.message);
                    loadData();
                  }
                  if (res?.data?.code === "LVLE0073") {
                    message.warn(res.data.message);
                    loadData();
                  }
                })
                .catch((error) => {
                  // 处理删除失败的情况，例如显示错误消息
                  console.error("Error deleting product: ", error);
                })
                .finally(() => {
                  onClose(); // 关闭确认对话框
                });
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  return (
    <>
      <ListLayout navigateBack={false} title={t("RTPD0001")}>
        <Grid
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
          justifyContent={"flex-end"}
          container
        >
          <Box
            display={"flex"}
            width={"100%"}
            flexDirection={"row-reverse"}
            my={2}
          >
            <AccessControl authorization={"1029"}>
              <Box
                item
                pr={2}
                onClick={() => {
                  toAdd();
                }}
              >
                <AddIcon width={"35"} height={"35"} className="pointer" />
              </Box>
            </AccessControl>
            <Box item pr={2}>
              <RefreshIcon
                width={"35"}
                height={"35"}
                className="pointer"
                onClick={refreshClick}
              />
            </Box>
          </Box>
        </Grid>
        <Grid>
          {isLoading ? (
            <RingLoader
              color={"#597ef7"}
              loading={isLoading}
              cssOverride={override}
              size={60}
              speedMultiplier={3}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <DataTable
              height="auto"
              columns={columns}
              rows={records || []}
              getRowId={getRowId}
              page={filters.pageNumber - 1}
              totalRecords={totalRecords}
              rowsPerPage={filters.pageSize}
              onSelection={() => console.log()}
              onPageChange={(pn) => handlePageChange(pn)}
              onPageSizeChange={(ps) => handlePageSize(ps)}
              checkboxSelection={false}
            />
          )}
        </Grid>

        <ViewPhoto
          open={open}
          setOpen={setOpen}
          productImage={productImage}
        ></ViewPhoto>
      </ListLayout>
    </>
  );
}
