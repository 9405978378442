import * as React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import MinervaIoT from "@/assets/images/logo_miot.svg";

import { useTranslation } from "react-i18next";

const MinervaIoTImg = styled("img")({
  height: " 8px",
});

export default function Footer(props) {
  const { t } = useTranslation();
  return (
    <Box display={"flex"} minWidth="100vw" justifyContent={"center"}>
      Copyright @ 2023 ZKDIGIMAX CO.LTD A rghts reserved
    </Box>
  );
}
