import dayjs from "dayjs";

export const dateFormat = (date) => {
  var year = date.getFullYear();
  var month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return day + "/" + month + "/" + year;
};

export const getValue = (fu) => {
  if (fu) {
    let v1 = Math.random();
    if (v1 < 0.5) {
      return Math.round(Math.random() * (90 - 1) - 1);
    } else {
      return Math.round(Math.random() * (90 - 1) - 1) * -1;
    }
  } else {
    return Math.round(Math.random() * (90 - 1) - 1);
  }
};

export const gainAllDateBetRange = (e) => {
  if (e && e.length === 2) {
    let dateArr = [];
    let startTime = e[0].valueOf(); // 获取开始日期的毫秒数
    let endTime = e[1].valueOf(); // 获取结束日期的毫秒数
    let oneTime = 24 * 60 * 60 * 1000; // 一天的毫秒数
    for (let time = startTime; time <= endTime; ) {
      dateArr.push(dateFormat(new Date(time))); // 将格式化后的时间存入结果数组
      time += oneTime; // 每次加一天
    }
    return dateArr;
  } else {
    return [];
  }
};

export const setOption = (options, data) => {
  if (data.length > 20) {
    options.chart = {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        autoSelected: "zoom",
      },
    };
  } else {
    options.chart = {
      toolbar: {
        show: false,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        autoSelected: "zoom",
      },
    };
  }
  options.legend = {
    itemMargin: {
      horizontal: 10,
      vertical: 15,
    },
    markers: {
      width: 14,
      height: 14,
      strokeWidth: 0,
      strokeColor: "#fff",
      fillColors: undefined,
      radius: 4,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0,
    },
  };
};

export const preRange = (e) => {
  if (e && e.length === 2) {
    let startDate = e[0];
    let endDate = e[1];
    startDate = new Date(
      startDate.year(),
      startDate.month() - 1,
      startDate.date()
    );
    endDate = new Date(endDate.year(), endDate.month() - 1, endDate.date());
    let start = dayjs(startDate);
    let end = dayjs(endDate);
    return [start, end];
  } else {
    return [];
  }
};

export const preLastYear = (e) => {
  if (e && e.length === 2) {
    let startDate = e[0];
    let endDate = e[1];
    startDate = new Date(
      startDate.year() - 1,
      startDate.month(),
      startDate.date()
    );
    endDate = new Date(endDate.year() - 1, endDate.month(), endDate.date());
    let start = dayjs(startDate);
    let end = dayjs(endDate);
    return [start, end];
  } else {
    return [];
  }
};

// const data = [
//   {
//     time: "10:00 - 11:59",
//     f0: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f1: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f2: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f3: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f4: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m0: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m1: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m2: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m3: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m4: { value: getValue(), preValue: getValue(), change: getValue(true) },
//   },
//   {
//     time: "12:00 - 13:59",
//     f0: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f1: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f2: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f3: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f4: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m0: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m1: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m2: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m3: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m4: { value: getValue(), preValue: getValue(), change: getValue(true) },
//   },
//   {
//     time: "14:00 - 15:59",
//     f0: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f1: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f2: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f3: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f4: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m0: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m1: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m2: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m3: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m4: { value: getValue(), preValue: getValue(), change: getValue(true) },
//   },
//   {
//     time: "16:00 - 17:59",
//     f0: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f1: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f2: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f3: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     f4: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m0: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m1: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m2: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m3: { value: getValue(), preValue: getValue(), change: getValue(true) },
//     m4: { value: getValue(), preValue: getValue(), change: getValue(true) },
//   },
// ];
